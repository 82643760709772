import { ScriptDefinition } from '@fry/lib/utils/script-loader.service';

export const StripeConnectMode = {
    direct: 'direct',
} as const;
export type StripeConnectMode =
    typeof StripeConnectMode[keyof typeof StripeConnectMode];


export const PaymentGatewayVendor = {
    stripe: 'stripe',
    moneris: 'moneris',
} as const;
export type PaymentGatewayVendor =
    typeof PaymentGatewayVendor[keyof typeof PaymentGatewayVendor]

export interface IntegrationConfiguration {
    /**
     * Identification of the payment gateway
     */
    readonly vendor: PaymentGatewayVendor;

    /**
     *  Key to authenticate with the payment gateway
     */
    readonly api_key: string;

    /**
     * Description of the script/SDK to dynamically load for the vendor
     */
    readonly script: ScriptDefinition;

    readonly browser: { windowObject: string };

    /**
     * Environment identification eg. test/production
     *
     * The value must be valid Vendor SDK value as it's passed directly to the
     * vendor API!
     */
    readonly environment: string;

    // Stripe specific
    readonly stripeConnectMode?: StripeConnectMode;
    readonly stripeConnectConnectedAccountID?: string;
}
