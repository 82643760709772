import { Component, Optional } from '@angular/core';

import { ListService, ListState } from '@fry/lib/list';
import { ExportListService } from './export-list/export-list.service';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'eas-simple-table',
  templateUrl: './simpletable.component.html',
  styleUrls: ['./list.component.css'],
})
export class SimpleTableComponent {

  public state: ListState;

  public supportExport = false;

  public exportInProgress$: Observable<boolean>;

  public pageSizeControl = new UntypedFormControl(10);
  public pageSizeOptions = [
    { value: 10, label: $localize `10 bookings per page`},
    { value: 20, label: $localize `20 bookings per page`},
    { value: 50, label: $localize `50 bookings per page`},
    { value: 100, label: $localize `100 bookings per page`},
  ];


  constructor(
    public list: ListService,
    @Optional() exportService: ExportListService
  ) {
    if (exportService) {
      this.supportExport = true;
    }

    this.list.state.subscribe(state => {
      this.state = state;
    });

    this.pageSizeControl.valueChanges.subscribe(val => {
      this.setPageSize(val);
    })
  }

  //
  // Template Helpers
  //

  public isLoadedWithItems(): boolean {
    return this.state.type === 'full';
  }

  public isLoading(): boolean {
    return this.state.type === 'loading';
  }

  //
  // Actions
  //

  public onReload(): boolean {
    return this.doReload();
  }

  public setPageSize(val: number): boolean {
    this.list.setPageSize(val);
    return this.doReload();
  }

  private doReload(): boolean {
    if (this.isLoading()) { return false; }

    this.list.reload().subscribe(
      () => {},
      () => {
        // We swallow errors because we handle errors for List using ListState,
        // see above. We need to subscribe to reload here though.
      }
    );

    return true;
  }
}
