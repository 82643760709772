import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'roles.view', name: $localize `View roles`, description: $localize `Allow user to view roles list` },
  { id: 'roles.edit', name: $localize `Edit roles`, description: $localize `Allow user to create and edit roles` },
  { id: 'roles.delete', name: $localize `Delete roles`, description: $localize `Allow user to delete roles` },
  // { id: 'roles.assign', name: $localize `Assign roles`, description: $localize `Allow user to assign roles to other people` },
  // { id: 'roles.assign.own', name: $localize `Assign own roles`, description: $localize `Allow user to assign roles to themselves` }
];

export const rolesPermissions: PermissionGroup = {
  id: 'roles',
  name: $localize `Roles`,
  description: '',
  permissions
};
