import { Injectable } from '@angular/core';
import { FBFieldEvaluator } from '@fry/lib/forms';
import { EasElement, EasFieldGroup } from '../easform/easform.interfaces';
import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EasBuilderField, EasBuilderGroup } from './builder.interfaces';
import { BuilderEvaluatorService } from './evaluators.service';
import { intersect } from '@fry/lib/utils';

/** Form service builds an EAS Form from a Form JSON (Builder Form) */
@Injectable({
  providedIn: 'root'
})
export class BuilderFormService {

  constructor(
    private evalService: BuilderEvaluatorService,
  ) {
  }

  /**
   * Recursively build fields
   */
  private buildFields(fields: EasBuilderField[], options?: any): Observable<EasElement[]> {
    const fields$: Observable<EasElement>[] = [];
    fields.forEach(field => {
      let field$: Observable<EasElement>;

      const defaults: any = {
        id: field.id,
        options: field.options ?? {},
        meta: {},
        dependentFields: []
      };

      const meta = (field.options ?? {}).meta ?? {};

      Object.entries(meta).forEach(([itm, expr]: [string, any]) => {
        const parts = (expr['value'] as string).split('.');
        const lastPart = parts.pop();
        defaults.dependentFields.push(lastPart);
        defaults.meta[itm] = (_value, parent, _model) => {
          const op = expr.op || 'eq';
          const cmpValue = expr['cmp'];
          let val = lastPart ? parent[lastPart] : false;
          if (op === 'eq') {
            return val === cmpValue;
          } else if (op === 'has_any') {
            if (!Array.isArray(cmpValue)) {
              return false;
            }
            if (Array.isArray(val)) {
              return intersect(cmpValue, val).length > 0;
            } else {
              return cmpValue.includes(val);
            }
          } else if (op === 'has_all') {
            if (!Array.isArray(cmpValue)) {
              return false;
            }
            if (!Array.isArray(val)) {
              val = [val];
            }

            return cmpValue.reduce((curr, value)  => {
              return curr && val.includes(value);
            }, true);
          }
        };
      });

      if (field.type === 'group' || field.type === 'array') {
        if (field.fields) {
          field$ = this.buildFields(field.fields, options).pipe(
            map(_fields => {
              return {
                ...defaults,
                type: field.type,
                fields: _fields,
              };
            })
          );
        } else {
          field$ = of({
            ...defaults,
            type: field.type,
            fields_from: field.fields_from,
          });
        }
      } else {
        let evaluator: FBFieldEvaluator = this.evalService.evaluators[field.fieldType];
        if (!evaluator) {
          evaluator = this.evalService.evaluators['general'];
        }
        if (!evaluator) {
          return;
        }

        field$ = evaluator.buildField(field, options).pipe(
          map(data => {
            if (data !== undefined) {
              const fld = { ...data };
              fld.meta = { ...(data.meta || {}), ...(defaults.meta || {}) };
              fld.dependentFields = defaults.dependentFields;
              return fld;
            }
            return undefined;
          })
         );
      }

      fields$.push(field$);
    });

    if (fields$.length === 0) {
      return of([]);
    }

    return forkJoin(fields$).pipe(
      map(res => res.filter(itm => itm !== undefined)),
    );
  }

  /**
   * Create a EAS Form definition by applying all evaluators
   */
  buildForm(form: EasBuilderGroup, options?: any): Observable<EasFieldGroup> {
    const {fields, validators, asyncValidators, ...rest} = form;
    return this.buildFields(fields, options).pipe(
      tap(flds => console.log(flds)),
      map(_fields => ({...rest, fields: _fields}))
    );
  }

}
