import { cloneDeep, flatMap } from 'lodash';
import { SelectableListGroup, SelectableListItem, SelectableListPresentable } from './selectable-list.presentable';

export enum SelectableListStyle {
    /** Items are presented as simple list */
    Plain,

    /** Items are presented as groups per their Groups */
    Grouped
}

export interface SelectableListOptions {
    /** Should list provide filtering of its content? */
    allowsFiltering: boolean;

    /** Should user be able to reorder list with Drag & Drop */
    allowsItemReordering: boolean;

    /** Should list provide multi selection? */
    allowsMultipleSelection: boolean;

    /** Sort function which will be applied to list content. */
    comparator?: (a: SelectableListPresentable, b: SelectableListPresentable) => number;
}

const NOT_FOUND = -1;

function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

export class SelectableListService {

    private items: SelectableListGroup[];

    public get selectedItems(): SelectableListItem[] {
        return flatMap(this.items, group => group.items)
               .filter(item => this.value.indexOf(item.id) !== NOT_FOUND);
    }

    public get style(): SelectableListStyle {
        if (this.items.length > 1) { return SelectableListStyle.Grouped; }
        return SelectableListStyle.Plain;
    }

    public value: string[];

    private _visibleGroups: SelectableListGroup[];
    public get visibleGroups(): SelectableListGroup[] {
        return this._visibleGroups;
    }

    public get visibleItems(): SelectableListItem[] {
        return flatMap(this._visibleGroups, group => group.items);
    }

    public get allowsMultipleSelection(): boolean {
        return this.options.allowsMultipleSelection;
    }

    public get allowsItemReordering(): boolean {
        return this.options.allowsItemReordering;
    }

    public get isItemReorderingSupported(): boolean {
        return this.style === SelectableListStyle.Plain;
    }

    private options: SelectableListOptions;
    private defaultOptions: SelectableListOptions = {
        allowsFiltering: true,
        allowsItemReordering: false,
        allowsMultipleSelection: true,
    };

    constructor(items: SelectableListGroup[],
                value: string[] = [],
                options: Partial<SelectableListOptions> = {}) {
        this.options = {...this.defaultOptions, ...options};
        this.items = cloneDeep(items);
        if (!!this.options.comparator) {
            this.items = this.items.sort(this.options.comparator);
            this.items.forEach(group => {
                group.items = group.items.sort(this.options.comparator);
            });
        }
        this._visibleGroups = cloneDeep(this.items);
        this.value = value;
    }

    isLast(obj: SelectableListGroup): boolean {
        const index: number = this._visibleGroups.indexOf(obj);
        if (index === NOT_FOUND) { return false; }
        return this._visibleGroups.length - 1 === index;
    }

    isSelected(obj: SelectableListItem): boolean {
        return this.value.indexOf(obj.id) === NOT_FOUND ? false : true;
    }

    patchValue(value: string | string[]) {
        if (typeof value === 'string') {
            const index = this.value.indexOf(value);
            const isItemSelected = index !== NOT_FOUND;

            if (!this.allowsMultipleSelection) {
                this.value = isItemSelected ? [] : [value];
                return;
            }

            isItemSelected ? this.value.splice(index, 1) : this.value.push(value);
        } else {
            value.forEach(element => this.patchValue(element));
        }
    }

    // TODO: When we have add characters we don't need to copy the array when we
    //       start removing characters we need to copy
    applyVisibleItemsFilter(query: string) {
        // Reset visible items with fresh copy of Items
        this._visibleGroups = cloneDeep(this.items);
        if (query.length === 0) { return; }
        const lowercaseQuery = query.toLocaleLowerCase();

        // Filter items to match the filter
        const filtered = this._visibleGroups.map(group => {
            const items = group.items.filter(item => {
                return item.title.toLocaleLowerCase().startsWith(lowercaseQuery);
            });
            if (items.length === 0) { return undefined; }
            group.items = items;
            return group;
        });
        // Filter items to remove undfines
        this._visibleGroups = filtered.filter(isPresent);
    }

    moveItem(previousIndex: number, currentIndex: number) {
        if (!this.isItemReorderingSupported) { return; }

        const items = this._visibleGroups[0].items;
        const element = items[previousIndex];
        items.splice(previousIndex, 1);
        items.splice(currentIndex, 0, element);
        this._visibleGroups[0].items = items;

        this.value = items.filter(i => this.isSelected(i))
                          .map(i => i.id);
    }
}
