import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUpload } from './file-upload';

@Component({
  selector: 'eas-file-upload-control-upload-list-item',
  template: `
    <mat-list-item>
    <p matLine>
      {{ upload.filename }} {{ upload.size | storage }} &nbsp;
      <span style="float: right">
      <a *ngIf="canDownload(upload)" (click)="onDownload(upload)" i18n>Download</a>
      </span>
      <span *ngIf="isInProgress(upload)" style="float:right">
        <ng-container i18n>Uploading...</ng-container>
        <mat-progress-spinner style="margin-left: 10px; float:right"
                              diameter="20"
                              mode="determinate"
                             [value]="upload.progress"></mat-progress-spinner>
      </span>
      <span *ngIf="didFail(upload)" style="float:right">
        <ng-container i18n>Upload failed</ng-container> <mat-icon matListIcon>report_problem</mat-icon>
      </span>
    </p>
    </mat-list-item>
    `
})
export class FileUploadControlUploadListItemComponent  {
  @Input() upload: FileUpload;
  @Output() downloadRequested = new EventEmitter<FileUpload>();

  onDownload(upload: FileUpload) {
      this.downloadRequested.emit(upload);
  }

  canDownload(upload: FileUpload): boolean {
    return upload.state === 'successful';
  }

  didFail(upload: FileUpload): boolean {
      return upload.state === 'failed';
  }

  isInProgress(upload: FileUpload): boolean {
      return upload.state === 'in-progress';
  }
}
