import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UBCSSProperties, UBGridDirection } from '../unboxed.module.typings';

@Component({
  selector: 'ub-grid',
  exportAs: 'unboxedGrid',
  styles: [
    `
          .ub-grid {
            align-items: center;
            display: -ms-flexbox;
            display: grid;
          }
        `
  ],
  template: `
    <div class="ub-grid" [ngStyle]="gridStyles()">
      <ng-content></ng-content>
    </div>
  `,
  encapsulation: ViewEncapsulation.None
})
export class UnboxedGrid {
  @Input()
  public direction: UBGridDirection = 'column';

  @Input()
  public min: number = 250;

  @Input()
  public gap: string = '8px';

  @Input()
  public template: string;

  public gridStyles(): UBCSSProperties {
    return {
      gap: this.gap,
      [`grid-template-${this.direction}s`]:
        this.template || `repeat(auto-fill, minmax(${this.min}px, 1fr))`
    };
  }
}
