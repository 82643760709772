import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { UBStep } from '../unboxed.module.typings';

@Component({
  selector: 'ub-stepper',
  exportAs: 'unboxedStepper',
  styleUrls: ['./stepper.css'],
  templateUrl: 'stepper.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedStepper implements OnInit {
  @Input()
  public steps: UBStep[] = [];

  @Input()
  public condensed: boolean = false;

  @Input()
  public truncateTitles: boolean = false;

  public _steps: UBStep[];

  public ngOnInit(): void {
    // We need to keep a copy of the steps so that we can expand/collaps each
    // without affecting the original steps
    this._steps = [...this.steps];
  }

  public expand(step: UBStep): void {
    if (!step.steps || !step.steps.length) {
      return;
    }

    const currentStepIndex = this._steps.findIndex(st => step === st);
    this._steps = [
      ...this.steps.slice(0, currentStepIndex),
      { ...step, expanded: !step.expanded },
      ...this.steps.slice(currentStepIndex + 1, this.steps.length)
    ];
  }

  public stepTitle(title: string): string {
    const MAX_WORDS = 3;

    if (this.truncateTitles) {
      return `${title
        .split(' ')
        .splice(0, MAX_WORDS)
        .join(' ')}...`;
    }

    return title;
  }

  public stepStatus({ done, next, steps = [] }: UBStep): string {
    if (steps.length === 0) {
      if (done) {
        return 'Complete';
      } else if (next) {
        return 'Up next';
      } else {
        return 'Not completed';
      }
    } else {
      const stepsDone = steps.reduce((stats, { done }) => {
        if (done) {
          stats++;
        }

        return stats;
      }, 0);

      return `${stepsDone} / ${steps.length} completed`;
    }
  }

  public gridTemplate(): string {
    return `repeat(${this.steps.length}, minmax(64px, 1fr))`;
  }

  public stepClassNames(step: UBStep, isChild: boolean = false): string[] {
    return [
      'ub-stepper__step',
      this.condensed ? 'ub-stepper__step--condensed' : null,
      step.expanded ? 'ub-stepper__step--expanded' : null,
      step.done ? 'ub-stepper__step--done' : null,
      step.next ? 'ub-stepper__step--next' : null,
      isChild ? 'ub-stepper__step--straight' : null
    ].filter(Boolean);
  }

  public showSubSteps({ expanded = false, steps = [] }: UBStep): boolean {
    return steps && steps.length && expanded;
  }
}
