import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { FieldType, FormDataOptions, FormField } from './forms.interface';
import { FormData } from './form-data';

@Injectable({
  providedIn: 'root'
})
export class FormProviderService {

  private fields: FormField[];
  private options: FormDataOptions;

  constructor(
      private fb: UntypedFormBuilder
  ) { }

  private _getGroup(fields: FormField[], options?: FormDataOptions) {
    const group = {};
    fields.forEach(item => {
      if (item.type === FieldType.GROUP) {
        console.log('Group Type', item.type);
        group[item.id] = this._getGroup(item.fields, options);
        return;
      }

      if (item.type === FieldType.ARRAY) {
        console.log('Array Type', item.type);
        group[item.id] = this.fb.array([]);
        return;
      }
      if (item.noFormControl) {
        return;
      }
      if (item.readOnly) {
        return;
      }
      group[item.id] = new UntypedFormControl(item.initial, item.validators, item.asyncValidators);
    });
    return this.fb.group(group);
  }

  public getFormData(fields: FormField[], options?: FormDataOptions) {
    this.fields = cloneDeep(fields);
    this.options = options;
    const form = this._getGroup(fields, options);
    const formData = new FormData(this.fb, form, fields, options);
    return of(formData);
  }

  public getFormDataWith(fields: FormField[], model: any, options?: FormDataOptions) {
    console.log('Model', model);
    return this.getFormData(fields, options).pipe(
      tap(formData => formData.initForm(model))
    );
  }

  public getGroup(fields: FormField[], options: FormDataOptions) {
    return this._getGroup(fields, options);
  }

  public getGroupFor(identifier: string) {
    const fields = this.fields.find(itm => itm.id === identifier);
    return this._getGroup(fields.fields, this.options);
  }
}
