import { Injectable } from '@angular/core';

import { ApiStore, SearchType, SuperType } from '@fry/lib/store';
import { APIService } from '@fry/lib/api';

import { BookingType } from './booking-type';


@Injectable({
  providedIn: 'root'
})
export class BookingTypesStore extends ApiStore<BookingType> {
  AssociatedModel = BookingType;

  docType = 'bookingType';
  endpoint = 'booking-types';
  superType = SuperType.Org;
  searchType = SearchType.LOCAL;

  constructor(api: APIService) {
    super(api);
  }
}
