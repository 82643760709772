import { Pipe, PipeTransform } from '@angular/core';

export type StorageUnitsStandard = 'SI' | 'IEC';

/*
 * Formats file size in bytes to human readable string
 *
 * Takes
 *   `precision` argument which defaults to *2*
 *   `units` argument which defaults to *SI* (use either SI or IEC unit system)
 *
 * Usage:
 *   value | storage:precision:units
 *
 * Example:
 *   {{ 98023420 | fileSize:3 }}
 *   formats to: 98.023 MB
*/
@Pipe({ name: 'storage', pure: true })
export class StoragePipe implements PipeTransform {
  private static CONFIGURATION = {
    'SI': { base: 1000, suffix: 'B' },
    'IEC': { base: 1024, suffix: 'iB' }
  };

  transform(bytes: number, precision: number = 2, units: StorageUnitsStandard = 'SI'): string {
    const config = StoragePipe.CONFIGURATION[units];
    // tslint:disable-next-line no-bitwise
    const exponent = Math.log(bytes) / Math.log(config.base) | 0;
    const result = (bytes / Math.pow(config.base, exponent)).toFixed(precision);
    return `${result} ${exponent === 0 ? 'bytes' : 'KMGTPEZY'[exponent - 1] + config.suffix}`;
  }
}
