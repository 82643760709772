import { Observable } from 'rxjs';
import { FileUpload, FileUploadEvent } from './file-upload';

export  type FileUploadStoreState = 'idle' |
                                    'pending' |
                                    'uploading';

export abstract class FileUploadStore {

    protected _state: FileUploadStoreState;
    protected _error: Error;

    public get isUploading(): boolean {
        return this._state === 'uploading' ||
               this._state === 'pending';
    }

    constructor() {
        this._state = 'idle';
        this._error = undefined;
    }

    abstract downloadURLFor(upload: FileUpload, metadata: any): Observable<URL>;
    abstract upload(uploads: FileUpload[]): Observable<FileUploadEvent>;
    abstract cancel();
}
