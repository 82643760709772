import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';


@Pipe({ name: 'easPriceRange', pure: true })
export class EASPriceRageFormatPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    value: any,
    currencyCode?: string,
    display: 'code'|'symbol'|'symbol-narrow'|string|boolean = 'symbol',
    digitsInfo?: string,
    locale?: string
  ): string|null {

    if (value == null || value === '') {
      return null;
    }

    const min = this.currencyPipe.transform(value.min, currencyCode, display, digitsInfo, locale);
    const max = this.currencyPipe.transform(value.max, currencyCode, display, digitsInfo, locale);
    if (min === max) {
      return min;
    }

    return $localize `${min} to ${max}`;
  }
}
