import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

export enum EasFieldType {
  Field = 'field',
  Group = 'group',
  Array = 'array',
}

export interface EasFieldOptions {
  noFormControl?: boolean;
  readOnly?: boolean;
  categories?: any[];
  categoriesFactory?: (easControl, value, parent, doc) => any[];
  options?: any;
}

export interface EasField {
  id: string;
  type: EasFieldType;
  templates?: {
    before?: string;
    after?: string;
    main?: string;
  };
  validators?: ValidatorFn[];
  asyncValidators?: AsyncValidatorFn[];
  meta?: {
    [key: string]: (value: any, parent: any, doc: any, path?: string) => boolean;
  };
  dependentFields?: string[];
  options?: EasFieldOptions;
  getTitle?: (value, field) => string;
  serializeValue?: (value) => string;
}

export interface EasFieldGroup extends EasField {
  type: EasFieldType.Group;
  fields: EasElement[];
}

export interface EasFieldArray extends EasField {
  type: EasFieldType.Array;
  fields: EasElement[];
  fields_from: string;
}

export interface EasFieldControl extends EasField {
  type: EasFieldType.Field;
  title: string;
  widget: {
    type: string;
  };
  initial?: any;
}

export type EasElement = EasField | EasFieldGroup | EasFieldArray | EasFieldControl;
