import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { DependentTransitionGuard } from '@fry/components/common/form-builder/builder-transition-guard';
import { intersect } from '@fry/lib/utils';

@Injectable()
export class FieldGuard implements DependentTransitionGuard {
    public static readonly code: string = 'field_guard';
    public static readonly title?: string = '';
    public get code() { return FieldGuard.code; }
    public get title() { return FieldGuard.title; }

    constructor() {}

    public evaluateData(data: any, _user: any, guardData: any): Observable<boolean> {
      const fields = guardData.field.split('.');
      let val = data;
      const cmpValue = guardData.value;
      for (let i = 0; i < fields.length; i++) {
        val = val[fields[i]];
      }


      let res;
      if (guardData.op === 'eq') {
        res = val === cmpValue;
        if (cmpValue === false) {
          res = val === cmpValue || val === null || val === undefined;
        }
      } else if (guardData.op === 'has_any') {
        if (!Array.isArray(cmpValue)) {
          return of(false);
        }

        if (Array.isArray(val)) {
          res = intersect(cmpValue, val).length > 0;
        } else {
          res = cmpValue.includes(val);
        }
      } else if (guardData.op === 'has_all') {
        if (!Array.isArray(cmpValue)) {
          return of(false);
        }
        if (!Array.isArray(val)) {
          val = [val];
        }

        res = cmpValue.reduce((curr, value)  => {
          return curr && val.includes(value);
        }, true);
      }

      return of(res);
    }
}

