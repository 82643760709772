// tslint:disable: no-inferrable-types

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { EasFormControl } from './easform';
import { GMCValidationError, GMCValidatorService } from '../gmc-form-control';

@Component({
  selector: 'eas-easform-control',
  templateUrl: './easform-control.component.html'
})
export class EasFormControlComponent implements OnInit, OnDestroy {
  @Input() easControl: EasFormControl;
  @Input() templates = {};

  /**
   * Weather the read-only fields should be visible regardless their values.
   *
   * By default the field is ommited from the form if it does not have value
   * which it does not in the Workflow Editor - From preview.
   */
  @Input() forceDisplayReadOnly: boolean = false;

  private unsubscribe: Subject<void> = new Subject();

  _categories$ = new BehaviorSubject<any[]>([]);
  categories$ = this._categories$.asObservable().pipe(
    distinctUntilChanged()
  );

  filteredCategories$: Observable<any[]>;
  public errors;

  constructor() {
    this.errors = this.errorMessages();
  }

  showType: 'readOnly' | 'editable' | 'hidden';
  readOnlyVisibility: boolean;


  private errorMessages() {
    const messages = { required: () => $localize `This field is required` };

    messages[GMCValidatorService.FormatValidatorErrorKey] =
      (value: GMCValidationError) => $localize `GMC Number '${value.value}' is not valid!`;

    messages[GMCValidatorService.CorrectnessValidatorErrorKey] =
      (value: GMCValidationError) => $localize `GMC Number '${value.value}' could not be verified!`;

    return messages;
  }

  ngOnInit() {
    const options = this.easControl.field.options || {};
    if (options.categories) {
      this._categories$.next(options.categories);
    } else if (options.categoriesFactory) {
      const root = this.easControl.root;

      this._categories$.next(options.categoriesFactory(
        this.easControl,
        this.easControl.control.value,
        this.easControl.parent.parent.control.value,
        root.control.value
      ));
      root.control.valueChanges.pipe(
        takeUntil(this.unsubscribe),
      ).subscribe(value => {
        this._categories$.next(options.categoriesFactory(
          this.easControl,
          this.easControl.control.value,
          this.easControl.parent.parent.control.value,
          value
        ));
      });

    }
    if (this.easControl.control) {
      this.filteredCategories$ = this.easControl.control.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = this.displayFn()(value);
          const sense = name || value;
          return this._filter(sense);
        })
      );
    }
    this.showType = this.getShowType();
    this.readOnlyVisibility = this.getReadOnlyVisibility();
  }

  private getReadOnlyVisibility() {
    const widgetType = this.easControl.field.widget.type;
    if (['heading', 'paragraph'].includes(widgetType)) {
      return true;
    }
    return !!this.easControl.readableValue;
  }

  private getShowType() {
    if (this.easControl.field.widget.type === 'hidden') {
      return 'hidden';
    }

    return this.easControl.readOnly ? 'readOnly' : 'editable';
  }

  displayFn() {
    return (value) => {
      if (!this._categories$) {
        return;
      }
      const idField = this.easControl.field.options && this.easControl.field.options['key'] || 'id';
      const opt = this._categories$.value.find(itm => itm[idField] === value);
      return opt !== undefined ? opt.name : '';
    };
  }

  private _filter(name: string): any[] {
    const ret = this._categories$.value;
    if (!name) {
      return ret.slice();
    }

    const filterValue = name.toLowerCase();

    return ret.filter(option => option.name.toLowerCase().indexOf(filterValue) !== -1);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }
}
