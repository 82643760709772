import { Component, forwardRef, Input, OnDestroy, OnInit, Optional, } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

import { FileUpload, FileUploadJSON } from './file-upload';
import { FileUploadStore } from './file-upload-store';
import { FileUploadControlComponentDelegate } from './file-upload-control.delegate';
import { SentryService } from '@fry/lib/error';
import { NotifyService } from '@fry/lib/utils';

//
// Custom Type guards
//
function isHTMLInputElement(element: HTMLElement): element is HTMLInputElement {
  return (element as HTMLInputElement).files !== undefined;
}

function isArrayOfFileUploads(arg: any): arg is FileUpload[] {
  if (!(arg instanceof Array)) { return false; }
  if (arg.length === 0) { return true; }
  return arg[0] instanceof FileUpload;
}

function isArrayOfFileUploadJSONs(arg: any): arg is FileUploadJSON[] {
  if (!(arg instanceof Array)) { return false; }
  if (arg.length === 0) { return true; }
  return !(arg[0] instanceof FileUpload);
}

// Component options
export interface FileUploadControlComponentOptions {
  uploadOnSelection: boolean;
  allowsMultipleUploads: boolean;
  acceptedFileTypes: string;
}

/**
 * Single/Multiple file upload component
 *
 */
@Component({
  selector: 'eas-file-upload-control',
  templateUrl: './file-upload-control.component.html',
  styleUrls: ['./file-upload-control.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadControlComponent),
      multi: true
    }]
})
export class FileUploadControlComponent implements OnInit, OnDestroy, ControlValueAccessor {

 @Input() readonly: boolean;
 @Input() readonlyValue: FileUploadJSON[];

 @Input() options: FileUploadControlComponentOptions;
 private defaultOptions: FileUploadControlComponentOptions = {
    uploadOnSelection: true,
    allowsMultipleUploads: true,
    acceptedFileTypes: ''
  };

  public filesInputControl = this.fb.control('');

  public get hasUploads(): boolean {
    return this.uploads.length > 0;
  }

  public get isUploading(): boolean {
    return this.store.isUploading;
  }

  public get hasFailedUploads(): boolean {
    if (!this.uploads) { return false; }
    const upload = this.uploads.find(u => u.state === 'failed');
    return upload !== undefined;
  }

  public uploads: FileUpload[] = [];

  private _destroyed$: Subject<void> = new Subject();

  public get isDisabled(): boolean {
    return this._disabled;
  }
  private _disabled: boolean;

  constructor(private fb: UntypedFormBuilder,
              private store: FileUploadStore,
              private notify: NotifyService,
              @Optional() private delegate: FileUploadControlComponentDelegate,
              @Optional() private sentry: SentryService) { }

  //
  // Lifecycle
  //

  ngOnInit(): void {
    this.options = {...this.defaultOptions, ...this.options};

    if (this.readonly) {
      this.writeValue(this.readonlyValue);
      this.setDisabledState(true);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  //
  // Upload management
  //

  private visibleUploads(current: FileUpload[], update: FileUpload[]): FileUpload[] {
    const successful = current.filter(u => u.state === 'successful');
    return [...successful, ...update];
  }

  private upload(pending: FileUpload[]) {
    if (this.isUploading) { return; }
    if (!this.delegate) { return; }

    this.delegate.fileUploadDidRequestUploadFor(pending)
                 .pipe(
                   takeUntil(this._destroyed$),
                   throttleTime(260) // Because Material CSS transition lasts 250ms
                 ).subscribe(
                   event => {
                     const upload = this.uploads.find(u => u.filename === event.upload.filename);
                     switch (event.state) {
                       case 'pending':
                       case 'successful':
                         break;
                       case 'in-progress':
                         upload.progress = event.upload.progress;
                         break;
                       case 'failed':
                         break;
                     }
                     console.log(`Event: ${event.state} - Upload: ${event.upload.filename} - Progress: ${event.upload.progress}`);
                   },
                   () => {
                     console.log('error');
                   },
                   () => {
                     // Push representation of uploads to Form
                     this.patchValue(pending);
                     this.filesInputControl.setValue([]);
                   }
                 );
  }

  //
  // ControlValueAccessor interface
  //

  private onChange = (_: any) => {};
  // private onTouch = () => {};

  writeValue(value: FileUpload[]|FileUploadJSON[]) {
    if (isArrayOfFileUploads(value)) {
      const result = this.uploads.map(upload => upload.toJSON());
      this.onChange(result);
    } else if (isArrayOfFileUploadJSONs(value)) {
      const result = value.map(json => new FileUpload(json));

      // Get errors and report to sentry...
      result.filter(obj => obj.state === 'failed' )
            .forEach(obj => {
              this.sentry
               ? this.sentry.captureException(obj.error)
               : console.error(obj.error);
      });

      this.uploads = result;
      this.onChange(value);
    } else {
      this.onChange([]);
    }
  }

  patchValue(value: FileUpload[]) {
    this.writeValue([
      ...this.uploads.filter(u => u.state === 'successful'),
      ...value.filter(u => u.state === 'successful'),
    ]);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched() {
    // this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  //
  // Form events (material, etc...)
  //

  onDownload(upload) {
    if (!this.delegate) { return; }

    this.delegate.fileUploadDidRequestDownloadURLFor(upload)
                 .subscribe(url => {
                   upload.location = url['url'];
                   window.open(upload.location);
                 },
                 err => {
                  this.notify.error(err);
                 });
  }

  // FIXME: Cancelations are not supported on the FileUploadControl.
  //        In order to support cancelations we would need to be able to cancel
  //        HTTP request and remove currently uploaded file from the
  //        `this.uploads`.
  onCancel() {
    if (this.isUploading) {
      this.store.cancel();
    }
    this.filesInputControl.setValue([]);
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLElement;
    if (!isHTMLInputElement(target)) { return; }
    if (target.files.length === 0) { return; }

    const update = Array.from(target.files)
                        .map(file => new FileUpload(file));
    this.uploads = this.visibleUploads(this.uploads, update);

    // Reset the file element value as we have it all in Update & this.uploads
    target.value = null;
    this.filesInputControl.setValue([]);

    if (this.options.uploadOnSelection) {
      this.upload(update);
    }
  }

  onUpload() {
    const pending = this.uploads.filter(u => u.state === 'pending');
    this.upload(pending);
  }

  onRetry() {
    const failed = this.uploads.filter(u => u.state === 'failed');
    this.upload(failed);
  }
}
