import { DBDoc, PublishableBaseModel } from '@fry/lib/store';


export interface EligibilityUsage {
  id: string;
  name: string;
  type: string;
}


export interface ExtendedEligibility {
  doc: DBDoc;
  usage: EligibilityUsage[];
}


export class Eligibility extends PublishableBaseModel {
  usage: EligibilityUsage[];

  constructor(doc: DBDoc, nonDocProperties?: ExtendedEligibility) {
    super(doc, nonDocProperties);

    if (!!nonDocProperties) {
      this.usage = nonDocProperties.usage;
    }
  }
}
