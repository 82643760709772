import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-number',
  exportAs: 'unboxedNumberInput',
  styleUrls: ['../vars.css', '../common.css', './input.css'],
  templateUrl: './number.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedNumberInput extends UnboxedBaseFormComponent {
  @Input()
  public min = 0;

  @Input()
  public max: number;

  @Input()
  public step = 1;
}
