import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { ApiStore, SuperType } from '@fry/lib/store';

import { Organisation } from './organisation';
import { APIService } from '../api';


@Injectable({
  providedIn: 'root'
})
export class OrganisationStore extends ApiStore<Organisation> {
  AssociatedModel = Organisation;

  endpoint = 'organisations';
  docType = 'organisation';
  superType = SuperType.Org;

  constructor(api: APIService) {
    super(api);
  }

  public update(org: Organisation, data: any) {
   return this.api.put(`${this.endpoint}/${org.id}/update`, data).pipe(
      switchMap(() => this.get(org.id))
    );
  }
}
