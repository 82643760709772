import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LoggerService } from '@fry/lib/utils';
import { ClusterService } from '@fry/lib/organisations/cluster.service';
import { AuthService } from '@fry/lib/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class APIService {

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private cluster: ClusterService,
    private auth: AuthService,
    @Inject(LOCALE_ID) private locale: string,
  ) {  }

  private errorHandler = (response: HttpErrorResponse) => {
    if (response.error.message) {
      this.logger.error('API: Known error', response.error.message);
      return throwError(response.error.message);
    }

    this.logger.error('API: An unknown error occurred', response);
    if (response.statusText) {
      return throwError(response.statusText);
    }

    return throwError('API: Unknown error occurred');
  }

  private get apiUrl() {
    const org = this.cluster.getCurrentOrganisation();
    return org?.urls.api;
  }

  private orgApiUrl(orgId: string) {
    const org = this.cluster.getClusterOrganisation(orgId);
    return org?.urls.api;
  }

  private getUrl(endpoint: string) {
    return new URL(endpoint, this.apiUrl).href;
  }

  private getHeaders(headers?: any): HttpHeaders {
    let authHeaders = {};
    if (this.auth.isLoggedIn) {
      authHeaders = {
        'Authorization': `Bearer ${this.auth.token}`,
        'EAS-Organisation': `${this.auth.organisation}`,
        'Accept-Language': this.locale
      };
    }

    const httpHeaders = {
      ...authHeaders,
      ...(headers || {}),
    };

    return new HttpHeaders(httpHeaders);
  }

  public getAs(orgId: string, endpoint: string, params?: any) {
    const httpParams = new HttpParams({ fromObject: params });
    const httpHeaders = this.getHeaders({
      'EAS-Organisation': orgId
    });
    const url = new URL(endpoint, this.orgApiUrl(orgId)).href;
    return this.http.get<any>(url, {
      params: httpParams,
      headers: httpHeaders
    }).pipe(
      catchError(this.errorHandler)
    );
  }

  public get(endpoint: string, params?: any) {
    const httpParams = new HttpParams({ fromObject: params });
    const httpHeaders = this.getHeaders();
    return this.http.get<any>(this.getUrl(endpoint), {
      params: httpParams,
      headers: httpHeaders
    }).pipe(
      catchError(this.errorHandler)
    );
  }

  public post(endpoint: string, data: any, headers?: HttpHeaders) {
    const httpOptions = {
      headers: this.getHeaders(headers),
      responseType: 'json' as 'json'
    };
    if (headers && headers.has('accept')) {
      httpOptions.responseType = 'blob' as 'json';
    }
    return this.http.post<any>(this.getUrl(endpoint), data, httpOptions).pipe(
      catchError(this.errorHandler)
    );
  }

  public put(endpoint: string, data: any) {
    const httpOptions = {
      headers: this.getHeaders()
    };
    return this.http.put<any>(this.getUrl(endpoint), data, httpOptions).pipe(
      catchError(this.errorHandler)
    );
  }

  public delete(endpoint: string) {
    const httpOptions = {
      headers: this.getHeaders()
    };
    return this.http.delete<any>(this.getUrl(endpoint), httpOptions).pipe(
      catchError(this.errorHandler)
    );
  }

  public patch(endpoint: string, data: any) {
    const httpOptions = {
      headers: this.getHeaders()
    };
    return this.http.patch<any>(this.getUrl(endpoint), data, httpOptions).pipe(
      catchError(this.errorHandler)
    );
  }
}
