import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface DBDoc {
  _id: string;
  _rev?: string;
  dates?: any[];
  [key: string]: any;
}

export enum StoreType {
  API,
  DB,
  LOCAL,
}

export enum SearchType {
  LOCAL,
  REMOTE
}

export interface Model {
  id: string;
  doc: any;
}

export interface ModelSearch<T> {
  data: T[];
  meta: {
    total: number;
  };
}

export interface EasTableColumn {
  id: string;
  label: string;
  type?: 'link' | 'timefromnow' | 'datetime' | 'dateRange' | 'priceRange' | 'button';
}

export interface EasTableRow {
  id: string;
  doc?: DBDoc;
  obj: Model;
  row: any;
}

export interface EasTableMeta {
  size: number;
  start: number;
  total: number;
}


export interface EasTable {
  columns: EasTableColumn[];
  data: EasTableRow[];
  meta?: EasTableMeta;
}

export interface TypeInfoSettings {
  showInSettings?: boolean;
}

export interface TypeInfo {
  type: string;
  title: string;
  settings: TypeInfoSettings;
  resolvedList: () => any;
}

export const TYPE_INFO = new InjectionToken<TypeInfo>('TYPE_INFO');

export interface SearchStoreService<T> {
  searchType: SearchType;

  createObject(doc: DBDoc, nonDocProperties?: any): T;

  search(params: any): Observable<ModelSearch<T>>;
  table(params: any): Observable<EasTable>;
  export(params: any): Observable<any>;
  startExport(listId: string, params: any): Observable<any>;
  reset(): void;
}

export interface BackendStoreService<T> extends SearchStoreService<T> {
  storeType: StoreType;

  get(id: string): Observable<T>;
  getAuditLog(id: string): Observable<any[]>;
  all(): Observable<T[]>;
  save(doc: any): Observable<T>;
  remove(id: string): Observable<any>;

  one(): Observable<T>;
  some(ids: string[]): Observable<T[]>;
}
