import { BaseModel } from './base-model';

const StateTitles = {
  draft: $localize `Draft`,
  published: $localize `Published`,
  archived: $localize `Archived`,
};


export class PublishableBaseModel extends BaseModel {
  private get _workflow() {
    return { state: 'draft', ...(this.doc.workflow || {}) };
  }

  public get workflow() {
    const wf = this._workflow;
    return { title: StateTitles[wf.state], ...wf };
  }

  public initial() {
    const wf = this._workflow;
    wf.state = 'draft';
    this.doc.workflow = wf;
  }

  public publish() {
    const wf = this._workflow;
    wf.state = 'published';
    this.doc.workflow = wf;
  }

  public archive() {
    const wf = this._workflow;
    wf.state = 'archived';
    this.doc.workflow = wf;
  }

  public retract() {
    this.initial();
  }

  public doTransition(transition: "publish"|"archive"|"retract") {
    if (transition === 'publish') {
      this.publish();
    } else if (transition === 'archive') {
      this.archive();
    } else if (transition === 'retract') {
      this.retract();
    }
  }
}
