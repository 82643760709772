import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { Log, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { ConfigService } from '@fry/lib/config';
import { AuthToken } from '@fry/lib/auth/auth.token';

Log.logger = console;

@Injectable()
export class AuthOidcService {
  private manager?: UserManager;
  private user?: User | null;
  private _userChanged = new Subject<User>();
  public userChanged = this._userChanged.asObservable();

  constructor(
    private config: ConfigService,
    private authToken: AuthToken,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    window.addEventListener('storage', () => {
      if (this.manager) {
        this.updateUser();
      }
    });
  }

  async loginAs(username?: string) {
    if (this.manager) {
      this.manager.stopSilentRenew();
      this.manager.revokeAccessToken();
      await this.manager.removeUser();
    }

    const scope = username !== undefined ? `profile lase lase:${username}` : '';
    localStorage.setItem('eas:lastScope', scope);
    this.initManager({force: true, scope});
    this.startAuthentication();
  }

  initManager(options?: { force: boolean, scope?: string}): void {
    if (options && options.force) {
      this.manager = undefined;
    }

    if (this.manager) {
      return;
    }

    let scope = 'openid';
    if (options && options.scope) {
      scope += ' ' + options.scope;
    } else {
      scope += ' ' + (localStorage.getItem('eas:lastScope') || '');
    }
    console.log('OIDC Claims: Setting up scope', scope);

    // If ever we want to use local storage
    const settings: UserManagerSettings = {
      ...this.config.server.oidc,
      automaticSilentRenew: false,
      accessTokenExpiringNotificationTime: 60,
      scope,
      loadUserInfo: true,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      extraQueryParams: {}
    };

    if (this.authToken.organisation) {
      settings.extraQueryParams['kz_org'] = this.authToken.organisation;
    }
    const fast = sessionStorage.getItem('fastRedirect');
    if (fast === 'fast') {
      settings.extraQueryParams['kz_fast_redirect'] = true
    }
    settings.extraQueryParams['kz_lang'] = this.locale;

    this.manager = new UserManager(settings);
  }

  public init(options?: { force: boolean, scope?: string}): Promise<boolean> {
    console.log('OIDC: Initialising oidc manager');
    this.initManager(options);

    if (!this.manager) {
      throw new Error('No User manager, cannot initialize OIDC!');
    }

    return this.manager.getUser().then(user => {
      if (!user || user.expired) {
        console.log('OIDC: User is empty');
        let path = location.pathname;
        if (path.startsWith('/org')) {
          path = '/';
        }
        localStorage.setItem('lastUrl', path);

        this.startAuthentication();
        return false;
      }

      console.log('OIDC: New user', user);
      if (user) {
        console.log('OIDC: Expires in ', user.expires_in);
      }
      this.user = user;
      this._userChanged.next(user);
      return true;
    });
  }

  setupEvents() {
    console.log('OIDC: Setting up oidc events');
    if (!this.manager) {
      console.log('No manager yet, cannot set up events');
      return;
    }

    this.manager.events.addUserLoaded((data) => {
      console.log('OIDC: Loaded', data);
      if (data) {
        console.log('OIDC: Expires in ', data.expires_in);
      }
      this.user = data;
      this._userChanged.next(this.user);
    });
    this.manager.events.addUserUnloaded(() => {
      this.user = undefined;
      this._userChanged.next(this.user);
    });

    this.manager.events.addAccessTokenExpiring(() => {
      console.log('OIDC: Expiring');
      if (this.user) {
        console.log('OIDC: Expires in ', this.user.expires_in);
      }
    });

    this.manager.events.addAccessTokenExpired(() => {
      console.log('OIDC: Expired');
      this.user = undefined;
      this._userChanged.next(this.user);
    });

    this.manager.events.addSilentRenewError(err => {
      // this.user = undefined;
      console.log('OIDC: Silent renew error', err);
      this.manager.stopSilentRenew();
      setTimeout(() => {
        console.log('OIDC: Retrying silent renew');
        this.manager.startSilentRenew();
      }, 5000);
      // this._userChanged.next(this.user);
      // this.startAuthenticationPopup();
    });
  }

  async updateUser() {
    if (!this.manager) {
      console.log('OIDC: Cannot update user because manager does not exist');
      return;
    }
    const user = await this.manager.getUser();
    console.log('OIDC: Updated user from manager', user);
    this.user = user;
    this._userChanged.next(user);
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  getClaims(): any {
    return this.user && this.user.profile;
  }

  getScope(): any {
    return this.user && this.user.scope;
  }

  getAuthorizationHeaderValue(): string | undefined{
    if (!this.user) {
      return undefined;
    }
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  getToken(): string {
    if (!this.user) {
      return '';
    }
    return this.user.access_token;
  }

  startAuthenticationPopup(): Promise<User> {
    return this.manager.signinPopup()
      .then(user => {
        this.user = user;
        this._userChanged.next(user);
        return user;
      });
  }

  async completeAuthenticationSilent(): Promise<any> {
    try {
      const user = await this.manager.signinSilentCallback();
      // this.user = user;
      console.log('OIDC: Exp renewal', user);
    } catch (err) {
      console.log('OIDC: Failed renewal', err);
    }
  }

  async completeAuthenticationPopup(): Promise<void> {
    await this.manager.signinPopupCallback();
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  startLogout(): Promise<void> {
     localStorage.removeItem('eas:lastScope');
     return this.manager.signoutRedirect();
  }

  startSilentRenew() {
    if (!this.manager) {
      return;
    }

    this.manager.startSilentRenew();
  }

  stopSilentRenew() {
    if (!this.manager) {
      return;
    }

    this.manager.stopSilentRenew();
  }

  async check() {
    console.log('OIDC: Initialising oidc manager');
    if (!this.manager) {
      return;
    }

    this.manager.clearStaleState();
    return this.manager.querySessionStatus()
    .catch(err => {
      console.log(err);
      const logoutErrors = [
        'login_required',
        'shibcookie_validation_required',
        'consent_required',
        'interaction_required',
      ];
      if (err && logoutErrors.indexOf(err.error) !== -1) {
       return this.manager && this.manager.removeUser();
      }
      return undefined;
    });
  }

  async completeAuthentication(): Promise<void> {
    if (!this.manager) {
      console.log('OIDC: no manager, cannot finish authenticateion');
      return;
    }
    await this.manager.signinRedirectCallback();
  }
}
