import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'bookingTypes.view', name: $localize `View booking types`, description: $localize `Allow user to view list of booking types` },
  { id: 'bookingTypes.create', name: $localize `Create booking types`, description: $localize `Allow user to create new booking types` },
  { id: 'bookingTypes.edit', name: $localize `Edit booking types`, description: $localize `Allow user to edit booking types`},
];

export const bookingTypePermissions: PermissionGroup = {
  id: 'bookingTypes',
  name: $localize `Booking Types`,
  description: '',
  permissions
};
