import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DashboardCard } from '../dashboard-card';
import { CardData, DashboardCardsService } from '../dashboard-cards.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  providers: [
    DashboardCardsService
  ]
})
export class DashboardPageComponent implements OnInit {

  public cards: Observable<DashboardCard[]>;

  constructor(private cardsService: DashboardCardsService) {
    this.cards = this.cardsService.cards;
  }

  @Input() cardsData: CardData[];

  ngOnInit() {
    this.cardsService.addCards(this.cardsData);
  }

}
