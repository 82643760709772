import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { CONFIG, ConfigService, FrontendConfig } from '@fry/lib/config';
import { AuthService, AuthToken, AuthErrorNotAuthenticated } from '@fry/lib/auth';
import { AuthOidcService } from '@fry/lib/auth/oidc/auth.oidc.service';
import { LoggerService } from '@fry/lib/utils';
import { ClusterService } from '@fry/lib/organisations/cluster.service';
import { SentryService } from '@fry/lib/error';

import { environment } from '../../environments/environment';

// 1. Load configuration from the server (for API)
// 2. Get AuthToken and handle the path org/org_ components
// 3. Attempt authenticate using OIDC
//    When authenticated
// 4.   Load cluster configuration
// 5.   Setup sentry
// 6.   Run Angular bootstrap if we're authenticated


@Injectable()
export class AppLoadService {

  constructor(
    private auth: AuthService,
    private authToken: AuthToken,
    private cluster: ClusterService,
    private config: ConfigService,
    private logger: LoggerService,
    private oidc: AuthOidcService,
    private sentry: SentryService,
    @Inject(CONFIG) private configFrontend: FrontendConfig
  ) { }

  async initializeApp(): Promise<void> {
    console.info('InitializeApp:: start init process');
    try {
        // Load server and frontend configuration
        const configuration =
          await lastValueFrom(this.config.prepare(this.configFrontend, localStorage));
        if (!configuration) {
          throw new Error($localize `Unable to load application configuration!`);
        }

        let searchstring = window.location.search;
        if (searchstring && searchstring[0] === '?') {
          searchstring = searchstring.substring(1);
        }
        if (searchstring) {
          const params = new HttpParams({fromString: searchstring});
          const org = params.get('org');
          if (org) {
            this.authToken.organisation = `org_${org}`;
          }
        }
        const basePath = location.href.replace(document.baseURI, '/')
        if (basePath.startsWith('/org/')) {
          const path = basePath.split('/');
          const org = path[2];
          const fast = path[3];
          if (fast) {
            sessionStorage.setItem('fastRedirect', fast);
          }
          if (org) {
            this.authToken.organisation = `org_${org}`;
            // this.location.replaceState(document.baseURI.replace(window.location.origin, ''));
            window.location.href = document.baseURI;
          }
        }

        // Sentry configuration
        const sentryConfig = this.config.server.sentry;
        if (sentryConfig) {
          sentryConfig.release = environment.version.version;
          sentryConfig.tracingOrigins = Object.values(this.config.server.urls);
          this.sentry.init(sentryConfig);
          this.sentry.watchUser(this.auth.currentUser());
          if (!this.sentry.isEnabled) {
            console.warn('Sentry is configured but explicitly disabled!');
          }
        } else {
          console.warn('Sentry is not configured and will be disabled!');
        }

        this.logger.init();

        // Check if we're logged in and if not we throw AuthError to stop
        // initialisation.
        if (!await this.oidc.init()) {
          throw new AuthErrorNotAuthenticated($localize `User not authenticated, initiated auth...`);
        }

        // Load all information about the User's cluster
        await this.cluster.init();

        this.oidc.setupEvents();
        this.auth.subscribeUserChange();
        await this.oidc.updateUser();

        sessionStorage.removeItem('fastRedirect');

        console.info('InitializeApp:: done process');
    } catch (error) {
        console.info('InitializeApp:: failed process');
        throw error;
    }
  }
}
