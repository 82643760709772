import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UsersStore } from '@fry/lib/users';

@Component({
  selector: 'eas-user-select',
  templateUrl: './user-select.component.html',
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectComponent),
      multi: true
    }]
})
export class UserSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  public selectedUser = new UntypedFormControl();
  public users: any[] = [];
  public isLoading = false;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private usersStore: UsersStore
  ) {

  }

  //
  // Lifecycle
  //

  ngOnInit(): void {
    this.selectedUser.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this.destroyed$),
      tap(() => {
        this.users = [];
        this.isLoading = true;
      }),
      switchMap(value => {
        return this.usersStore.search({
          fullname: value,
          size: 10
        }).pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
      })
    ).subscribe(users => {
      this.users = users.data;
    })
  }

  public displayFn() {
    return (value: string) => {
      const user = this.users.find(itm => itm.doc.user === value)
      if (user) {
        return user.fullname;
      }
      return value;
    };
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  //
  // ControlValueAccessor interface
  //

  private onChange = (_: any) => {};
  // private onTouch = () => {};

  writeValue(value: string) {
    this.onChange(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched() {
    // this.onTouch = fn;
  }

  onOptionSelect() {
    const val = this.selectedUser.value;
    this.onChange(val);
  }

}
