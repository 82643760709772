// tslint:disable: max-classes-per-file

import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as EASDialogError from './dialog.error';

export interface DialogComponentData {
  title?: string;
  content?: string;
  type?: DialogComponentType;
  okButton?: string;
  cancelButton?: string;
}

export class DialogComponentData implements DialogComponentData {
  constructor(
    type: DialogComponentType,
    title: string,
    content: string,
    okButton?: string,
    cancelButton?: string,
  ) {
    this.title = title;
    this.content = content;
    this.type = type;
    this.okButton = okButton;
    this.cancelButton = cancelButton;
  }

  public static generic(
    title: string,
    content: string,
    okButton = $localize `OK`,
    cancelButton = $localize `Cancel`): DialogComponentData {
    return new DialogComponentData('generic', title, content, okButton, cancelButton);
  }

  public static progress(title: string, content: string): DialogComponentData {
    return new DialogComponentData('progress', title, content);
  }

  public static error(title: string, content: string): DialogComponentData {
    return new DialogComponentData('error', title, content, $localize `Acknowledge`);
  }

  public static success(title?: string, content?: string): DialogComponentData {
    return new DialogComponentData('success', title, content);
  }

  public static canceled(title?: string, content?: string): DialogComponentData {
    return new DialogComponentData('canceled', title, content);
  }
}

export type DialogComponentType = 'canceled' |
                                  'error' |
                                  'generic' |
                                  'progress' |
                                  'success';

const DEFAULT_DATA: DialogComponentData = {
  title: $localize `You have unsaved changes. Lose them?`,
  content: $localize `You can go back and save or navigate away`,
  type: 'generic',
  cancelButton: $localize `Cancel`,
  okButton: $localize `OK`
};

@Component({
  selector: 'app-dialog-component',
  templateUrl: 'dialog.component.html',
})
export class DialogComponent {

  public canceled = EASDialogError.UserCanceledError();

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogComponentData = {}
  ) {
    this.data = { ...DEFAULT_DATA, ...data };
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close(false);
  }
}
