import { Component, Input } from '@angular/core';
import { BuilderUIFormService } from './builder-ui-form.service';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { EasFormDialogComponent } from '../easform/easform-dialog.component';

@Component({
  selector: 'eas-builder-transitions',
  templateUrl: './builder-transitions.component.html'
})
export class BuilderTransitionsComponent {

  @Input() transitions: any[];
  @Input() states: any[];

  constructor(
    private builder: BuilderUIFormService,
    private dialog: MatDialog,
  ) { }

  addTransition() {
    this.builder.getTransitionsForm({}, this.states).subscribe(
      group => {
        this.dialog.open(EasFormDialogComponent, {
          data: {
            title: $localize `Add workflow transition`,
            group,
            onSubmit: (value) => {
              const field = this.builder.initField(value);
              this.transitions.push(field);
              return of(true);
            }
          }
        });
      }
    );
  }

  editTransition(i: number) {
    const trans = this.transitions[i];
    this.builder.getTransitionsForm(trans, this.states).subscribe(
      group => {
        this.dialog.open(EasFormDialogComponent, {
          data: {
            title:  $localize `Edit workflow transition`,
            group,
            onSubmit: (value) => {
              this.transitions[i] = value;
              return of(true);
            }
          }
        });
      }
    );
  }

  removeTransition(i: number) {
    this.transitions = [...this.transitions.slice(0, i), ...this.transitions.slice(i + 1)];
  }

  getStateTitle(stateId) {
    return (this.states.find(itm => itm.id === stateId) || {}).name;
  }
}
