import { isObservable } from 'rxjs';
import { finalize } from 'rxjs/operators';

// TypeGuard if object implements required interface
function isTrackingSupported(obj: any): obj is TracksOperationProgress {
    return !!obj && obj.isOperationInProgress !== undefined;
}

/**
 * Interface for components which provide operation tracking
 *
 * Each component which uses decorators must implement this interface.
 */
export interface TracksOperationProgress {
    isOperationInProgress: boolean;
}

/**
 * Decorator tracking operation progress
 *
 * This decorator consumes method and manages setting and resetting
 * `isOperationInProgress` flag.
 *
 * Original method may return any value in which case we consider execution
 * synchronous and just wrap method.
 *
 * When original method retuns Observable we append flag handling using `pipe()`
 * and `finalize()` operators.
 *
 * @example
 *  ```
 *  @TrackOperationProgress()
 *  private fetchResults$(): Observable<any> {
 *      return this.api.get('/xy')
 *  }
 *
 *  ngOnInit() {
 *      this.fetchResults$().subscribe(...)
 *  }
 *  ```
 *
 * @throws Error when operation is already in progress.
 * @throws If client component does not implement `TracksOperationProgress`
 *         protocol.
 */
export function TrackOperationProgress(): MethodDecorator {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function(this: TracksOperationProgress, ...args: any[]) {
      if (!isTrackingSupported(this)) {
        throw new Error($localize `Component does not support Operation Tracking.`);
      }

      if (this.isOperationInProgress) {
        throw new Error($localize `Operation is already in progress...`);
      }

      this.isOperationInProgress = true;
      const result: any = originalMethod.apply(this, args);
      if (isObservable(result)) {
        return result.pipe(
          finalize(() => this.isOperationInProgress = false)
        );
      }

      this.isOperationInProgress = false;
      return result;
    };

    return descriptor;
  };
}

/**
 * Decorator ensuring operation is not run while another is running
 *
 * This decorator consumes method which returns Observable<any> and uses the
 * Observable to respond to events and set `isOperationInProgress` accordingly.
 *
 * @throws Error when operation is already in progress.
 * @throws If client component does not implement `TracksOperationProgress`
 *         protocol.
 */
export function EnsureExclusiveExecution(): MethodDecorator {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function(...args: any[]) {
      if (!isTrackingSupported(this)) {
        throw new Error($localize `Component does not support Operation Tracking.`);
      }

      if (this.isOperationInProgress) {
        throw new Error($localize `Operation is already in progress...`);
      }
      return originalMethod.apply(this, args);
    };

    return descriptor;
  };
}
