import { Injectable } from '@angular/core';
import { AuthService, CurrentUser } from '@fry/lib/auth';
import { EASBrowserStorageService, EASBrowserStorageType } from '@fry/lib/utils';

@Injectable({
  providedIn: 'root'
})
export class EASPersonalStorageService {
  private currentUser: CurrentUser | undefined = undefined;
  private type: EASBrowserStorageType = 'sessionStorage';
  private storage: EASBrowserStorageService;


  public constructor(
     private auth: AuthService
  ) {
    this.storage = new EASBrowserStorageService(this.type);
    this.auth.currentUser().subscribe(current => {
      this.currentUser = current;
    });
  }

  private getKey(key: string) {
    const prefix = this.currentUser ?
      `${this.currentUser.organisation}_${this.currentUser.username}` :
      'unknown_user';

    return `${prefix}_${key}`;
  }

  public getItem(key: string): any {
    if (key === undefined || key === null) {
      throw new Error('Key must be specified in order to retreive value!');
    }

    let obj: any;
    try {
      const value = this.storage.getItem(this.getKey(key));
      if (value) {
        obj = JSON.parse(value);
      }
    } catch (err) {
      console.log('EASPersonalStorageService.getItem', err);
    }
    return obj;
  }

  public setItem(key: string, obj: any) {
    if (key === undefined || key === null) {
      throw new Error('Key must be specified in order to store value!');
    }
    try {
      const value = JSON.stringify(obj);
      this.storage.setItem(this.getKey(key), value);
    } catch(err) {
      console.log('EASPersonalStorageService.setItem', err);
    }
  }
}
