import { SelectableListGroup, SelectableListItem } from './selectable-list.presentable';

/**
 * List of keys on the SelectableListMappingInput which we understand and
 * allow to use.
 */
type AllowedKey = keyof SelectableListItem |
                  keyof SelectableListGroup |
                  'name' | 'permissions' | 'description';

/**
 * Mapping Input
 *
 * Type definition providing compile time check of the expected properties of
 * input structures. Only AllowedKeys will be allowed.
 */
export type SelectableListMappingInput = {
    [Key in AllowedKey]?: any
};
type Input = SelectableListMappingInput;

/**
 * Mapping from SelectableListMapping to SelectableListGroup/Item
 *
 * This class provides mapping from an Input data structrure which is loosely
 * typed to SelectableListGroup[] or SelectableListGroup/Item structures.
 *
 * Idea is to provide easy way how to transform common structures we use to
 * SelectableList datastructures without rewriting mapping over and over.
 */
export class SelectableListMapping {

    private static key(input: Input, keys: AllowedKey[]): AllowedKey|undefined {
        const strings = keys.map<string>(k => k);
        const result = Object.keys(input).find(key => strings.indexOf(key) !== -1);
        return result ? result as AllowedKey : undefined;
    }

    /**
     * Deeply Mapps array of Inputs
     *
     * This method returns structure which is expected by the SelectableList.
     *
     * @param input Array of Inputs representing SelectableListGroup information.
     */
    static create(input: Input[]): SelectableListGroup[] {
        return input.map<SelectableListGroup>(value => {
            return SelectableListMapping.createGroup(value);
        });
    }

    static createGroup(input: Input): SelectableListGroup {
        const title = SelectableListMapping.key(input, ['title', 'name']);
        const items = SelectableListMapping.key(input, ['items', 'permissions']);

        let group: SelectableListItem[] = [];
        if (items) {
            group = input[items].map((item: Input) => {
                return SelectableListMapping.createItem(item);
            });
        }
        return {
                 title: title ? input[title] ?? '' : '',
                 items: group
               };
    }

    static createItem(input: Input): SelectableListItem {
        const id = SelectableListMapping.key(input, ['id']);
        const title = SelectableListMapping.key(input, ['title', 'name']);
        const subtitle = SelectableListMapping.key(input, ['subtitle', 'description']);

        return { id: id ? input[id] ?? '' : '',
                 title: title ? input[title] ?? '' : '',
                 subtitle: subtitle ? input[subtitle] ?? '' : ''
               };
    }
}
