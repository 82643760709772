import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'payments.capture', name: $localize `Settle transaction`, description: $localize `Allow user to settle/fullfil transaction.` },
  { id: 'payments.create', name: $localize `Create transaction`, description: $localize `Allow user to create new transaction` },
  { id: 'payments.edit', name: $localize `Edit transaction`, description: $localize `Allow user to edit transaction`},
  { id: 'payments.refund', name: $localize `Refund transaction`, description: $localize `Allow user to refund existing transaction`},
  { id: 'payments.view', name: $localize `View transactions`, description: $localize `Allow user to view other users transactions`},
];

export const paymentsPermissions: PermissionGroup = {
  id: 'payments',
  name: $localize `Payments`,
  description: '',
  permissions
};
