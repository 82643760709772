import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-not-found',
  template: '<span i18n>We are sorry but the page could not be found</span>',
})
export class PageNotFoundComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }
}
