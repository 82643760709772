import { ANALYZE_FOR_ENTRY_COMPONENTS, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@fry/components/material';
import { DashboardCardsSpawnerComponent } from './dashboard-cards-spawner/dashboard-cards-spawner.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { DASHBOARD_CARDS } from './dashboard-cards.service';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [DashboardPageComponent, DashboardCardsSpawnerComponent],
  providers: [],
  exports: [DashboardPageComponent, DashboardCardsSpawnerComponent]
})
export class DashboardPageModule {
  static WithComponents(components: Type<any>[]): ModuleWithProviders<DashboardPageModule> {
    return {
        ngModule: DashboardPageModule,
        providers: [
            { provide: DASHBOARD_CARDS, useValue: components, multi: true },
            {
                provide: ANALYZE_FOR_ENTRY_COMPONENTS,
                useValue: components,
                multi: true
            }
        ]
    };
}
}
