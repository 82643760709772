import { EasElement, EasFieldType } from '@fry/components/common/easform';
import { Validators } from '@angular/forms';

export function getRefundOptionsForm(actionData, selected?) {
  const options = actionData.refund_options || [];

  const initial = options.find(itm => itm.default);

  let initialTitle = initial ? initial.title : '';
  let initialAmount = initial ? initial.default : null;

  if (selected && selected.refund_option) {
    initialTitle = selected.refund_option;
  }

  if (selected && selected.refund_amount !== undefined) {
    initialAmount = selected.refund_amount;
  }

  if (options.length === 0) {
    return [];
  }

  // We can skip the form if there is less than one option and
  // a default one. If not a default one we still let them pick the one
  // It is the admin responsibility to make it default in order to hide
  if (options.length <= 1 && initial) {
    return [];
  }

  const fields: EasElement[] = [
    {
      id: 'refund_option',
      type: EasFieldType.Field,
      title: $localize `Specify refund option`,
      initial: initialTitle,
      validators: [Validators.required],
      widget: {
        type: 'simpleSelect',
      },
      options: {
        categories: options.map(opt => ({id: opt.title, name: opt.title})),
      }
    },
    {
      id: 'refund_amount',
      type: EasFieldType.Field,
      title: $localize `Specify amount`,
      initial: initialAmount,
      widget: {
        type: 'number'
      },
      meta: {
        hidden: (_value, parent) => {
          const opt = options.find(itm => !parent.refund_option || itm.title === parent.refund_option);
          if (!opt) {
            return true;
          }

          return opt.type !== 'custom';
        }
      }
    }
  ];
  return fields;
}
