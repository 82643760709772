import { Injectable } from '@angular/core';
import { forkJoin, of, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BookingItemsStore } from '../booking-items';
import { BookingsStore } from './bookings.store';
import { BookingTypesStore } from '../booking-types';
import { ListSearch, Filter } from '@fry/lib/list';
import { sortBy } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class BookingsSearch implements ListSearch {

  constructor(
    private bookingTypes: BookingTypesStore,
    private bookingItems: BookingItemsStore,
    protected bookings: BookingsStore,
  ) {
  }

  protected fulltext() {
    return of({
      id: 'text',
      label: '',
      featured: true,
      widget: {
        type: 'string',
        placeholder: $localize `Full text search`,
      }
    });
  }


  protected types() {
    return this.bookingTypes.all().pipe(
      map(types => {
        return {
          id: 'bookingType',
          label: $localize `Booking type`,
          initial: [],
          widget: {
            type: 'multiSelect'
          },
          options: {
            categories: types.map(btype => {
              return { id: btype.doc._id, name: btype.doc.name };
            })
          }
        };
      })
    );
  }

  protected bookingConfirmation() {
    return of({
      id: 'metadata:bookingConfirmed',
      label: $localize `Show only confirmed bookings`,
      initial: false,
      widget: {
        type: 'boolean'
      }
    });
  }

  protected items(): Observable<Filter> {
    return this.bookingItems.allStub().pipe(
      map(types => {
        return {
          id: 'bookingItem',
          label: $localize `Booking item`,
          initial: [],
          widget: {
            type: 'multiSelect'
          },
          options: {
            categories: types.map(btype => {
              return { id: btype._id, name: btype.name };
            })
          }
        };
      })
    );
  }

  protected stateName(aggs: any): Observable<Filter | undefined> {
    if (!aggs) {
      return of(undefined);
    }
    const opts = aggs['by_bookingStateName']['buckets'].map(itm => {
      return {id: itm.key, name: `${itm.key} (${itm.doc_count})`};
    });
    return of({
      id: 'es_extra.stateName',
      label: $localize `State`,
      initial: [],
      widget: {
        type: 'multiSelect'
      },
      options: {
        categories: sortBy(opts, ['name'])
      }
    });
  }

  protected bookingItemName(aggs: any): Observable<Filter | undefined> {
    if (!aggs) {
      return of(undefined);
    }
    const opts = aggs['by_bookingItem']['buckets'].map(itm => {
      return {id: itm.key, name: `${itm.key} (${itm.doc_count})`};
    });
    return of({
      id: 'parsed_option.bookingItem.name',
      label: $localize `Booking item`,
      initial: [],
      widget: {
        type: 'multiSelect'
      },
      options: {
        categories: sortBy(opts, ['name'])
      }
    });
  }

  protected bookingOptionName(aggs: any): Observable<Filter | undefined> {
    if (!aggs) {
      return of(undefined);
    }
    const opts = aggs['by_bookingOption']['buckets'].map(itm => {
      return {id: itm.key, name: `${itm.key} (${itm.doc_count})`};
    });
    return of({
      id: 'parsed_option.title',
      label: $localize `Booking option`,
      initial: [],
      widget: {
        type: 'multiSelect'
      },
      options: {
        categories: sortBy(opts, ['name'])
      }
    });
  }

  public filters(): Observable<Filter[]> {
    return this.bookings.aggregates().pipe(
      catchError(err => {
        console.log(err);
        return of([]);
      }),
      switchMap(aggs => {
        return forkJoin([
          this.fulltext(),
          this.types(),
          this.bookingItemName(aggs),
          this.bookingOptionName(aggs),
          // this.items(),
          this.bookingConfirmation(),
          this.stateName(aggs),
        ]);
      }),
      map((filters): Filter[] => {
        const isFilter = (obj: any): obj is Filter => {
          return obj !== undefined;
        };

        return filters.filter(isFilter);
      })
    );
  }

  public sorts() {
    return of([
      {
        id: 'addedDate',
        name: $localize `Created`,
      },
      {
        id: 'modifiedDate',
        name: $localize `Last modified`,
      },
      {
        id: 'stateName',
        name: $localize `State`,
      }
    ]);
  }

}
