import { BaseModel } from '@fry/lib/store';
import { forOwn } from 'lodash';
import { SYSTEM_ROLE_ID } from '../roles/roles.system';

interface OriginalHistory {
  id: string;
  type: 'relation' | 'user_dependent';
  start: string;
  end: string;
  event: string;
  value: any;
}

export interface ProfileHistoryGroup {
  id: string;
  type: 'relation' | 'user_dependent';
  history: ProfileHistory[];
}

export interface ProfileHistory {
  start: string;
  end: string;
  event: string;
  value: any;
  period: 'previous' | 'current' | 'future';
}

export class User extends BaseModel {
  public cache: any;
  public userFields: any;

  init() {
    this.doc = this._getDoc();
    this.cache = this.orig['_tmp_cache'] || {};
    this.dates = this.orig['auditLog'] || [];
    if (this.orig['userFields']) {
      this.userFields = (this.orig['userFields'] || []).reduce((cum, itm) => {
        cum[itm._id] = itm.value || itm.values;
        return cum;
      }, {});
    } else {
      this.userFields = this.orig;
    }
  }

  public get fullname(): string {
    return (
      `${this.doc['firstName'] || ''} ${this.doc['lastName'] || ''}`.trim() ||
      this.doc['email']
    );
  }

  public get initials() {
    return this.fullname.split(' ').map(itm => itm.charAt(0)).join('');
  }

  private _getDoc() {
    const doc = Object.assign({}, this.orig);
    delete doc['_tmp_cache'];
    delete doc['dates'];
    return doc;
  }

  public applyUserFields() {
    const data = [];
    forOwn(this.userFields, (value, id) => {
      data.push({ _id: id, value });
    });
    this.doc['userFields'] = data;
  }

  public applyDoc() {
    this.applyUserFields();
    Object.assign(this.orig, this.doc);
  }

  public get email() {
    return this.emails.find(email => {
      return email.type === 'primary';
    }).email;
  }

  public get emails() {
    // return pick(this.doc, ['email', 'secondaryEmails']);
    const emails = [{ type: 'primary', email: this.doc.email }];
    const secondary = (this.doc.secondaryEmails || []).map(email => ({
      type: 'secondary',
      email
    }));
    return [...emails, ...secondary];
  }

  public getLastAction(actionType?: string) {
    let dates = this.dates;
    if (actionType !== undefined) {
      dates = dates.filter(itm => itm.action === actionType);
    }
    return dates.slice(-1)[0];
  }

  public getRoles(): string[] {
    console.log('Getting roles');
    if (this.doc.state === 'disabled') {
      return [];
    }

    const roles = [].concat(
      [SYSTEM_ROLE_ID.TIMELINE_OWNER, SYSTEM_ROLE_ID.ANYONE],
      this.doc.roles || []
    );
    if (this.doc.isSuperUser) {
      roles.push(SYSTEM_ROLE_ID.SUPER_ADMIN);
      roles.push(SYSTEM_ROLE_ID.ADMIN);
    }

    if (this.doc.isOrgSuperUser) {
      roles.push(SYSTEM_ROLE_ID.ADMIN);
    }

    if (this.doc.state === 'waiting_for_approval') {
      roles.push(SYSTEM_ROLE_ID.PENDING_USER);
    }

    return roles;
  }

  public history(): ProfileHistoryGroup[] {
    console.log('Fetching history');
    if (!this.cache.profile || !this.cache.profile.history) {
      return [];
    }
    let history = this.cache.profile.history as OriginalHistory[];
    const result = {};
    const today = new Date().toISOString().slice(0, 10);
    const orderMapping = {
      previous: -1,
      current: 0,
      future: 1,
    };
    history.forEach(itm => {
      if (!itm.value || (Array.isArray(itm.value) && itm.value.length === 0)) {
        return;
      }
      if (result[itm.id] === undefined) {
        result[itm.id] = {
          type: itm.type,
          id: itm.id,
          history: []
        };
      }
      let period: 'previous' | 'current' | 'future';
      if (itm.end < today) {
        period = 'previous';
      } else if (itm.start <= today) {
        period = 'current';
      } else {
        period = 'future';
      }

      const historyItem: ProfileHistory = {
        start: itm.start,
        end: itm.end,
        value: Array.isArray(itm.value) ? itm.value : [itm.value],
        event: itm.event,
        period,
      };

      result[itm.id].history.push(historyItem);

    });

    Object.keys(result).forEach(key => {
      result[key].history = result[key].history.sort((a, b) => {
        if (a.period !== b.period) {
          return orderMapping[b.period] - orderMapping[a.period];
        }

        if (a.start !== b.start) {
          return a.start > b.start ? -1 : 1;
        } else {
          return a.end > b.end ? -1 : 1;
        }
      });
    });

    history = history.sort((a, b) => {
      if (a.start !== b.start) {
        return a.start > b.start ? -1 : 1;
      } else {
        return a.end > b.end ? -1 : 1;
      }
    });


    return Object.values(result);
  }
}
