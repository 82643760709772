import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IndependentTransitionGuard } from '@fry/components/common/form-builder/builder-transition-guard';
import { TransactionInternalReference, TransactionsStore } from '@fry/payments/lib';

@Injectable()
export class AllTransactionsPaidGuard implements IndependentTransitionGuard {
    public static readonly code: string = 'ensure_payments_paid';
    public static readonly title?: string = '';
    public get code() { return AllTransactionsPaidGuard.code; }
    public get title() { return AllTransactionsPaidGuard.title; }

    constructor(private transactionsStore: TransactionsStore) {}

    public evaluate(model: any, _user: any): Observable<boolean> {
        const subject: TransactionInternalReference = {
            type: model.type,
            id: model.id
        };
        return this.transactionsStore.searchBySubject(subject).pipe(
            map(transactions => transactions.reduce((paid, transaction) => {
                                    if (!paid) { return false; }
                                    // This construction is weird but we need to ignore
                                    // cancelled transactions as they are not meant to be paid
                                    // and thus shouldn't block this guard.
                                    if (transaction.role !== 'payment') { return true; }
                                    if (transaction.isCanceled) { return true; }
                                    return transaction.isSettled;
                                }, true)
            )
        );
    }
}
