// tslint:disable: no-inferrable-types
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { EasFormGroup } from './easform';

@Component({
  selector: 'eas-easform-group',
  templateUrl: './easform-group.component.html'
})
export class EasFormGroupComponent implements OnInit {
  @Input() easGroup: EasFormGroup;
  @Input() templates = {};

  /**
   * Weather the read-only fields should be visible regardless their values.
   *
   * By default the field is ommited from the form if it does not have value
   * which it does not in the Workflow Editor - From preview.
   */
  @Input() forceDisplayReadOnly: boolean = false;

  beforeTemplate: TemplateRef<any>;
  afterTemplate: TemplateRef<any>;
  mainTemplate: TemplateRef<any>;

  ngOnInit() {
    const templates = this.easGroup.field.templates || {};
    this.beforeTemplate = this.templates[templates.before];
    this.afterTemplate = this.templates[templates.after];
    this.mainTemplate = this.templates[templates.main];
  }
}
