import { Inject, Injectable, InjectionToken } from '@angular/core';
import { forkJoin, isObservable, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import {
  FBField,
  FBFieldEvaluator,
  FBFieldList,
  FBForm,
  FBFormOptions,
  FieldType,
  FormField
} from './forms.interface';
import {
  EasElement,
  EasField,
  EasFieldArray,
  EasFieldControl,
  EasFieldGroup,
  EasFieldType
} from '@fry/components/common/easform/easform.interfaces';

export const FORM_EVALUATORS = new InjectionToken<FBFieldEvaluator>('FORM_EVALUATORS');


@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {

  private evaluators: {[K: string]: FBFieldEvaluator} = {};

  constructor(
      @Inject(FORM_EVALUATORS) private _evaluators: FBFieldEvaluator[],
  ) {

    this._evaluators.forEach(evaluator => {
      this.evaluators[evaluator.fieldType] = evaluator;
    });

    console.log(this.evaluators);
  }

  private buildFields(fields: FBField[], options: FBFormOptions): Observable<FormField[]> {
    const fields$: Observable<FormField>[] = [];
    fields.forEach(field => {
      let field$: Observable<FormField>;
      const defaults = {
        id: field.id,
        showCondition: field.showCondition,
        templateRefId: field.templateRefId,
      };

      if (field.type === 'group' || field.type === 'array') {
        if (field.fields) {
          field$ = this.buildFields(field.fields, options).pipe(
            map(_fields => {
              return {
                ...defaults,
                type: field.type as FieldType,
                fields: _fields,
                validators: [],
              };
            })
          );
        } else {
          field$ = of({
            ...defaults,
            type: field.type as FieldType,
            fields_from: field.fields_from,
            validators: []
          });
        }
      } else {
        let evaluator: FBFieldEvaluator = this.evaluators[field.type];
        if (!evaluator) {
          evaluator = this.evaluators['general'];
        }
        if (!evaluator) {
          return;
        }

        field$ = evaluator.evalField(field, options).pipe(
          map(data => {
            if (data !== undefined) {
              return {...defaults, ...data};
            } else {
              return undefined;
            }
          })
         );
      }

      fields$.push(field$);
    });

    if (fields$.length === 0) {
      return of([]);
    }

    return forkJoin(fields$).pipe(
      map(res => res.filter(itm => itm !== undefined)),
    );

  }

  private _evalFields(fields: FBFieldList): Observable<FBField[]> {
    const fields$: Observable<FBField>[] =  fields.map(field => {
      const field$ = isObservable(field) ? field : of(field);
      return field$.pipe(
        mergeMap(fld => {
          if (Array.isArray(fld.fields)) {
            return this._evalFields(fld.fields).pipe(
              map(subflds => {
                return { ...fld, fields: subflds };
              })
            );
          } else if (isObservable(fld.fields)) {
            return fld.fields.pipe(
              switchMap(flds => this._evalFields(flds)),
              map(subflds => {
                return { ...fld, fields: subflds };
              })
            );
          }
          return of(fld as FBField);
        })
      );
    });
    return forkJoin(fields$);
  }

  public evalFields(fields: FBFieldList): Observable<FBField[]> {
    return this._evalFields(fields);

  }

  public buildForm(form: FBForm, options: FBFormOptions): Observable<FormField[]> {
    if (form.fields.length === 0) {
      return of([]);
    }

    return this.evalFields(form.fields).pipe(
      switchMap(fields => this.buildFields(fields, options))
    );
  }

  private _transformToEas(fields: FormField[]): EasField[] {
    return fields.map(field => {
      const easField: EasElement = {
        id: field.id,
        type: (field.type || 'field') as EasFieldType,
        templates: {
          before: field.templateRefId,
        },
        validators: field.validators,
        options: {
          noFormControl: field.noFormControl,
          readOnly: field.readOnly,
          ...(field.options || {})
        },
        meta: {},
      };

      if (field.showCondition) {
        easField.meta.hidden = (value: any, parent: any, doc: any): boolean => {
          return !field.showCondition(value, parent, doc);
        };
      }

      if (field.type === 'group') {
        (easField as EasFieldGroup).fields = this._transformToEas(field.fields);
      } else if (field.type === 'array') {
        if (field.fields) {
          (easField as EasFieldArray).fields = this._transformToEas(field.fields);
        } else {
          (easField as EasFieldArray).fields_from = field.fields_from;
        }
      } else {
        const easFieldControl = easField as EasFieldControl;
        easFieldControl.widget = field.widget;
        easFieldControl.title = field.title;
        easFieldControl.initial = field.initial;

        if (field.options && field.options.categories) {
          easFieldControl.options.categories = field.options.categories;
        }
      }

      return easField;
    });
  }

  public transformToEas(fields: FormField[]): EasField[] {
    return this._transformToEas(fields);
  }
}
