import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'userFields.view', name: $localize `View user fields`, description: $localize `Allow user to view user fields list` },
  { id: 'userFields.edit', name: $localize `Edit user fields`, description: $localize `Allow user to edit or create user fields`},
];

export const userFieldPermissions: PermissionGroup = {
  id: 'userFields',
  name: $localize `User Fields`,
  description: '',
  permissions
};
