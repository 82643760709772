import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EasFormGroupComponent } from './easform-group.component';
import { EasFormArrayComponent } from './easform-array.component';
import { EasFormControlComponent } from './easform-control.component';
import { MaterialModule } from '@fry/components/material';
import { FryCommonModule } from '../common.module';
import { EasFormDialogComponent } from './easform-dialog.component';
import { UnboxedModule } from '@fry/ui';
import { EasFormElementComponent } from './easform-element.component';
import { FileUploadControlModule } from '@fry/components/common/file-upload-control/file-upload-control.module';
import { FileUploadStore, S3PresignedFileUploadStore } from '@fry/components/common/file-upload-control';

const components = [
  EasFormGroupComponent,
  EasFormArrayComponent,
  EasFormControlComponent,
  EasFormDialogComponent,
  EasFormElementComponent,
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FryCommonModule,
    UnboxedModule,
    MaterialModule,
    FileUploadControlModule
  ],
  providers: [
    { provide: FileUploadStore, useClass: S3PresignedFileUploadStore }
  ],
  exports: components
})
export class EasFormModule { }
