import { Injectable } from '@angular/core';
import { EasFormGroup } from './easform';
import { EasField, EasFieldGroup, EasFieldType } from './easform.interfaces';


@Injectable({
  providedIn: 'root'
})
export class EasFormService {

  constructor(

  ) { }

  public createForm(fields: EasField[] | EasFieldGroup, initialValue: any): EasFormGroup {
    let group: EasFieldGroup;

    if (Array.isArray(fields)) {
      group = {
        id: 'root',
        type: EasFieldType.Group,
        fields
      };
    } else {
      group = fields;
    }
    const form = new EasFormGroup(group);
    form.createControl();
    form.setValue(initialValue);
    return form;
  }
}
