import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CONFIG, FrontendConfig } from '@fry/lib/config/config';

@NgModule()
export class ConfigModule {
  constructor(@Optional() @SkipSelf() parentModule: ConfigModule) {
    if (parentModule) {
      throw new Error('ConfigModule is already loaded. It should only be imported in your application\'s main module.');
    }
  }
  static forRoot(config: FrontendConfig): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        { provide: CONFIG, useValue: config }
      ]
    };
  }
}
