import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { TransactionsStore } from './transactions.store';
import { ListSearch } from '@fry/lib/list';
import { PaymentMethodsStore } from './payment_methods.store';
import { BookingItemsStore } from '@fry/booking/lib/booking-items';


@Injectable({
  providedIn: 'root'
})
export class TransactionSearch implements ListSearch {

  constructor(
    private transactions: TransactionsStore,
    private paymentMethods: PaymentMethodsStore,
    private bookingItems: BookingItemsStore
  ) {
  }

  protected fulltext() {
    return of({
      id: 'text',
      label: '',
      featured: true,
      widget: {
        type: 'string',
        placeholder: $localize `Full text search`,
      }
    });
  }

  protected byPaymentMethod() {
    return this.paymentMethods.all().pipe(
      map(pms => {
        return {
          id: 'method',
          label: $localize `Payment method`,
          initial: [],
          widget: {
            type: 'multiSelect'
          },
          options: {
            categories: pms.map(pm => {
              return { id: pm.doc._id, name: pm.doc.name };
            })
          }
        };
      })
    );
  }

  protected bySubject() {
    return this.bookingItems.all().pipe(
      map(items => {
        return {
          id: 'subject',
          label: $localize `Booking item`,
          initial: [],
          widget: {
            type: 'multiSelect'
          },
          options: {
            categories: items.map(itm => {
              return { id: itm.doc._id, name: itm.doc.name };
            })
          }
        };
      })
    );
  }

  protected byState() {
    return of({
      id: 'state',
      label: $localize `State`,
      initial: [],
      widget: {
        type: 'multiSelect'
      },
      options: {
        categories: [
          { id: 'pending', name: $localize `Pending` },
          { id: 'actioned', name: $localize `Actioned` },
          { id: 'failed', name: $localize `Failed` },
          { id: 'completed', name: $localize `Completed` },
        ]
      }
    });
  }

  protected byRole() {
    return of({
      id: 'role',
      label: $localize `Transaction role`,
      initial: [],
      widget: {
        type: 'multiSelect'
      },
      options: {
        categories: [
          { id: 'payment', name: $localize `Payment` },
          { id: 'refund', name: $localize `Refund` }
        ]
      }
    });
  }

  protected createdDateStart() {
    return of({
      id: 'createdDate_start',
      label: $localize `Created on or after`,
      initial: null,
      widget: {
        type: 'date'
      }
    });
  }

  protected createdDateEnd() {
    return of({
      id: 'createdDate_end',
      label: $localize `Created on or before`,
      initial: null,
      widget: {
        type: 'date'
      }
    });
  }

  protected processedDateStart() {
    return of({
      id: 'processedDate_start',
      label: $localize `Processed on or after`,
      initial: null,
      widget: {
        type: 'date'
      }
    });
  }

  protected processedDateEnd() {
    return of({
      id: 'processedDate_end',
      label: $localize `Processed on or before`,
      initial: null,
      widget: {
        type: 'date'
      }
    });
  }

  public filters() {
    return this.transactions.aggregates().pipe(
      switchMap(() => {
        return forkJoin([
          this.fulltext(),
          this.byState(),
          this.byPaymentMethod(),
          this.byRole(),
          this.createdDateStart(),
          this.createdDateEnd(),
          this.processedDateStart(),
          this.processedDateEnd(),
        ]);
      })
    );
  }

  public sorts() {
    return of([
      {
        id: 'addedDate',
        name: $localize `Added date`,
      },
      {
        id: 'state',
        name: $localize `State`,
      },
      {
        id: 'role',
        name: $localize `Role`,
      },
      {
        id: 'currency',
        name: $localize `Currency`,
      },
      {
        id: 'user',
        name: $localize `User`,
      }
    ]);
  }

}
