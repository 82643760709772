// import { SwUpdate } from '@angular/service-worker';
import { Injectable } from '@angular/core';
// import { NotifyService } from './notify.service';

@Injectable({
    providedIn: 'root'
})
export class SWService {
  // public available = this.updates.available;

  constructor(
    // private updates: SwUpdate,
    // private notify: NotifyService
  ) {
    // updates.available.subscribe(event => {
    //   console.log('SW: current version is', event.current);
    //   console.log('SW: available version is', event.available);
    //   this.notify.warning('New version is available. Please reload the page');
    // });
    // updates.activated.subscribe(event => {
    //   console.log('SW: old version was', event.previous);
    //   console.log('SW: new version is', event.current);
    //   this.notify.warning('New version has been activated.');
    // });
  }

  logSelf() {
    console.log('SW: I\'m here');
  }

  checkUpdate() {
    return;
    // this.updates.checkForUpdate().then(dd => {
    //   console.log('SW: Checked', dd);
    // }).catch(dd => {
    //   console.log('SW: Failed', dd);
    // });
  }
}
