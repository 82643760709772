import { APP_BASE_HREF } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ServerConfig, SERVER_CONFIG } from '../config';
import { AppLoadService } from './appload.service';

@NgModule()
export class AppLoadModule {
  constructor(@Optional() @SkipSelf() parentModule: AppLoadModule) {
    if (parentModule) {
      throw new Error($localize `AppLoadModule is already loaded. It should only be imported in your application's main module.`);
    }
  }
  static forRoot(): ModuleWithProviders<AppLoadModule> {
    return {
      ngModule: AppLoadModule,
      providers: [
        AppLoadService,
        {
          provide: APP_INITIALIZER,
          useFactory: (appLoadService: AppLoadService) => {
            return () => appLoadService.initializeApp();
          },
          deps: [AppLoadService],
          multi: true
        },
        {
          provide: APP_BASE_HREF,
          useFactory: (
            config: ServerConfig,
            locale: string
          ) => {
            if (config.languages === undefined) {
              return '/';
            } else {
              return config.languages[locale] || '/';
            }
          },
          deps: [SERVER_CONFIG, LOCALE_ID],
        },
      ]
    };
  }
}
