import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProfileStore } from '@fry/lib/profile';
import { RolesStore } from '@fry/lib/roles';
import { intersect, LoggerService } from '@fry/lib/utils';
import { APIService } from '@fry/lib/api';
import { PermissionsService } from '../permissions';
import { AuthService } from '../auth';
import { SYSTEM_ROLE_ID } from '../roles/roles.system';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(
    private profile: ProfileStore,
    private roles: RolesStore,
    private api: APIService,
    private permissions: PermissionsService,
    private auth: AuthService,
    private logger: LoggerService,
  ) { }

  /**
   * Return whether the current user has a given permission
   *
   * @return {Observable<boolean>} [description]
   */
  public hasPermission(permission: string | string[]): Observable<boolean> {
    this.logger.debug('SecurityService.hasPermissions: Checking ', permission);
    if (!permission) {
      return of(true);
    }

    const permissions = Array.isArray(permission) ? permission : [permission];

    return this.permissions.withAvailable(permission).pipe(
      switchMap(() => {
        return forkJoin([
          this.profile.getCurrent(),
          this.roles.withPermissions(permissions)
        ]).pipe(
          map(result => {
            const profile = result[0];
            const roles = result[1];
            const profileRoles = profile.getRoles();
            if (profileRoles.includes(SYSTEM_ROLE_ID.ADMIN)) {
              return true;
            }
            return intersect(roles, profileRoles).length > 0;
          })
        );
      }),
      catchError(error => {
        this.logger.debug(`SecurityService.hasPermissions: ${error}`);
        return of(false);
      })
    );
  }

  /**
   * Return whether the current user has a given permission over a given user
   *
   * @return {Observable<boolean>} [description]
   */
  public hasPermissionFor(permission: string | string[], username: string, potential: boolean = false, context: string = ''): Observable<boolean> {
    this.logger.debug('SecurityService.hasPermissionsFor: Checking ', permission, username);
    if (!permission) {
      return of(true);
    }
    return this.permissions.withAvailable(permission).pipe(
      switchMap(() => this.api.get(`security/has_permission_for/${permission}/${username}?potential=${potential}&context=${context}`)),
      map((data: any) => data.has),
      catchError(error => {
        this.logger.debug(`SecurityService.hasPermissions: ${error}`);
        return of(false);
      }),
    );
  }

  /**
   * Return whether the current user has a given permission over a given user
   *
   * @return {Observable<boolean>} [description]
   */
  public hasPotentialPermissionFor(permission: string | string[], username: string): Observable<boolean> {
    return this.hasPermissionFor(permission, username, true);
  }

  public hasOwnPotentialPermissionFor(permission: string, username: string, ownPermission?: string, _potential?: boolean) {
    return this.hasOwnPermissionFor(permission, username, ownPermission, true);
  }

  public hasOwnPermissionFor(permission: string, username: string, ownPermission?: string, potential?: boolean) {
    return this.auth.isUserCurrent(username).pipe(
      switchMap(isCurrent => {
        if (isCurrent) {
          const perm = ownPermission !== undefined ? ownPermission : `${permission}.own`;
          return this.hasPermission(perm);
        } else {
          return this.hasPermissionFor(permission, username, potential);
        }
      })
    );
  }

  public withPermission(permission: string): Observable<void> {
    return this.hasPermission(permission)
      .pipe(
        map(has => {
          if (!has) {
            throw new Error(`User does not have permission ${permission}`);
          }

          return;
        })
      );
  }

  public withPermissionFor(permission: string, username: string): Observable<void> {
    return this.hasPermissionFor(permission, username)
      .pipe(
        map(has => {
          if (!has) {
            throw new Error(`User does not have permission ${permission}`);
          }

          return;
        })
      );
  }

  public hasRole(role: string): Observable<boolean> {
    return this.profile.getCurrent().pipe(
      map(profile => {
        const roles = profile.getRoles();
        return roles.indexOf(role) !== -1;
      })
    );
  }

  public hasRoleFor(_role: string, _username: string): Observable<boolean> {
    return of(false);
  }

  public getRolesFor(username: string): Observable<string[]> {
    return this.profile.getCurrent().pipe(
      switchMap(current => {
        if (current.doc.user === username) {
          return of(current.getRoles());
        }

        return this.api.get(`security/roles_for/${username}`).pipe(
          map(data => data.roles)
        );
      })
    )
  }
}
