import { Injectable } from '@angular/core';

import { UserField } from './user-field';

@Injectable()
export class UserFieldService {

  public userField: UserField;

  constructor() { }

  set(userField: UserField) {
      this.userField = userField;
  }

}
