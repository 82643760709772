import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BookingsStore } from '@fry/booking/lib/bookings';
import { of, Subject } from 'rxjs';
import { EASButtonAction } from '@fry/components/common/buttons/buttons.service';

enum STATES {
  INITIAL,
  CONFIRMATION,
  APPLIED,
  ERROR,
  WARNING,
  PROGRESS,
};

@Component({
  selector: 'eas-booking-item-option-apply',
  templateUrl: './booking-item-option-apply.component.html',
})
export class BookingItemOptionApplyComponent implements OnInit, OnDestroy {

  public STATES = STATES;
  public state: STATES = STATES.INITIAL;
  public applyInfo?: any;

  private destroyed$ = new Subject<void>();

  public buttons: EASButtonAction[] = [
    {
      type: 'button',
      label: $localize `Select`,
      color: 'primary',
      condition: () => of(this.state === STATES.INITIAL),
      click: () => {
        this.onFetchInfo();
      }
    },
    {
      type: 'button',
      label: $localize `Apply`,
      color: 'primary',
      condition: () => of(this.state === STATES.CONFIRMATION),
      click: () => {
        this.onApply();
      }
    },
    {
      type: 'button',
      label: $localize `Pick someone else`,
      color: 'warn',
      condition: () => of(this.state === STATES.CONFIRMATION),
      click: () => {
        this.onNewOne();
      }
    },
    {
      type: 'button',
      label: $localize `Create a new one`,
      color: 'primary',
      condition: () => of(this.state === STATES.APPLIED),
      click: () => {
        this.onNewOne();
      }
    },
    {
      type: 'button',
      label: $localize `View new booking`,
      color: 'warn',
      condition: () => of(this.state === STATES.APPLIED),
      click: () => {
        this.onNavigate();
      }
    },
    {
      type: 'button',
      label: $localize `Close`,
      color: 'warn',
      condition: () => of(true),
      click: () => {
        this.dialogRef.close();
      }
    },
  ];

  public formGroup = new UntypedFormGroup({
    user: new UntypedFormControl('', [Validators.required])
  });
  public error?: string;
  private bookingItem: string;
  private bookingOption: string;
  private bookableType: string;
  public createdBookingId?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      bookingOption: string,
      bookingItem: string,
      bookableType: string,
    },
    public dialogRef: MatDialogRef<BookingItemOptionApplyComponent>,
    private bookingsStore: BookingsStore,
  ) {
    this.bookingOption = data.bookingOption;
    this.bookingItem = data.bookingItem;
    this.bookableType = data.bookableType;
  }

  private setState(newState: STATES) {
    this.state = newState;
    // Trigger button evaluation
    this.buttons = [...this.buttons];
  }

  ngOnInit() {
    if (this.bookableType !== 'bookable') {
      this.setState(STATES.WARNING);
      this.error = $localize `It is not possible to apply for this option. Please navigate to a bookable one.`;
    }
  }

  onFetchInfo() {
    const user = this.formGroup.value.user;
    console.log(user);
    this.setState(STATES.PROGRESS);
    this.bookingsStore.applyInfo(
      user,
      this.bookingItem,
      this.bookingOption,
      {}
    ).subscribe(
      result => {
        this.applyInfo = result;
        this.setState(STATES.CONFIRMATION);
      },
      error => {
        this.setState(STATES.ERROR);
        this.error = error;
      }
    );
  }

  onApply() {
    const user = this.applyInfo?.user.user;
    if (!user) {
      throw new Error($localize `User is not selected`);
    }
    this.setState(STATES.PROGRESS);
    this.bookingsStore.apply(
      user,
      this.bookingItem,
      this.bookingOption,
      {
        ignoreWindow: true,
        ignoreEligibility: true,
        skipInitialCheck: true
      }
    ).subscribe(
      result => {
        this.createdBookingId = result.result.id;
        this.setState(STATES.APPLIED);
      },
      error => {
        this.error = error;
        this.setState(STATES.ERROR);
      }
    );
  }

  onNewOne() {
    this.createdBookingId = undefined;
    this.applyInfo = undefined;
    this.error = undefined;
    this.setState(STATES.INITIAL);
  }

  onNavigate() {
    this.dialogRef.close({ id: this.createdBookingId });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
