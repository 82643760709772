import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { FryConfig, FrontendConfig, ServerConfig, SERVER_CONFIG} from './config';

function isNotUndefinedOrNull<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

/**
 * Provides single access to all configurations
 *
 * `ConfigService` is an injectable service provided in root
 * (single instance available everywhere).
 *
 * It has to be prepared properly using `prepare()` method which handles
 * fetching configuration and making it available.
 */
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  /**
   * Key under which the configuration is stored in the browser storage
   */
  public static CONFIG_STORAGE_KEY = 'eas_server_config' as const;

  private config$$ = new BehaviorSubject<FryConfig|undefined>(undefined);

  /**
   * Observable for valid configuration
   *
   * This observable will fire when valid configuration is acquired or changes
   */
  public config$ = this.config$$.asObservable().pipe(filter(isNotUndefinedOrNull));

  /**
   * Access current configuration
   *
   * @throws An error when access is attempted before ConfigService::prepare() is called
   */
  public get config(): FryConfig {
    if (!this.config$$.value) {
      throw new Error($localize `Application configuration not done yet! Call ConfigService::prepare()!`);
    }
    return this.config$$.value;
  }

  /**
   * Access front end configuration
   *
   * @throws An error when access is attempted before ConfigService::prepare() is called
   */
  public get frontend(): FrontendConfig {
    return this.config.frontend;
  }

  /**
   * Access server configuration
   *
   * @throws An error when access is attempted before ConfigService::prepare() is called
   */
  public get server(): ServerConfig {
    return this.config.server;
  }

  public constructor(@Inject(SERVER_CONFIG) private serverConfig: ServerConfig) {}

  /**
   * Prepares configuration
   *
   * This methods handles loading of the server configuration and potential
   * fallback to defaults if all fails.
   *
   * @param frontend Default front end configuration
   * @param storage Web Storage API compatible object
   * @returns FryConfig which can either be from backend, storage or default.
   */
  public prepare(frontend: FrontendConfig, storage: Storage): Observable<FryConfig> {
    return this.loadServerConfig(storage).pipe(
      switchMap(data => {
        const config: FryConfig = {
          frontend: frontend,
          server: data ?? frontend.defaultServerConfig
        }
        this.config$$.next(config);
        return of(config);
      })
    );
  }

  /**
   * Loads server configuration
   *
   * We attempt to load the server configuration from the backend first.
   * If that fails for some reason we attempt to load it from Storage.
   *
   * @param storage Web Storage API compatible object
   * @returns Observable of ServerConfig or undefined if we fail to find
   *          valid configuration.
   */
  private loadServerConfig(_storage: Storage): Observable<ServerConfig|undefined> {
    return of(this.serverConfig);
  }
}
