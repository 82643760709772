export class PaymentsError extends Error {
  constructor(message: string) {
    const trueProto = new.target.prototype;
    super(message);
    Object.setPrototypeOf(this, trueProto);

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, PaymentsError);
    }

    this.name = 'PaymentsError';
  }
}

export class PaymentsErrorUnknownVendor extends PaymentsError {
    public readonly name = 'PaymentsErrorUnknownVendor';
}

export class PaymentsErrorMoneris extends PaymentsError {
    public readonly name = 'PaymentsErrorMoneris';
}

export class PaymentsErrorIntegrationNotReady extends PaymentsError {
    public readonly name = 'PaymentsErrorIntegrationNotReady';

    constructor(message: string = $localize `Integration or its dependencies not configured`) {
      super(message);
    }
}
