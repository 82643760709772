import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ListSearch } from '@fry/lib/list';


@Injectable()
export class BookingTypesSearch implements ListSearch {

  constructor(
  ) {}

  public filters() {
    return of([]);
  }

  public sorts() {
    return of([
      {
        id: 'name',
        name: $localize `name`,
      }
    ]);
  }

}
