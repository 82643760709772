export function pick(obj, fields) {
  return Object.assign({}, ...fields.map(key => ({ [key]: obj[key] || null })));
}

export function intersect(a: any[], b: any[]): any[] {
  // Filter is not mutable so we can run it directly on the list
  return a.filter(value => -1 !== b.indexOf(value));
}

function _categoriesToFlattenOptions(categories: any[], parent?: string) {
  let result = [];
  let name;
  categories.forEach(categ => {
    name = parent !== undefined ? `${parent} > ${categ.name}` : categ.name;
    result.push({ id: categ._id, name });
    if (Array.isArray(categ.categories)) {
      result = result.concat(_categoriesToFlattenOptions(categ.categories, name));
    }
  });
  return result;
}

export function categoriesToOptions(categories: any[]) {
  return _categoriesToFlattenOptions(categories);
  // return categories.map(categ => ({ id: categ._id, name: categ.name }));
}

export function flattenCategories(categories: any[]) {
  const flat = {};
  categories.forEach(categ => {
    flat[categ._id] = categ.name;
    if (categ.categories) {
      Object.assign(flat, flattenCategories(categ.categories));
    }
  });
  return flat;
}

export function findCategory(categories: any[], id: string) {
  const flatten = flattenCategories(categories);
  return flatten[id];
}

export function guid() {
  // Came from https://gist.github.com/jed/982883, so let's not eslint it
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function now() {
  return new Date();
}

export function dateWithinRange(value: Date, start: Date, end: Date) {
  return (value >= start && value <= end);
}

export function nowWithinRange(start: Date, end: Date) {
  return dateWithinRange(now(), start, end);
}


export function findOption(options: any[], optionId) {
  let option = options.find(itm => itm.id === optionId);
  if (option) {
    return option;
  }

  for (const itm of options) {
    option = findOption(itm.subsessions, optionId);
    if (option) {
      return option;
    }
  }

  return;
}


export function iCaseCompare(a, b): number {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}


/**
 * Scrolls element specified by the CSS Selector into view.
 *
 * @param selector Valid CSS Selector of element to scroll into view.
 *
 * @param options Options for `HTMLElement.scrollIntoView()` method,
 *                see `ScrollIntoViewOptions`.
 *
 * @throws Mthod throws error when invalid value for Selector is passed.
 *
 * @returns True when it did scroll, false otherwise (no element with selector).
 */
export function scrollSelectorIntoView(selector: string,
                                       options: boolean | ScrollIntoViewOptions = { behavior: 'smooth' }): boolean {
  if (!(selector && selector.length > 0)) {
    throw new Error(`Provide valid CSS class name, provided: '${selector}'.`);
  }

  const elementList = document.querySelectorAll(selector);
  if (elementList.length === 0) { return false; }

  const element = elementList[0] as HTMLElement;
  element.scrollIntoView(options);
  return true;
}

export function objectDownload(filename, objectUrl) {
  const link = document.createElement('a');
  link.href = objectUrl;
  link.download = filename;
  link.click();
}
