import { Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { ButtonsService, EASAction, EASButtonAction, EASLinkAction } from './buttons.service';

export enum MenuIcon {
  Open = 'close',
  Closed = 'more_horiz',
  ClosedVert = 'more_vert',
}

interface MenuOptions {
  closed: MenuIcon;
  open: MenuIcon;
}

@Component({
  selector: 'eas-buttons-list',
  templateUrl: './buttons.list.component.html',
})
export class ButtonsListComponent implements OnChanges {
  @Input() buttons: EASAction[];
  @Input() options: Partial<MenuOptions>;
  @Input() disabled: boolean;
  @Input() processing: boolean;

  public filteredButtons$: Observable<EASAction[]>;
  public menuIcon: MenuIcon = MenuIcon.ClosedVert;

  private _options: MenuOptions;

  constructor(private buttonsService: ButtonsService) {
    this.disabled = false;
    this.processing = false;
  }

  ngOnChanges() {
    this._options = {closed: MenuIcon.Closed, open: MenuIcon.Open, ...(this.options || {})};
    this.menuIcon = this._options.closed;
    this.filteredButtons$ = this.buttonsService.processButtons(this.buttons);
  }

  //
  // Template helpers
  //

  public isLinkAction(item: EASAction): item is EASLinkAction {
    return (item as EASLinkAction).type === 'link';
  }

  public isButtonAction(item: EASAction): item is EASButtonAction {
    return (item as EASButtonAction).type === 'button';
  }

  public menuOpened() {
    this.menuIcon = this._options.open;
  }

  public menuClosed() {
    this.menuIcon = this._options.closed;
  }
}
