import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AuthToken {

  private _token = '';
  private _org = '';

  constructor(
  ) { }

  public getToken() {
    let token = this._token;
    let loaded = false;
    if (!token) {
      token = localStorage.getItem('access_token');
      if (token) {
        this._token = token;
        loaded = true;
      }
    }
    return { token, loaded };
  }

  public getOrganisation() {
    let org = this._org;
    let loaded = false;
    if (!org) {
      org = localStorage.getItem('access_org');
      if (org) {
        this._org = org;
        loaded = true;
      }
    }
    return { organisation: org, loaded };
  }

  public get token() {
    let token = this._token;
    if (!token) {
      token = localStorage.getItem('access_token');
    }
    return token;
  }

  public set token(token: string) {
    this._token = token;
    if (token) {
      localStorage.setItem('access_token', token);
    } else {
      localStorage.removeItem('access_token');
    }
  }

  public get organisation() {
    let org = this._org;
    if (!org) {
      org = localStorage.getItem('access_org');
    }
    return org;
  }

  public set organisation(org: string) {
    this._org = org;
    if (org) {
      localStorage.setItem('access_org', org);
    } else {
      localStorage.removeItem('access_org');
    }
  }
}
