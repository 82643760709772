import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, } from '@angular/forms';

/**
 * Custom field for GMC number
 *
 * **WARNING**
 * THIS COMPONENT SEEMS NOT IN USE AND SHOULD BE RETIRED!
 */
@Component({
  selector: 'eas-gmc-form-control',
  templateUrl: './gmc-form-control.component.html',
  styleUrls: ['./gmc-form-control.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GMCFormControlComponent),
      multi: true
    }
  ]
})
export class GMCFormControlComponent implements ControlValueAccessor {

  gmcNumber = '';
  disabled = false;

  @Input() firstName;
  @Input() lastName;

  /*
  static ValidFormatValidator = (control: AbstractControl) => {
    const pattern = new RegExp('^[0-9]{7}$');
    const pass = pattern.test(control.value);

    return pass ? null : { 'gmcInvalidFormat': { value: control.value } };
  }

  static ValidGMCNumberValidator = (gmcValidatorService: GMCValidatorService) => {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      let firstName;
      let lastName;

      if (!isNullOrUndefined(control.parent)) {
        firstName = control.parent.get('firstName').value || '';
        lastName = control.parent.get('lastName').value || '';
        return gmcValidatorService.validate(control.value, firstName, lastName).pipe(
          map(res => {
            console.log(res);
            return res.valid ? null : { 'gmcInvalidNumber': {
              'value': control.value,
              'firstName': firstName,
              'lastName': lastName
            }};
          })
        );
      }
    };
  }
  */

  // static ValidGMCNumberValidator = (control: AbstractControl) => {

  //   const mockedGMCList = [
  //     {
  //       'GMC Ref No': '1111111',
  //       'Surname': 'Test1',
  //       'Given Name': 'Test1',
  //       'Gender': 'M',
  //       'Qualification': 'MB BS',
  //       'Year Of Qualification': '2001',
  //       'Place of Qualification': 'University of Karachi',
  //       'Registration Status': 'Registered with Licence'
  //     },
  //     {
  //       'GMC Ref No': '1111112',
  //       'Surname': 'Test2',
  //       'Given Name': 'Test2',
  //       'Gender': 'W',
  //       'Qualification': 'MB BCh',
  //       'Year Of Qualification': '2003',
  //       'Place of Qualification': 'University of Wales',
  //       'Registration Status': 'Registered with Licence'
  //     },
  //     {
  //       'GMC Ref No': '1111113',
  //       'Surname': 'Test3',
  //       'Given Name': 'Test3',
  //       'Gender': 'W',
  //       'Qualification': 'MB BS',
  //       'Year Of Qualification': '1998',
  //       'Place of Qualification': 'University of London',
  //       'Registration Status': 'Not Registered - Administrative Reason'
  //     }
  //   ];

  //   let firstName;
  //   let lastName;
  //   let gmcRecord;

  //   if (!isNullOrUndefined(control.parent)) {
  //     firstName = control.parent.get('firstName').value || '';
  //     lastName = control.parent.get('lastName').value || '';

  //     gmcRecord = mockedGMCList.filter((x => x['GMC Ref No'] === control.value &&
  //       x['Surname'] === lastName && x['Given Name'] === firstName &&
  //       x['Registration Status'] === 'Registered with Licence'))[0];
  //   } else {
  //     firstName = control.value || '';
  //     lastName = control.value || '';
  //   }

  //   return gmcRecord ? null : { 'gmcInvalidNumber': {
  //     'value': control.value,
  //     'firstName': firstName,
  //     'lastName': lastName,
  //     'gmcRecord': gmcRecord
  //   }};
  // }

  constructor() {
    console.warn('GMC Component created!');
  }

  ///
  /// ControlValueAccessor Interface
  ///
  onChange = () => {};
  onTouch = () => {};

  writeValue(value: string) {
    this.gmcNumber = value ? value : '';
    this.onChange();
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
