import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
// import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

import { AppLoadModule } from '@fry/lib/appload';
import { ConfigModule } from '@fry/lib/config';
import { AuthModule } from '@fry/lib/auth';
import { LayoutModule } from '@fry/components/layout/layout.module';
import { FryCommonModule } from '@fry/components/common';
import { MenuService, RemoteMenuService } from '@fry/lib/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@fry/components/material';
import { EasFormModule } from '@fry/components/common/easform/easform.module';
import { BookingWorkflowModule } from '@fry/booking/lib/bookings/workflow/workflow.module';

import { PERMISSIONS, PermissionsModule } from '@fry/lib/permissions';
import { userPermissions } from '@fry/lib/users';
import { rolesPermissions } from '@fry/lib/roles';
import { userFieldPermissions } from '@fry/lib/user-fields';
import { bookingTypePermissions } from '@fry/booking/lib';
import { bookingItemsPermissions } from '@fry/booking/lib/booking-items';
import { venuesPermissions } from '@fry/booking/lib/venues';
import { BookingInfo, bookingsPermissions } from '@fry/booking/lib/bookings';
import { paymentsPermissions } from '@fry/payments/lib';
import { TYPE_INFO } from '@fry/lib/store';
import { PaymentMethodInfo } from '@fry/payments/lib/payment_method.info';
import { TransactionInfo } from '@fry/payments/lib/transaction.info';

import { ErrorModule } from '@fry/lib/error';
import { workflowPermissions } from '@fry/booking/lib/workflow';
import { eligibilityPermissions } from '@fry/booking/lib/eligibility';
import { translationPermissions } from '@fry/booking/lib/translation';


// This cannot be any clever, it has to be a single constant for the AOT compiler to understand
const permissionProviders = [
  { provide: PERMISSIONS, multi: true, useValue: userPermissions },
  { provide: PERMISSIONS, multi: true, useValue: rolesPermissions },
  { provide: PERMISSIONS, multi: true, useValue: userFieldPermissions },
  { provide: PERMISSIONS, multi: true, useValue: bookingTypePermissions },
  { provide: PERMISSIONS, multi: true, useValue: bookingItemsPermissions },
  { provide: PERMISSIONS, multi: true, useValue: venuesPermissions },
  { provide: PERMISSIONS, multi: true, useValue: bookingsPermissions },
  { provide: PERMISSIONS, multi: true, useValue: paymentsPermissions },
  { provide: PERMISSIONS, multi: true, useValue: workflowPermissions },
  { provide: PERMISSIONS, multi: true, useValue: eligibilityPermissions },
  { provide: PERMISSIONS, multi: true, useValue: translationPermissions },
];

const searchProviders = [
  { provide: TYPE_INFO, multi: true, useClass: BookingInfo},
  { provide: TYPE_INFO, multi: true, useClass: PaymentMethodInfo},
  { provide: TYPE_INFO, multi: true, useClass: TransactionInfo},
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppLoadModule.forRoot(),
    PermissionsModule.forRoot(permissionProviders),
    CommonModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    MaterialModule,
    FryCommonModule,
    LayoutModule,
    AuthModule.forRoot(),
    ConfigModule.forRoot(environment),
    AppRoutingModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    EasFormModule,
    BookingWorkflowModule.forRoot(),
    ErrorModule,
  ],
  providers: [
    CurrencyPipe,
    MenuService,
    { provide: RemoteMenuService, useClass: MenuService },
    ...searchProviders,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
