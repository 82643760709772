import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UBAppearance, UBAriaLiveRegionPriority } from '../unboxed.module.typings';

@Component({
  selector: 'ub-alert',
  exportAs: 'unboxedAlert',
  styleUrls: ['./alert.css'],
  templateUrl: 'alert.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedAlert implements OnInit {
  @Input()
  public message: string;

  @Input()
  public variant: UBAppearance;

  @Input()
  public dismissable: boolean;

  @Input()
  public dismissAfter: number;

  @Input()
  public visible: boolean = true;

  // Accessibility for aria-live regions
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
  @Input()
  public priority: UBAriaLiveRegionPriority = 'off';

  @Output()
  public dismissed = new EventEmitter<boolean>();

  public isDismissed = false;

  private timeout: NodeJS.Timeout;

  public ngOnInit(): void {
    if (this.dismissAfter) {
      this.timeout = setTimeout(() => {
        this.remove();
      }, this.dismissAfter);
    }
  }

  public dismiss(): void {
    if (this.dismissable) {
      this.remove();
    }
  }

  public getClassNames(): string[] {
    return [
      'ub-alert',
      this.variant ? `ub-alert--${this.variant}` : null
    ].filter(Boolean);
  }

  private remove(): void {
    clearTimeout(this.timeout);
    this.dismissed.emit(true);
    this.isDismissed = true;
  }
}
