import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UBAppearance } from '../unboxed.module.typings';

@Component({
  selector: 'ub-card',
  exportAs: 'unboxedCard',
  styleUrls: ['./card.css'],
  templateUrl: 'card.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedCard {
  @Input()
  public rounded: boolean = false;

  @Input()
  public variant: UBAppearance = 'none';

  @Input()
  public dividers: boolean = true;

  @Input()
  public flat: boolean = false;

  public getClassNames(): string[] {
    return [
      'ub-card',
      this.rounded ? 'ub-card--rounded' : null,
      this.variant ? `ub-card--${this.variant}` : null,
      this.dividers ? null : 'ub-card--plain',
      this.flat ? 'ub-card--flat' : null
    ].filter(Boolean);
  }
}
