/* tslint:disable:max-classes-per-file */
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  EasElement,
  EasField,
  EasFieldArray,
  EasFieldControl,
  EasFieldGroup,
  EasFieldType
} from './easform.interfaces';


interface EasFieldMeta {
  hidden?: boolean;
  visible?: boolean;
  [key: string]: boolean | undefined;
}

export abstract class AbstractEasForm {
  readonly controlType: 'group' | 'array' | 'control';
  id: string;
  control?: AbstractControl;
  meta: EasFieldMeta = {};
  model: any;

  constructor(
    public field: EasField,
    public parent?: AbstractEasForm,
  ) {
    this.id = field.id;
  }

  abstract createStructure(): void;
  abstract createControl(): AbstractControl;
  abstract updateControl(value: any): AbstractControl;
  abstract updateMeta(value: any, parent: any, model: any): void;
  abstract filterValue(value: any): any;
  abstract disable(): void;
  abstract enable(): void;
  abstract getFullValue(): any;

  protected markAsTouched() {
    if (this.control) {
      this.control.markAsTouched();
    }
  }

  public _getMeta(_currentValue: any, currentParent: any, currentModel: any): EasFieldMeta {
    const meta: EasFieldMeta = {};
    // const value: any = {...this.model, ...currentValue};
    const parent: any = {...this.parent?.model || {}, ...currentParent};

    // This is unused at the moment
    const model = currentModel;

    if (this.field.meta && Object.keys(this.field.meta).length) {
      Object.entries(this.field.meta).forEach(([key, fn]) => {
        meta[key] = fn(this.getFullValue(), this.parent?.getFullValue(), model, this.path);
        // meta[key] = fn(value, parent, model, this.path, this.parent);
      });
    }

    if (meta.hidden === undefined && meta.visible !== undefined) {
      meta.hidden = !meta.visible;
    }

    // Handle dependents
    if (this.field.dependentFields) {
      const hiddens = this.field.dependentFields.map(fieldId => {
        const parentGroup: EasFormGroup = this.parent as EasFormGroup;
        const fld = parentGroup.getControl(fieldId);
        // If control is not found we don't limit anything
        // => we return false
        if (!fld) {
          return false;
        }
        const fldMeta = fld._getMeta(parent[fld.field.id], parent, model);
        return fldMeta.hidden;
      });
      if (hiddens.some(Boolean)) {
        meta.hidden = true;
      }
    }
    return meta;
  }

  protected _updateMeta(value: any, parent: any, model: any): void {
    const originalMeta: EasFieldMeta = {...this.meta};
    this.meta = this._getMeta(value, parent, model);
    this.meta = {...originalMeta, ...this.meta};

    if (this.meta.hidden !== undefined) {
      if (this.meta.hidden) {
        this.disable();
      } else {
        this.enable();
      }
    }
  }

  setValue(value: any) {
    this.model = value;
    this.updateControl(value);
    if (this.control) {
      this.control.patchValue(value);
    }
    this.updateMeta(value, value, value);
  }

  patchValue(value: any) {
    if (this.control) {
      this.control.patchValue(value);
    }
  }

  get readOnly(): boolean {
    const options = this.field.options || {};
    if (options.readOnly) {
      return true;
    } else if (this.parent) {
      return this.parent.readOnly;
    } else {
      return false;
    }
  }

  get root(): AbstractEasForm {
    return this.parent !== undefined ? this.parent.root : this;
  }

  get path(): string {
    let path = this.id;
    if (this.parent) {
      if (this.parent.controlType === 'array') {
        const idx = (this.parent as EasFormArray).controls.indexOf(this as unknown as EasFormGroup);
        path = `${this.parent.path}[${idx}]`;
      } else {
        path = `${this.parent.path}.${path}`;
      }
    }
    return path;
  }
}

export class EasFormGroup extends AbstractEasForm {

  readonly controlType = 'group';
  id: string;
  control: UntypedFormGroup;
  meta: EasFieldMeta = {};

  public controls: (EasFormGroup | EasFormArray | EasFormControl)[] = [];
  public model: any;

  private validateSubscription: Subscription;

  constructor(
    public field: EasFieldGroup,
    public parent?: AbstractEasForm,
  ) {
    super(field, parent);
    if (this.field.type !== EasFieldType.Group) {
      throw new Error('Field type has to be group');
    }

    if (!Array.isArray(this.field.fields)) {
      throw new Error('Group has to have fields');
    }

    this.createStructure();
  }

  disable() {
    if (!this.control) {
      return;
    }

    this.controls.forEach(itm => itm.disable());
  }
  enable() {
    if (!this.control) {
      return;
    }

    this.controls.forEach(itm => itm.enable());
  }

  markAsTouched() {
    this.controls.forEach(itm => itm.markAsTouched());
  }


  createStructure() {
    this.field.fields.forEach(fld => {
      this.controls.push(this.createField(fld));
    });
  }

  createField(fld: EasElement): (EasFormGroup | EasFormArray | EasFormControl) {
    let element: (EasFormGroup | EasFormArray | EasFormControl);
    if (fld.type === EasFieldType.Group) {
      element = new EasFormGroup(fld as EasFieldGroup, this);
    } else if (fld.type === EasFieldType.Array) {
      element = new EasFormArray(fld as EasFieldArray, this);
    } else {
      element = new EasFormControl(fld as EasFieldControl, this);
    }
    return element;
  }

  createControl(): UntypedFormGroup {
    if (this.readOnly || (this.field.options || {}).noFormControl) {
      return undefined;
    }

    const group = {};
    this.controls.forEach(control => {
      const ctl = control.createControl();
      if (ctl) {
        group[control.id] = ctl;
      }
    });
    this.control = new UntypedFormGroup(group);

    if (!this.parent) {
      if (this.validateSubscription) {
        console.log('unsubscribing');
        this.validateSubscription.unsubscribe();
      }

      console.log('setting up subscriber');
      this.validateSubscription = this.control.valueChanges.pipe(
        debounceTime(300)
      ).subscribe(data => {
        console.log('Updating visibility within form');
        console.log(data);
        const value = this.control.value;
        this.updateMeta(value, value, value);
      });
    }

    return this.control;
  }

  public updateControl(value): UntypedFormGroup {
    if (value === undefined) {
      value = {};
    }
    this.model = value;
    this.controls.forEach(control => {
      control.updateControl(value[control.id]);
    });
    return this.control;
  }

  public getControl(id: string) {
    return this.controls.find(itm => itm.id === id);
  }

  updateMeta(value, parent, model) {
    this._updateMeta(value, parent, model);
    value = value || {};
    // if (value === undefined || value === null) {
    //   return;
    // }
    this.controls.forEach(control => {
      control.updateMeta(value[control.id], value, model);
      if (this.control !== undefined) {
        value = this.control.value;
      }
    });
  }

  public addControl(element: EasElement) {
    this.field.fields.push(element);
    const el = this.createField(element);
    this.controls.push(el);
    if (this.control) {
      this.control.addControl(element.id, el.createControl());
    }
  }

  public removeControl(id: string) {
    if (this.control) {
      this.control.removeControl(id);
    }
    this.field.fields = this.field.fields.filter(itm => itm.id !== id);
    this.controls = this.controls.filter(itm => itm.id !== id);
  }

  // public updateVisibility(value, parent, model) {
  //   this.meta.hidden = this.field.showCondition &&
  //     !this.field.showCondition(value, parent, model);

  //   this.controls.forEach(control => {
  //     control.updateVisibility(value[control.id], value, model);
  //   });
  // }

  getValue() {
    if (!this.control) {
      return undefined;
    }
    const value = this.control.value;
    return this.filterValue(value);
  }

  getFullValue() {
    const value: any = {};
    this.controls.forEach(cnt => {
      value[cnt.id] = cnt.getFullValue();
    })
    return value;
  }

  filterValue(value: any) {
    if (this.meta.hidden || !value || this.readOnly) {
      return undefined;
    }

    const inner: any = {};
    this.controls.forEach(control => {
      const val = control.filterValue(value[control.id]);
      if (val !== undefined) {
        inner[control.id] = val;
      }
    });
    return inner;
  }

  /* This is not thought-through
     A group is readonly if all subcontrls are readOnly
  */
  get readOnlyGroup() {
    return this.controls.filter(itm => itm.control !== undefined).length === 0;
  }

}

export class EasFormArray extends AbstractEasForm {

  readonly controlType = 'array';
  id: string;
  control: UntypedFormArray;
  meta: EasFieldMeta = {};

  controls: EasFormGroup[] = [];

  constructor(
    public field: EasFieldArray,
    public parent?: AbstractEasForm,
  ) {
    super(field, parent);
    if (this.field.type !== EasFieldType.Array) {
      throw new Error('Field type has to be array');
    }

    if (!Array.isArray(this.field.fields) && !this.field.fields_from) {
      throw new Error('Array has to have fields');
    }

    this.createStructure();
  }

  disable() {}
  enable() {}

  createStructure() {
    // Deliberately empty for now
  }

  public createControl() {
    if (this.readOnly) {
      return undefined;
    }

    this.control = new UntypedFormArray([]);
    return this.control;
  }

  markAsTouched() {
    this.controls.forEach(itm => itm.markAsTouched());
  }

  public updateControl(value) {
    if (value === undefined) {
      value = [];
    }
    this.model = value;
    if (Array.isArray(value)) {
      value.forEach((item) => {
        const group = this.push();
        group.updateControl(item);
      });
    }
    return this.control;
  }

  public updateMeta(value, parent, model) {
    this._updateMeta(value, parent, model);
    this.controls.forEach((control, idx) => {
      control.updateMeta(value[idx], value, model);
    });
  }

  // public updateVisibility(value, parent, model) {
  //   this.meta.hidden = this.field.showCondition &&
  //     !this.field.showCondition(value, parent, model);

  //   this.controls.forEach((control, idx) => {
  //     control.updateVisibility(value[idx], value, model);
  //   });
  // }

  private _getSubGroup(): EasFieldGroup {
    let fields;
    if (this.field.fields_from) {
      let parentGroup = this.parent;
      while (parentGroup && !(parentGroup instanceof EasFormGroup)) {
        parentGroup = parentGroup.parent;
      }
      if (!parentGroup) {
        throw new Error('Could not find fields');
      }
      fields = [...(parentGroup as EasFormGroup).field.fields];
    } else {
      fields = [...this.field.fields];
    }
    return {
      id: this.id,
      type: EasFieldType.Group,
      fields,
      templates: this.field.templates,
    };
  }

  public push() {
    const group = new EasFormGroup(this._getSubGroup(), this);
    this.controls.push(group);
    if (this.control) {
      this.control.push(group.createControl());
    }
    return group;
  }

  public remove(i: number) {
    this.controls.splice(i, 1);
    if (this.control) {
      this.control.removeAt(i);
    }
  }

  filterValue(value: any[]) {
    if (this.meta.hidden) {
      return [];
    }

    const inner = [];
    this.controls.forEach((control, idx) => inner.push(control.filterValue(value[idx])));
    return inner;
  }

  getFullValue() {
     const inner: any = [];
     this.controls.forEach((control) => inner.push(control.getFullValue()));
     return inner;
  }
}

export class EasFormControl extends AbstractEasForm {
  control: UntypedFormControl;
  id: string;
  readonly controlType = 'control';
  meta: EasFieldMeta = {};
  readableValue: string;

  constructor(
    public field: EasFieldControl,
    public parent?: AbstractEasForm,
  ) {
    super(field, parent);
    if (this.field.type !== EasFieldType.Field) {
      throw new Error('Field type has to be field');
    }

    this.createStructure();
  }

  markAsTouched() {
    if (this.control) {
      this.control.markAsTouched();
    }
  }


  disable() {
    if (this.control && this.control.enabled) {
      this.control.disable({emitEvent: false});
    }
  }

  enable() {
    if (this.control && this.control.disabled) {
      this.control.enable({emitEvent: false});
    }
  }

  createStructure() {
  }

  createControl() {
    if (this.readOnly || (this.field.options || {}).noFormControl) {
      return undefined;
    }

    const options = this.field.options || {};
    if (options.noFormControl || options.readOnly) {
      this.control = null;
      return undefined;
    }

    let initial = this.field.initial;
    if (initial instanceof Function) {
      initial = initial();
    }

    this.control = new UntypedFormControl(initial, this.field.validators, this.field.asyncValidators);
    return this.control;
  }

  updateControl(value) {
    this.model = value;
    if (this.readOnly) {
      this.readableValue = this.getReadableValue();
    }
    return this.control;
  }

  public updateMeta(value, parent, model) {
    this._updateMeta(value, parent, model);
  }

  filterValue(value) {
    if (!this.control) {
      return;
    }

    if (this.meta.hidden) {
      return null;
    }

    return this.field.serializeValue ? this.field.serializeValue(value) : value;
  }

  getFullValue() {
    if (this.readOnly) {
      return this.model || this.field.initial;
    }
    if (this.field.options?.noFormControl) {
      return;
    }
    console.log(this.field);
    return this.control.value;
  }

  private getReadableValue() {
    if (this.field.getTitle !== undefined) {
      return this.field.getTitle(this.model, this.field);
    }
    return this.model;
  }

}

