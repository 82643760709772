import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { Observable, of } from 'rxjs';

import {
  FBFieldEvaluator,
  FormField,
  ObservableFBField
} from '@fry/lib/forms/forms.interface';
import { defaultWidget } from '@fry/lib/forms/fields';

import { EasFieldControl, EasFieldType } from '@fry/components/common/easform/easform.interfaces';

@Injectable()
export class HeadingFieldEvaluator implements FBFieldEvaluator {

  public readonly fieldType = 'heading';
  provides = [
    {id: 'heading', name: $localize `Heading`},
    {id: 'paragraph', name: $localize `Explanatory text`},
  ];

  constructor(
  ) {
  }

  getForm(type) {
    return of([
        {
          id: 'content',
          type: EasFieldType.Field,
          title: type === 'heading' ? 'Heading' : 'Text',
          initial: '',
          widget: {
              type: type === 'heading' ? 'string' : 'text',
          },
          validators: [Validators.required],
        },

    ]);
  }

  buildField(field: any): Observable<EasFieldControl> {
    return of({
      id: field.id,
      type: field.type,
      title: field.options.content,
      widget: {
        type: field.fieldType,
      },
      options: {
        ...field.options,
        noFormControl: true,
      }
    });
  }

  evalField(field: ObservableFBField): Observable<FormField> {
    return of({
      id: field.id,
      title: field.fieldOptions.content,
      initial: '',
      validators: [],
      noFormControl: true,
      widget: { type: defaultWidget['heading'] },
      options: {
        ...field.options,
        noFormControl: true,
      }
    });
  }

}
