import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExportListService } from './export-list.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ExportListComponent } from './export-list.component';

@Component({
  selector: 'eas-export-button',
  templateUrl: './export-button.component.html',
})
export class ExportButtonComponent implements OnInit, OnDestroy {

  public exportInProgress$: Observable<boolean>;
  public destroyed$ = new Subject<void>();

  constructor(
    private exportService: ExportListService,
    private dialog: MatDialog
  ) {
      this.exportInProgress$ = this.exportService.exportInProgress;
  }

  ngOnInit() {
      this.exportService.currentTasks$
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
  }

  onExport() {
    this.dialog.open(ExportListComponent, {
      data: {
        exportService: this.exportService
      }
    });
    // Open the bloody popup
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


}
