import { Component, OnInit } from '@angular/core';
import { AuthService, CurrentUser } from '@fry/lib/auth';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-auth-loggedout',
  templateUrl: './auth-loggedout.component.html',
})
export class AuthLoggedoutComponent implements OnInit {

  currentUser: Observable<CurrentUser>;
  organisation;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  resetOrganisation() {
    this.authService.organisation = null;
  }
}
