import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UBListType } from '../unboxed.module.typings';

@Component({
  selector: 'ub-simple-list',
  exportAs: 'unboxedSimpleList',
  styleUrls: ['./simple-list.css'],
  templateUrl: 'simple-list.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedSimpleList {
  @Input()
  public type: UBListType = 'inline';

  public getClassNames(): string[] {
    return ['ub-simple-list', `ub-simple-list--${this.type}`];
  }
}
