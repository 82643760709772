import { BookingsStore } from './bookings.store';
import { BookingsSearch } from './bookings.search';
import { Injectable } from '@angular/core';
import { TypeInfo, TypeInfoSettings } from '@fry/lib/store';

@Injectable({
  providedIn: 'root'
})
export class BookingInfo implements TypeInfo {

  type = 'booking';
  title = $localize `Booking`;
  settings: TypeInfoSettings = {};

  constructor(
    public store: BookingsStore,
    public search: BookingsSearch,
  ) { }

  resolvedList(): any {
    return {
      store: this.store,
      search: this.search,
      defaults: { listType: 'table', engine: 'es' },
      title: $localize `Bookings`
    };
  }
}
