import { Observable, of } from 'rxjs';

export abstract class AbstractDashboardCard {
  constructor(private _name: string,
              // private _col: string,
              // private _row: string,
              private _options: any) {
  }

  get name(): string {
    return this._name;
  }

  // get col(): string {
  //   return this._col;
  // }

  // get row(): string {
  //   return this._row;
  // }

  get options(): any {
    return this._options;
  }

  isVisible(): Observable<boolean> {
    return of(true);
  }


}
