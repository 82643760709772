import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractDashboardCard, DashboardCard } from '@fry/components/common/dashboard-page';
import { ListService } from '@fry/lib/list';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'eas-text-card',
  templateUrl: './text.card.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ListService,
  ]
})
export class TextCardComponent extends AbstractDashboardCard implements OnInit {
  static key = 'TextCardComponent';

  public title;
  public paragraph;

  constructor(
    injector: Injector,
  ) {
    super(
      injector.get(DashboardCard.metadata.NAME),
      injector.get(DashboardCard.metadata.OPTIONS)
    );
  }

  ngOnInit(): void {
    this.title = this.options.title;
    this.paragraph = this.options.paragraph;
  }

  isVisible(): Observable<boolean> {
    return of(true);
  }
}
