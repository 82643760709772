import { Component } from '@angular/core';
import { AuthService } from '@fry/lib/auth';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-auth-login-org',
  template: '',
})
export class AuthLoginOrgComponent {

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {
    localStorage.removeItem('lastUrl');
    const org = `org_${this.route.snapshot.params.org}`;
    this.authService.organisation = org;
    this.authService.startLogin();
  }
}
