import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ub-page-header',
  exportAs: 'unboxedPageHeader',
  styleUrls: ['./page-header.css'],
  templateUrl: 'page-header.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedPageHeader {}
