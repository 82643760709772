import { Injectable } from '@angular/core';

import { ApiStore, DBDoc, SearchType, SuperType } from '@fry/lib/store';
import { APIService } from '@fry/lib/api';
import { Eligibility, ExtendedEligibility } from './eligibility';
import { cloneDeep } from 'lodash';
import { guid } from '@fry/lib/utils';


@Injectable({
  providedIn: 'root'
})
export class EligibilityStore extends ApiStore<Eligibility> {
  AssociatedModel = Eligibility;

  docType = 'eligibility';
  endpoint = 'eligibility';
  superType = SuperType.Org;
  searchType = SearchType.LOCAL;

  constructor(api: APIService) {
    super(api);
  }

  createObject(doc: DBDoc | ExtendedEligibility): Eligibility {
    if (!doc.usage) {
      return new this.AssociatedModel(doc as DBDoc);
    }

    return new this.AssociatedModel(doc.doc as DBDoc, doc as ExtendedEligibility);
  }

  duplicate(orig: Eligibility): Eligibility {
    const doc = cloneDeep(orig.doc);
    doc._id = guid();
    doc.name = `Copy of ${doc.name}`;
    delete doc._rev;
    return this.createObject(doc);
  }
}
