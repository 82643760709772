import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ListFilterForm } from './list.filter.form';
import { FormData } from '@fry/lib/forms';
import { LoggerService, NotifyService } from '@fry/lib/utils';

@Component({
  selector: 'app-list-featured-filter',
  templateUrl: './list.featured-filter.component.html',
  providers: [
    ListFilterForm
  ]
})
export class ListFeaturedFilterComponent implements OnInit {

  public model: any;
  public formData$: Observable<FormData>;

  constructor(
    private filterForm: ListFilterForm,
    private notify: NotifyService,
    private logger: LoggerService
  ) {
  }

  ngOnInit() {
    this.formData$ = this.filterForm.init({ featured: true });
  }

  onSubmit() {
    this.filterForm.submit().subscribe(
      () => this.logger.debug('Searching'),
      (error) => this.notify.error(error)
    );
  }

  reset() {
    this.filterForm.reset().subscribe(
      () => this.logger.debug('Resetting filter'),
      (error) => this.notify.error(error)
    );
  }
}
