import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ExportListService } from './export-list.service';
import { EasTable } from '@fry/lib/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormProviderService, FormData } from '@fry/lib/forms';

@Component({
  selector: 'eas-export-list',
  templateUrl: './export-list.component.html',
})
export class ExportListComponent implements OnInit, OnDestroy {

  private exportService: ExportListService;
  table$: Observable<EasTable>;
  private destroyed$ = new Subject<void>();
  public exportModel: any = {};
  public formData: FormData | undefined;

  public columns = ['createdDate', 'updatedDate', 'state', 'progress', 'download'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { exportService: ExportListService },
    private provider: FormProviderService,
  ) {
    this.exportService = data.exportService;
    this.table$ = this.exportService.currentTasks$;
  }

  ngOnInit() {
    this.exportService.updateTaskTable();
    const fields = this.exportService.exportFormFields();
    this.provider.getFormDataWith(fields, this.exportModel)
      .subscribe(formData => this.formData = formData);
  }

  startExport() {
    const tableOptions = this.formData?.getValue();
    this.exportService.startExport$(tableOptions)
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  update() {
    this.exportService.updateTaskTable();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


}
