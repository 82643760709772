import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-email',
  exportAs: 'unboxedEmailInput',
  styleUrls: ['../vars.css', '../common.css', './input.css'],
  templateUrl: './email.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedEmailInput extends UnboxedBaseFormComponent
  implements OnInit {
  public ngOnInit(): void {
    this.errorMessages['email'] = this.errorMessages['email'] || this.email;
  }

  private email(): string {
    return 'This field needs to contain a valid email address';
  }
}
