// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { environment as base } from './environment.common';

export const environment = {
  ...base,
  production: false,
  defaultServerConfig: {
    urls: {
      api: 'https://api-devin.kaizenep.com',
      db: 'https://devin.kaizenep.com/db',
      auth: 'https://devin.id.fry-it.com',
      epf: 'https://devin.kaizenep.com',
      org: 'https://org.devin.kaizenep.com',
    },
    oidc: {
      authority: 'https://auth.eas:8443',
      client_id: 'local',
      response_type: 'id_token token',
      scope: 'openid email',
      redirect_uri: 'https://booking.eas:8443/auth-callback',
    },
    liveChat: {
      licenses: {}
    },
    sentry: undefined
  }};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
