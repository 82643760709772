import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, take, tap } from 'rxjs/operators';

import { OrganisationStore } from './ogranisation.store';
import { Organisation } from './organisation';
import { AuthService } from '../auth';
import { intersect, LoggerService } from '../utils';
import { NotificationsService } from '../error';

import { PaymentGatewayVendor } from '@fry/payments/integrations';

const DEFAULT_DASHBOARD = `
{
  "columns": [
    [
      {
        "name": "my name",
        "options": {
        },
        "component": "ProfileCardComponent"
      },
      {
        "name": "text",
        "options": {
          "title": "Notice",
          "paragraph": "<p>Please note that your examination progress bar may display incorrectly the first time you login into the risr/apply dashboard.</p><p>If this happens, please click refresh. If you experience any problems after clicking refresh, please logout of the booking system and log back in again. The booking system should sync with your correct exam progress data and you will then see correct details regarding exams that are available to you to book onto.</p><p>NOTICE – FOR THEORY EXAM APPLICANTS ONLY Seats are only reserved for 10 minutes after opening an application. Having a draft application does not hold a seat - only completing payment confirms your seat. Drafts and payment pending applications will be cleared from the system every 30 minutes.</p>"
        },
        "component": "TextCardComponent"
      },
      {
        "name": "available-bookings",
        "options": {
          "title": "Available bookings",
          "subtitle": "- Please delete any drafts you have in RECENT BOOKINGS to see available exams below."
        },
        "component": "UserAvailableCardComponent"
      }
    ],
    [
      {
        "name": "walkthroughs",
        "options": {
        },
        "component": "WalkthroughsCardComponent"
      },

      {
        "name": "my-bookings",
        "options": {
        },
        "component": "UserBookingCardComponent"
      }
    ]
  ]
}
`;

export const AllowedTypes = ['local', 'proxy'];

export interface CreateUserTypeConfiguration {
  autoGenerateUsername: boolean;
  autoGeneratePassword: boolean;
  hasIntegration: boolean;
}

export interface CreateUserConfiguration {
  [type: string]: CreateUserTypeConfiguration;
}

export interface OrganisationGeneralSettings {
  showKaizenLink?: boolean;
  paymentVendor?: PaymentGatewayVendor;
  allowedCurrencies?: string[];
}

const defaultOrganisationGeneralSettings = {
  showKaizenLink: true,
  paymentVendor: PaymentGatewayVendor.stripe,
  allowedCurrencies: []
}

const defaultLocalConfig: CreateUserTypeConfiguration = {
  autoGenerateUsername: false,
  autoGeneratePassword: false,
  hasIntegration: false,
};

const defaultProxyConfig: CreateUserTypeConfiguration = {
  autoGenerateUsername: false,
  autoGeneratePassword: true,
  hasIntegration: false,
};

const defaultConfig: CreateUserConfiguration = {
  local: defaultLocalConfig,
  proxy: defaultProxyConfig,
};


@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  private _current: Observable<Organisation> | undefined;

  constructor(
    private store: OrganisationStore,
    private auth: AuthService,
    private logger: LoggerService,
    private notifications: NotificationsService,
  ) {
    this.auth.currentUser().subscribe(() => {
      this.invalidate();
    });
  }

  private invalidate() {
    this._current = undefined;
  }

  getCurrent(): Observable<Organisation> {
    if (this._current) {
      return this._current;
    }

    this._current = this.store.get(this.auth.organisation).pipe(
      tap(org => {
        if (this.auth.organisation !== org.doc.organisation) {
          throw new Error('Organisation does not match');
        }
        if (!(org.doc.allowedProducts || []).includes('eas')) {
          this.notifications.error('This organisation does not have access');
          throw new Error('This organisation does not have access');
        }
      }),
      shareReplay({ refCount: false, bufferSize: 1})
    );
    return this._current;
  }

  canCreateUsers() {
    return this.getCurrent().pipe(
      take(1),
      map(org => {
        return intersect(org.doc.allowedAccountTypes || [], ['local', 'proxy', '__all__']).length > 0;
      }),
      catchError(err => {
        this.logger.warn('OrganisationService.canCreateUsers: ', err);
        return of(false);
      })
    );
  }

  getAllowedComponents(): Observable<string[]> {
    return this.getCurrent().pipe(
      map(org => {
        return org.doc.allowedComponents;
      }),
    );
  }

  getCreateUserConfiguration(): Observable<CreateUserConfiguration> {
    return this.getCurrent().pipe(
      take(1),
      map(org => {
        let allowedTypes = org.doc.allowedAccountTypes || [];
        if (allowedTypes.indexOf('__all__') !== -1) {
          allowedTypes = [...AllowedTypes];
        }
        const config: CreateUserConfiguration = {};

        // FIXME: The below show be using organisation settings
        allowedTypes.forEach(type => {
          config[type] = {...defaultConfig[type]};
        });
        return config;
      })
    );
  }

  getDashboardConfig(): Observable<any> {
    return this.getCurrent().pipe(
      map(org => {
        return org.doc.dashboardSetup?.default ||
               JSON.parse(DEFAULT_DASHBOARD);
      })
    )
  }

  getUserDashboardConfig(): Observable<any> {
    return this.getCurrent().pipe(
      map(org => {
        return org.doc.dashboardSetup?.remote ||
               org.doc.dashboardSetup?.default ||
               JSON.parse(DEFAULT_DASHBOARD);
      })
    )
  }

  getGeneralSetup(): Observable<OrganisationGeneralSettings> {
    return this.getCurrent().pipe(
      map(org => {
        return org.doc.generalSetup || defaultOrganisationGeneralSettings;
      })
    )
  }
}
