import { ElementRef } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap, map, catchError, switchMap } from 'rxjs/operators';

import { APIService } from '@fry/lib/api';
import { ScriptLoaderService } from '@fry/lib/utils';

import { TransactionCardFormComponent } from '@fry/payments/components';
import {
  PaymentsErrorIntegrationNotReady,
  PaymentsErrorMoneris,
  Transaction,
  TransactionVendorReference } from '@fry/payments/lib';
import {
  IntegrationConfiguration,
  PaymentGatewayVendor,
  PaymentGatewayIntegration
} from  '@fry/payments/integrations';

export interface MonerisElementClasses {
  base?: string;
  complete?: string;
  empty?: string;
  focus?: string;
  invalid?: string;
  webkitAutofill?: string;
}
export interface MonerisElementOptions {
  classes?: MonerisElementClasses;
}

export interface MonerisCheckoutPreloadResponse {
  state: string;
  ticket: string;
  date: string;
}

export interface MonerisCheckoutCallbackResponse {
  handler: string;
  ticket: string;
  response_code: string; // '001'|'902'|'2001';
}

export class MonerisIntegrationService implements PaymentGatewayIntegration {

  public readonly vendor: PaymentGatewayVendor = PaymentGatewayVendor.moneris;

  public get isStripe(): boolean { return false; }
  public get isMoneris(): boolean { return true; }

  public get isInitialized(): boolean {
    return this._configuration !== undefined;
  }

  private _configuration?: IntegrationConfiguration;
  public get configuration(): IntegrationConfiguration|undefined {
    return this._configuration;
  }

  /**
   * Instance of the Moneris JS SDK
   */
  private _moneris?: any;

  /**
   * Did the integration already started the checkout?
   */
  private didStartMonerisCheckout = false;

  constructor(private scriptLoader: ScriptLoaderService, private apiService: APIService) { }

  public initialize(): Observable<IntegrationConfiguration> {
    // TODO: This could be implemented as typequard and then it would make this type
    // safe without addeing the |undefined to the type.
    if (this.isInitialized && this.configuration) { return of(this.configuration); }

    // Fetch configuration, use it to load vendor scripts,
    // configure vendor integration.
    return this.fetchVendorConfiguration().pipe(
      tap(configuration => {
        this._configuration = configuration;
      }),
      switchMap(configuration => {
        return this.scriptLoader.load(configuration.script);
      }),
      map(_result => {
        if (!this.configuration) { throw new PaymentsErrorIntegrationNotReady(); }
        return this.configuration;
      }),
      catchError(error => {
        this._configuration = undefined;
        return throwError(error);
      })
    );
  }

  public fetchVendorConfiguration(): Observable<IntegrationConfiguration> {
    if (this.configuration) { return of(this.configuration); }
    return this.apiService.get(`${this.vendor}/`);
  }

  public prepareVendorInteraction(_transaction: Transaction): Observable<MonerisCheckoutPreloadResponse> {
    return of({} as MonerisCheckoutPreloadResponse);
  }

  public startVendorInteraction(transaction: Transaction, _options: MonerisElementOptions): Observable<TransactionVendorReference|undefined> {
    if (!this.configuration) {
      throw new PaymentsErrorIntegrationNotReady();
    }

    return this.apiService.post(`${this.vendor}/token`, { transaction: transaction.id })
                          .pipe(
                            switchMap((result: MonerisCheckoutPreloadResponse) => {
                               this._moneris.startCheckout(result.ticket);
                               this.didStartMonerisCheckout = true;
                               return of(undefined);
                            }),
                            catchError((err: any) => {
                              console.log(err);
                              return throwError(() => new Error(err));
                            })
                          );
  }

  public mountUIElement(elementRef: ElementRef,
                        _elementOptions: MonerisElementOptions,
                        elementChanges: (event: Record<string, any>) => void): boolean {
    if (!this.isInitialized) { return false; }
    if (!this.configuration) { throw new Error('Integration not initialised!'); }

    this._moneris = new window['monerisCheckout']();
    this._moneris.setMode(this.configuration.environment);
    this._moneris.setCheckoutDiv(elementRef.nativeElement.id);
    this._moneris.setCallback('page_loaded', elementChanges);
    this._moneris.setCallback('cancel_transaction', elementChanges);
    this._moneris.setCallback('error_event', elementChanges);
    this._moneris.setCallback('payment_receipt', elementChanges);
    this._moneris.setCallback('payment_complete', elementChanges);

    return true;
  }

  /**
   * We unmout the integration from UI
   *
   * In case of Moneris we must only remove it if Moneris SDK is initialised
   * and close checkout only when we actually started the checkout.
   *
   * Otherwise .... boom.
   */
  public unmountUIElement(): void {
    if (!this._moneris) { return; }
    this.closeCheckout();
    this._moneris = undefined;
  }

  private closeCheckout(): void {
    if (!this._moneris) { return; }
    if (this.didStartMonerisCheckout) { this._moneris.closeCheckout(); }
    this.didStartMonerisCheckout = false;
  }

  public handleUIElementChanges(payload: string, component: TransactionCardFormComponent) {
    const event: MonerisCheckoutCallbackResponse = JSON.parse(payload);

    console.log(`MONERIS EVENT: ${event.handler} - ${event.response_code}`);

    switch (event.handler) {
      case 'page_loaded':
        break;
      case 'cancel_transaction':
        this.closeCheckout();
        break;
      case 'error_event':
        this.unmountUIElement();
        component.error.emit(new PaymentsErrorMoneris(`Checkout error: ${event.response_code}`));
        break;
      case 'payment_receipt':
        break;
      case 'payment_complete':
        this.closeCheckout();

        const data: TransactionVendorReference = {
          id: event.ticket,
          object: 'ticket',
          vendor: this.vendor
        };
        component.capture.emit(data);
        break;
      default:
        throw new PaymentsErrorMoneris(`Unknown element callback response: '${event.handler}'`)
    }
  }

  // Unused when using Moneris checkout
  public handleVerificationChallenge(_challenge: Record<string, any>): Promise<TransactionVendorReference> {
    return new Promise<TransactionVendorReference>((resolve) => { resolve ({} as TransactionVendorReference); });
  }
}
