import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import * as FryCommonUtils from '@fry/lib/utils/common';
import { DialogComponent, DialogComponentData } from './dialog.component';
import * as EASDialogError from './dialog.error';

export type DialogComponentStateFactory = (
  title?: string,
  content?: string,
  cancelButton?: string,
  okButton?: string) => DialogComponentData;

@Injectable()
export class DialogComponentService {
  public forceModalDialog = true;

  private dialogRef: MatDialogRef<DialogComponent>;

  public states: { [K: string]: DialogComponentStateFactory } = {};

  public stateFor(key: string, title?: string, content?: string): DialogComponentData | null {
    const factory = this.states[key];
    if (factory === undefined) { return null; }
    return factory(title, content);
  }

  constructor(private matDialogService: MatDialog) {}

  public updateState(state: DialogComponentData): MatDialogRef<DialogComponent> {
    if (this.forceModalDialog &&
        this.matDialogService.openDialogs.length > 0 &&
        this.dialogRef === undefined) {
      throw new Error($localize `There is modal dialog already open!`);
    }

    if (this.dialogRef) {
      // Update of the submission dialog
      this.dialogRef.componentInstance.data = state;
    } else {
      // Creation of new submission dialog
      const configuration: MatDialogConfig<DialogComponentData> = {
        closeOnNavigation: true,
        data: state,
        disableClose: true,
        hasBackdrop: true,
        maxWidth: '27vw',
      };
      this.dialogRef = this.matDialogService.open(DialogComponent, configuration);

      // Tidy up after closing the dialog...
      this.dialogRef.afterClosed().subscribe(res => {
        this.dialogRef = undefined;

        // When we cancel, we just close the dialog
        if (EASDialogError.isDialogError(res)) { return; }

        // When we have done something, we scroll...
        FryCommonUtils.scrollSelectorIntoView('.app-top');
      });
    }

    // At the moment when we succeed we just close the dialog for
    // this component.
    if (state.type === 'success') {
      this.dialogRef.close(true);
    }
    if (state.type === 'canceled') {
      this.dialogRef.close(EASDialogError.UserCanceledError());
    }

    return this.dialogRef;
  }
}
