import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ub-avatar',
  exportAs: 'unboxedAvatar',
  styleUrls: ['./avatar.css'],
  templateUrl: 'avatar.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedAvatar {
  @Input()
  public round: boolean;

  @Input()
  public name: string = 'User';

  @Input()
  public img: string;

  public get initials(): string {
    return this.name
      .split(' ')
      .map(itm => itm.charAt(0))
      .join('')
      .toUpperCase();
  }

  public getClassNames(): string[] {
    return ['ub-avatar', this.round ? 'ub-avatar--rounded' : null].filter(
      Boolean
    );
  }
}
