import { Component, Input, ViewEncapsulation } from '@angular/core';

type HeadingType = 'primary' | 'secondary';

@Component({
  selector: 'ub-heading',
  exportAs: 'unboxedHeading',
  styleUrls: ['./typography.css'],
  templateUrl: 'heading.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedHeading {
  @Input()
  public type: HeadingType = 'primary';

  public getClassNames(): string[] {
    return ['ub-heading', this.type ? `ub-heading--${this.type}` : null].filter(
      Boolean
    );
  }
}
