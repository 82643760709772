import { Component, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-text',
  exportAs: 'unboxedTextInput',
  styleUrls: ['../vars.css', '../common.css', './input.css'],
  templateUrl: './text.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedTextInput extends UnboxedBaseFormComponent {}
