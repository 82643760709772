import { Injectable, InjectionToken } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MenuItem } from './menu.interface';
import { AuthService } from '../auth';

export const RemoteMenuService = new InjectionToken<MenuService>('RemoteMenuService');

const _findId = (id: string, menu: MenuItem): MenuItem => {
  if (menu.id === id) {
    return menu;
  }

  if (menu.subitems) {
    let found;
    for (let i = 0, len = menu.subitems.length; i < len; i++) {
      found = _findId(id, menu.subitems[i]);
      if (found) {
        break;
      }
    }
    if (!found) {
      return undefined;
    }

    return found;
  }

  return undefined;
};

const findId = (id: string, menu: MenuItem): MenuItem => {
  const result = _findId(id, menu);
  if (!result) {
      throw new Error(`Could not find id ${id}`);
  }
  return result;
};

@Injectable()
export class MenuService {
  public menu$: Observable<MenuItem[]>;

  public menuItems: MenuItem = {
    id: 'home',
    title: $localize `Home`,
    link: '/',
    subitems: []
  };

  constructor(
    private auth: AuthService,
  ) {
    this.auth.currentUser().subscribe(() => {
      this._menu();
    });
  }

  private registered: Observable<MenuItem[]>[] = [];

  public register(obser: Observable<MenuItem[]>) {
    console.log('registering');
    this.registered.push(obser);
    this._menu();
  }
/*
  private simpleMerge(data: MenuItem[][]): MenuItem[] {
    return [].concat(...data);
  }
*/
  private hierarchyMerge(data: MenuItem[][]): MenuItem[] {
    data = JSON.parse(JSON.stringify(data));
    const menu: MenuItem = {
      id: 'home',
      title: $localize `Home`,
      subitems: []
    };

    let submenu;
    data.forEach(items => {
      items.forEach(item => {
        if (item.parent) {
          try {
            submenu = findId(item.parent, menu);
          } catch (err) {
            submenu = {
              id: item.parent,
              title: item.parent,
              subitems: []
            };
            menu.subitems.push(submenu);
          }
        } else {
          submenu = menu;
        }
        if (submenu.subitems === undefined) {
          submenu.subitems = [];
        }

        // If item.id found just update it - this is useful if parent is defined
        // later than children
        const found = submenu.subitems.find(itm => {
          return itm.id === item.id;
        });

        if (found) {
          found.title = item.title;
        } else {
          submenu.subitems.push(item);
        }

      });
    });

    // Flat all single-item subitems
    menu.subitems.forEach(item => {
      if ((item.subitems || []).length === 1) {
        item.id = item.subitems[0].id;
        item.title = item.subitems[0].title;
        item.link = item.subitems[0].link;
        item.abslink = item.subitems[0].abslink;
        item.subitems = [];
      }
    });

    return menu.subitems;
  }

  public _menu() {
    console.log('Retrieving menu', this.registered.length);
    this.menu$ = combineLatest(this.registered).pipe(
      map(data => {
        // Not sure how the merge will work
        // const res = this.simpleMerge(data);
        const res = this.hierarchyMerge(data);
        console.log('Merged menu to', data, res);
        return res;
      })
    );
  }
}
