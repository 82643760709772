import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, filter, map, reduce } from 'rxjs/operators';

export interface EASAction {
  type: 'link' | 'button';
  label: string;
  condition: (() => Observable<boolean>);
  color?: 'primary' | 'accent' | 'warn';
}

export interface EASLinkAction extends EASAction {
  type: 'link';
  link: Observable<string[]>;
  queryParams?: any;
}

export interface EASButtonAction extends EASAction {
  type: 'button';
  click: (() => any);
}

@Injectable({
  providedIn: 'root'
})
export class ButtonsService {

  processButtons(buttons: EASAction[]): Observable<EASAction[]> {
    return from(buttons).pipe(
      concatMap(button => {
        return button.condition().pipe(
          filter(bool => bool),
          map(() => button)
        );
      }),
      reduce((acc: EASAction[], button: EASAction) => [...acc, button], []),
    );
  }

}
