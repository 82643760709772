import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ub-button-list',
  exportAs: 'unboxedButtonList',
  styleUrls: ['./button-list.css'],
  templateUrl: 'button-list.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedButtonList {}
