import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ub-box',
  exportAs: 'unboxedBox',
  styleUrls: ['./box.css'],
  templateUrl: 'box.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedBox {
  @Input()
  public rounded: boolean;

  @Input()
  public dotted: boolean;

  public getClassNames(): string[] {
    return [
      'ub-box',
      this.rounded ? 'ub-box--rounded' : null,
      this.dotted ? 'ub-box--dotted' : null
    ].filter(Boolean);
  }
}
