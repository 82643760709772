import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'bookingItems.view', name: $localize `View booking items`, description: $localize `Allow user to view list of booking items` },
  { id: 'bookingItems.create', name: $localize `Create booking items`, description: $localize `Allow user to create new booking items` },
  { id: 'bookingItems.edit', name: $localize `Edit booking items`, description: $localize `Allow user to edit booking items`}
];

export const bookingItemsPermissions: PermissionGroup = {
  id: 'bookingItems',
  name: $localize `Booking Items`,
  description: '',
  permissions
};
