import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

export interface ScriptDefinition {
    readonly name: string;
    readonly src: string;
    loaded: boolean;
}

/**
 * On-demand external script loader
 *
 * This service provides means how to load external JS files on demand so that
 * it's possible to minimise the loading time at the start of the application
 * and isolate loading to a feature which needs it.
 *
 * TODO: At the moment we add SCRIPT & remove it when there is error. BUT we do
 *       not remove SCRIPT when not needed. Should we provide mechanism to do
 *       removal?
 */
@Injectable()
export class ScriptLoaderService {

    // Scripts handled by the service
    public get scripts() { return this._scripts; }
    private _scripts: ScriptDefinition[] = [];

    public constructor() {}

    /**
     * Loads script
     *
     * Loads script asynchronously providing finite Observable for tracking. In
     * case of error inserted SCRIPT tag is removed from DOM.
     *
     * @param script Definition of script to be loaded.
     * @returns Finite Observable tracking SCRIPT element loading.
     */
    public load(script: ScriptDefinition): Observable<ScriptDefinition> {
        return new Observable<ScriptDefinition>((observer: Observer<ScriptDefinition>) => {
            const existingScript = this.scripts
                                       .find(def => def.name === script.name);

            // Complete if already loaded
            if (existingScript && existingScript.loaded) {
                observer.next(existingScript);
                observer.complete();
            } else {
                const bodies = document.getElementsByTagName('body');
                if (bodies.length === 0) {
                    observer.error(new Error('Document has no BODY, unable to attach SCRIPTs.'));
                }

                this._scripts.push(script);

                // Load the script
                const element = document.createElement('script');
                element.type = 'text/javascript';
                element.src = script.src;
                element.onload = () => {
                    script.loaded = true;
                    observer.next(script);
                    observer.complete();
                };
                element.onerror = () => {
                    script.loaded = false;
                    element.remove();
                    observer.error(new Error(`Couldn't load script '${script.src}'`));
                };

                // Append script to DOM
                bodies[0].appendChild(element);
            }
        });
    }
}
