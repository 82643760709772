import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UBAppearance, UBDirection } from '../unboxed.module.typings';

@Component({
  selector: 'ub-label',
  exportAs: 'unboxedLabel',
  styleUrls: ['./label.css'],
  templateUrl: 'label.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedLabel {
  @Input()
  public display: UBDirection = 'row';

  @Input()
  public variant: UBAppearance;

  @Input()
  public text: string;

  @Input()
  public label: string;

  @Input()
  public icon: string;

  public getClassNames(): string[] {
    return [
      'ub-label',
      this.variant ? `ub-label--${this.variant}` : null,
      `ub-label--${this.display}`,
    ].filter(Boolean);
  }
}
