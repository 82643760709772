import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UsersStore } from '@fry/lib/users';

@Component({
  selector: 'eas-user-title',
  template: '{{ title$ | async }}',
})
export class UserTitleComponent implements OnInit {
  @Input() username: string;

  title$: Observable<string>;

  constructor(private users: UsersStore) {}

  ngOnInit() {
    this.getTitles();
  }

  private getTitles() {
    this.title$ = this.users.getStubUsername(this.username).pipe(
      map(itm => itm.fullname)
    );
  }
}
