import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldType, FormDataOptions, FormField } from './forms.interface';
import { Observable, Subscription, throwError } from 'rxjs';
import { debounceTime, finalize, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';


export interface HasFormData {
    formData: any;
}


export function PreventDoubleSubmission() {
  return (_target: any, _propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = function(this: HasFormData, ...args: any[]) {
      if (!this.formData) {
        throw new Error(
          'Form data is not defined. ensure formData ' +
            'property exists in the enclosing class '
        );
      }
      if (!this.formData.startSubmitting()) {
        return throwError('The submit is already running');
      }
      return originalMethod.apply(this, args).pipe(
        tap(() => {
          this.formData.setPristine();
        }),
        finalize(() => {
          this.formData.stopSubmitting();
        })
      );
    };
    return descriptor;
  };
}

export class FormData {
  submitting = false;
  model: any;
  fields: FormField[];
  private validateSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    public form: UntypedFormGroup,
    fields: FormField[],
    public options: FormDataOptions
  ) {
    if (!options) {
      this.options = {};
    }
    this.fields = cloneDeep(fields);
    this.updateForm();
  }

  private _getGroup(fields: FormField[], model: any, options?: FormDataOptions) {
    const group = {};
    fields.forEach(item => {
      if (item.type === FieldType.GROUP) {
        console.log('Group Type', item.type);
        group[item.id] = this._getGroup(item.fields, model[item.id] || {}, options);
        return;
      }

      if (item.type === FieldType.ARRAY) {
        console.log('Array Type', item.type);
        item.meta = [];
        const farr = (model[item.id] || []).map(itm => {
            item.meta.push({});
            return this._getGroup(item.fields, itm, options);
        });
        group[item.id] = this.fb.array(farr);
        return;
      }
      if (item.noFormControl) {
        return;
      }
      if (item.readOnly) {
        return;
      }
      group[item.id] = new UntypedFormControl(item.initial, item.validators, item.asyncValidators);
    });
    return this.fb.group(group);
  }

  private _updateVisibility(fields: FormField[], submodel: any, model: any) {
    fields.forEach(item => {
      item.hidden = item.showCondition && !item.showCondition(submodel[item.id], submodel, model);
      if (item.type === 'array') {
        submodel[item.id].forEach(itm => {
          this._updateVisibility(item.fields, itm, model);
        });
        return;
      }

      if (!item.hidden && item.fields) {
        this._updateVisibility(item.fields, submodel[item.id], model);
      }
    });
  }

  public updateVisibility() {
    this._updateVisibility(this.fields, this.form.value, this.form.value);
  }

  private setForm(value: any) {
    this.form = this._getGroup(this.fields, value, this.options);

    if (this.validateSubscription) {
      console.log('unsubscribing');
      this.validateSubscription.unsubscribe();
    }

    console.log('setting up subscriber');
    this.validateSubscription = this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(() => {
      console.log('Updating visibility within form');
      this.updateVisibility();
    });
  }

  public updateForm() {
    const value = this.form.value;
    this.setForm(value);
    this.setValue(value);
    this.updateVisibility();
  }

  public initForm(model: any) {
    this.model = model;
    this.setForm(model);
    this.setValue(model);
  }

  public setValue(model: any) {
    const value = Object.assign(
      {},
      ...this.fields
        // .filter(field => !field.noFormControl && !field.readOnly)
        .map(field => ({ [field.id]: model[field.id] || field.initial }))
    );
    this.model = model;
    this.form.patchValue(value);
  }

  public _getValue(fields: FormField[], value: any) {
    const newValue = {};
    fields.forEach(field => {
      if (field.type === 'group') {
        if (!field.hidden) {
          newValue[field.id] = this._getValue(field.fields, value[field.id] || {});
        }
      } else if (field.type === 'array') {
        newValue[field.id] = [];
        value[field.id].forEach(valgroup => {
          newValue[field.id].push(this._getValue(field.fields, valgroup));
        });
      } else {
        if (!field.hidden) {
          newValue[field.id] = value[field.id];
        }
      }
    });
    return newValue;
  }
  public getValue(): any {
    return this._getValue(this.fields, this.form.value);
  }

  public startSubmitting() {
    if (this.submitting) {
      return false;
    }
    this.submitting = true;
    return true;
  }

  public stopSubmitting() {
    this.submitting = false;
  }

  public get invalid() {
    return this.form.invalid;
  }

  public get pristine() {
    return this.form.pristine;
  }

  public setPristine() {
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  public submit(fn: () => Observable<any>) {
    if (!this.startSubmitting()) {
      return throwError('The submit is already running');
    }

    return fn().pipe(
      tap(() => {
        this.setPristine();
      }),
      finalize(() => {
        this.stopSubmitting();
      })
    );
  }

  public addGroup(identifier: string, _group: UntypedFormGroup) {
    const value = this.form.value;
    value[identifier].push({});
    this.setForm(value);
    this.form.patchValue(value);
    this.updateForm();
  }

  public get controlCount() {
    return Object.keys(this.form.controls).length;
  }

}
