import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserField } from '@fry/lib/user-fields/user-field';
import {ApiStore, SearchType, SuperType} from '@fry/lib/store';

import { FBForm } from '../forms';
import { intersect } from '../utils';
import { SYSTEM_ROLE_ID } from '../roles/roles.system';
import {APIService} from "@fry/lib/api";

@Injectable({
  providedIn: 'root'
})
export class UserFieldsStore extends ApiStore<UserField> {
  /**
   * List of special Profile fields
   *
   * These fields have defined IDs and special functions throughout the system.
   * We want them to behave like UserFields with the exception of deletion &
   * editing.
   */
  public static PROFILE_FIELDS: UserField[] = [
    new UserField({ _id: 'email',     name: $localize `E-mail`,     fieldType: 'string' }),
    new UserField({ _id: 'lastName',  name: $localize `Last name`,  fieldType: 'string' }),
    new UserField({ _id: 'firstName', name: $localize `First name`, fieldType: 'string' }),
    new UserField({ _id: 'name',      name: $localize `Name`,       fieldType: 'string' }),
  ];

  public static DOC_TYPE = 'userField';

  AssociatedModel = UserField;
  endpoint = 'user-fields';
  searchType = SearchType.LOCAL;

  public get docType(): string {
    return UserFieldsStore.DOC_TYPE;
  }

  public superType = SuperType.Org;

  public static findProfileFieldByID(fieldID: string): UserField|undefined {
    return UserFieldsStore.PROFILE_FIELDS.find(f => f.id === fieldID);
  }

  constructor(api: APIService) {
    super(api);
  }

  /**
   * List of all UserFields, including Profile Fields if requested
   *
   * If list is sorted (default) we sort the fields first by their order attribute.
   * If the field does not define the order it will be appeneded at the end of the
   * list in alphabetical order.
   *
   * @param includeProfileFields Should the special `PROFILE_FIELDS` be included?
   * @param sort Should the returned list be sorted?
   */
  public all(includeProfileFields: boolean = false, sort: boolean = true): Observable<UserField[]> {
    let result = super.all();

    if (includeProfileFields) {
      result = result.pipe(
        map(fields => [...UserFieldsStore.PROFILE_FIELDS, ...fields])
      );
    }

    if (sort) {
      result = result.pipe(map(fields => {
        const sortedFields: UserField[] = [];
        const unsortedFiels: UserField[] = [];
        fields.forEach(f => {
          f.doc['order'] !== undefined
            ? sortedFields.push(f)
            : unsortedFiels.push(f);
        });
        return [
                  ...sortedFields.sort((a, b) => {
                    if (a.doc.order === b.doc.order) { return 0; }
                    return a.doc.order < b.doc.order ? -1 : 1;
                  }),
                  ...unsortedFiels.sort((a, b) => a.title.localeCompare(b.title))
               ];
      }));
    }

    return result;
  }

  private fieldsToForm(userFields: UserField[]): FBForm {
    const ufields = userFields.map(field => {
      return {
        id: field.doc._id,
        type: this.docType,
        fieldOptions: { related: field.doc._id }
      };
    });

    return {
      fields: ufields
    };
  }

  defaultForm(): Observable<FBForm> {
    return this.all().pipe(
      map(data => {
        return this.fieldsToForm(data);
      })
    );
  }

  defaultFormVisibleBy(roles: string[]): Observable<FBForm> {
    return this.all().pipe(
      map(data => {
        var ufs = data.filter(uf => {
          if (!Array.isArray(uf.doc.visibleBy)) {
            return true;
          }

          const allRoles = [
            ...roles,
            '__all__'
          ];

          if (roles.includes(SYSTEM_ROLE_ID.TIMELINE_OWNER)) {
            allRoles.push('__owner__');
          }

          return intersect(uf.doc.visibleBy, allRoles).length > 0;
        })
        return this.fieldsToForm(ufs);
      })
    );

  }
}

