import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ListService } from '@fry/lib/list';
import { AsyncTasksStore } from '@fry/lib/async-tasks';
import { concatMap, map, shareReplay, tap } from 'rxjs/operators';
import { EasTable } from '@fry/lib/store';
import { FormField } from '@fry/lib/forms';

const INTERVAL = 2000;

@Injectable()
export class ExportListService implements OnDestroy {

  // currentTasks$: Observable<EasTable>;
  private waitingFor: string;

  private trigger$ = new ReplaySubject<void>(1);
  // private destroyed$ = new Subject<void>();
  private reloading$ = new BehaviorSubject(false);

  public exportInProgress = this.reloading$.asObservable();

  currentTasks$ = this.trigger$.pipe(
    concatMap(_ => this.fetchTaskTable$()),
    tap(table => this.checkRefresh(table)),
    shareReplay({ refCount: false, bufferSize: 1})
    // takeUntil(this.destroyed$),
  );

  constructor(
    private list: ListService,
    private tasks: AsyncTasksStore,
  ) {
  }

  exportFormFields(): FormField[] {
    return this.list.exportFormFields;
  }

  startExport$(tableOptions: any): Observable<any> {
    return this.list.startExport(tableOptions).pipe(
      tap(data => {
        this.waitingFor = data.taskId;
        this.updateTaskTable();
      })
    );
  }

  updateTaskTable() {
    this.trigger$.next();
  }

  private checkRefresh(table: EasTable): void {
    let refresh = false;
    if (this.waitingFor !== undefined) {
      const ids = table.data.map(item => item.id);
      if (!ids.includes(this.waitingFor)) {
        console.log('Waiting for', this.waitingFor);
        refresh = true;
      }
    }

    const states = table.data.map(item => ['new', 'progress'].includes(item.row.state) ? 'refresh' : 'done');
    if (states.includes('refresh')) {
      console.log('Something is not finished');
      refresh = true;
    }

    if (refresh) {
      setTimeout(() => this.trigger$.next(), INTERVAL);
    }

    this.reloading$.next(refresh);
  }

  private fetchTaskTable$(): Observable<EasTable> {
    return this.tasks.table({ action: 'ExportTable', size: 5, 'metadata:listId': this.list.listId }).pipe(
      // takeUntil(this.destroyed$),
      map(table => {
        table.columns.push({ 'id': 'download', 'label': $localize `Download`, type: 'button' });
        table.data.forEach(item => {
          if (item['row']['state'] === 'success') {
            item['row']['download'] = {
              label: $localize `Download`,
              onClick: () => {
                return this.tasks.download(item.id);
              }
            };
          }
        });

        return table;
      }),
    );
  }

  ngOnDestroy() {
    // this.destroyed$.next();
    // this.destroyed$.complete();
  }


}
