import { Component, Input } from '@angular/core';
import { FormData } from '@fry/lib/forms';

@Component({
  selector: 'app-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.scss']
})
export class FormFieldsComponent {
  @Input() formData: FormData;

  constructor(
  ) { }

}
