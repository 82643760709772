import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { FormData, FormProviderService } from '@fry/lib/forms';

import { Filter, ListService } from '@fry/lib/list';

@Injectable()
export class ListFilterForm {
  private formData: FormData;

  constructor(
      private provider: FormProviderService,
      private list: ListService,
  ) { }

  private getFieldsFromFilters(filters: Observable<Filter[]>, options) {
    return filters.pipe(
      map(items => {
        return items
          .filter(item => options.featured === (item.featured || false))
          .map(item => {
            return {
              id: item.id,
              title: item.label,
              initial: item.initial !== undefined ? item.initial : '',
              validators: [],
              widget: item.widget,
              options: item.options,
            };
          });
      })
    );
  }

  init(options: { featured: boolean }) {
    return this.list.listReady.pipe(
      switchMap(() => {
        const filter = this.list.searchModel.filter;
        return this.getFieldsFromFilters(this.list.filters, options).pipe(
          switchMap(fields => this.provider.getFormDataWith(fields, filter, { submitTitle: $localize `Apply filter` })),
          tap(formData => this.formData = formData)
        );
      })
    );
  }

  submit() {
    this.list.searchModel.filter = {...this.list.searchModel.filter, ...this.formData.getValue()};
    this.list.resetFilter();
    return this.list.load();
  }

  reset() {
    this.formData.form.reset(this.list.defaultSearchModel.filter);
    return this.submit();
  }
}
