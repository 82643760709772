import { GeneralListResolved } from '@fry/components/general/general-list/general.list.interfaces';
import { Injectable } from '@angular/core';
import { TypeInfo, TypeInfoSettings } from '@fry/lib/store';
import { PaymentMethodsStore } from './payment_methods.store';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodInfo implements TypeInfo {

  type = 'paymentMethod';
  title = $localize `Payment methods`;
  settings: TypeInfoSettings = {
    showInSettings: true,
  };

  constructor(
    public store: PaymentMethodsStore,
  ) { }

  resolvedList(): GeneralListResolved {
    return {
      store: this.store,
      search: undefined,
      defaults: {listType: 'table', engine: 'db'},
      title: $localize `Payment methods`,
      type: this.type
    };
  }
}
