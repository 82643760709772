import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'venues.view', name: $localize `View venues`, description: $localize `Allow user to view list of venues` },
  { id: 'venues.create', name: $localize `Create venues`, description: $localize `Allow user to create new venue` },
  { id: 'venues.edit', name: $localize `Edit venues`, description: $localize `Allow user to edit venues`},
];

export const venuesPermissions: PermissionGroup = {
  id: 'venues',
  name: $localize `Venues`,
  description: '',
  permissions
};
