import { Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { ButtonsService, EASAction } from './buttons.service';
import { tap } from 'rxjs/operators';

enum MenuIcon {
  Open = 'close',
  Closed = 'more_horiz',
  ClosedVert = 'more_vert',
}

interface MenuOptions {
  closed: MenuIcon;
  isClosedSVGIcon: Boolean,
  open: MenuIcon;
  isOpenSVGIcon: Boolean,
}


@Component({
  selector: 'eas-buttons-menu',
  templateUrl: './buttons.menu.component.html',
})
export class ButtonsMenuComponent implements OnChanges {
  @Input() buttons: EASAction[];
  @Input() options: Partial<MenuOptions>;

  // Material API: Capture aria-label on this component 
  @Input('aria-label') ariaLabel: string = 'Toggle Menu';

  private _options: MenuOptions;

  filteredButtons$: Observable<EASAction[]>;
  menuIcon: MenuIcon = MenuIcon.ClosedVert;
  isSVGIcon: Boolean;

  constructor(
    private btnService: ButtonsService,
  ) { }

  ngOnChanges() {
    this._options = {
      closed: MenuIcon.Closed,
      open: MenuIcon.Open,
      isClosedSVGIcon: false,
      isOpenSVGIcon: false,
      ...(this.options ?? {})
    };
    this.menuClosed()
    this.filteredButtons$ = this.btnService.processButtons(this.buttons).pipe(
      tap(data => console.log('Buttons', data)),
    );
  }

  public menuOpened() {
    this.menuIcon = this._options.open;
    this.isSVGIcon = this._options.isOpenSVGIcon;
  }

  public menuClosed() {
    this.menuIcon = this._options.closed;
    this.isSVGIcon = this._options.isClosedSVGIcon;
  }
}
