import { Component, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-toggle',
  exportAs: 'unboxedToggle',
  styleUrls: ['../vars.css', '../common.css', './toggle.css'],
  templateUrl: 'toggle.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedToggle extends UnboxedBaseFormComponent {}
