import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { AuthService, CurrentUser } from '@fry/lib/auth';
import { DBService } from '@fry/lib/db';
import { DBDoc, SearchType, StoreType } from '@fry/lib/store/store.interface';
import { BaseModel } from '@fry/lib/store/base-model';
import { DB } from '@fry/lib/db/db';
import { BackendStore } from '@fry/lib/store/backend.store';
import { guid } from '@fry/lib/utils';

export enum SuperType {
  User,
  Org
}

/** FIXME - how to do it using the enum above? ES6 Map? */
const ddocMapping = {
  0: 'public',
  1: 'public_conf'
};

const byTypeMapping = {
  0: 'by_org_user_and_type',
  1: 'by_org_and_type'
};

/*
const byIdMapping = {
  0: 'by_org_user_and_id',
  1: 'by_org_and_id'
};
*/
export abstract class SimpleDBStore<T extends BaseModel> extends BackendStore<T> {

  protected abstract docType: string;
  protected abstract superType: SuperType;

  private _db: DB;
  private currentUser: CurrentUser;

  public storeType = StoreType.DB;
  public searchType = SearchType.LOCAL;

  public _all$: Observable<T[]>;
  public _all?: T[];
  public _all_time?: Date;

  constructor(
    protected dbService: DBService,
    protected auth: AuthService
  ) {
    super();
    this.auth.currentUser().subscribe(data => this.currentUser = data);

    this.auth.currentUser().subscribe(() => {
      this.invalidate();
    });
  }

  private get db(): DB {
    if (!this._db) {
      this._db = this.dbService.getDB();
    }

    return this._db;
  }

  public reset() {}

  public blank(defaults?: Partial<DBDoc>): T {
    const blankValues = {
      _id: guid(),
      organisation: this.auth.organisation,
      type: this.docType,
      dates: []
    };
    const doc = {
      ...(defaults || {}),
      ...blankValues
    };
    delete doc._rev;
    return this.createObject(doc);
  }

  public get(id: string): Observable<T> {
    return this.db.get(id)
      .pipe(
        tap(data => {
          if (data['type'] !== this.docType) {
            throw new Error('Type mismatch');
          }
        }),
        map((doc: DBDoc) => {
          return this.createObject(doc);
        }),
        catchError((error) => {
          console.log('Received an error:', error.message);
          return throwError(error.reason);
        }),
      );
  }

  public getAuditLog(): Observable<any[]> {
    return of([]);
  }

  public one(): Observable<T> {
    return this.all()
      .pipe(
        map(data => {
          if (data.length === 0) {
            throw new Error(`Not found ${this.docType}`);
          }
          return data[0];
        })
      );
  }

  public some(ids: string[]): Observable<T[]> {
    return this.all()
      .pipe(
        map(items => items.filter(item => ids.indexOf(item.doc['_id']) !== -1))
      );
  }

  public all(): Observable<T[]> {
    const options = {
      key: this.injectKey(this.docType),
      include_docs: true
    };
    if (this._all !== undefined && this._all_time) {
      const diff = (new Date()).getTime() - this._all_time.getTime();
      if (diff < 6000) {
        console.log(`Reusing all ${this.docType}`);
        return of(this._all);
      }
    }

    let all$: Observable<T[]>;
    all$ = this.db.query(this.byTypeView, options)
      .pipe(
        tap(() => console.log(`Fetched all ${this.docType}`)),
        map(data => {
          const res = data['rows'].map(doc => this.createObject(doc.doc));
          this._all = res;
          this._all_time = new Date();
          return res;
        }),
      );

    return all$;
  }

  public table() {
    return of({columns: [], data: []});
  }

  public search() {
    return this.all().pipe(
      map(data => {
        return {
          data,
          meta: {
            total: data.length
          }
        };
      })
    );
  }

  public export(): Observable<any> {
    throw new Error('Export not supported for local store');
  }

  public startExport(): Observable<any> {
    throw new Error('Export not supported for local store');
  }

  public save(model: T): Observable<T> {
    const doc = model.orig;
    if (doc['type'] !== this.docType) {
      throw new Error('Type mismatch');
    }

    return this.db.put(doc)
      .pipe(
        mergeMap((data: any) => {
          return this.get(data.id);
        }),
        catchError((error) => {
          console.log('Received an error:', error.message);
          return throwError(error);
        }),
      );
  }

  public remove(id: string): Observable<any> {
    return this.db.remove(id);
  }

  private get byTypeView() {
    const ddoc = ddocMapping[this.superType];
    const view = byTypeMapping[this.superType];
    return `${ddoc}/${view}`;
  }

  private injectKey(key: any) {
    let arr;
    if (this.superType === SuperType.User) {
      arr = [this.currentUser.organisation, this.currentUser.username];
    } else if (this.superType === SuperType.Org) {
      arr = [this.currentUser.organisation];
    } else {
      throw new Error('Invalid super type');
    }

    arr.push(key);
    return arr;
  }

  private invalidate(): void {
    this._db = undefined;
    this._all$ = undefined;
  }

}
