// tslint:disable: no-inferrable-types

import { Component, Input } from '@angular/core';
import { AbstractEasForm } from './easform';

@Component({
  selector: 'eas-easform-element',
  templateUrl: './easform-element.component.html'
})
export class EasFormElementComponent {
  @Input() easElement: AbstractEasForm;
  @Input() templates = {};

  /**
   * Weather the read-only fields should be visible regardless their values.
   *
   * By default the field is ommited from the form if it does not have value
   * which it does not in the Workflow Editor - From preview.
   */
  @Input() forceDisplayReadOnly: boolean = false;
}
