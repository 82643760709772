/**
 *  This is just a playground - still not sure how to do it
 *  Currently only defaultWidget is used
 */

/*
enum Fields {
  string = 'string',
  text = 'text',
  number = 'number',
  boolean = 'boolean',
  select = 'select',
  multiSelect = 'multiselect',
  date = 'date',
  datetime = 'datetime',
  likert = 'likert',
  heading = 'heading',
  hierarchy = 'hierarchy',
}
*/
export const typeMapping: {[K: string]: string} = {
  'discrete_multiple': 'multiSelect',
  'discrete': 'select',
  'likert': 'likert',
  'string': 'string',
  'text': 'text',
  'numeric': 'number',
  'boolean': 'boolean',
  'date': 'date',
  'datetime': 'datetime',
  'document': 'document',
  'hierarchy': 'hierarchy',
};

export const reversedTypeMapping: {[K: string]: string} =
  Object.entries(typeMapping)
        .reduce((acc, cur) => ({ ...acc, [cur[1]]: cur[0] }), {});
// reversedTypeMapping['hierarchy'] = 'discrete_multiple';

export const fieldTypes: {[K: string]: string} = {
  string: 'String',
  text: 'Long text',
  number: 'Number',
  boolean: 'Boolean',
  select: 'Select',
  multiSelect: 'Select multiple',
  date: 'Date',
  datetime: 'Datetime',
  likert: 'Likert',
  hierarchy: 'Hierarchy',
};

export const defaultWidget: {[K: string]: string} = {
  'discrete_multiple': 'multiSelect',
  'discrete': 'select',
  'likert': 'likert',
  'string': 'string',
  'text': 'text',
  'numeric': 'number',
  'boolean': 'boolean',
  'date': 'date',
  'datetime': 'datetime',
  'document': 'document',
  'heading': 'heading',
  'hierarchy': 'treeSelectorFormControl',
};
