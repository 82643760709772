import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'bookings.view', name: $localize `View booking`, description: $localize `Allow user to view bookings of other users` },
  { id: 'bookings.view.own', name: $localize `View own booking`, description: $localize `Allow user to view own bookings` },
  { id: 'bookings.create', name: $localize `Create booking for other users`, description: $localize `Allow user to create new bookings` },
  { id: 'bookings.create.own', name: $localize `Create booking`, description: $localize `Allow user to create new bookings` },
  { id: 'bookings.edit', name: $localize `Edit booking`, description: $localize `Allow user to edit bookings`},
  { id: 'bookings.manage', name: $localize `Manage booking`, description: $localize `Allow user to manage bookings`},
  { id: 'bookings.delete', name: $localize `Delete booking`, description: $localize `Allow user to delete all bookings of other users`},
];

export const bookingsPermissions: PermissionGroup = {
  id: 'booking',
  name: $localize `Bookings`,
  description: '',
  permissions
};
