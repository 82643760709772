import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService, NotifyService } from '@fry/lib/utils';
import { ListService } from '@fry/lib/list';

@Component({
  selector: 'app-list-sort',
  styleUrls: ['./list.sort.component.scss'],
  templateUrl: './list.sort.component.html'
})
export class ListSortComponent implements OnInit {
  public sorts$: Observable<any[]>;
  public active: string;

  constructor(
    private list: ListService,
    private notify: NotifyService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.list.listReady.subscribe(() => {
      this.sorts$ = this.list.sorts;
      this.active = this.list.searchModel.sort.sort_on;
    });
  }

  onSelect(sort) {
    this.list.searchModel.sort.sort_on = sort.id;
    this.list.resetFilter();
    return this.list.load().subscribe(
      () => {
        this.active = this.list.searchModel.sort.sort_on;
        this.logger.debug(`New sort on ${this.active}`);
      },
      error => {
        this.logger.debug(`Failed setting sort to ${sort.id}`);
        this.notify.error(error);
      }
    );
  }
}
