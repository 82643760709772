import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';
import { environment } from '../environments/environment';
import { AuthError } from '@fry/lib/auth/errors';
import { SERVER_CONFIG, ConfigService } from '@fry/lib/config';

if (environment.production) {
  enableProdMode();
}

fetch('__config__')
.then(response => response.json())
.then(config => {
  try {
    const payload = JSON.stringify(config);
    localStorage.setItem(ConfigService.CONFIG_STORAGE_KEY, payload);
  } catch (err) {
    console.log('Failed storing server config in local storage');
  }
  console.log('Config loaded');
  return config;
})
.catch(err => {
  console.log('Could not fetch server config, attempting local storage', err);
  let config;
  try {
    const data = localStorage.getItem(ConfigService.CONFIG_STORAGE_KEY);
    config = JSON.parse(data ?? '');
  } catch(err) {
    console.log('Failed loading from local storage', err);
    config = {}
  }
  return config;
})
.then(config => {
  console.log('Bootstraping angular')
  platformBrowserDynamic([{provide: SERVER_CONFIG, useValue: config}]).bootstrapModule(AppModule)
                          .catch((error: Error|string) => {
                            // Ignore authentication errors, OIDC will handle it!
                            if (error instanceof AuthError) {
                              console.log(error.message);
                              return;
                            }

                            // Update error message if should display it.
                            const errorElement = window.document.getElementById('bootstrap-error');
                            const errorDetailsElement = window.document.getElementById('bootstrap-error-details');
                            if (errorElement && errorDetailsElement) {
                              errorDetailsElement.innerHTML =
                                error instanceof Error ? error.message : error;
                              errorElement.style.display = 'block';
                              errorElement.style.visibility = 'visible';
                            }
                          });

});
