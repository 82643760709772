import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, Router } from '@angular/router';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

import { User } from './user';
import { UsersStore } from './users.store';
import { AuthService } from '../auth';

@Injectable({
    providedIn: 'root'
})
export class RemoteUserService {

    public get user(): User {
        return this.user$.value;
    }
    public set user(user: User) {
        this.user$.next(user);
    }
    public user$: BehaviorSubject<User>;

    private routerWatcher$: Observable<User>;
    private subscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private users: UsersStore,
        private auth: AuthService,
    ) {
        this.user$ = new BehaviorSubject<User>(undefined);
    }

    /**
     * getRemoteUser
     *
     * Look in all the routes for a 'user' parameter
     * If found, load the remote user profile
     */
    startWatchingForRemoteUser() {
        this.routerWatcher$ = this.router.events.pipe(
            filter(evt => evt instanceof NavigationEnd || evt instanceof RouteConfigLoadEnd),
            tap(dd => console.log('Changed', dd)),
            map(() => this.route),
            map((activatedRoute) => {
                // Try to get from url
                let user = activatedRoute.snapshot.params.user;
                while (activatedRoute.firstChild) {
                    activatedRoute = activatedRoute.firstChild;
                    user = activatedRoute.snapshot.params.user || user;
                }
                return user !== 'profile' ? user : undefined;
            }),
            switchMap(username => {
                return this.auth.currentUser().pipe(
                    map(current => {
                        // Try to get from resolved data
                        let route = this.route;
                        while (route.firstChild) {
                            route = route.firstChild;
                        }

                        const data = route.snapshot.data;
                        let datauser;
                        Object.values(data).forEach(itm => {
                            if (itm.doc && itm.doc.user) {
                                datauser = itm.doc.user;
                            }
                        });

                        if (datauser === current.username) {
                            datauser = undefined;
                        }
                        return datauser || username;
                    })
                );

            }),
            distinctUntilChanged(),
            mergeMap(username => {
                if (username === undefined) {
                    return of(undefined);
                }
                return this.users.getByUsername(username).pipe(
                    catchError(() => {
                        return this.users.getStubUsername(username);
                    })
                );
            }),
        );

        this.subscription = this.routerWatcher$.subscribe(user => {
            this.user = user;
        });
    }

    public stopWatching() {
        this.user = undefined;
        this.subscription.unsubscribe();
        this.routerWatcher$ = undefined;
    }
}
