import { BaseModel } from '@fry/lib/store';

export class Role extends BaseModel {

  public isInvitable: boolean;
  public isUserDependent: boolean;
  public permissions: [];
  public roles: [];
  public relations: [];

  init() {
    this.doc = this._getDoc();
  }

  private _getDoc() {
    const doc = Object.assign({}, this.orig);
    delete doc['_tmp_cache'];
    delete doc['dates'];
    return doc;
  }

  public get title() {
    return this.orig['title'];
  }

  public get description() {
    return this.orig['description'];
  }

  public get transformed() {
    const tr = {...this.doc};
    return tr;
  }

  public applyDoc() {
    Object.assign(this.orig, this.doc);
  }

  public setValue(value) {
    this.orig = {...this.orig, ...value};
    this.init();
  }

}
