import { Component, Input } from '@angular/core';
import { FormField } from '@fry/lib/forms';

@Component({
  selector: 'app-form-field-readonly',
  templateUrl: './form-field-readonly.component.html',
})
export class FormFieldReadOnlyComponent {
  @Input() field: FormField;
  @Input() value: any;

  constructor(
  ) { }

}
