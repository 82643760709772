import { Component, Input } from '@angular/core';
import { BuilderUIFormService } from './builder-ui-form.service';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { EasElement, EasFieldGroup } from '../easform/easform.interfaces';
import { EasFormDialogComponent } from '../easform/easform-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'eas-builder-group',
  templateUrl: './builder-group.component.html'
})
export class BuilderGroupComponent {

  @Input() group: EasFieldGroup;

  constructor(
    private builder: BuilderUIFormService,
    private dialog: MatDialog,
  ) { }

  getAvailableFields() {
    return this.builder.getAvailableFields();
  }

  addField() {
    this.builder.getFieldForm({}).subscribe(
      group => {
        this.dialog.open(EasFormDialogComponent, {
          data: {
            title: $localize `Add field`,
            group,
            onSubmit: (value) => {
              const field = this.builder.initField(value);
              this.group.fields.push(field);
              return of(true);
            }
          }
        });
      }
    );
  }

  editField(i: number) {
    const field: EasElement = this.group.fields[i];
    this.builder.getFieldForm(field).subscribe(
      group => {
        this.dialog.open(EasFormDialogComponent, {
          data: {
            title: $localize `Edit field`,
            group,
            onSubmit: (value) => {
              // const field = this.builder.initField(value);
              // this.group.fields.push(field);
              // Merge options
              const options = {
                ...(this.group.fields[i].options || {}),
                ...(value.options || {})
              }
              this.group.fields[i] = {...this.group.fields[i], ...value};
              this.group.fields[i].options = options;
              return of(true);
            }
          }
        });
      }
    );
  }

  removeField(i: number) {
    this.group.fields = [...this.group.fields.slice(0, i), ...this.group.fields.slice(i + 1)];
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.group.fields, event.previousIndex, event.currentIndex);
  }

  getDefaultGroupTitle() {
    return $localize `unnamed`;
  }
}
