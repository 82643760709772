import { ErrorHandler, Injectable, Injector, Optional } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { NotificationsService } from './notifications.service';
import { SentryService } from './sentry.service';
import { AuthErrorNotAuthenticated } from '@fry/lib/auth';

/**
 * Global error handler
 *
 * This error handler catches any errors within the system which are not
 * handled. Eg.: `throw new Error()` without catch and so on.
 *
 * Also by default all HTTP errors, which are not handled in the Observable
 * chain will endup here.
 *
 * At the moment we log error to console as the default Angular handler does
 * and we push error to NotificationsService for handling & display.
 */
@Injectable()
export class EASErrorHandler extends ErrorHandler {

  constructor(private injector: Injector,
              @Optional() private sentry: SentryService) {
    super();
  }

  handleError(error: any) {
    const notificationsService = this.injector.get(NotificationsService);
    const router = this.injector.get(Router);
    notificationsService.registerCleanupOnNavigationStart(router);

    // Ignore errors which are thrown to facilitate some kind of flow control.
    // For example in APP_INITIALISER we have to fail to stop loading the app
    // the Promise.reject or throw Error will get us here so we need to skip
    // those kind of 'Handled errors'...
    if (error instanceof AuthErrorNotAuthenticated) {
      return;
    }

    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
      } else {
        // Handle Http Error (error.status === 403, 404...)
        // notificationsService.push({ type: 'error', message: `HTTP ERROR: ${error.status} ${error.url}`});
      }

      this.reportError(error);
    } else if (error instanceof Error) {
      // Handle Client Error (Angular Error, ReferenceError...)
      // notificationsService.push({ type: 'error', message: `ERROR: ${error.message}` });

      // Resolve Chunk loading errors when deploying new version
      // https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
      const chunkFailedMessageRegExp = /Loading chunk [\d]+ failed/;
      if (chunkFailedMessageRegExp.test(error.message)) {
        if (this.sentry) {
          // tslint:disable-next-line: quotemark
          this.sentry.captureMessage("Reloading due to 'Loading chunk # failed.' error.",
                                     'warning' as Sentry.SeverityLevel);
        }
        window.location.reload();
        return;
      }

      this.reportError(error);
    } else {
      // We do get unfortunately strings as well, this will come from
      // throw 'string'
      // notificationsService.push({ type: 'error', message: `STRING: ${error}` });

      this.reportError(error);
    }
  }

  private reportError(error: any) {
    // Report the captured error to Sentry if we have it
    if (this.sentry) {
      this.sentry.captureException(error.originalError || error);
    }

    // Output the captured error to Console
    console.error(error.originalError || error);
  }
}
