import { Component } from '@angular/core';
import { SWService } from '@fry/lib/utils';
import { AuthService } from '@fry/lib/auth';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RemoteUserService } from '@fry/lib/users/remote-user.service';
import { ConfigService } from '@fry/lib/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  organisation$: Observable<string>;

  constructor(
    private swservice: SWService,
    private auth: AuthService,
    private remoteUserService: RemoteUserService,
    private config: ConfigService
  ) {
    this.swservice.logSelf();
    this.organisation$ = this.auth.currentUser().pipe(
      map(current => current.organisation)
    );

    this.auth.currentUser().subscribe(user => {
      const license = this.config.server.liveChat?.licenses[user.organisation];
      if (license) {
        window['__lc'] = window['__lc'] || {};
        window['__lc'].license = license;
        var startChat = function() {
          var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
          lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
          var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
        };
        startChat();
      }
    });

    this.remoteUserService.startWatchingForRemoteUser();
  }
}
