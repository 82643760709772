import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UBSize } from '../unboxed.module.typings';

@Component({
  selector: 'ub-table',
  exportAs: 'unboxedTable',
  styleUrls: ['./table.css'],
  templateUrl: 'table.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedTable {
  @Input()
  public rowsize: UBSize = 'sm';

  @Input()
  public striped = false;

  @Input()
  public bordered = false;

  public getClassNames(): string[] {
    return [
      'ub-table',
      `ub-table--${this.rowsize}`,
      this.striped ? 'ub-table--striped' : null,
      this.bordered ? 'ub-table--bordered' : null
    ].filter(Boolean);
  }
}
