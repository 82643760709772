import { Component, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-textarea',
  exportAs: 'unboxedTextarea',
  styleUrls: ['../vars.css', '../common.css', './textarea.css'],
  templateUrl: './textarea.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedTextarea extends UnboxedBaseFormComponent {}
