/**
 * This file defines System hardcoded user roles
 *
 * Please use the constants defined in this file to work with the
 * system roles.
 */

export enum SYSTEM_ROLE_ID {
  ADMIN              = 'system:admin',
  ANONYMOUS_EXTERNAL = 'system:anonymous-external',
  ANYONE             = 'system:anyone',
  PENDING_USER       = 'system:pending-user',
  SUPER_ADMIN        = 'system:superadmin',
  SYSTEM             = 'system:system',
  TIMELINE_OWNER     = 'system:timeline-owner',
}

export const SYSTEM_ROLES = [
  createRole(SYSTEM_ROLE_ID.ADMIN, $localize `Organisation admin`, true, false),
  createRole(SYSTEM_ROLE_ID.ANONYMOUS_EXTERNAL, $localize `Anonymous external user`),
  createRole(SYSTEM_ROLE_ID.ANYONE, $localize `Anyone`),
  createRole(SYSTEM_ROLE_ID.PENDING_USER, $localize `Pending user`, true, false ,['events.view', 'events.edit', 'users.edit.own']),
  createRole(SYSTEM_ROLE_ID.SUPER_ADMIN, $localize `risr/admin`, true, false),
  createRole(SYSTEM_ROLE_ID.SYSTEM, $localize `risr/automation`),
  createRole(SYSTEM_ROLE_ID.TIMELINE_OWNER, $localize `Timeline owner`),
];

function createRole(id: string,
                    title: string,
                    system: boolean = true,
                    hidden: boolean = true,
                    roles: string[] = []) {
  return {
    key: id,
    id: id,
    doc: {
      _id: id,
      dates: [],
      description: '',
      hidden: hidden,
      roles: roles,
      system: system,
      title: title,
    }
  }
}
