import { EasFieldGroup, EasFieldType } from '@fry/components/common/easform/easform.interfaces';
import { EasFormGroup } from '../easform';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';

export enum FieldPermission {
  DEFAULT = 'default',
  HIDDEN = 'hidden',
  VIEW = 'view',
  EDIT = 'edit',
}

export interface EasBuilderField {
  id: string;
  type: EasFieldType;
  fieldType: string;
  options?: any;
  fields?: EasBuilderField[];
  fields_from?: string;
  validators?: string[];
  asyncValidators?: string[];
}

export interface EasBuilderGroup extends EasBuilderField {
  type: EasFieldType.Group;
  fields: EasBuilderField[];
}

interface ExtraPermision {
  role: string;
  permissions: string[];
}

interface RolePermission {
  role: string;
  group: {[key: string]: FieldPermission};
  transitions: string[];
  canDelete: boolean;
}

interface InitialState {
  state: string;
  actions: EasBuilderTransitionAction[];
}

export interface EasBuilderState {
  id: string;
  name: string;
  permissions: ExtraPermision[];
  roles: RolePermission[];
}

export interface EasBuilderTransitionAction {
  action: string;
  data: any;
}

export interface EasBuilderTransitionGuard {
  guard: string;
  data: any;
}

export interface EasBuilderTransition {
  id: string;
  name: string;
  destination: string;
  sources: string[];
  actions?: EasBuilderTransitionAction[];
  guards?: EasBuilderTransitionGuard[];
  allowInvalidForm?: boolean;
  requireConfirmation?: boolean;
  confirmationTitle?: string;
  confirmationDescription?: string;
  cancelButton?: string;
  okButton?: string;
}

export interface EasBuilderForm {
  group: EasBuilderGroup;
  states: EasBuilderState[];
  initial: InitialState;
  transitions: EasBuilderTransition[];
}

export interface EasBuilderSecurity {
  group: {[key: string]: FieldPermission};
  transitions: string[];
  canDelete: boolean;
}

export interface EasBuilderSecureForm {
  group: EasBuilderGroup;
  transitions: EasBuilderTransition[];
  canDelete: boolean;
}

export interface EasWorkflowContext {
  state: string;
  metadata: any;
}

export interface EasFormWFGroup {
  group: EasFormGroup;
  transitions: EasBuilderTransition[];
  state: EasBuilderState;
}

export interface TransitionActionComponent {
  component: ComponentType<any>;
  data: any;
}

export interface TransitionAction {
  actionId: string;
  scope: string;
  getForm: (doc: any, actionData: any) => Observable<EasFieldGroup | undefined>;
  getComponent?: (doc: any, actionData: any) => Observable<TransitionActionComponent>;
}
