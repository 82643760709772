import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '@fry/lib/auth';
import { APIService } from '@fry/lib/api';
import { ApiStore, DBDoc, SearchType, SuperType } from '@fry/lib/store';

import { Relation } from './relation';
import { findCategory, guid, LoggerService } from '../utils';


@Injectable({
  providedIn: 'root'
})
export class RelationsStore extends ApiStore<Relation> {
  AssociatedModel = Relation;

  docType = 'relation';
  endpoint = 'relations';
  superType = SuperType.Org;
  searchType = SearchType.LOCAL;

  constructor(api: APIService, protected auth: AuthService, private logger: LoggerService) {
    super(api);
  }

  public blank(defaults?: Partial<DBDoc>): Relation {
    const blankValues = {
      _id: guid(),
      organisation: this.auth.organisation, // TODO: is this needed using the API?
      type: this.docType,
      dates: []
    };
    const doc = {
      ...(defaults || {}),
      ...blankValues
    };
    delete doc._rev;
    return this.createObject(doc);
  }

  getCategory(id: string, category: string) {
    this.logger.warn('FIXME: Needs optimisation');
    return this.get(id).pipe(
      map(item => findCategory(item.doc.categories, category)),
      catchError(err => {
        console.log(err);
        return of(undefined);
      }),
    );
  }
}
