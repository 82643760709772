import { Component, Input } from '@angular/core';
import { BuilderUIFormService } from './builder-ui-form.service';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { EasFieldGroup } from '../easform/easform.interfaces';
import { EasFormDialogComponent } from '../easform/easform-dialog.component';

@Component({
  selector: 'eas-builder-states',
  templateUrl: './builder-states.component.html'
})
export class BuilderStatesComponent {

  @Input() states: any[];
  @Input() transitions: any[];
  @Input() group: EasFieldGroup;

  constructor(
    private builder: BuilderUIFormService,
    private dialog: MatDialog,
  ) { }

  addState() {
    this.builder.getStatesForm(this.group, this.transitions, {}).subscribe(
      group => {
        this.dialog.open(EasFormDialogComponent, {
          data: {
            title: $localize `Add worklow state`,
            group,
            onSubmit: (value) => {
              const field = this.builder.initField(value);
              this.states.push(field);
              return of(true);
            }
          }
        });
      }
    );
  }

  editState(i: number) {
    const state = this.states[i];
    this.builder.getStatesForm(this.group, this.transitions, state).subscribe(
      group => {
        this.dialog.open(EasFormDialogComponent, {
          data: {
            title: $localize `Edit worklow state`,
            group,
            onSubmit: (value) => {
              this.states[i] = {...this.states[i], ...value};
              return of(true);
            }
          }
        });
      }
    );
  }

  removeState(i: number) {
    this.states = [...this.states.slice(0, i), ...this.states.slice(i + 1)];
  }
}
