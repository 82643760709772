import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { APIService } from '@fry/lib/api';
import { ConfigService } from '@fry/lib/config';
import { ClusterService } from '@fry/lib/organisations/cluster.service';
import { User } from '@fry/lib/users';


export interface UserLanguage {
  languageName: string;
  languageCode: string;
  localeName: string;
}


@Injectable()
export class UserLanguageService {
  availableLanguages: UserLanguage[];

  private readonly _preferredLanguage$: BehaviorSubject<Observable<UserLanguage>>;
  readonly preferredLanguage$: Observable<UserLanguage>;

  constructor(
    private api: APIService,
    private config: ConfigService,
    private cluster: ClusterService,
  ) {
    const organisationLanguages = this.cluster.getCurrentOrganisation().availableLanguages;
    this.availableLanguages = organisationLanguages === null
      ? []
      : organisationLanguages.map(availableLanguage => this.extractData(availableLanguage));

    this._preferredLanguage$ = new BehaviorSubject<Observable<UserLanguage>>(this.getPreferredLanguage$());
    this.preferredLanguage$ = this._preferredLanguage$.asObservable().pipe(
      switchMap(value => value),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  private extractData(data: any): UserLanguage {
    return {
      languageName: data['language_name'],
      languageCode: data['language_code'],
      localeName: data['locale_name'],
    }
  }

  private getPreferredLanguage$(): Observable<UserLanguage> {
    return this.api.get('account/language').pipe(
      map(data => this.extractData(data))
    );
  }

  getPreferredLanguage(user: User): UserLanguage {
    // TODO: we should have some better default here
    const code = this.getPreferredLanguageCode(user);
    let language = this.availableLanguages.find(language => language.languageCode === code);
    if (language === undefined) {
      language = this.availableLanguages[0];
    }
    return language;
  }

  getPreferredLanguageCode(user: User) {
    // TODO: we should have some better default here
    return user.doc.preferredLanguageCode ?? this.availableLanguages[0].languageCode;
  }

  setPreferredLanguage(languageCode: string): Observable<UserLanguage> {
    return this.api.post('account/language', { language_code: languageCode }).pipe(
      map(data => this.extractData(data)),
      tap(language => this._preferredLanguage$.next(of(language)))
    );
  }

  getLanguageBaseHref(languageCode: string): string {
    const languages = this.config.server.languages;
    if (languages !== undefined && languages[languageCode]) {
      return languages[languageCode];
    } else {
      return '';
    }
  }
}
