import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { UBErrorMessages } from '../../unboxed.module.typings';

@Component({
  template: ''
})
export class UnboxedBaseFormComponent {
  @Input()
  public label: string;

  @Input()
  public placeholder = '';

  @Input()
  public hint: string;

  @Input()
  public control: UntypedFormControl;

  @Input()
  public errorMessages: UBErrorMessages = {};

  @Input()
  public readonly: boolean;

  public validationMessage(key, value): string {
    if (!this.errorMessages || !this.errorMessages[key]) {
      console.warn(`You have not provided an error message for ${key}!`);
      return undefined;
    }

    return this.errorMessages[key](value);
  }
}
