import { Injectable } from '@angular/core';

import { EasFieldGroup, EasFieldType } from '@fry/components/common/easform';
import { TransitionAction } from '@fry/components/common/form-builder/builder.interfaces';
import { of } from 'rxjs';
import { getRefundOptionsForm } from './change-option.action';
import { RefundOptionComponent } from './refund-option.component';

@Injectable()
export class SelectRefundOptionAction implements TransitionAction {
  actionId = 'select_refund_option';
  scope = 'booking';

  constructor() {}

  getComponent(doc: any, actionData: any) {
    return of({
      component: RefundOptionComponent,
      data: {
        actionId: this.actionId,
        doc,
        actionData
      }
    });
  }

  getForm(_doc: any, actionData: any) {
/*
    const options = actionData.refund_options || {};
    const initial = options.find(itm => itm.default);
*/
    const fields = getRefundOptionsForm(actionData);
    if (fields.length === 0) {
      return of(undefined);
    }

    const changeOptionForm: EasFieldGroup = {
      id: 'select_refund_option',
      type: EasFieldType.Group,
      fields: [
        ...fields
      ]
    };
    return of(changeOptionForm);
  }
}
