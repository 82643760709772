import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UBListType } from '../../unboxed.module.typings';

@Component({
  selector: 'ub-form-readable',
  exportAs: 'unboxedFormReadable',
  styleUrls: ['../vars.css', './readable.css'],
  templateUrl: './readable.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedReadable {
  @Input()
  public label: string;

  @Input()
  public value: string;

  @Input()
  public display: UBListType = 'inline';

  public getClassNames(): string[] {
    return [
      'ub-form__readable',
      this.display === 'block' ? `ub-form__readable--block` : null
    ].filter(Boolean);
  }
}
