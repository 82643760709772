import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[ubFormLabel]'
})
export class UnboxedFormLabelDirective {
  public constructor(el: ElementRef) {
    el.nativeElement.style.display = 'block';
    el.nativeElement.style.fontWeight = 'bold';
    el.nativeElement.style.margin = 'calc(var(--gutter) / 2) 0';
    el.nativeElement.style.width = '100%';
  }
}
