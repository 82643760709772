import { HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap, shareReplay } from 'rxjs/operators';

import { APIService } from '@fry/lib/api';
import { DBDoc, EasTable, SearchType, StoreType } from '@fry/lib/store/store.interface';
import { BaseModel } from '@fry/lib/store/base-model';
import { BackendStore } from '@fry/lib/store/backend.store';
import { guid } from '@fry/lib/utils';


export abstract class ApiStore<T extends BaseModel> extends BackendStore<T> {
  protected abstract docType: string;
  protected abstract endpoint: string;

  public storeType = StoreType.API;
  public searchType = SearchType.REMOTE;

  private _all$?: Observable<T[]>;
  private _all?: T[];
  private _all_time?: Date;

  protected constructor(
    protected api: APIService,
  ) {
    super();
}

  public reset() {}

  public blank(defaults?: Partial<DBDoc>): T {
    const blankValues = {
      _id: guid(),
      type: this.docType,
      dates: []
    };
    const doc = {
      ...(defaults || {}),
      ...blankValues
    };
    delete doc._rev;
    return this.createObject(doc);
  }

  private getFromUrl(url: string) {
    return this.api.get(url)
      .pipe(
        tap(data => {
          if (data.type !== this.docType) {
            throw new Error('Type mismatch');
          }
        }),
        map((doc: DBDoc) => {
          return this.createObject(doc);
        }),
        catchError(error => {
          console.log('Received an error:', error.message);
          return throwError(error);
        }),
      );
  }

  public get(id: string): Observable<T> {
    return this.getFromUrl(`${this.endpoint}/${id}`);
  }

  public getForEdit(id: string): Observable<T> {
    return this.getFromUrl(`${this.endpoint}/${id}/edit`);
  }

  public getDuplicate(id: string): Observable<T> {
    return this.getFromUrl(`${this.endpoint}/${id}/new-copy`);
  }

  public getWithDependencies(id: string): Observable<T> { // Can this also be moved to getFromUrl?
    return this.api.get(`${this.endpoint}/${id}/extended`)
      .pipe(
        tap(data => {
          if (data.doc.type !== this.docType) {
            throw new Error('Type mismatch');
          }
        }),
        map((data: any) => {
          return this.createObject(data);
        }),
        catchError(error => {
          console.log('Received an error:', error.message);
          return throwError(error);
        }),
      );
  }

  public getAuditLog(id: string): Observable<any[]> {
    return this.api.get(`${this.endpoint}/${id}/auditlog`)
      .pipe(
        catchError(error => {
          console.log('Received an error:', error.message);
          return throwError(error);
        }),
      );
  }

  public one(): Observable<T> {
    return this.all()
      .pipe(
        map(data => {
          if (data.length === 0) {
            throw new Error(`Not found ${this.docType}`);
          }
          return this.createObject(data[0]['doc']);
        })
      );
  }

  public some(ids: string[]): Observable<any> {
    return this.all()
      .pipe(
        filter(item => ids.indexOf(item['doc']['_id']) !== -1)
      );
  }

  public all(): Observable<T[]> {
    if (this._all !== undefined && this._all_time) {
      const diff = (new Date()).getTime() - this._all_time.getTime();
      if (diff < 6000) {
        console.log(`Reusing all ${this.docType}`);
        return of(this._all);
      }
    }

    if (this._all$ === undefined) {
      this._all$ = this.api.get(`${this.endpoint}/`)
        .pipe(
          map(data => {
            var res = data.map(doc => this.createObject(doc))
            this._all = res;
            this._all_time = new Date();
            return res;
          }),
          shareReplay({ refCount: false, bufferSize: 1 }),
          catchError(error => {
            console.log('Received an error:', error.message);
            return throwError(error);
          }),
        );
    } else {
      console.log(`Reusing all promise ${this.docType}`);
    }

    return this._all$;
  }

  public search(params: any = {}) {
    return this.api.post(`${this.endpoint}/search`, params)
      .pipe(
        map(data => {
          return {
            data: data.hits.map(doc => this.createObject(doc)),
            meta: {
              total: data.total
            }
          };
        }),
        catchError(error => {
          console.log('Received an error:', error.message);
          return throwError(error);
        })
      );
  }

  public filter(params: any = {}) {
    return this.api.post(`${this.endpoint}/filter`, params)
      .pipe(
        map((data: any[]) => {
          return {
            data: data.map(doc => this.createObject(doc)),
            meta: {
              total: data.length
            }
          };
        }),
        catchError(error => {
          console.log('Received an error:', error.message);
          return throwError(error);
        })
      );
  }

  public table(params: any = {}): Observable<EasTable> {
    return this.api.post(`${this.endpoint}/table`, params)
      .pipe(
        map(data => {
          return {
            columns: data.columns,
            data: data.data.map(itm => {
              if (itm.doc) {
                itm.obj = this.createObject(itm.doc);
              }
              return itm;
            }),
            meta: data.meta,
          };
        }),
        catchError(error => {
          console.log('Received an error:', error.message);
          return throwError(error);
        })
      );
  }

  public export(params: any) {
    return this.downloadCSV(params).pipe(
      map(res => window.URL.createObjectURL(res))
    );
  }

  public startExport(listId: string, params: any) {
    const payload = {
      params,
      options: params.tableOptions,
      listId
    };
    return this.api.post(`${this.endpoint}/start-export`, payload);
  }

  public downloadCSV(params: any = {}) {
    const headers = new HttpHeaders({'accept': 'text/csv'});
    return this.api.post(`${this.endpoint}/table`, params, headers)
      .pipe(
        catchError(error => {
          console.log('Received an error:', error.message);
          return throwError(error);
        })
      );
  }

  public aggregates(params: any = {}) {
    const _params = {size: 0, includeAggs: true, ...params};
    return this.api.post(`${this.endpoint}/search`, _params)
      .pipe(
        map(data => data.aggs),
        catchError(error => {
          console.log('Received an error:', error.message);
          return throwError(error);
        })
      );
  }

  public save(model: T): Observable<T> {
    return this.api.put(`${this.endpoint}/${model.id}/edit`, model.doc)
      .pipe(
        switchMap(() => this.getForEdit(model.id)),
        catchError(error => {
          console.log('Received an error:', error);
          return throwError(error);
        }),
        tap(() => {
          this.invalidate();
        })
      );
  }

  public create(model: T): Observable<T> {
    return this.api.post(`${this.endpoint}/`, model.doc).pipe(
      switchMap(data => this.getForEdit(data.id)),
      catchError(error => {
        console.log('Received an error:', error);
        return throwError(error);
      }),
      tap(() => {
        this.invalidate();
      })
    );
  }

  public createOrSave(model: T): Observable<T> {
    return model.isNew() ? this.create(model) : this.save(model);
  }

  public remove(id: string): Observable<any> {
    return this.api.delete(`${this.endpoint}/${id}`)
      .pipe(
        catchError(error => {
          console.log('Received an error:', error.message);
          return throwError(error);
        }),
      );
  }

  private invalidate(): void {
    this._all$ = undefined;
    this._all = undefined;
  }
}
