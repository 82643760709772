import { Component, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-select',
  exportAs: 'unboxedSelect',
  styleUrls: ['../vars.css', '../common.css', './select.css'],
  templateUrl: './select.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedSelect extends UnboxedBaseFormComponent {}
