import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { APIService } from '@fry/lib/api';
import { ApiStore, DBDoc } from '@fry/lib/store';
import { User } from '@fry/lib/users/user';

enum ProfileParts {
  basic = 'basic',
  details = 'userDetails',
  emails = 'emails',
  roles = 'roles'
}


@Injectable({
  providedIn: 'root'
})
export class UsersStore extends ApiStore<User> {
  AssociatedModel = User;

  docType = 'user';
  endpoint = 'users';

  constructor(
    api: APIService
  ) {
    super(api);
  }

  public blank(defaults?: Partial<DBDoc>): User {
    const blankValues = {
      _id: '__new__', // TODO: If '__new__' can be replaced by guid(), blank() can be inherited
      type: this.docType,
      dates: []
    };
    const doc = {
      ...(defaults || {}),
      ...blankValues
    };
    delete doc._rev;
    return this.createObject(doc);
  }

  public createLegacy(model: User): Observable<User> {
    return this.api.post(`${this.endpoint}/legacy/`, model.doc).pipe(
      switchMap(data => this.get(data.id)),
      catchError((error) => {
        console.log('Received an error:', error);
        return throwError(error);
      }),
    );
  }

  public getByUsername(username: string): Observable<User> {
    return this.api.get(`${this.endpoint}/by_username/${username}`)
      .pipe(
        tap(data => {
          if (data.type !== this.docType) {
            throw new Error('Type mismatch');
          }
        }),
        map(doc => {
          return this.createObject(doc);
        }),
        catchError((error) => {
          console.log('Received an error:', error.message);
          return throwError(error);
        }),
      );
  }

  public getStubUsername(username: string): Observable<User> {
    return this.api.get(`${this.endpoint}/stub/by_username/${username}`)
      .pipe(
        map(doc => {
          return this.createObject(doc);
        }),
        catchError((error) => {
          console.log('Received an error:', error.message);
          return throwError(error);
        }),
      );
  }

  public saveProfile(user: User) {
    // This does not have support in API - on purpose so far
    user.applyDoc();
    return this.save(user);
  }

  private updatePartialProfile(user: User, type: ProfileParts, data: any) {
    const extendedData = {
     type,
     data: {...data, _id: user.doc._id, _rev: user.doc._rev}
    };
    return this.api.put(`${this.endpoint}/${user.id}/update`, extendedData).pipe(
      switchMap(() => this.get(user.id))
    );
  }

  public updateBasic(user: User, data: any) {
    return this.updatePartialProfile(user, ProfileParts.basic, data);
  }

  public updateDetails(user: User, data: any) {
    return this.updatePartialProfile(user, ProfileParts.details, data);
  }

  public updateRoles(user: User, data: any) {
    return this.updatePartialProfile(user, ProfileParts.roles, data);
  }

  public updateEmails(user: User, data: any) {
    return this.updatePartialProfile(user, ProfileParts.emails, data);
  }

  public updateLanguage(user: User, languageCode: string) {
    const data = {
      _id: user.doc._id,
      _rev: user.doc._rev,
      preferredLanguageCode: languageCode
    }
    return this.api.put(`${this.endpoint}/${user.id}`, data).pipe(
      switchMap(() => this.get(user.id))
    );
  }

  public disable(user: User, data: { reason: string }) {
    return this.api.post(`${this.endpoint}/${user.id}/disable`, data).pipe(
      switchMap(() => this.get(user.id))
    );
  }

  public enable(user: User) {
    return this.api.post(`${this.endpoint}/${user.id}/enable`, {}).pipe(
      switchMap(() => this.get(user.id))
    );
  }
}
