import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';

import { DialogComponent } from '@fry/components/common/dialog';


export interface CanComponentDeactivate {
  /**
   * Return a boolean whether it is ok to navigate away or whether the router
   * should ask for confirmation.
   *
   * @return boolean Indicate whether it is ok to navigate away
   */
  canSafelyDeactivate: () => boolean;
}


@Injectable({
  providedIn: 'root'
})
export class UnsavedGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private dialog: MatDialog) {

  }

  canDeactivate(component: CanComponentDeactivate) {
    const canSafelyDeactivate = component.canSafelyDeactivate ? component.canSafelyDeactivate() : true;

    if (canSafelyDeactivate) {
      return true;
    }

    const dialog = this.dialog.open(DialogComponent, {
      data: {}
    });

    return dialog.afterClosed();
  }
}
