import { Component, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-radio',
  exportAs: 'unboxedRadio',
  styleUrls: ['../vars.css', '../common.css', './radio.css'],
  templateUrl: 'radio.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedRadio extends UnboxedBaseFormComponent {}
