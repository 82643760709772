import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { BookingItem } from './booking-item';
import { BookingItemsStore } from './booking-items.store';

@Injectable()
export class BookingItemResolver implements Resolve<BookingItem> {

    constructor(private store: BookingItemsStore) { }

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<BookingItem> {
        const id = route.paramMap.get('id');
        return this.store.getForEdit(id);
    }
}
