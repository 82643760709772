import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UnboxedModule } from '@fry/ui';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import {
  FileUploadControlComponent,
  FileUploadControlUploadListItemComponent,
  StoragePipe,
} from '@fry/components/common/file-upload-control';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    UnboxedModule,
  ],
  exports: [
    FileUploadControlComponent,
    StoragePipe,
  ],
  declarations: [
    FileUploadControlComponent,
    FileUploadControlUploadListItemComponent,
    StoragePipe,
  ],
  providers: [ ]
})
export class FileUploadControlModule {}
