import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LoggerService } from '@fry/lib/utils';
import { EasFormGroup } from './easform';

import * as EASDialogError from '@fry/components/common/dialog/dialog.error';

export interface FormDialogData {
  title: string;
  group: EasFormGroup;
  onSubmit: (value) => Observable<any>;
}

@Component({
  selector: 'eas-easform-dialog-component',
  templateUrl: 'easform-dialog.component.html',
})
export class EasFormDialogComponent {

  public submitted = false;
  public canceled = EASDialogError.UserCanceledError();

  constructor(
    public dialogRef: MatDialogRef<EasFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData,
    private logger: LoggerService
  ) { }

  onSubmit() {
    if (this.submitted) { return; }
    this.submitted = true;

    const value = this.data.group.getValue();
    this.data.onSubmit(value).subscribe(
      (result) => {
        this.dialogRef.close(result);
      },
      () => {
        this.logger.warn('Could not submit form');
        this.submitted = false;
      }
    );
  }
}
