import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@fry/components/material';
import { FryCommonModule } from '@fry/components/common';

import { MainComponent } from './main/main.component';
import { SimpleComponent } from './simple/simple.component';
import { ErrorModule } from '@fry/lib/error';

const components = [
    MainComponent,
    SimpleComponent
];

@NgModule({
  imports: [
      RouterModule,
      CommonModule,
      FryCommonModule,
      MaterialModule,
      ErrorModule,
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class LayoutModule {}
