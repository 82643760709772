import { Component, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-password',
  exportAs: 'unboxedPasswordInput',
  styleUrls: ['../vars.css', '../common.css', './input.css'],
  templateUrl: './password.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedPasswordInput extends UnboxedBaseFormComponent {}
