import { Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { FBFieldEvaluator, FormField, ObservableFBField } from '@fry/lib/forms/forms.interface';
import { EasElement, EasFieldType } from '@fry/components/common/easform/easform.interfaces';

const PROVIDE_FIELDS = [
  {id: 'string', name: $localize `Text (short)`, widget: 'string'},
  {id: 'text', name: $localize `Text (long)`, widget: 'text'},
  {id: 'number', name: $localize `Number`, widget: 'number'},
  {id: 'select', name: $localize `Select`, widget: 'select'},
  {id: 'date', name: $localize `Date`, widget: 'date'},
  {id: 'datetime', name: $localize `Datetime`, widget: 'datetime'},
  {id: 'likert', name: $localize `Likert`, widget: 'likert'},
  {id: 'boolean', name: $localize `Boolean`, widget: 'boolean'},
  {id: 'freetext', name: $localize `Free text`, widget: 'freetext'},
  {id: 'hidden', name: $localize `Hidden`, widget: 'hidden'},
  {id: 'file', name: $localize `File(s)`, widget: 'file'},
];

@Injectable()
export class GeneralFieldEvaluator implements FBFieldEvaluator {

  public readonly fieldType = 'general';

  public constructor() { }

  public get provides() {
    return PROVIDE_FIELDS.map(itm => ({id: itm.id, name: itm.name}));
  }

  public getForm(type: any): Observable<EasElement[]> {
    const fields: EasElement[] = [
      {
        id: 'label',
        type: EasFieldType.Field,
        title: $localize `Label`,
        initial: '',
        widget: {
            type: 'string',
        },
        validators: [Validators.required],
      },
      {
        id: 'required',
        title: $localize `Required`,
        type: EasFieldType.Field,
        initial: false,
        widget: {
          type: 'boolean'
        }
      },
    ];

    if (type === 'select') {
      fields.push({
        id: 'categories',
        title: $localize `Categories`,
        type: EasFieldType.Field,
        initial: [],
        widget: {
          type: 'treeEditorFormControl'
        },
        options: {
          options: { allowsHierarchy: true }
        },
      });
    }

    return of(fields);
  }

  public buildField(field: any): Observable<EasElement> {
    const providedField = PROVIDE_FIELDS.find(itm => itm.id === field.fieldType);
    if (!providedField) {
      throw new Error(`Unknown General Field type '${field.fieldType}'!`);
    }

    const options = field.options ?? {};
    options.key = '_id';
    // const meta = options.meta || {}; // Commented out as it's unused. Is it meant to be used below in place of `meta: {}`?

    const fld: EasElement = {
      id: field.id,
      type: field.type,
      title: options.label,
      widget: {
        type: providedField.widget
      },
      options,
      meta: {},
      validators: []
    };

    if (providedField.widget === 'boolean') {
      fld.getTitle = (value) => {
        return value ? 'Yes' : 'No';
      };
    }
    if (providedField.widget === 'select') {
      fld.getTitle = (value) => {
        const opt = fld.options?.categories?.find(itm => itm._id === value);
        return opt ? opt.name : 'unknown option';
      };
      if (fld.options?.categories && fld.options.categories.length < 1000) {
        fld.widget.type = 'simpleSelect';
      }
    }

    if (providedField.widget === 'date') {
      fld.serializeValue = (value) => {
        if (!value) {
          return '';
        }
        if (!value.format) {
          return value;
        }

        return value.format("YYYY-MM-DD");
      }
    }

    if (options.required) {
      fld.validators = [];
      if (providedField.widget === 'boolean') {
        fld.validators.push(Validators.requiredTrue);
      } else {
        fld.validators.push(Validators.required);
      }
    }

    return of(fld);
  }

  public evalField(field: ObservableFBField): Observable<FormField> {
    let fieldType = field.type;
    if (field.type === 'select' && field.options && field.options.categories.length < 1000) {
      fieldType = 'simpleSelect';
    }
    return of({
      id: field.id,
      title: field.title,
      initial: field.initial,
      validators: [],
      noFormControl: false,
      widget: { type: fieldType },
      options: {
        ...(field.options || {})  ,
        ...(field.fieldOptions || {}),
      }
    });
  }

}
