import { Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { ButtonsService, EASAction } from './buttons.service';


@Component({
  selector: 'eas-buttons-compact',
  templateUrl: './buttons.compact.component.html',
})
export class ButtonsCompactComponent implements OnChanges {
  @Input() buttons: EASAction[];

  filteredButtons$: Observable<EASAction[]>;

  constructor(
    private btnService: ButtonsService,
  ) { }

  ngOnChanges() {
    this.filteredButtons$ = this.btnService.processButtons(this.buttons);
  }
}
