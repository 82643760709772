import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { sortBy } from 'lodash';

import { APIService } from '@fry/lib/api';
import { ApiStore } from '@fry/lib/store';

import { TransactionInternalReference, TransactionVendorReference } from './transaction.interfaces';
import { Transaction, TransactionState } from './transaction';
import { PaymentMethodsStore } from './payment_methods.store';

/**
 * Transaction progress
 * 
 * Object describes the transaction progress of the server-server
 * communication with the Vendor and state mapping to our
 * state machine.
 */
export interface TransactionProgress {
    /**
     * Our ID of the transaction
     */
    transaction: string;

    /**
     * State of transaction in our state-machine
     */
    state: TransactionState;

    /**
     * Arbitrary payload from the Vendor procesing the payment
     */ 
    vendor: Record<string, any>;
}

export interface ReceiptPreview {
    html: string;
    subject: string;
    text: string;
}


@Injectable({
  providedIn: 'root'
})
export class TransactionsStore extends ApiStore<Transaction> {
    AssociatedModel = Transaction;

    docType = 'transaction';
    endpoint = 'transactions';

    constructor(
        api: APIService,
        private paymentsStore: PaymentMethodsStore
    ) {
        super(api);
    }

    getWithDependencies(id: string): Observable<Transaction> {
        return this.get(id).pipe(
            switchMap(transaction => {
                return transaction.resolveDependencies(this.paymentsStore,
                                                       this);
            })
        );
    }

    // TODO: This should use the ApiStore.search() method most likely.
    //       - improve search by introducing APISearchResult<T>
    searchBySubject(subject: TransactionInternalReference): Observable<Transaction[]> {
        const data = { subject: subject.id };
        return this.api.post(`${this.endpoint}/filter`, data).pipe(
            map(result => sortBy(result, item => item.createdDate).reverse()),
            map(dbdocs => dbdocs.map(dbdoc => this.createObject(dbdoc))),
            catchError((error) => {
                console.log('Received an error:', error.message);
                return throwError(error);
            })
        );
    }

    action(transaction: Transaction): Observable<Transaction> {
        const payload = { method: transaction.method.id };
        return this.api.post(`${this.endpoint}/${transaction.id}/action`, payload).pipe(
            map(dbdoc => this.createObject(dbdoc.doc)),
            catchError((error) => {
                console.log('Received an error:', error.message);
                return throwError(error);
            })
        );
    }

    confirm(transaction: Transaction): Observable<TransactionProgress> {
        const payload = { method: transaction.method.id };
        return this.api.post(`${this.endpoint}/${transaction.id}/confirm`, payload).pipe(
            // map(dbdoc => this.createObject(dbdoc.doc)),
            catchError((error) => {
                console.log('Received an error:', error.message);
                return throwError(error);
            })
        );
    }

    capture(transaction: Transaction, reference?: TransactionVendorReference): Observable<TransactionProgress> {
        const payload = {
            method: transaction.method.id,
            amount: transaction.amount,
            'reference': reference
        };
        return this.api.post(`${this.endpoint}/${transaction.id}/capture`, payload).pipe(
            map(result => result),
            catchError((error) => {
                console.log('Received an error:', error.message);
                return throwError(error);
            })
        );
    }

    receipt(transaction: Transaction): Observable<ReceiptPreview> {
        return this.api.get(`${this.endpoint}/${transaction.id}/receipt`).pipe(
        );
    }
}
