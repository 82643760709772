// tslint:disable: no-inferrable-types

import { Component, Input } from '@angular/core';
import { EasFormArray } from './easform';

@Component({
  selector: 'eas-easform-array',
  templateUrl: './easform-array.component.html'
})
export class EasFormArrayComponent {
  @Input() easArray: EasFormArray;
  @Input() templates = {};

  /**
   * Weather the read-only fields should be visible regardless their values.
   *
   * By default the field is ommited from the form if it does not have value
   * which it does not in the Workflow Editor - From preview.
   */
  @Input() forceDisplayReadOnly: boolean = false;

  push() {
    this.easArray.push();
  }

  remove(i: number) {
    this.easArray.remove(i);
  }
}
