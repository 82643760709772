import { Observable } from 'rxjs';

import { BaseModel } from '@fry/lib/store/base-model';
import { DBDoc, EasTable, ModelSearch, SearchStoreService, SearchType } from '@fry/lib/store/store.interface';


export abstract class SearchStore<T extends BaseModel> implements SearchStoreService<T> {
  protected abstract AssociatedModel: new (docs: DBDoc, nonDocProperties?: any) => T;
  abstract searchType: SearchType;

  abstract search(params: any): Observable<ModelSearch<T>>;
  abstract table(params: any): Observable<EasTable>;
  abstract export(params: any): Observable<any>;
  abstract startExport(listId: string, params: any): Observable<any>;
  abstract reset(): void;

  createObject(doc: DBDoc, nonDocProperties?: any): T {
    return new this.AssociatedModel(doc, nonDocProperties);
  }
}
