import { InjectionToken } from '@angular/core';
import { SentryOptions } from '@fry/lib/error';

export const CONFIG = new InjectionToken('CONFIG');
export const SERVER_CONFIG = new InjectionToken('SERVER_CONFIG');

export interface FrontendConfig {
  production: boolean;
  defaultServerConfig: ServerConfig;
}

export function isServerConfig(data: any): data is ServerConfig {
  if (!data) { return false; }
  return data.urls !== undefined && data.oidc !== undefined;
}

export interface ServerConfig {
  liveChat?: {
    licenses: { [key: string]: number }
  };
  oidc: OIDCConfig;
  sentry?: SentryOptions;
  urls: {
    api: string,
    db: string,
    auth: string,
    epf: string,
    org: string,
  };
  languages?: { [key: string]: string };
}

export interface OIDCConfig {
  authority: string,
  client_id: string,
  response_type: string,
  scope: string,
  redirect_uri: string,
}

/**
 * Application configuration
 *
 * The config has two parts:
 *  * `frontend` is a compile time config based on environment
 *  * `server` is a cluster related config loaded from the server
 *
 * As part of `frontend` there is a default server settings in case the server
 * one is not available. This can be configured in the {@link environment} class.
 */
export interface FryConfig {
  server: ServerConfig;
  frontend: FrontendConfig;
}

export const TEST_CONFIG: ServerConfig = {
  oidc: {
    authority: "",
    client_id: "",
    response_type: "",
    scope: "",
    redirect_uri: "",
  },
  urls: {
    api: "",
    db: "",
    auth: "",
    epf: "",
    org: "",
  }
};