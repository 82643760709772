import { Inject, LOCALE_ID } from '@angular/core';

import { UserFieldsStore } from './user-fields.store';
import { FBFieldEvaluator, FBFormOptions, FormField, ObservableFBField } from '@fry/lib/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { categoriesToOptions, flattenCategories } from '../utils';
import { defaultWidget } from '../forms/fields';
import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { SecurityService } from '../security';
import { formatDate } from '@angular/common';
import { EasFieldType } from '@fry/components/common/easform/easform.interfaces';
import { EasBuilderField } from '@fry/components/common/form-builder/builder.interfaces';
import { GMCValidatorService } from '@fry/components/common/gmc-form-control';

export class UserFieldFormEvaluator implements FBFieldEvaluator {

  public readonly fieldType = UserFieldsStore.DOC_TYPE;

  constructor(
    private store: UserFieldsStore,
    private security: SecurityService,
    private gmcValidator: GMCValidatorService,
    @Inject(LOCALE_ID) private locale: string
  ) {
  }

  provides = [{
    id: UserFieldsStore.DOC_TYPE,
    name: $localize `Profile field`
  }];
  getForm() {
    return this.store.all(true).pipe(
      map(userFields => {
        const fieldTypes = userFields.map(uf => ({ id: uf.id, name: uf.title }));
        return [{
          id: 'related',
          type: EasFieldType.Field,
          title: $localize `Select field`,
          options: {
            categories: fieldTypes,
          },
          widget: {
            type: 'select',
          }
        }];
      })
    );
  }

  buildField(field: EasBuilderField, options?: any) {
    const user = options.owner;

    // Builds profile field
    const profileField = UserFieldsStore.findProfileFieldByID(field.options.related);
    if (profileField !== undefined) {
      const initial = (field.options.related === 'name')
                      ? user !== undefined ? user.fullname : ''
                      : user !== undefined ? user.doc[field.options.related] : '';
      const uf = {
        id: field.id,
        type: EasFieldType.Field,
        title: field.options?.label ? field.options.label : (profileField?.title ?? ''),
        initial,
        widget: { type: defaultWidget['string'] },
        getTitle: (value: any, fld) => {
          if (value === undefined) { return fld.initial; }
          return `${value}`;
        },
        options: {
          ...field.options
        }
      };
      return of(uf);
    }

    // Builds General & User fields (custom)
    return this.store.get(field.options.related).pipe(
      // switchMap(userField => {
      //   if (userField.doc.isLocked) {
      //     return this.security.withPermission('users.edit.locked').pipe(
      //       map(() => userField)
      //     );
      //   }
      //   return of(userField);
      // }),
      map(userField => {
        const validators: ValidatorFn[] = [];
        const asyncValidators: AsyncValidatorFn[] = [];
        if (field.options.required) {
          validators.push(Validators.required);
        }
        if (field.validators) {
          field.validators.forEach(validator => {
            if (validator === GMCValidatorService.FormatValidatorName) {
              validators.push(this.gmcValidator.formatValidator());
            }
          });
        }
        if (field.asyncValidators) {
          field.asyncValidators.forEach(validator => {
            if (validator === GMCValidatorService.CorrectnessValidatorName) {
              asyncValidators.push(this.gmcValidator.correctnessValidator(user));
            }
          });
        }
        const flatCategories = flattenCategories(userField.doc.categories || []);
        const uf = {
          id: field.id,
          type: EasFieldType.Field,
          title: field.options?.label ? field.options.label : userField.doc.name,
          initial: user !== undefined ? user.userFields[field.options.related] : '',
          validators,
          asyncValidators,
          options: {
            ...field.options,
            categories: categoriesToOptions(userField.doc.categories || []),
          },
          widget: { type: defaultWidget[userField.doc.fieldType] },
          getTitle: (value, fld) => {
            value = value === undefined ? fld.initial : value;
            if (value === undefined) {
              return fld.initial;
            }

            if (userField.fieldType === 'select') {
              return flatCategories[value];
            }

            if (userField.fieldType === 'multiSelect' && Array.isArray(value)) {
              return value.map(itm => flatCategories[itm]).join(', ');
            }

            if (userField.fieldType === 'datetime') {
              if (!value) {
                return '';
              }
              return formatDate(value, 'medium', this.locale);
            }

            if (userField.fieldType === 'date') {
              if (!value) {
                return '';
              }
              return formatDate(value, 'mediumDate', this.locale);
            }

            return `${value}`;
          }
        };
        return uf;
      }),
      catchError((err) => {
        console.log(err);
        return of(undefined);
      }),
    );
  }

  evalField(field: ObservableFBField, options: FBFormOptions): Observable<FormField | undefined> {
    return this.store.get(field.fieldOptions.related).pipe(
      switchMap(userField => {
        if (!options.readOnly && userField.doc.isLocked) {
          return this.security.withPermission('users.edit.locked').pipe(
            map(() => userField)
          );
        }
        return of(userField);
      }),
      map(userField => {
        const validators = [];
        if (userField.doc.isRequired) {
          validators.push(Validators.required);
        }

        const flatCategories = flattenCategories(userField.doc.categories || []);
        const uf: FormField = {
          id: field.id,
          title: userField.doc.name,
          initial: '',
          validators,
          readOnly: options.readOnly,
          options: {
            categories: categoriesToOptions(userField.doc.categories || []),
          },
          widget: { type: defaultWidget[userField.doc.fieldType] },
          getTitle: (value) => {
            if (value === undefined) {
              return 'n/a';
            }

            if (userField.fieldType === 'select') {
              return flatCategories[value];
            }

            if (userField.fieldType === 'multiSelect' && Array.isArray(value)) {
              return value.map(itm => flatCategories[itm]).join(', ');
            }

            if (userField.fieldType === 'datetime') {
              return formatDate(value, 'medium', this.locale);
            }

            if (userField.fieldType === 'date') {
              return formatDate(value, 'mediumDate', this.locale);
            }

            return `${value}`;
          }
        };
        return uf;
      }),
      catchError(() => {
        return of(undefined);
      }),
    );
  }

}
