import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BookingItemsStore } from '../../booking-items';
import { EasElement, EasFieldGroup, EasFieldType } from '@fry/components/common/easform';
import { TransitionAction } from '@fry/components/common/form-builder/builder.interfaces';

export function getRefundOptionsForm(actionData, selected?) {
  const options = actionData.refund_options || [];

  const initial = options.find(itm => itm.default);

  let initialTitle = initial ? initial.title : '';
  let initialAmount = initial ? initial.default : null;

  if (selected && selected.refund_option) {
    initialTitle = selected.refund_option;
  }

  if (selected && selected.refund_amount !== undefined) {
    initialAmount = selected.refund_amount;
  }

  if (options.length === 0) {
    return [];
  }

  // We can skip the form if there is less than one option and
  // a default one. If not a default one we still let them pick the one
  // It is the admin responsibility to make it default in order to hide
  if (options.length <= 1 && initial) {
    return [];
  }

  const fields: EasElement[] = [
    {
      id: 'refund_option',
      type: EasFieldType.Field,
      title: $localize `Specify refund option`,
      initial: initialTitle,
      widget: {
        type: 'select'
      },
      options: {
        categories: options.map(opt => ({id: opt.title, name: opt.title}))
      }
    },
    {
      id: 'refund_amount',
      type: EasFieldType.Field,
      title: $localize `Specify amount`,
      initial: initialAmount,
      widget: {
        type: 'number'
      },
      meta: {
        hidden: (_value, parent) => {
          const opt = options.find(itm => !parent.refund_option || itm.title === parent.refund_option);
          return opt ? opt.type !== 'custom' : false;
        }
      }
    }
  ];
  return fields;
}


@Injectable()
export class ChangeOptionAction implements TransitionAction {
  actionId = 'change_option';
  scope = 'booking';

  constructor(private bookingItems: BookingItemsStore) {}

  getForm(doc: any, actionData: any) {
    actionData = actionData || {};
    const fltr = {
      bookingItem: doc.bookingItem,
      allowAnyOption: actionData.allowAnyOption,
      ignoreWindow: actionData.ignoreWindow,
      strict: true,
      skipInitial: true,
    };

    const initial = doc.metadata && doc.metadata.change_option && doc.metadata.change_option.option || '';

    return this.bookingItems.getAvailableOptions(doc.user, fltr).pipe(
      map(options => {
        const changeOptionForm: EasFieldGroup = {
          id: 'change_option',
          type: EasFieldType.Group,
          fields: [
            {
              id: 'option',
              type: EasFieldType.Field,
              initial,
              widget: {
                type: 'select'
              },
              options: {
                categories: options.hits
                  .filter(itm => itm.id !== doc.bookingOption)
                  .map(option => ({id: option.id, name: option.title}))
              }
            },
          ]
        };
        if (actionData.handle_payments) {
          changeOptionForm.fields = [...changeOptionForm.fields, ...(getRefundOptionsForm(actionData))];
        }
        return changeOptionForm;
      })
    );
  }


}
