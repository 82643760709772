const NAME = 'eas.dialog';

export class EASDialogError extends Error {

  public constructor(message) {
    super(message);
    this.name = NAME;
  }
}

export function isDialogError(argument: any): argument is EASDialogError {
  return argument instanceof Error && argument.name === NAME;
}

const ERROR_MESSAGE_USER_CANCELED = $localize `User canceled operation`;
export function isUserCanceledError(argument: any): boolean {
  if (!isDialogError(argument)) { return false; }
  return argument.message === ERROR_MESSAGE_USER_CANCELED;
}
export function UserCanceledError(): EASDialogError {
  return new EASDialogError(ERROR_MESSAGE_USER_CANCELED);
}
