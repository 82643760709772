import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RelationsStore } from '@fry/lib/relations';

@Component({
  selector: 'eas-relation-category-title',
  template: '{{ title$ | async }}',
})
export class RelationCategoryTitleComponent implements OnInit {
  @Input() id: string;
  @Input() category: string;

  title$: Observable<string>;

  constructor(private relations: RelationsStore) {}

  ngOnInit() {
    console.log('Chagnes');
    this.getTitles();
  }

  private getTitles() {
    this.title$ = this.relations.getCategory(this.id, this.category);
  }
}
