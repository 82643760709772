// tslint:disable: no-namespace

import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, toArray } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { BaseModel } from '@fry/lib/store/base-model';
import { DBDoc } from '@fry/lib/store/store.interface';

import { CurrencyService, Currency } from '@fry/payments/lib/currency';
import { PaymentMethod } from './payment_method';
import { PaymentMethodsStore } from './payment_methods.store';
import {
    TransactionInternalReference,
    TransactionOutcome,
    TransactionVendorReference
} from './transaction.interfaces';
import { TransactionsStore } from './transactions.store';

/**
 * State of the Transaction
 *
 * Workflow is Pending -> Actioned -> Completed [ retry goes to -> Actioned],
 * this is enforced by the ecapsulation & methods action() & complete().
 */
export enum TransactionState {
    // Default state when created
    Pending = 'pending',

    // There has been user action to either pay or refund (depending if it's
    // Payment or Refund).
    //
    // In case of Card payment this would be state after
    // attempt to pay via Gateway other methods may be that User will indicate
    // they transfered money.
    Actioned = 'actioned',

    // Transaction failure for any reason. Failed transaction can be retried, in
    // which case it goes Failed -> Pending and the outcome & vendor reference
    // should be reseted.
    Failed = 'failed',

    // Completed transaction (either successfully or not, investigate Outcome)
    Completed = 'completed',
}

export namespace TransactionState {
    export function parse(state: string): TransactionState {
        switch (state.toLowerCase()) {
            case TransactionState.Pending:
                return TransactionState.Pending;
            case TransactionState.Actioned:
                return TransactionState.Actioned;
            case TransactionState.Failed:
                return TransactionState.Failed;
            case TransactionState.Completed:
                return TransactionState.Completed;
            default:
                throw new Error($localize `Unknown transaction state: '${state}'`);
        }
    }
    export function description(state: TransactionState,
                                isBeingRefunded: boolean = false,
                                isRefunded: boolean = false): string {
        switch (state) {
            case TransactionState.Pending:
                if (isBeingRefunded) {
                    return $localize `cancelled`;
                }
                return $localize `required`;
            case TransactionState.Actioned:
                if (isBeingRefunded) {
                    return $localize `actioned, refund in progress`;
                }
                return $localize `actioned`;
            case TransactionState.Failed:
                return $localize `failed`;
            case TransactionState.Completed:
                if (isBeingRefunded) {
                    return $localize `complete, refund in progress`;
                }
                if (isRefunded) {
                    return $localize `complete, refund processed`;
                }
                return $localize `complete`;
            default:
                throw new Error($localize `Unknown transaction state: '${state}'`);
        }
    }
}

export interface TransactionRefundOption {
    adjustment?: number;
    amount?: number;
    default: boolean;
    title: string;
}

export type TransactionRole = 'payment' | 'refund';

export class Transaction extends BaseModel {
             amount: number;
    readonly currency: Currency;
    readonly description: string;

    // EB reference, eg. what this request is paying for or refunding
    readonly referenceInternal: TransactionInternalReference;

    public refundOption: TransactionRefundOption;
    public refundOptions: TransactionRefundOption[];

    // List of refunds, in case of Payment
    // List in case we support partial refunds
    refunds: Transaction[] = [];

    // Human readable reference for the transaction, this will be used for
    // Cheque, Transfer, Card payments to identify it for User
    // This should be generated using PaymentReference.generate()
    readonly reference: string;

    readonly relatedTransaction: string;

    readonly role: TransactionRole;

    readonly createdAt: number;
    readonly dueAt: number;

    public method: PaymentMethod;

    /**
     * Payment methods available for this transaction
     *
     * Methods are expected to be sorted by PaymentMethod.order when setting
     * this property.
     */
    public availableMethods: PaymentMethod[] = [];

    private _outcome: TransactionOutcome;
    get outcome(): TransactionOutcome {
        return this._outcome;
    }

    get vendor(): string {
        return this.doc.init?.vendor;
    }

    // Payment Gateway or other 3rd party reference
    private _referenceVendor: TransactionVendorReference;
    get referenceVendor(): TransactionVendorReference {
        return this._referenceVendor;
    }

    private _state: TransactionState;
    get state(): TransactionState {
        return this._state;
    }

    get stateDescription(): string {
        return TransactionState.description(this.state,
                                            this.isBeingRefunded,
                                            this.isRefunded);
    }

    private _settledAt: number;
    get settledAt(): number {
        return this._settledAt;
    }

    private _updatedAt: number;
    get updatedAt(): number {
        return this._updatedAt;
    }

    public get isTransfer(): boolean {
        return this.method ? this.method.isTransfer() : false;
    }

    public get isCheque(): boolean {
        return this.method ? this.method.isCheque() : false;
    }

    public get isCash(): boolean {
        return this.method ? this.method.isCash() : false;
    }

    public get isCard(): boolean {
        return this.method ? this.method.isCard() : false;
    }

    public get isSettled(): boolean {
        return this.state === TransactionState.Completed;
    }

    public get hasError(): boolean {
        return this.state === TransactionState.Failed;
    }

    public get isBeingRefunded(): boolean {
        if (this.refunds.length === 0) { return false; }
        return this.findCompletedRefunds().length !== this.refunds.length;
    }

    public get isRefunded(): boolean {
        if (this.refunds.length === 0) { return false; }
        return this.findCompletedRefunds().length === this.refunds.length;
    }

    /**
     * Is transaction cancelled?
     *
     * Transaction is canceled when it's a Payment in Pending state having
     * pending or finished refunds.
     *
     * This must use the same logic as TransactionState.description().
     */
    public get isCanceled(): boolean {
        if (this.role !== 'payment') { return false; }

        return this.state === TransactionState.Pending &&
               (this.isBeingRefunded || this.isRefunded);
//               (this.orig.refunds || []).length > 0;
    }

    // Generic constructor used for Serialization
    constructor(doc: DBDoc) {
        super(doc);
        this.orig = cloneDeep(doc);

        // This could be in init() but then it can't be readonly.
        // This is de-serialization it should be better
        this._outcome = this.orig.outcome;
        this._referenceVendor = this.orig.vendorReference;
        this._settledAt = this.orig.settletAt;
        this._state = TransactionState.parse(this.orig.state);
        this._updatedAt = this.orig.updatedAt;

        const amount = this.orig.amount;
        if (typeof amount !== 'number') {
            throw new Error($localize `Deserialization error: Invalid amount ${this.orig.amount}`);
        }
        this.amount = this.orig.amount;

        this.createdAt = this.orig.createdDate;

        const currency = CurrencyService.currencyByCode(this.orig.currency);
        if (!currency) {
            throw new Error($localize `Deserialization error: Unknown currency ${this.orig.currency}`);
        }
        this.currency = currency;

        this.description = this.orig.description || 'Lorem ipsum dolor transaction...';

        if (typeof this.orig.method === 'string') {
        } else if (this.orig.method instanceof Object) {
            const method = new PaymentMethod(this.orig.method);
            if (!method) {
                throw new Error($localize `Deserialization error: Unknown payment method ${this.orig.method}`);
            }
            this.method = method;
        }

        this.reference = this.orig.reference || '0000-0000';
        this.referenceInternal = this.orig.subject;
        this.relatedTransaction = this.orig.relatedTransaction;
        this.role = this.orig.role;

        this.refundOption = this.orig.refundOption;
        this.refundOptions = this.orig.refundOptions || [];
    }

    public resolveDependencies(paymentMethodsStore: PaymentMethodsStore,
                               transactionsStore: TransactionsStore): Observable<Transaction> {

        const method$ = this.orig.method
                        ? paymentMethodsStore.get(this.orig.method).pipe(
                            catchError(err => {
                                console.log(err);
                                return of(null);
                            }))
                        : of(null);

        const availableMethods$ = from(this.orig.availableMethods as string[] || []).pipe(
            concatMap((id: string) => paymentMethodsStore.get(id)),
            toArray(),
            map(methods => methods.sort((a, b) => a.order - b.order))
        );
        const refunds$ = from(this.orig.refunds as string[] || []).pipe(
            concatMap((id: string) => transactionsStore.get(id)),
            toArray()
        );

        return forkJoin([method$, availableMethods$, refunds$]).pipe(
            map(([method, methods, refunds]) => {
                this.method = method;
                this.availableMethods = methods;
                this.refunds = refunds;
                return this;
            })
        );
    }

    protected patch() {
        super.patch();

        this._doc.amount = this.amount;
        this._doc.createdAt = this.createdAt;
        this._doc.currency = this.currency.code;
        this._doc.description = this.description;
        // Not sure about this, there should always be method
        this._doc.method = this.method ? this.method.id : undefined;
        this._doc.outcome = this.outcome;
        this._doc.reference = this.reference;
        this._doc.referenceVendor = this.referenceVendor;
        this._doc.refundOption = this.refundOption;
        this._doc.relatedTransaction = this.relatedTransaction;
        this._doc.role = this.role;
        this._doc.settledAt = this.settledAt;
        this._doc.state = this.state;
        this._doc.subject = this.referenceInternal;
        this._doc.updatedAt = this.updatedAt;
    }

    public findCompletedRefunds(): Transaction[] {
        if (this.refunds.length === 0) { return []; }
        return this.refunds.filter(refund => {
            return refund.state === TransactionState.Completed;
        });
    }
}
