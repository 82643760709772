import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'eligibilities.view', name: $localize `View eligibility rules`, description: $localize `Allow user to view list of eligibility rules` },
  { id: 'eligibilities.create', name: $localize `Create eligibility rules`, description: $localize `Allow user to create new eligibility rules` },
  { id: 'eligibilities.edit', name: $localize `Edit eligibility rules`, description: $localize `Allow user to edit eligibility rules`},
];

export const eligibilityPermissions: PermissionGroup = {
  id: 'eligibilities',
  name: $localize `Eligibilities`,
  description: '',
  permissions
};
