import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { UBPager } from '../unboxed.module.typings';

const settings = {
  maxButtons: 5
};

@Component({
  selector: 'ub-pagination',
  exportAs: 'unboxedPagination',
  styleUrls: ['./pagination.css'],
  templateUrl: 'pagination.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedPagination implements OnChanges {
  @Input()
  public pagesCount: number;

  @Input()
  public currentPage: number;

  @Output()
  public pageSelected = new EventEmitter<number>();

  private settings = settings;
  public buttons: UBPager[];
  public start;
  public end;

  public ngOnChanges(_changes): void {
    this.init();
  }

  private init(): void {
    if (this.settings.maxButtons % 2 !== 1) {
      throw new Error('The maxButtons number has to be odd');
    }

    const margin = (this.settings.maxButtons - 1) / 2;
    const currentPage = this.currentPage - 1;
    let start;
    let end;

    // First handle if no dots
    if (this.pagesCount <= this.settings.maxButtons) {
      start = 0;
      end = this.pagesCount;
    } else {
      start = Math.max(0, currentPage - margin);
      end = Math.min(start + this.settings.maxButtons, this.pagesCount);
      if (start + this.settings.maxButtons > this.pagesCount) {
        start = this.pagesCount - this.settings.maxButtons;
        end = this.pagesCount;
      }
    }
    const iter = Array.from(Array(end - start).keys());
    this.buttons = iter.map(idx => ({
      page: idx + 1 + start,
      title: (idx + 1 + start).toString()
    }));
    this.start = start + 1;
    this.end = end;
  }

  public select(page: number): void {
    this.pageSelected.emit(page);
  }
}
