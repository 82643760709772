import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { LoaderComponent } from './loader.component';

export type LoaderStateType = 'ready' | 'loading' | 'error' | 'empty' | 'waiting';
export interface LoaderState {
  state: LoaderStateType;
  error?: Error;
}

export const LoaderReadyState: LoaderState = {
  state: 'ready',
};

export const LoaderLoadingState: LoaderState = {
  state: 'loading',
};

export const LoaderWaitingState: LoaderState = {
  state: 'waiting',
};


@Directive({ selector: '[easLoader]'})
export class LoaderDirective {
  loadingFactory: ComponentFactory<LoaderComponent>;
  loadingComponent: ComponentRef<LoaderComponent>;

  @Input()
  set easLoader(state: LoaderState) {
    this.vcRef.clear();

    if (state.state === 'ready') {
      this.vcRef.createEmbeddedView(this.templateRef);
    } else {
      this.loadingComponent = this.vcRef.createComponent(this.loadingFactory);
      this.loadingComponent.instance.state = state;
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    // Create resolver for loading component
    this.loadingFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
  }
}
