import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MenuComponent } from '@fry/components/common/menu';
import { FormDefaultComponent, FormDialogComponent, FormFieldsComponent } from '@fry/components/common/form';

import { ListFilterComponent } from './list/filter/list.filter.component';
import { RouterModule } from '@angular/router';
import { ListPaginatorComponent } from './list/paginator/list.paginator.component';
import { MaterialModule } from '@fry/components/material';
import { DialogComponent } from './dialog';
import { FORM_EVALUATORS, GeneralFieldEvaluator, HeadingFieldEvaluator } from '@fry/lib/forms';
import { UserFieldFormEvaluator, UserFieldsStore } from '@fry/lib/user-fields';
import { FormFieldComponent } from './form/form-field.component';
import { FormFieldReadOnlyComponent } from './form/form-field-readonly.component';
import { UnboxedModule } from '@fry/ui';
import { ListFeaturedFilterComponent } from './list/filter/list.featured-filter.component';
import { ListComponent } from './list';
import { ListSortComponent } from './list/sort/list.sort.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { AuthLoginComponent, AuthLoginOrgComponent } from './oidc';
import { AuthLoginPopupComponent } from './oidc/auth-login-popup.component';
import { FormFieldsSubComponent } from './form/form-fields-sub.component';
import { SecurityService } from '@fry/lib/security';
import { RolesTitleComponent } from '../roles/roles.title.component';
import { ButtonsMenuComponent } from './buttons/buttons.menu.component';
import { ButtonsListComponent } from './buttons/buttons.list.component';
import { SelectableListComponent } from './selectable-list/selectable-list.component';
import { AuditLogComponent } from './auditlog/auditlog.component';
import { RelationTitleComponent } from './titles/relation.title.component';
import { RelationCategoryTitleComponent } from './titles/relation.category.title.component';
import { UserTitleComponent } from './titles/user.title.component';
import { TreeEditorFormControlComponent } from './tree-form-control/tree-editor-form-control.component';
import { TreeSelectorFormControlComponent } from './tree-form-control/tree-selector-form-control.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { GMCFormControlComponent } from './gmc-form-control/gmc-form-control.component';
import { GMCValidatorService } from './gmc-form-control/gmc-validator.service';
import { BuilderFormComponent } from './form-builder/builder-form.component';
import { BuilderGroupComponent } from './form-builder/builder-group.component';
import { BuilderStatesComponent } from './form-builder/builder-states.component';
import { BuilderTransitionsComponent } from './form-builder/builder-transitions.component';
import { BuilderInitialStateComponent } from './form-builder/builder-initial-state.component';
import { TransitionGuards } from './form-builder/workflow.service';
import { FieldGuard } from './form-builder/guards/field.guard';
import { ButtonsCompactComponent } from './buttons/buttons.compact.component';
import { PickPipe } from './pipes/pick.pipe';
import { TableComponent } from './table/table.component';
import { EASDateFormatPipe, EASDateFromNowPipe } from './pipes/date.pipe';
import { AuthLoggedoutComponent } from './oidc/auth-loggedout.component';
import { EASDateRageFormatPipe } from './pipes/daterange.pipe';
import { EASPriceRageFormatPipe } from './pipes/pricerange.pipe';
import { AuditLogTableComponent } from './auditlog/auditlog.table.component';
import { LoaderDirective } from './loader/loader.directive';
import { LoaderComponent } from './loader/loader.component';
import { TextCardComponent } from './text-card/text.card';
import { DashboardPageModule } from './dashboard-page/dashboard-page.module';
import { BuilderTransitionConfirmationComponent } from './form-builder/transition-confirmation.component';
import { ExportListComponent } from './list/list/export-list/export-list.component';
import { ExportButtonComponent } from './list/list/export-list/export-button.component';
import { SimpleTableComponent } from './list/list/simpletable.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { BookingItemOptionApplyComponent } from '@fry/booking/components/booking-items/booking-item-option-view/booking-item-option-apply.component';
import { UserLanguageService } from "@fry/components/users/user-language/user-language.service";
import { VersionInformationComponent } from './version-information.component';


const UserFieldEval = { provide: FORM_EVALUATORS, multi: true,
  useClass: UserFieldFormEvaluator, deps: [UserFieldsStore, SecurityService, GMCValidatorService, LOCALE_ID] };
const HeadingEval = { provide: FORM_EVALUATORS, multi: true,
  useClass: HeadingFieldEvaluator };
const GeneralEval = { provide: FORM_EVALUATORS, multi: true,
  useClass: GeneralFieldEvaluator };

const providedGuards = [
    { provide: TransitionGuards, useClass: FieldGuard, multi: true},
];

const components = [
  MenuComponent,
  TableComponent,
  ButtonsMenuComponent,
  ButtonsListComponent,
  ButtonsCompactComponent,
  AuditLogComponent,
  AuditLogTableComponent,
  FormFieldsComponent,
  FormFieldComponent,
  FormFieldsSubComponent,
  FormFieldReadOnlyComponent,
  FormDefaultComponent,
  BuilderFormComponent,
  BuilderGroupComponent,
  BuilderStatesComponent,
  BuilderTransitionsComponent,
  BuilderInitialStateComponent,
  ListFilterComponent,
  ListSortComponent,
  ListFeaturedFilterComponent,
  ListPaginatorComponent,
  ListComponent,
  SimpleTableComponent,
  ExportListComponent,
  ExportButtonComponent,
  BookingItemOptionApplyComponent,
  DialogComponent,
  FormDialogComponent,
  AuthLoginComponent,
  AuthLoggedoutComponent,
  AuthLoginOrgComponent,
  AuthLoginPopupComponent,
  PageNotFoundComponent,
  RolesTitleComponent,
  RelationTitleComponent,
  RelationCategoryTitleComponent,
  UserTitleComponent,
  SelectableListComponent,
  UserSelectComponent,
  TreeEditorFormControlComponent,
  TreeSelectorFormControlComponent,
  GMCFormControlComponent,
  PickPipe,
  EASDateFormatPipe,
  EASDateFromNowPipe,
  EASDateRageFormatPipe,
  EASPriceRageFormatPipe,
  LoaderDirective,
  LoaderComponent,
  TextCardComponent,
  BuilderTransitionConfirmationComponent,
  VersionInformationComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    UnboxedModule,
    TreeModule,
    DashboardPageModule.WithComponents([TextCardComponent]),
  ],
  providers: [
    UserFieldEval,
    HeadingEval,
    GeneralEval,
    providedGuards,
    GMCValidatorService,
    UserLanguageService,
    DatePipe
  ],
  exports: components
})
export class FryCommonModule { }
