import { Component, Input } from '@angular/core';
import { BuilderUIFormService } from './builder-ui-form.service';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { EasFormDialogComponent } from '../easform/easform-dialog.component';

@Component({
  selector: 'eas-builder-initial-state',
  templateUrl: './builder-initial-state.component.html'
})
export class BuilderInitialStateComponent {

  @Input() initialState: any;
  @Input() states: any[];

  constructor(
    private builder: BuilderUIFormService,
    private dialog: MatDialog,
  ) { }

  editInitialState() {
    this.builder.getInitialStateForm(this.initialState, this.states).subscribe(
      group => {
        this.dialog.open(EasFormDialogComponent, {
          data: {
            title: $localize `Edit workflow initial state`,
            group,
            onSubmit: (value) => {
              Object.assign(this.initialState, value);
              return of(true);
            }
          }
        });
      }
    );
  }

  getStateTitle(stateId) {
    return (this.states.find(itm => itm.id === stateId) || {}).name;
  }
}
