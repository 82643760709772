// Helper to generate (prefix-)?dddd-dddd references
export class PaymentReference {
    static generate(prefix?: string): string {
        const min = 10000000;
        const max = 100000000;
        const number = String(Math.floor(Math.random() * (max - min + 1)) + min);
        const reference = `${number.substr(0, 4)}-${number.substr(4, 8)}`;
        return prefix ? `${prefix}-${reference}` : reference;
    }
}
