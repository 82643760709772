import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { BookingType } from './booking-type';
import { BookingTypesStore } from './booking-types.store';

@Injectable()
export class BookingTypeResolver implements Resolve<BookingType> {

    constructor(private store: BookingTypesStore) { }

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<BookingType> {
        const id = route.paramMap.get('id');
        return this.store.getForEdit(id);
    }
}
