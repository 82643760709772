
export class AuthError extends Error {
  constructor(message: string) {
    const trueProto = new.target.prototype;
    super(message);
    Object.setPrototypeOf(this, trueProto);

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, AuthError);
    }

    this.name = 'AuthError';
  }
}

export class AuthErrorNotAuthenticated extends AuthError {
    public readonly name = 'AuthErrorNotAuthenticated';
}