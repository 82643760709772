import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, switchMap } from 'rxjs';

import { ApiStore, SearchType, SuperType } from '@fry/lib/store';
import { APIService } from '@fry/lib/api';
import { guid } from '@fry/lib/utils';

import { BookingItem } from './booking-item';
import { BookingOptionDoc } from './booking-option';

@Injectable({
  providedIn: 'root'
})
export class BookingItemsStore extends ApiStore<BookingItem> {
  AssociatedModel = BookingItem;

  docType = 'bookingItem';
  endpoint = 'booking-items';
  superType = SuperType.Org;
  searchType = SearchType.REMOTE;

  constructor(api: APIService) {
    super(api);
  }

  duplicate(orig: BookingItem): BookingItem {
    const doc = cloneDeep(orig.doc);
    doc._id = guid();
    doc.name = `Copy of ${doc.name}`;
    delete doc._rev;
    const newBi = this.createObject(doc);
    newBi.initial();
    return newBi;
  }

  doTransition(bookingItem: BookingItem, transition: "publish"|"archive"|"retract"): Observable<BookingItem> {
    return this.getForEdit(bookingItem.id)
      .pipe(
        switchMap(obj => {
          obj.doTransition(transition)
          return this.save(obj)
        })
      )
  }

  describe(id: string) {
    return this.api.get(`${this.endpoint}/${id}/describe`);
  }

  getBookableOptions(id: string) {
    return this.api.get(`${this.endpoint}/${id}/options`);
  }

  getBookableOptionTable(id: string, fltr?: any) {
    return this.api.post(`${this.endpoint}/${id}/options/table`, fltr);
  }

  getBookableOption(id: string, bookingItem: string): Observable<BookingOptionDoc> {
    return this.api.get(`${this.endpoint}/${bookingItem}/options/${id}`);
  }

  getCapacityInfo(id: string, bookingItem: string): Observable<any> {
    return this.api.get(`${this.endpoint}/${bookingItem}/options/${id}/capacity_info`);
  }

  getAvailableOptions(user: string, fltr?: any): Observable<{hits: BookingOptionDoc[]}> {
    if (fltr === undefined) {
      return this.api.get(`${this.endpoint}/available/${user}`);
    } else {
      return this.api.post(`${this.endpoint}/available/${user}`, fltr);
    }
  }

  getAvailableOptionsHierarchy(user: string, fltr?: any): Observable<{hits: BookingOptionDoc[]}> {
    // TODO: the next function `getOptionsHierarchy` gets `omitParts` from the caller, we could do the same here
    if (fltr === undefined) {
      fltr = {};
    }
    fltr.omitParts = ['form', 'eligibility'];
    return this.api.post(`${this.endpoint}/available_hierarchy/${user}`, fltr);
  }

  getOptionsHierarchy(id: string, fltr?: any): Observable<BookingOptionDoc> {
    return this.api.post(`${this.endpoint}/${id}/options_hierarchy`, fltr);
  }

  describeEligibility(rules: any) {
    return this.api.post(`${this.endpoint}/eligibility/describe`, rules);
  }

  allStub() {
    return this.api.get(`${this.endpoint}/stub`);
  }
}
