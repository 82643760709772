import { Injectable } from '@angular/core';
import { forkJoin, Observable  } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ListSearch, Filter } from '@fry/lib/list';
import { BookingsSearch } from './bookings.search';
import { BookingTypesStore } from '../booking-types';
import { BookingItemsStore } from '../booking-items';
import { BookingsStore } from './bookings.store';
import { UserBookingsStore } from './user-bookings.store';


@Injectable()
export class UserBookingsSearch extends BookingsSearch implements ListSearch {

  constructor(
    bookingTypes: BookingTypesStore,
    bookingItems: BookingItemsStore,
    bookings: BookingsStore,
    private userBookings: UserBookingsStore,
  ) {
    super(bookingTypes, bookingItems, bookings);
  }


  public filters(): Observable<Filter[]> {
    return this.userBookings.aggregates().pipe(
      switchMap(aggs => {
        return forkJoin([
          this.fulltext(),
          this.types(),
          this.items(),
          this.bookingConfirmation(),
          this.stateName(aggs),
        ]);
      })
    );
  }



}
