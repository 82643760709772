import { Inject, Injectable } from '@angular/core';
import { FBFieldEvaluator, FORM_EVALUATORS } from '@fry/lib/forms';

/**
 * A simple object just to handle the loading of all registered field evaluators
 */
@Injectable({
  providedIn: 'root'
})
export class BuilderEvaluatorService {

  private _evaluators: {[key: string]: FBFieldEvaluator} = {};

  constructor(
    @Inject(FORM_EVALUATORS) private registeredEvaluators: FBFieldEvaluator[]|undefined
  ) {
    if (!this.registeredEvaluators) { return; }
    this.registeredEvaluators.forEach(evaluator => {
      evaluator.provides.forEach(itm => {
        this._evaluators[itm.id] = evaluator;
      });
    });
  }

  /** Return a mapping of field types to their respective evaluator class */
  public get evaluators() {
    return this._evaluators;
  }
}
