import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
// import { Moment } from 'moment';
import * as moment from 'moment-timezone';

@Pipe({ name: 'easDateRange', pure: true })
export class EASDateRageFormatPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  /**
   * @param value The date expression: a `Date` object,  a number
   * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
   * @param format The date/time components to include, using predefined options or a
   * custom format string.
   * @param timezone A timezone offset (such as `'+0430'`), or a standard
   * UTC/GMT or continental US timezone abbreviation. Default is
   * the local system timezone of the end-user's machine.
   * @param locale A locale code for the locale format rules to use.
   * When not supplied, uses the value of `LOCALE_ID`, which is `en-US` by default.
   * See [Setting your app locale](guide/i18n#setting-up-the-locale-of-your-app).
   * @returns A date string in the desired format.
   */
  transform(value: any, format = 'mediumDate', timezone?: string, locale?: string): string|null {
    const showTimezone = false;
    if (timezone !== undefined) {
      timezone = moment.tz(timezone).zoneAbbr();
    }

    if (value == null || value === '' || value !== value || !value.min) {
      return null;
    }

    const min = formatDate(value.min, format, locale || this.locale, timezone);
    const max = formatDate(value.max, format, locale || this.locale, timezone);
    if (min === max) {
      if (showTimezone && timezone !== undefined) {
        return `${min} ${timezone}`;
      } else {
        return min;
      }
    }
    if (showTimezone && timezone !== undefined) {
      return $localize `${min} to ${max} ${timezone}`;
    } else {
      return $localize `${min} to ${max}`;
    }
  }
}
