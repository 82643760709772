import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Notification, NotificationsService, NotificationType } from './notifications.service';
import { Observable } from 'rxjs';
import { UBAppearance } from '@fry/ui';

@Component({
  selector: 'eas-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsComponent implements OnInit {

    public notifications$: Observable<Notification[]>;

    constructor(private notificationsService: NotificationsService) { }

    ngOnInit() {
        this.notifications$ = this.notificationsService.notifications$;
    }

    //
    // Template helpers
    //

    public notificationType2Appearance(type: NotificationType): UBAppearance {
      const map: { [K in NotificationType]: UBAppearance } = {
        error: 'danger',
        warning: 'warn',
        success: 'success',
      };
      return map[type];
    }
}
