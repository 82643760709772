import { ModuleWithProviders, NgModule } from '@angular/core';

import { AllTransactionsPaidGuard } from './all-transactions-paid.guard';
import { ChangeOptionAction } from './change-option.action';
import { TransactionActions, TransitionGuards } from '@fry/components/common/form-builder/workflow.service';
import { BookingWindowOpenGuard } from './booking_window_open.guard';
import { BookingWindowClosedGuard } from './booking_window_closed.guard';
import { RefundPaymentsAction } from './refund-payments.action';
import { SelectRefundOptionAction } from './select-refund-option.action';
import { RefundOptionComponent } from './refund-option.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EasFormModule } from '@fry/components/common/easform/easform.module';
import { CommonModule } from '@angular/common';
import { FryCommonModule } from '@fry/components/common';
import { MaterialModule } from '@fry/components/material';
import { UnboxedModule } from '@fry/ui';


const providedActions = [
  { provide: TransactionActions, useClass: ChangeOptionAction, multi: true},
  { provide: TransactionActions, useClass: RefundPaymentsAction, multi: true},
  { provide: TransactionActions, useClass: SelectRefundOptionAction, multi: true},
];

const providedGuards = [
  { provide: TransitionGuards, useClass: AllTransactionsPaidGuard, multi: true},
  { provide: TransitionGuards, useClass: BookingWindowOpenGuard, multi: true},
  { provide: TransitionGuards, useClass: BookingWindowClosedGuard, multi: true},
];

@NgModule({
  imports: [
    ReactiveFormsModule,
    EasFormModule,
    CommonModule,
    MaterialModule,
    FryCommonModule,
    UnboxedModule,
  ],
  declarations: [
    RefundOptionComponent,
  ]
})
export class BookingWorkflowModule {
  static forRoot(): ModuleWithProviders<BookingWorkflowModule> {
    return {
      ngModule: BookingWorkflowModule,
      providers: [providedActions, providedGuards],
    };
  }
}
