import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep, uniq } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { User } from '@fry/lib/users';
import { LoggerService } from '@fry/lib/utils';
import { SecurityService } from '@fry/lib/security';
import {
  EasBuilderField,
  EasBuilderForm,
  EasBuilderSecureForm,
  EasBuilderSecurity,
  EasBuilderState,
  EasBuilderTransition,
  EasWorkflowContext,
  FieldPermission,
  TransitionAction,
  TransitionActionComponent
} from './builder.interfaces';
import { EasFieldGroup, EasFieldType } from '../easform/easform.interfaces';
import { EasFormService } from '../easform';
import { EasFormDialogComponent } from '../easform/easform-dialog.component';

import { TransitionGuard } from '@fry/components/common/form-builder/builder-transition-guard';
import { GuardOptions, TransitionGuardEvaluator } from './builder-transition-guard-evaluator';

import * as EASDialogError from '@fry/components/common/dialog/dialog.error';
import { ComponentType } from '@angular/cdk/portal';
import { BuilderTransitionConfirmationComponent } from './transition-confirmation.component';
import { SYSTEM_ROLE_ID } from '@fry/lib/roles/roles.system';

export const TransactionActions = new InjectionToken('TransactionActions');
export const TransitionGuards = new InjectionToken('TransactionGuards');

const PermOrder = {
  default: 0,
  hidden: 1,
  view: 2,
  edit: 3
};

/**
 * Workflow service creates a user and context aware forms
 */

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {

  transitionActions: { [key: string]: TransitionAction } = {};
  transitionGuards: { [key: string]: TransitionGuard } = {};

  constructor(
    @Optional() @Inject(TransactionActions) actions: TransitionAction[],
    @Optional() @Inject(TransitionGuards) guards: TransitionGuard[],
    private security: SecurityService,
    private easform: EasFormService,
    private dialog: MatDialog,
    private logger: LoggerService,
  ) {
    if (actions) {
      actions.forEach(action => {
        this.logger.info(`Registering transition action ${action.actionId}`);
        this.transitionActions[action.actionId] = action;
      });
    }

    if (guards) {
      guards.forEach(guard => {
        this.logger.info(`Registering transition action ${guard.code}`);
        this.transitionGuards[guard.code] = guard;
      });
    }
  }

  /**
   * Construct and return a security object based on current context and user
   *
   * @see getFormSecurity()
   */
  getFormSecurityNg(state: EasBuilderState, owner: string, user: User): Observable<EasBuilderSecurity> {
    return this.security.getRolesFor(owner).pipe(
      map(roles => {
        if (user.doc.user === owner) {
          roles.push(SYSTEM_ROLE_ID.TIMELINE_OWNER);
        }

        return this.getFormSecurity(state, roles);
      })
    );
  }

  /**
   * Construct a security object
   *
   * Each EasBuilderState contains 'who can do what' information. This function
   * applies the logic in builder form and apply it onto the fields themselves.
   *
   * Should we change the way we store the information (whether we have permissions,
   * roles, or something completely different) we can adapt this function but
   * always end up with the same security object.
   *
   * This separates the permission logic from the actual builder itself.
   *
   * @param state State to use for calculation of the Security
   * @param roles Roles for which should the security be evaulated
   * @throws
   */
  public getFormSecurity(state: EasBuilderState, roles: string[]): EasBuilderSecurity {
    const formSecurity: EasBuilderSecurity = {
      group: {},
      transitions: [],
      canDelete: false,
    };

    let foundRoles = false;
    // Not sure about this, just hacked it quickly
    state.roles.forEach(role => {
      if (roles.indexOf(role.role) === -1) {
        return;
      }

      foundRoles = true;
      Object.entries(role.group).forEach(([key, val]) => {
        if (formSecurity.group[key] === undefined) {
          formSecurity.group[key] = val;
        }

        if (PermOrder[val] > PermOrder[formSecurity.group[key] ?? FieldPermission.HIDDEN]) {
          formSecurity.group[key] = val;
        }
      });

      formSecurity.transitions = uniq((formSecurity.transitions ?? []).concat(role.transitions));

      if (role.canDelete) {
        formSecurity.canDelete = true;
      }
    });

    if (!foundRoles) {
      formSecurity.group.root = FieldPermission.HIDDEN;
      throw new Error('You have no permissions to view this booking in its current state');
    }

    return formSecurity;
  }

  /**
   * Apply any security object on a form itself.
   *
   * The security object holds all the information about visible and editable
   * fields and transitions. This should be applied before we evaluate the form fields
   * as some of them can be skipped or displayed just read-only
   *
   * This is to separate the security application from the actual builder.
   *
   * @param defaultVisibility If user does not have permission for a field what
   *                          will be default visibility? For legacy use it
   *                          is `FieldPermission.VIEW` for new Workflow editor
   *                          it should be set to `FieldPermission.HIDDEN`.
   */
  applySecurity(wfForm: EasBuilderForm,
                wfSecurity: EasBuilderSecurity,
                defaultVisibility: FieldPermission = FieldPermission.HIDDEN): Observable<EasBuilderSecureForm> {
    if (wfSecurity.group.root === FieldPermission.HIDDEN) {
      return of({
        group: { ...wfForm.group, fields: [] },
        transitions: [],
        canDelete: wfSecurity.canDelete,
      });
    }
    const newFields: EasBuilderField[] = wfForm.group.fields.reduce((cum, curr) => {
      const perm = wfSecurity.group[curr.id] || defaultVisibility;
      if (perm === FieldPermission.HIDDEN) {
        return cum;
      }
      const fld: EasBuilderField = cloneDeep(curr);
      if (perm === FieldPermission.VIEW) {
        fld.options = { ...fld.options || {}, readOnly: true };
      }
      cum.push(fld);
      return cum;
    }, []);

    const newGroup = { ...wfForm.group, fields: newFields };

    return of({
      group: newGroup,
      transitions: wfForm.transitions.filter(itm => wfSecurity.transitions.indexOf(itm.id) !== -1),
      canDelete: wfSecurity.canDelete,
    });
  }

  getState(wfForm: EasBuilderForm, wfContext: EasWorkflowContext): EasBuilderState|undefined {
    return wfForm.states.find(itm => itm.id === wfContext.state);
  }

  collectTransitionData(transition: EasBuilderTransition, doc: any): Observable<any> {

    // Decide whether we need to get a specific user and create a form if needed

    // Get actions
    const actions = transition.actions || [];
    const subForms$: Observable<EasFieldGroup>[] = [];
    const subComponents$: Observable<TransitionActionComponent>[] = [];

    const queryComponent = (component: ComponentType<any>, data: any): Observable<any> => {
      return this.dialog.open(component, { data }).afterClosed().pipe(
        tap((value) => {
          if (EASDialogError.isDialogError(value)) { throw value; }
        })
      );
    };

    actions.forEach(action => {
      const trAction = this.transitionActions[action.action];
      if (trAction === undefined) { return; }

      if (trAction.getComponent) {
        subComponents$.push(trAction.getComponent(doc, action.data));
      } else {
        subForms$.push(trAction.getForm(doc, action.data));
      }
    });

    let obs = of({});
    let data = {};
    subComponents$.forEach(subC => {
      obs = obs.pipe(
        switchMap(_ => subC),
        switchMap(val => {
          return queryComponent(val.component, val.data);
        }),
        map(value => {
          if (value) {
            data = {...data, ...value};
          }
          return data;
        })
      );
    });

    if (subForms$.length > 0) {
      obs = obs.pipe(
        switchMap(_ => {
          return forkJoin(subForms$);
        }),
        map(result => {
          result = result.filter(itm => itm !== undefined);
          if (result.length === 0) { return undefined; }

          const group: EasFieldGroup = {
            id: 'root',
            type: EasFieldType.Group,
            fields: result
          };
          const form = this.easform.createForm(group, {});
          return form;
        }),
        switchMap(form => {
          if (form === undefined) {
            return of(null);
          }

          return queryComponent(EasFormDialogComponent, {
              title: transition.name,
              group: form,
              onSubmit: value => of(value)
          });
        }),
        map(value => {
          if (value) {
            data = {...data, ...value};
          }
          return data;
        })
      );
    }

    return obs.pipe(
      map(() => {
        return { data, info: [
          {'message': 'Hello'}
        ]};
      }),
      switchMap(res => {
        if (!transition.requireConfirmation) {
          return of({});
        }

        return this.dialog.open(BuilderTransitionConfirmationComponent, {
           data: {
             data: res.data,
             info: res.info,
             transition
           }
        }).afterClosed().pipe(
          tap((value) => {
            if (EASDialogError.isDialogError(value)) { throw value; }
          })
        );
      }),
      map(() => {
        return data;
      })
    );
  }

  applyDependentGuards(secureForm: EasBuilderSecureForm, model: any, user: User) {
    return this.getAllowedTransitions(secureForm.transitions, model, user, { type: 'dependent' }).pipe(
      map(allowed => secureForm.transitions.filter(itm => allowed.indexOf(itm.id) !== -1))
    );
  }

  applyIndependentGuards(formSecurity: EasBuilderSecurity, wfForm: EasBuilderForm, model: any, user: User): Observable<EasBuilderSecurity> {
    const transitions = wfForm.transitions.filter(itm => formSecurity.transitions.indexOf(itm.id) !== -1);
    return this.getAllowedTransitions(transitions, model, user, { type: 'independent' }).pipe(
      map(allowed => {
        return {
          group: formSecurity.group,
          transitions: allowed,
          canDelete: formSecurity.canDelete,
        };
      })
    );
  }

  getAllowedTransitions(transitions: EasBuilderTransition[], model: any, user: User, options: GuardOptions) {
    const guardEvaluator = new TransitionGuardEvaluator(this.transitionGuards, model, user);
    return guardEvaluator.allowedTransitions(transitions, options);
  }
}
