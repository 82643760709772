import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EasFieldType } from '../easform/easform.interfaces';
import { map } from 'rxjs/operators';
import { EasFormService } from '../easform';
import { MatDialog } from '@angular/material/dialog';
import { EasFormDialogComponent } from '../easform/easform-dialog.component';
import { of } from 'rxjs';
import { guid } from '@fry/lib/utils';
import { BuilderFormService } from './form.service';

@Component({
  selector: 'eas-builder-form',
  templateUrl: './builder-form.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuilderFormComponent),
      multi: true
    },
  ]
})
export class BuilderFormComponent implements ControlValueAccessor {

  form: any;

  group: any = {
    id: 'root',
    type: 'group',
    fields: [],
  };

  private onChange: any;
  // private onTouch: any;

  constructor(
    private easForm: EasFormService,
    private dialog: MatDialog,
    private builder: BuilderFormService,
  ) { }

  writeValue(form) {
    this.form = this.getInitialForm(form);
    // If form is a new object send it back in the next tick
    // The other options is to use markForCheck or something
    if (this.form !== form) {
      console.log('BuilderFormComponent.writeValue: Sending form back');
      setTimeout(() => {
        this.onChange(this.form);
      });
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched() {
    // this.onTouch = fn;
  }

  getInitialForm(form) {
    const newForm = form || {};
    if (!newForm.id) {
      newForm.id = guid();
    }

    if (!newForm.group) {
      newForm.group = {
        id: 'root',
        type: EasFieldType.Group,
        fields: [],
      };
    }

    if (!newForm.states) {
      newForm.states = [];
    }

    if (!newForm.transitions) {
      newForm.transitions = [];
    }

    if (!newForm.initial) {
      newForm.initial = { state: null, actions: [] };
    }
    return newForm;
  }

  preview() {
    this.builder.buildForm(this.form.group).pipe(
      map(group => {
        return this.easForm.createForm(group, {});
      })
    ).subscribe(group => {
      this.dialog.open(EasFormDialogComponent, {
        data: {
          title: $localize `Form preview`,
          group,
          onSubmit: () => {
            return of(true);
          }
        }
      });
    });

  }
}
