import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ActionMapping } from './auditlog.mapping';


@Component({
  selector: 'eas-auditlog-table',
  templateUrl: './auditlog.table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditLogTableComponent implements OnChanges {
  @Input() dates: any[];

  public logs: any[];
  public mapping: any = ActionMapping;
  show = false;

  constructor(
  ) { }

  ngOnChanges() {
    this.updateLogs();
  }

  updateLogs() {
    if (this.dates) {
      this.logs = this.dates.reverse(); //.splice(0, 5);
    } else {
      this.logs = [];
    }
  }

  getButtonTitle() {
    return this.show ? $localize `Hide audit log` : $localize `Show audit log`;
  }
}
