import { Injectable } from '@angular/core';
import { APIService } from '@fry/lib/api';
import { AuthService } from '../auth';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private api: APIService,
    private authService: AuthService,
  ) {}

  organisations() {
    return this.api.get('/auth/organisations');
  }

  currentOrganisation(current) {
    return this.api.get('/auth/organisations').pipe(
      map(orgs => {
        if (!current) {
          current = this.authService.organisation;
        }
        if (!current) {
          return null;
        }

        return orgs.logins.find(itm => itm.id === current);
      })
    );
  }
}
