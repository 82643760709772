// tslint:disable: max-classes-per-file

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransactionInternalReference, TransactionsStore } from '@fry/payments/lib';
import { DBDoc } from '@fry/lib/store';
import { ReplaySubject } from 'rxjs';
import { getRefundOptionsForm } from './refund-option.form';
import { EasFieldGroup, EasFieldType, EasFormGroup, EasFormService } from '@fry/components/common/easform';
import * as EASDialogError from '@fry/components/common/dialog/dialog.error';

export interface RefundOptionData {
  actionId: string;
  doc: DBDoc;
  actionData: any;
}

interface RefundInfo {
  amount: number;
  paid: number;
  toRefund: number;
  currency: string;
}

@Component({
  selector: 'app-refund-option',
  templateUrl: 'refund-option.component.html',
})
export class RefundOptionComponent implements OnInit {

  public canceled = EASDialogError.UserCanceledError();
  public refundInfo$ = new ReplaySubject<RefundInfo>(1);
  public amount: number;
  public paid: number;
  public currency: string;
  public form: EasFormGroup;
  public title;
  public model = {};
  private preSelected: any;

  constructor(
    private dialogRef: MatDialogRef<RefundOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RefundOptionData,
    private transactions: TransactionsStore,
    private easForm: EasFormService,
  ) {
    this.data = data;
    this.title = (data.actionData.refund_options || []).length > 1 ? $localize `Select` : $localize `Confirm`;
    this.preSelected = this.data.doc.metadata && this.data.doc.metadata.refund_option;
  }

  ngOnInit() {
    const doc = this.data.doc;
    const subject: TransactionInternalReference = {
      id: doc._id, type: doc.type
    };
    this.transactions.searchBySubject(subject).subscribe(trans => {
      this.amount = trans.reduce((cum, curr) => {
        let trAmount = 0;
        // This is quite ugly but I don't know how to work with transactions
        if (curr.role === 'payment' && (!curr.orig.refunds || curr.orig.refunds.length === 0)) {
          trAmount = curr.amount;
        }

        return cum + trAmount;
      }, 0);

      this.paid = trans.reduce((cum, curr) => {
        let trAmount = 0;
        // This is quite ugly but I don't know how to work with transactions
        if (curr.orig.state === 'completed' && curr.role === 'payment' && (!curr.orig.refunds || curr.orig.refunds.length === 0)) {
          trAmount = curr.amount;
        }

        return cum + trAmount;
      }, 0);

      if (trans.length > 0) {
        this.currency = trans[0].orig.currency;
      }
      const initialOption = this.getOption(this.preSelected ? this.preSelected.refund_option : undefined);
      this.updateRefundInfo(initialOption);
      this.form = this.getForm();
      this.form.control.valueChanges.subscribe(val => {
        const option = this.getOption(val.refund_option);
        this.updateRefundInfo(option);
      });

    });
  }

  getOption(title?: string) {
    const options = this.data.actionData.refund_options || [];
    return title ?
      options.find(itm => itm.title === title) :
      options.find(itm => itm.default);
  }

  updateRefundInfo(refundOption?) {
    let toRefund;
    if (refundOption === undefined) {
      toRefund = this.amount;
    } else if (refundOption.type === 'adjustment') {
      toRefund = this.amount * refundOption.adjustment;
    } else if (refundOption.type === 'fixed') {
      toRefund = refundOption.adjustment;
    } else if (refundOption.type === 'custom') {
      var amount = this.form.getValue().refund_amount;
      toRefund = amount === null ? refundOption.adjustment : amount;
    }
    const refundInfo = {
      amount: this.amount,
      paid: this.paid,
      toRefund,
      currency: this.currency
    };
    this.refundInfo$.next(refundInfo);
  }

  getForm(): EasFormGroup {
    const fields = getRefundOptionsForm(this.data.actionData, this.preSelected);
    // if (fields.length === 0) {
    //   return;
    // }

    const changeOptionForm: EasFieldGroup = {
      id: 'root',
      type: EasFieldType.Group,
      fields: [
        ...fields
      ]
    };
    return this.easForm.createForm(changeOptionForm, this.model);
  }

  onSubmit() {
    const value = { [this.data.actionId]: this.form.getValue() };
    this.dialogRef.close(value);
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close(this.canceled);
  }
}
