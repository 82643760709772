import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  HostBinding,
  Injector,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { DashboardCard } from '../dashboard-card';
import { AbstractDashboardCard } from '..';

@Component({
  selector: 'app-dashboard-cards-spawner',
  templateUrl: './dashboard-cards-spawner.component.html'
})
export class DashboardCardsSpawnerComponent implements OnInit {
  @ViewChild('spawn', { read: ViewContainerRef, static: true }) container;
  @HostBinding('style.display') display: string;


  constructor(private resolver: ComponentFactoryResolver) {
  }

  @Input() set card(data: DashboardCard) {
    if (!data) {
      return;
    }
    const inputProviders = Object.keys(data.input).map((inputName) => {
      return {
        provide: data.input[inputName].key,
        useValue: data.input[inputName].value,
        deps: []
      };
    });
    const injector = Injector.create({
      providers: inputProviders,
      parent: this.container.parentInjector,
      name: 'Dashboard Cards Spawner Injector'
    });
    const factory = this.resolver.resolveComponentFactory(data.component);
    const component = factory.create(injector) as ComponentRef<AbstractDashboardCard>;
    component.instance.isVisible().subscribe(isvisible => {
      if (isvisible) {
        this.container.insert(component.hostView);
      } else {
        this.display = 'none';
      }
    });
  }

  ngOnInit() {
  }

}
