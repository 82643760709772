import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { EasTable, EasTableColumn } from '@fry/lib/store';
import { FormDialogComponent } from '@fry/components/common/form';
import { SelectableListMapping } from '@fry/components/common/selectable-list/selectable-list.mapping';
import { FormData, FormProviderService } from '@fry/lib/forms';
import { EASBrowserStorageService } from '@fry/lib/utils';
import { DialogComponentData, DialogComponentService, DialogComponentStateFactory } from '../dialog';

export type EasTableColumnFilter = 'all' | 'selected' | 'default';

const MODAL_DIALOG_STATES: {[K: string]: DialogComponentStateFactory} = {
  'canceled':
    () => DialogComponentData.canceled(),

  'error':
    (content) => DialogComponentData.error('Submission error', content),

  'progress':
    () => DialogComponentData.progress($localize `Processing`,
                                       $localize `This may take a little while depending on your connection`),

  'success':
    () => DialogComponentData.success()
};


@Component({
  selector: 'eas-table',
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DialogComponentService
  ]
})
export class TableComponent implements OnInit, OnChanges {

  @Input() table: EasTable;

  /**
   * Is functionality of selecting columns enabled or not
   */
  @Input() canSelectColumns = false;

  /**
   * List of default column IDs
   *
   * This list is then used to display columns when no selection has been mande.
   */
  @Input() defaultColumns: string[];

  /**
   * List of selecte column IDs
   *
   * This list has precedence over the `default` and being it Input allows the
   * parent components to pass the list into component.
   *
   * This is at the moment used together with `didSelectColumns` EventEmitter to
   * allow parent component to cache the selected columns and provide them to
   * table component in case of reload or pagination.
   */
  @Input() set selectedColumns(columns: string[]) {
    this._selectedColumns = columns || [];
    if (this.storage) {
      this.storage.setArray(this.selectedColumnsStorageKey,
                            this._selectedColumns);
    }
    this.didSelectColumns.emit(this._selectedColumns);
  }
  get selectedColumns(): string[] {
    return this._selectedColumns;
  }
  private _selectedColumns: string[] = [];

  private _selectedColumnsStorageKey: string;
  @Input() set selectedColumnsStorageKey(value: string) {
    if (value === undefined) { return; }

    this._selectedColumnsStorageKey =
      EASBrowserStorageService.storageKey(value, 'eas-table');
  }
  get selectedColumnsStorageKey(): string {
    return this._selectedColumnsStorageKey;
  }


  /**
   * EventEmitter to inform parent component that the list of the selected
   * columns has changed.
   *
   * Parent component should accept the value of emitter and provide the value
   * to this component via the template to maintain the selected columns list.
   */
  @Output() didSelectColumns = new EventEmitter<string[]>();

  // List of visible columns at the moment in the table
  public columnsVisible: EasTableColumn[] = [];

  public get columnsAll(): EasTableColumn[] {
    return this.table.columns;
  }

  public get columnsDefault(): EasTableColumn[] {
    return this.columnsAll.filter(col => this.defaultColumns.includes(col.id));
  }

  private columnsDialogRef: MatDialogRef<FormDialogComponent, any>;

  private storage: EASBrowserStorageService;

  constructor(private matDialog: MatDialog,
              private formProvider: FormProviderService,
              private changeDetector: ChangeDetectorRef,
              @Self() private dialogComponentService: DialogComponentService
             ) {
    this.dialogComponentService.states = MODAL_DIALOG_STATES;
    this.dialogComponentService.forceModalDialog = false;
    try {
      this.storage = new EASBrowserStorageService('localStorage');
    } catch (error) {
      console.warn(error);
    }
  }

  ngOnInit(): void {
    this.setup();
  }

  ngOnChanges(): void {
    this.setup();
  }

  onClick(column): void {
    this.dialogComponentService.updateState(
      this.dialogComponentService.stateFor('progress')
    );
    column.onClick().subscribe(
      () => {
        this.dialogComponentService.updateState(
          this.dialogComponentService.stateFor('success')
        );
      },
      (error) => {
        this.dialogComponentService.updateState(
          this.dialogComponentService.states['error'](error)
        );
      }
    );
  }

  private setup() {
    // If we don't have selected columns from @Input we try localStorage
    if (this.selectedColumns.length === 0
        && this.selectedColumnsStorageKey
        && this.storage) {
      const columns = this.storage.getArray(this.selectedColumnsStorageKey);
      this.selectedColumns = columns ? columns : [];
    }

    // If we have selected columns we use it otherwise we use defaults
    const filter = this.selectedColumns.length > 0 ? 'selected' : 'default';
    this.columnsVisible = this.columnsFiltered(filter);
  }


  //
  // Columns helpers
  //

  private columnsFiltered(filter: EasTableColumnFilter): EasTableColumn[] {
    switch (filter) {
      case 'all':
        return this.columnsAll;
      case 'default':
        return this.defaultColumns ? this.columnsDefault : this.columnsAll;
      case 'selected':
        return this.selectedColumns.length > 0
               ? this.selectedColumns.map(sc => this.columnsAll.find(c => c.id === sc)).filter(Boolean)
               : this.columnsAll;
    }
  }

  private openColumnSelectionDialog(matDialog: MatDialog,
                                    form: FormData,
                                    title: string): MatDialogRef<FormDialogComponent, any> {
    const dialogRef = matDialog.open(FormDialogComponent, {
      data: { 'title': title, form, onSubmit: () => of({}) }
    });

    return dialogRef;
  }

  private createColumnSelectionForm$(formProvider: FormProviderService,
                                     columns: EasTableColumn[],
                                     selectedColumns: EasTableColumn[]) {

    const sortedIds = selectedColumns.map(itm => itm.id);
    const sortedColumns = columns.sort((a, b) => {
      const bord = sortedIds.indexOf(b.id);
      const aord = sortedIds.indexOf(a.id);
      if (bord === -1 && aord === -1) {
        return columns.indexOf(a) - columns.indexOf(b);
      }

      if (aord === -1) {
        return 1;
      }

      if (bord === -1) {
        return -1;
      }

      return aord - bord;
    });
    const items = SelectableListMapping.create([{
      items: sortedColumns.map(col => ({id: col.id, title: col.label})),
      id: 'roles'
    }]);

    const fields = [
      {
        id: 'columns',
        title: '',
        initial: selectedColumns.map(col => col.id),
        widget: { type: 'selectableList' },
        options: {
          categories: items,
          options: { allowsItemReordering: true }
        }
      }
    ];
    return formProvider.getFormDataWith(fields, {});
  }

  //
  // Template helpers
  //

  public selectColumns() {
    if (this.columnsDialogRef) { return; }

    this.createColumnSelectionForm$(this.formProvider,
                                    this.columnsAll,
                                    this.columnsVisible)
        .pipe(
          concatMap(form => {
            this.columnsDialogRef =
              this.openColumnSelectionDialog(this.matDialog,
                                             form,
                                             $localize `Choose which columns to display`);
            return this.columnsDialogRef.afterClosed();
          })
        ).subscribe((result: any) => {
          this.columnsDialogRef = undefined;
          if (result === undefined) { return; }

          this.selectedColumns = result.columns;
          this.columnsVisible = this.columnsFiltered('selected');
          this.changeDetector.detectChanges();
        });
  }

  public showDefaultColumns() {
    this.selectedColumns = [];
    this.didSelectColumns.emit(this.selectedColumns);
    this.columnsVisible = this.columnsFiltered('default');
  }
}
