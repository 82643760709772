import { Injectable } from '@angular/core';

import { APIService } from '@fry/lib/api';
import { ApiStore } from '@fry/lib/store';

import { AsyncTask } from './async-task';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AsyncTasksStore extends ApiStore<AsyncTask> {
    AssociatedModel = AsyncTask;

    docType = 'celeryTask';
    endpoint = 'async-tasks';

    constructor(api: APIService) {
      super(api);
    }

    download(id: string) {
      return this.api.get(`${this.endpoint}/${id}/download`).pipe(
        map(result => {
          window.location.href = result.url;
        })
      );
    }

}
