import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormData } from '@fry/lib/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form-default',
  templateUrl: './form-default.component.html'
})
export class FormDefaultComponent {
  @Input() formData$: Observable<FormData>;
  @Output() submitted = new EventEmitter<void>();

  constructor(
  ) { }

  onSubmit() {
    this.submitted.emit();
  }

  getDefaultSubmitButtonTitle() {
    return $localize `Save`;
  }
}
