import { Component, OnInit } from '@angular/core';
import { AuthService, CurrentUser } from '@fry/lib/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '@fry/lib/login';
import { switchMap, tap } from 'rxjs/operators';


@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
})
export class AuthLoginComponent implements OnInit {

  currentUser: Observable<CurrentUser>;
  organisation;

  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.currentUser = this.authService.currentUser();
    this.organisation = this.authService.currentUser().pipe(
      tap(data => console.log('Login', data)),
      switchMap(user => this.loginService.currentOrganisation(user.organisation)),
      tap(data => console.log('Login', data))
    );
  }

  async login() {
    await this.authService.startLoginPopup();
    this.router.navigate(['/']);
  }

  resetOrganisation() {
    this.authService.organisation = null;
  }
}
