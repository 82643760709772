import { BaseModel, DBDoc } from '@fry/lib/store';
import { BookingOptionDoc } from '../booking-items/booking-option';
import { User } from '@fry/lib/users';


const StateTitles = {
  new: 'New'
};


export class Booking extends BaseModel {
  public bookingOption: BookingOptionDoc;
  public paymentStats: any;
  public owner: User;

  private get _workflow() {
    return { state: 'new', ...(this.doc.workflow || {}) };
  }

  public get workflow() {
    const wf = this._workflow;
    return { title: StateTitles[wf.state]  || wf.state, ...wf };
  }

  constructor(doc: DBDoc, nonDocProperties?: any) {
    super(doc, nonDocProperties);

    if (!!nonDocProperties) {
      this.bookingOption = nonDocProperties.bookingOption;
      this.paymentStats = nonDocProperties.paymentStats;
      this.owner = new User(nonDocProperties.owner);
    }
  }
}
