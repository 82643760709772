import { Injectable } from '@angular/core';

import { APIService } from '@fry/lib/api';
import { ApiStore } from '@fry/lib/store';
import { PaymentMethod } from './payment_method';


@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsStore extends ApiStore<PaymentMethod> {
  AssociatedModel = PaymentMethod;

  docType = 'paymentMethod';
  endpoint = 'payment-methods';

  constructor(api: APIService) {
    super(api);
  }
}
