import {Component, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';

import { MenuService, RemoteMenuService } from '@fry/lib/menu';
import { Profile, ProfileStore } from '@fry/lib/profile';
import { AccountService, AuthService, CurrentUser } from '@fry/lib/auth';
import { RemoteUserService, User } from '@fry/lib/users';
import { OrganisationService } from '@fry/lib/organisations';
import { EASButtonAction, EASLinkAction } from '../buttons/buttons.service';
import { AuthLoginPopupComponent } from '../oidc/auth-login-popup.component';
import { MenuIcon } from '@fry/components/common/buttons/buttons.list.component';
import { UserLanguage, UserLanguageService } from "@fry/components/users/user-language/user-language.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;

  // Export MenuIcons enum to the template
  public MenuIcons = MenuIcon;

  profile$: Observable<Profile>;
  anonymous$: Observable<boolean>;
  currentUser$: Observable<CurrentUser>;
  public org$;
  public menu$;
  public remoteMenu$;
  private dialogRef: MatDialogRef<any>;
  public remoteUser$: Observable<User>;

  availableLanguages: UserLanguage[];
  languagesButtonsButtons: (EASButtonAction | EASLinkAction)[] = [];
  avatarButtons: (EASButtonAction | EASLinkAction)[] = [
   {
      type: 'link',
      label: $localize `Change organisation`,
      condition: () => {
        // This could for example be a check for more than one org
        return this.account.availableOrganisations().pipe(
          map(orgs => orgs.length > 1),
          catchError(err => {
            console.log(err);
            return of(false);
          })
        );
      },
      link: of(['/', 'auth', 'set-organisation']),
    },
    {
      type: 'button',
      label: $localize `Logout`,
      condition: () => {
        return of(true);
      },
      click: () => this.auth.startLogout(),

    },
    {
      type: 'button',
      label: $localize `Leave masquerade mode`,
      condition: () => {
        return this.auth.currentUser().pipe(
          take(1),
          map(user => !!user.original),
        );
      },
      click: () => this.auth.logoutAs(),
    }
  ];

  constructor(
    private menuService: MenuService,
    @Inject(RemoteMenuService) private remoteMenuService: MenuService,
    @Inject(LOCALE_ID) private locale: string,
    private profile: ProfileStore,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private auth: AuthService,
    private dialog: MatDialog,
    private account: AccountService,
    private remoteUserService: RemoteUserService,
    private organisationService: OrganisationService,
    private userLanguageService: UserLanguageService
  ) {
    this.availableLanguages = this.userLanguageService.availableLanguages;
    if (this.availableLanguages.length > 1) {
      this.availableLanguages.forEach(item => {
        if (this.locale != item.languageCode) {
          this.languagesButtonsButtons.push(
            {
              type: 'button',
              label: item.languageName,
              condition: () => { return of(true); },
              click: () => {
                  const newHref = this.userLanguageService.getLanguageBaseHref(item.languageCode);
                  window.location.href = `${newHref}${this.router.url}`;
              },
            }
          );
        }
      });
    }

    this.router.events.pipe(
      withLatestFrom(this.isHandset$),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe(_ => this.drawer.close());

  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
    .pipe(
      map(result => result.matches)
  );

  ngOnInit() {
    console.log('Menu: Initialising menu');
    this.menu$ = this.menuService.menu$;
    this.remoteMenu$ = this.remoteMenuService.menu$;
    this.profile$ = this.profile.getCurrent();
    this.org$ = this.organisationService.getCurrent();
    this.anonymous$ = this.auth.currentUser().pipe(
      map(user => {
        console.log('Menu: Received', user);
        this.profile$ = this.profile.getCurrent();
        const anon = user.state === 'anonymous';
        if (anon) {
          if (this.auth.autoReconnect) {
            if (this.dialogRef) {
              console.log('Menu: We already have a dialog ref');
            } else {
              this.dialogRef = this.dialog.open(AuthLoginPopupComponent, {
                ...new MatDialogConfig(),
                hasBackdrop: true,
                disableClose: true,
              });
              this.dialogRef.afterClosed().subscribe(() => this.dialogRef = null);
            }
          }
        } else {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        }
        return anon ? this.auth.autoReconnect : false;
      }),
      tap(data => console.log('Menu: User state changed to', data)),
    );

    this.currentUser$ = this.auth.currentUser();
    this.remoteUser$ = this.remoteUserService.user$;
  }

}
