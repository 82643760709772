// tslint:disable: max-classes-per-file

import { Injectable, Injector, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { guid } from '../utils';

export type NotificationType = 'error'|'warning'|'success';
export class Notification {
  message: string;
  type: NotificationType;

  public constructor(type: NotificationType, message: string) {
      this.type = type;
      this.message = message;
  }

  public static error(value: string|Error): Notification {
      const message =  typeof value === 'string' ? value : value.message;
      return new Notification('error', message);
  }

  public static success(value: string): Notification {
      return new Notification('success', value);
  }

  public static warning(value: string): Notification {
      return new Notification('warning', value);
  }
}

function isNotification(arg: any): arg is Notification {
    return arg.message !== undefined;
}

// Implement this as imutable state using RXJS
// https://dev.to/avatsaev/simple-state-management-in-angular-with-only-services-and-rxjs-41p8
// Try Akita?

/**
 * NotificationsService (global) & LocalNotificationsService (component)
 *
 * These notification services maintin stack of notifications. There is Global
 * one and Component/Local one.
 *
 * In normal code ONLY LocalNotificationsService should be used as it does
 * communicate & tearsup automatically.
 *
 * Usage:
 * Just inject LocalNotificaionsService into your component and you can
 * push/remove notifications. When component goes out of scope notifications
 * and service will be automatically removed.
 *
 * If you don't want to handle errors/notifications it will be picked up by the
 * global handler and service.
 */
@Injectable()
export class NotificationsService implements OnDestroy {
    // Just to recognize different instances
    public readonly guid: string;

    public get notifications$(): Observable<Notification[]> {
        return this._notifications$.asObservable();
    }

    private readonly _notifications$: BehaviorSubject<Notification[]>;
    protected get notifications(): Notification[] {
        return this._notifications$.getValue();
    }
    protected set notifications(notifications: Notification[]) {
        this._notifications$.next(notifications);
    }

    private destroyed$ = new Subject<void>();
    private routerSubscription: Subscription;

    public constructor() {
        this._notifications$ = new BehaviorSubject<Notification[]>([]);
        this.guid = guid();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }


    // This must be idempotent function otherwise we will make multiple
    // subscriptions, so we remember the subscription. Also cleanup properly!
    public registerCleanupOnNavigationStart(router: Router) {
        if (this.routerSubscription) { return; }

        this.routerSubscription = router.events.pipe(
            filter(event => event instanceof NavigationStart),
            takeUntil(this.destroyed$)
        ).subscribe(() => this.removeAll());
    }

    public push(notification: Notification) {
        this.notifications = [ ...this.notifications, notification ];
    }

    public pop(): Notification {
        const [ deletion, ...tail] = this.notifications;
        this.notifications = tail;
        return deletion;
    }

    public remove(value: Notification | Notification[]) {
        if (isNotification(value)) {
            this.notifications = this.notifications.filter(n => n !== value);
        } else {
            this.notifications = this.notifications.reduce((result, notification) => {
                if (!value.find(n => n === notification)) {
                    result.push(notification);
                }
                return result;
            }, []);
        }
    }

    public removeAll(): void {
        this.notifications = [];
    }

    //
    // Helpers
    //

    public error(value: string|Error) {
        this.push(Notification.error(value));

    }

    public success(value: string) {
        this.push(Notification.success(value));
    }

    public warning(value: string) {
        this.push(Notification.warning(value));
    }
}

@Injectable() export class LocalNotificationsService extends NotificationsService
                                                     implements OnDestroy {
    private parent: NotificationsService;

    public constructor(injector: Injector) {
        super();
        this.parent = injector.get<NotificationsService>(NotificationsService);
    }

    public ngOnDestroy(): void {
        if (this.parent) {
            this.parent.remove(this.notifications);
        }
        super.ngOnDestroy();
    }

    public push(notification: Notification) {
        super.push(notification);
        this.parent.push(notification);
    }

    public remove(value: Notification | Notification[]) {
        super.remove(value);
        this.parent.remove(value);
    }

    public removeAll() {
        this.remove(this.notifications);
    }
}
