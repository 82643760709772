import { BaseModel } from '@fry/lib/store/base-model';
import { DBDoc } from '@fry/lib/store/store.interface';

/**
 * PAYMENT METHODS
 */

// Codes of the payment methods
export type PaymentMethodCode = 'cash' | 'card' | 'cheque' | 'transfer';

export class PaymentMethod extends BaseModel {
    public code: PaymentMethodCode;
    public name: string;
    public order: number;
    public displayName: string;
    public instructions: string;

    constructor(doc: DBDoc) {
        super(doc);

        this.code = this.orig.code;
        this.name = this.orig.name;
        this.order = this.orig.order;
        this.displayName = this.orig.displayName;
        this.instructions = this.orig.instructions;
    }

    public isCash(): boolean { return this.code === 'cash'; }
    public isCard(): boolean { return this.code === 'card'; }
    public isCheque(): boolean { return this.code === 'cheque'; }
    public isTransfer(): boolean { return this.code === 'transfer'; }
}
