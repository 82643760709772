import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { APIService } from '@fry/lib/api';
import { ClusterService } from '../organisations/cluster.service';

export interface Credential {
  org: string;
  state: 'enabled' | 'disabled';
  type: 'local' | 'proxy';
  username: string;
}

interface EditCredentialData {
  username: string;
  password1: string;
  password2: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private api: APIService,
    private cluster: ClusterService,
  ) { }

  public availableOrganisations() {
    return of(this.cluster.getCurrentOrganisations());
      // return this.api.get('/account/organisations')
      //   .pipe(
      //       map(data => {
      //         return data.organisations.filter(org => (org.allowedProducts || []).includes('eas'));
      //       })
      //   );
  }

  public credentials(username: string): Observable<Credential[]> {
    return this.api.get(`/accounts/${username}/credentials`).pipe(
      tap(data => console.log('Creds', data))
    );
  }

  public add_credentials(username: string, data: Partial<EditCredentialData>) {
    return this.api.post(`/accounts/${username}/credentials`, data);
  }

  public edit_credentials(username: string, credName: string, data: Partial<EditCredentialData>) {
    return this.api.put(`/accounts/${username}/credentials/${credName}`, data);
  }

  public remove_credentials(username: string, credName: string) {
    return this.api.delete(`/accounts/${username}/credentials/${credName}`);
  }
}
