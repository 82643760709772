import { ElementRef } from "@angular/core";
import { Observable } from "rxjs";

import { ScriptLoaderService } from "@fry/lib/utils";
import { APIService } from "@fry/lib/api";

import {
    PaymentsErrorUnknownVendor,
    Transaction,
    TransactionVendorReference,
} from "@fry/payments/lib";
import { TransactionCardFormComponent } from "@fry/payments/components";

import { IntegrationConfiguration, PaymentGatewayVendor } from "./integrations.interfaces";
import { StripeIntegrationService } from "./stripe.integration";
import { MonerisIntegrationService } from "./moneris.integration";

export interface PaymentGatewayIntegration {
    readonly vendor: PaymentGatewayVendor;

    readonly isStripe: boolean;

    readonly isMoneris: boolean;

    initialize(): Observable<IntegrationConfiguration>;

    prepareVendorInteraction(transaction: Transaction): Observable<Record<string,any>>;

    startVendorInteraction(transaction: Transaction, options: Record<string, any>): Observable<TransactionVendorReference|undefined>;

    fetchVendorConfiguration(): Observable<IntegrationConfiguration>;

    mountUIElement(elementRef: ElementRef,
                   elementOptions: Record<string, any>,
                   elementChanges: (event: any) => void): boolean;

    unmountUIElement(): void;

    handleUIElementChanges(event: any, component: TransactionCardFormComponent): void;

    handleVerificationChallenge(challenge: Record<string, any>): Promise<TransactionVendorReference>;

    /*
    This is used to implement mobile valets via Stripe
    handlePaymentRequestButton(paymentInfo: any,
                               elementRef: ElementRef,
                               elementChanges: (event: any) => void): void;
    */
}

export class PaymentIntegrationFactory {
    public static integrationForVendor(vendor: PaymentGatewayVendor,
                                       scriptLoader: ScriptLoaderService,
                                       apiService: APIService): PaymentGatewayIntegration {
        switch (vendor) {
            case PaymentGatewayVendor.stripe:
                return new StripeIntegrationService(scriptLoader, apiService);
            case PaymentGatewayVendor.moneris:
                return new MonerisIntegrationService(scriptLoader, apiService);
            default:
                throw new PaymentsErrorUnknownVendor($localize `Unknown vendor '${vendor}!'`);
        }
    }
}
