import { Injectable } from '@angular/core';

import { EasFieldGroup, EasFieldType } from '@fry/components/common/easform';
import { TransitionAction } from '@fry/components/common/form-builder/builder.interfaces';
import { of } from 'rxjs';
import { getRefundOptionsForm } from './change-option.action';
import { RefundOptionComponent } from './refund-option.component';

@Injectable()
export class RefundPaymentsAction implements TransitionAction {
  actionId = 'refund_payments';
  scope = 'booking';

  constructor() {}

  getComponent(doc: any, actionData: any) {
    return of({
      component: RefundOptionComponent,
      data: {
        actionId: this.actionId,
        doc,
        actionData
      }
    });
  }
  getForm(doc: any, actionData: any) {

    // const options = actionData.refund_options || {};
    const selected = doc.metadata && doc.metadata.select_refund_option;
    const fields = getRefundOptionsForm(actionData, selected);
    if (fields.length === 0) {
      return of(undefined);
    }

    const changeOptionForm: EasFieldGroup = {
      id: 'refund_payments',
      type: EasFieldType.Group,
      fields: [
        ...fields
      ]
    };
    return of(changeOptionForm);
  }
}
