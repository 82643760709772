import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';


@NgModule()
export class PermissionsModule {
  constructor(@Optional() @SkipSelf() parentModule: PermissionsModule) {
    if (parentModule) {
      throw new Error('PermissionsModule is already loaded. It should only be imported in your application\'s main module.');
    }
  }
  static forRoot(permissions: Provider[]): ModuleWithProviders<PermissionsModule> {
    return {
      ngModule: PermissionsModule,
      providers: permissions,
    };
  }
}
