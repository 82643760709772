import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '@fry/lib/users';
import { isDependentGuard, isIndependentGuard, TransitionGuard } from './builder-transition-guard';
import { EasBuilderTransition } from './builder.interfaces';

interface TransitionGuardEvaluation {
    [K: string]: boolean;
}

export interface GuardOptions {
  type: 'independent' | 'dependent';
}

export class TransitionGuardEvaluator {

    public constructor(private registeredGuards: { [K: string]: TransitionGuard },
                       private model: any,
                       private user: User) {}

    public allowedTransitions(transitions: EasBuilderTransition[], options: GuardOptions): Observable<string[]> {
        const transitions$: Observable<TransitionGuardEvaluation>[] = [];

        if (transitions.length === 0) {
            return of([]);
        }

        transitions.forEach(transition => {
          const evaluations$: Observable<boolean>[] = [];

          const guards = transition.guards || [];
          guards.forEach(guard => {
            const trGuard = this.registeredGuards[guard.guard];
            if (trGuard === undefined) { return; }
            if (options.type === 'independent' && isIndependentGuard(trGuard)) {
              evaluations$.push(trGuard.evaluate(this.model, this.user, guard.data));
            }

            if (options.type === 'dependent' && isDependentGuard(trGuard)) {
              evaluations$.push(trGuard.evaluateData(this.model, this.user, guard.data));
            }
          });

          let allowed$: Observable<TransitionGuardEvaluation>;
          if (evaluations$.length === 0) {
            const evaluation: TransitionGuardEvaluation = {};
            evaluation[transition.id] = true;
            allowed$ = of(evaluation);
          } else {
            allowed$ = forkJoin(evaluations$).pipe(
              map(result => {
                const allowed = result.reduce((acc, val) => {
                  if (!acc) { return false; }
                  return val;
                }, true);

                const evaluation: TransitionGuardEvaluation = {};
                evaluation[transition.id] = allowed;
                return evaluation;
              })
            );
          }
          transitions$.push(allowed$);
        });

        return forkJoin(transitions$)
               .pipe(
                   map(transitionResults => {
                       const outcome = transitionResults
                                       .filter(transition => {
                                           const values: boolean[] = Object.values(transition);
                                           return values[0] === true;
                                       })
                                       .reduce((obj, evaluation) => {
                                           return {...obj, ...evaluation};
                                       }, {});
                       return Object.keys(outcome);
                  })
               );
    }
}
