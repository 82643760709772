import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ListSearch } from '@fry/lib/list';

@Injectable()
export class VenuesSearch implements ListSearch {

  constructor(
  ) {}

  public filters() {
    return of([
      {
        id: 'fulltext',
        label: '',
        filter: (item, value) => {
          return of((item.doc.name || '').toLowerCase().indexOf(value.toLowerCase()) !== -1);
        },
        featured: true,
        widget: {
          type: 'string',
          placeholder: $localize `Full text search`,
        }
      },
      {
        id: 'nofulltext',
        label: $localize `Exclude Full text search`,
        filter: (item, value) => {
          if (!item.doc.name) {
            return of(false);
          }
          return of((item.doc.name || '').toLowerCase().indexOf(value.toLowerCase()) === -1);
        },
        widget: {
          type: 'string'
        }
      }
    ]);
  }

  public sorts() {
    return of([
      {
        id: 'name',
        name: $localize `Name`,
        sort: (itemA, itemB) => {
          return (itemA.doc.name || '').toLowerCase() > (itemB.doc.name || '').toLowerCase() ? 1 : -1;
        }
      }
    ]);
  }

}
