import { Injectable } from '@angular/core';

import {ApiStore, SearchType, SuperType} from '@fry/lib/store';
import {APIService} from "@fry/lib/api";

import { Venue } from './venue';


@Injectable({
  providedIn: 'root'
})
export class VenuesStore extends ApiStore<Venue> {
  AssociatedModel = Venue;

  docType = 'venue';
  endpoint = 'venues';
  superType = SuperType.Org;
  searchType = SearchType.LOCAL;

  constructor(api: APIService) {
    super(api);
  }
}
