import { Component, Input, Optional, TemplateRef } from '@angular/core';

import { ListService, ListState } from '@fry/lib/list';
import { EASButtonAction, EASLinkAction } from '../../buttons/buttons.service';
import { ExportListService } from './export-list/export-list.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() actions: (EASButtonAction | EASLinkAction)[];
  @Input() tableTemplate: TemplateRef<any>;
  @Input() cardTemplate: TemplateRef<any>;

  public get contentTemplate(): TemplateRef<any> {
    return this.isPresentingTable() ? this.tableTemplate : this.cardTemplate;
  }

  public state: ListState;

  public supportExport = false;

  public exportInProgress$: Observable<boolean>;

  constructor(
    public list: ListService,
    @Optional() exportService: ExportListService
  ) {
    if (exportService) {
      this.supportExport = true;
    }

    this.list.state.subscribe(state => {
      this.state = state;
    });
  }

  //
  // Template Helpers
  //

  public hasSubtitle(): boolean {
    return this.subtitle && this.subtitle.length > 0;
  }

  public isLoadedWithItems(): boolean {
    return this.state.type === 'full';
  }

  public isLoading(): boolean {
    return this.state.type === 'loading';
  }

  public isPresentingTable(): boolean {
    return this.list.listType === 'table';
  }

  public canSwitchPresentations(): boolean {
    return this.cardTemplate !== undefined && this.tableTemplate !== undefined;
  }

  //
  // Actions
  //

  public onReload(): boolean {
    return this.doReload();
  }

  public onShowTable(): boolean {
    this.list.listType = 'table';
    return this.doReload();
  }

  public onShowCards(): boolean {
    this.list.listType = 'normal';
    return this.doReload();
  }

  private doReload(): boolean {
    if (this.isLoading()) { return false; }

    this.list.reload().subscribe(
      () => {},
      () => {
        // We swallow errors because we handle errors for List using ListState,
        // see above. We need to subscribe to reload here though.
      }
    );

    return true;
  }
}
