import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { ApiStore, SearchType, SuperType } from '@fry/lib/store';
import { APIService } from '@fry/lib/api';

import { Booking } from './booking';


@Injectable({
  providedIn: 'root'
})
export class BookingsStore extends ApiStore<Booking> {
  AssociatedModel = Booking;

  docType = 'booking';
  endpoint = 'bookings';
  superType = SuperType.User;
  searchType = SearchType.REMOTE;

  constructor(api: APIService) {
    super(api);
  }

  createObject(doc: any): Booking {
    if (doc.bookingOption && doc.bookingOption.id) {
      return new this.AssociatedModel(doc.doc, doc);
    }

    return new this.AssociatedModel(doc);
  }

  update(id: string, rev: string, data: any) {
    const payload = { rev, data };
    return this.api.post(`${this.endpoint}/${id}/update`, payload).pipe(
      switchMap(() => this.get(id)),
    );
  }

  apply(username: string, bookingItem: string, bookingOption: string, options: any) {
    options = options || {};
    const payload = {
      bookingItem,
      bookingOption,
      user: username,
      ignoreEligibility: !!options.ignoreEligibility,
      ignoreWindow: !!options.ignoreWindow,
      skipInitialCheck: !!options.skipInitialCheck,
    };
    return this.api.post(`${this.endpoint}/apply`, payload);
  }

  applyInfo(username: string, bookingItem: string, bookingOption: string, options: any) {
    options = options || {};
    const payload = {
      bookingItem,
      bookingOption,
      user: username,
      ignoreEligibility: !!options.ignoreEligibility,
      ignoreWindow: !!options.ignoreWindow,
    };
    return this.api.post(`${this.endpoint}/apply-info`, payload);
  }

  transition(id: string, transition: string, data: any) {
    const payload = {
      transition,
      data
    };
    return this.api.post(`${this.endpoint}/${id}/transition`, payload).pipe(
      switchMap(() => this.get(id)),
    );
  }

  bulkTransition(ids: string[], transition: string, data: any) {
    const payload = {
      ids,
      transition,
      data
    };
    return this.api.post(`${this.endpoint}/bulk-transition`, payload).pipe(
    );
  }

  integrationCheck(payload: any) {
    return this.api.post(`${this.endpoint}/integration-check`, payload);
  }
}
