import { BaseModel } from '@fry/lib/store';
import { reversedTypeMapping, typeMapping } from '@fry/lib/forms';

export class UserField extends BaseModel {

  public get title(): string {
    return this.orig['name'];
  }

  public get fieldType() {
    return typeMapping[this.doc.fieldType];
  }

  public get transformed() {
    const tr = {...this.doc};
    tr.fieldType = this.fieldType;
    return tr;
  }

  public setValue(value: any) {
    const fieldType = reversedTypeMapping[value.fieldType];
    this.orig = {...this.orig, ...value, fieldType};
    this.init();
  }

}
