import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UBCSSProperties } from '../unboxed.module.typings';

@Component({
  selector: 'ub-visually-hidden',
  exportAs: 'unboxedVisuallyHidden',
  template: `
    <div [ngStyle]="styles()">
      <ng-content></ng-content>
    </div>
  `,
  encapsulation: ViewEncapsulation.None
})
export class UnboxedVisuallyHidden {
  @Input()
  public visible: boolean = false;

  public styles(): UBCSSProperties | null {
    return this.visible
      ? null
      : {
          border: 0,
          clip: 'rect(0, 0, 0, 0)',
          height: '1px',
          margin: '-1px',
          opacity: 0,
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          visibility: 'hidden',
          width: '1px'
        };
  }
}
