import { Version, VERSION } from '../environments/version';

export const environment = {
  production: true,

  sentry: undefined,

  get version(): Version {
    return VERSION;
  },
};
