import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';

import { AuthService } from '@fry/lib/auth';
import { APIService } from "@fry/lib/api";
import { SuperType } from '@fry/lib/store';
import { Profile } from '@fry/lib/profile/profile';
import {User} from "@fry/lib/users";


@Injectable({
  providedIn: 'root'
})
export class ProfileStore {
  AssociatedModel = Profile;

  endpoint = 'users'
  docType = 'user';
  superType = SuperType.User;
  private _current: Observable<Profile> | undefined;

  constructor(
    private api: APIService,
    private auth: AuthService,
  ) {
    // Invalidate when current user changes
    this.auth.currentUser().pipe(
      switchMap(_ => {
        this.invalidateProfile();
        return this.getCurrent()
      })
    ).subscribe(profile => {
      console.log('just loaded', profile);
    });
  }

  public getCurrent(): Observable<Profile> {
    if (this._current) {
      return this._current;
    }

    this._current = this.api.get('account/profile').pipe(
      map(data => {
        return new User(data);
      }),

      // tap(data => console.log('Before', data.doc._id)),
      shareReplay({ refCount: false, bufferSize: 1 }),
      // tap(data => console.log('After', data.doc._id))
      catchError(err => {
        this._current = undefined;
        return throwError(err);
      })
    );
    return this._current;
  }

  private invalidateProfile(): void {
    this._current = undefined;
  }
}
