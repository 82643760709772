import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Message {
  topic: string;
  payload: any;
}

@Injectable()
export class MessagesService {

  private _events = new Subject<Message>();
  public events = this._events.asObservable();

  emit(topic: string, payload: any) {
    this._events.next({ topic, payload });
  }

  forTopic(topic: string) {
    return this.events.pipe(
      filter(data => data.topic === topic),
      map(data => data.payload),
    );
  }

  forTopics(topics: string[]) {
    return this.events.pipe(
      filter(data => topics.includes(data.topic)),
      map(data => data),
    );
  }

}
