import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as EASDialogError from '../dialog/dialog.error';
import { EasBuilderTransition } from './builder.interfaces';

@Component({
  selector: 'eas-builder-transition-confirmation',
  templateUrl: './transition-confirmation.component.html'
})
export class BuilderTransitionConfirmationComponent {

  public canceled = EASDialogError.UserCanceledError();

  public data: any;
  public transition: EasBuilderTransition;

  constructor(
    private dialogRef: MatDialogRef<BuilderTransitionConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) data: any = {}
  ) {
    this.data = data.data;
    this.transition = data.transition;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close(this.canceled);
  }

  getDefaultOkButtonTitle() {
    return $localize `Confirm`;
  }

  getDefaultCancelButtonTitle() {
    return $localize `Cancel`;
  }
}
