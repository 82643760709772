import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeComponent, TreeNode } from '@circlon/angular-tree-component';
import {
  TREE_DEFAULT_OPTIONS,
  TreeFormControl,
  TreeFormControlNode,
  TreeFormControlOptions
} from './tree-form-control';

@Component({
  selector: 'eas-tree-editor-form-control',
  templateUrl: './tree-editor-form-control.component.html',
  styleUrls: ['./tree-editor-form-control.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TreeEditorFormControlComponent),
    multi: true
  }]
})
export class TreeEditorFormControlComponent implements AfterViewInit, ControlValueAccessor {
  @Input()
  set options(options: TreeFormControlOptions) {
    this._options = {
      ...TREE_DEFAULT_OPTIONS,
      useTriState: true,
      allowsHierarchy: true,
      ...options};
  }
  get options() {
    return this._options;
  }
  private _options: TreeFormControlOptions;

  public value: TreeFormControlNode[];

  @ViewChild('tree', { static: true }) tree: TreeComponent;

  constructor() { }

  ngAfterViewInit() {
    if (!this.tree.treeModel.isEmptyTree()) {
      this.tree.treeModel.expandAll();
    }
  }

  //
  // ControlValueAccessor interface
  //

  private onChange = (_: any) => {};
  // private onTouch = () => {};

  writeValue(value: TreeFormControlNode[]) {
    this.value = value;
    this.tree.treeModel.update();
    this.onChange(this.value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched() {
    // this.onTouch = fn;
  }

  //
  // Angular Tree events
  //

  onAddRoot() {
    const node = TreeFormControl.emptyNode();
    this.writeValue([node]);
  }

  onAddSibling(node: TreeNode, tree: TreeComponent) {
    const newNode = TreeFormControl.emptyNode();
    if (node.isRoot === true) {
      this.value.push(newNode);
    } else {
      const childrenField = tree.treeModel.options.childrenField;
      node.parent.data[childrenField].push(newNode);
    }
    this.writeValue(this.value);
  }

  onAddChild(node: TreeNode, tree: TreeComponent) {
    if (!this.options.allowsHierarchy) {
      return;
    }

    const newNode = TreeFormControl.emptyNode();
    const childrenField = tree.treeModel.options.childrenField;
    if (node.isRoot === true) {
      if (node.data[childrenField] === undefined) {
        node.data[childrenField] = [];
      }
      node.data[childrenField].push(newNode);
    } else if (node.isLeaf) {
      node.data[childrenField] = [newNode];
    } else {
      node.data[childrenField].push(newNode);
    }
    this.writeValue(this.value);

    const id = newNode[tree.treeModel.options.idField];
    const n: TreeNode = tree.treeModel.getNodeById(id);
    n.expand();
    n.setActiveAndVisible();
  }

  onDelete(node: TreeNode, tree: TreeComponent) {
    const path: string[] = node.path.reverse();
    const idField = tree.treeModel.options.idField;
    const childrenField = tree.treeModel.options.childrenField;
    let nodes: any[] = this.value;
    while (path.length > 0) {
      const id: string = path.pop();
      const idx = nodes.findIndex(n => n[idField] === id);
      if (idx > -1 && path.length === 0) {
        nodes.splice(idx, 1);
      } else {
        nodes = nodes[idx][childrenField];
      }
    }
    this.writeValue(this.value);
  }

  //
  // Angular element events
  //
  onInputUpdate(value: string, node: TreeNode) {
    node.data.name = value;
    this.writeValue(this.value);
  }
}
