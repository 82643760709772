import * as CSS from 'csstype';

export interface UBErrorMessages {
  [key: string]: (value: unknown) => string;
}

export enum UBPlacement {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom'
}

export type UBAriaLiveRegionPriority = 'off' | 'assertive' | 'polite';

export type UBGridDirection = 'column' | 'row';

export interface UBCSSProperties extends CSS.Properties, CSS.PropertiesHyphen {}

export interface UBStep {
  title: string;
  steps?: UBStep[];
  done: boolean;
  next: boolean;
  expanded?: boolean;
}

export interface UBPager {
  page: number;
  title: string;
}

export type UBAppearance =
  | 'primary'
  | 'accent'
  | 'warn'
  | 'success'
  | 'danger'
  | 'none';

export type UBListType = 'inline' | 'block';

export type UBDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';

export type UBSize = 'sm' | 'lg';

export interface UBViewportScrollPosition {
  top: number;
  left: number;
}

export interface UBViewportSize {
  width: number;
  height: number;
}

export interface UBPositionCollisions {
  top: boolean;
  bottom: boolean;
  left: boolean;
  right: boolean;
}

export interface UBRect {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

export interface UBTypeaheadCollection {
  hash?: number; // We use this internally to figure out if a result has changed
  status?: number; // If we are making an async request to a server, we should only accept 200
  value: string;
}
