import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeComponent } from '@circlon/angular-tree-component';
import { TREE_DEFAULT_OPTIONS, TreeFormControlNode, TreeFormControlOptions } from './tree-form-control';

@Component({
  selector: 'eas-tree-selector-form-control',
  templateUrl: './tree-selector-form-control.component.html',
  styleUrls: ['./tree-selector-form-control.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TreeSelectorFormControlComponent),
    multi: true
  }]
})
export class TreeSelectorFormControlComponent implements AfterViewInit, ControlValueAccessor {
  @Input() nodes: TreeFormControlNode[];

  @Input()
  set options(options: TreeFormControlOptions) {
    this._options = {...TREE_DEFAULT_OPTIONS, useCheckbox: true, ...options};
  }
  get options() {
    return this._options;
  }
  private _options: TreeFormControlOptions;

  public value: string[];

  @ViewChild('tree', { static: true }) tree: TreeComponent;

  constructor() { }

  ngAfterViewInit() {
    if (!this.tree.treeModel.isEmptyTree()) {
      this.tree.treeModel.expandAll();
    }
  }

  //
  // ControlValueAccessor interface
  //

  private onChange = (_: any) => {};
  // private onTouch = () => {};

  writeValue(value: string[]) {
    this.value = value;
    this.onChange(this.value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched() {
    // this.onTouch = fn;
  }

  //
  // Angular Tree events
  //
  onSelection($event) {
    const nodes = $event.treeModel.getState().selectedLeafNodeIds;
    const selected = Object.keys(nodes)
                           .filter(key => nodes[key] === true)
                           .reduce((res, key) => [...res, key], []);
    this.writeValue(selected);
  }

}
