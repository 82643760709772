import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ActionMapping } from './auditlog.mapping';


@Component({
  selector: 'eas-auditlog',
  templateUrl: './auditlog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditLogComponent implements OnChanges {
  @Input() dates: any[];

  public logs: any[];
  public mapping: any = ActionMapping;
  show = false;

  constructor(
  ) { }

  ngOnChanges() {
    this.updateLogs();
  }

  updateLogs() {
    this.logs = this.dates
      ? this.dates.reverse() // .splice(0, 5);
      : [];
  }

  getButtonAction() {
    return this.show ? $localize `Hide` : $localize `Show`;
  }
}
