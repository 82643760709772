import { Component, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-form-control',
  exportAs: 'unboxedFormControl',
  styleUrls: ['../vars.css', '../common.css'],
  templateUrl: 'form-control.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedFormControl extends UnboxedBaseFormComponent {}
