import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { RolesStore } from '@fry/lib/roles';
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'eas-roles-title',
  styleUrls: ['./roles-titles.css'],
  templateUrl: 'roles.titles.html'
})
export class RolesTitleComponent implements OnChanges, OnDestroy {
  @Input() roles: string[];

  private _roleTitles = new BehaviorSubject<string[]>([]);
  public roleTitles = this._roleTitles.asObservable();

  private unsubscribe: Subject<void> = new Subject();

  constructor(private rolesStore: RolesStore) {}

  ngOnChanges() {
    this.getTitles();
  }

  private getTitles() {
    this.rolesStore
      .someWithSystem(this.roles, { hidden: false })
      .pipe(
        map(roles => roles.map(itm => itm.doc.title)),
        takeUntil(this.unsubscribe)
      )
      .subscribe(titles => this._roleTitles.next(titles));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
