import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ub-filter-list',
  exportAs: 'unboxedFilterList',
  styleUrls: ['./filter-list.css'],
  templateUrl: 'filter-list.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedFilterList {}
