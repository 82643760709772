import { Observable } from 'rxjs';

import { BaseModel } from '@fry/lib/store/base-model';
import { BackendStoreService, StoreType } from '@fry/lib/store/store.interface';
import { SearchStore } from '@fry/lib/store/search.store';


export abstract class BackendStore<T extends BaseModel> extends SearchStore<T> implements BackendStoreService<T> {
  abstract storeType: StoreType;

  abstract get(id: string): Observable<T>;
  abstract getAuditLog(id: string): Observable<any[]>;
  abstract all(): Observable<T[]>;
  abstract save(doc: any): Observable<T>;
  abstract remove(id: string): Observable<any>;

  abstract one(): Observable<T>;
  abstract some(ids: string[]): Observable<T[]>;
}
