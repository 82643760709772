import { Pipe, PipeTransform, Type, ɵstringify as stringify, } from '@angular/core';
import { format, formatDistanceToNow, parseISO, subSeconds } from 'date-fns';
import enGB from 'date-fns/locale/en-GB/index';

function invalidPipeArgumentError(type: Type<any>, value: Object) {
  return Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(type)}'`);
}

@Pipe({ name: 'easDate' })
export class EASDateFormatPipe implements PipeTransform {
    constructor() { }

    transform(value: Date, customFormat: string = 'd MMMM yyyy HH:mm:ss'): string|null {
        if (value == null || value === undefined) { return null; }

        try {
            const options = { locale: enGB };
            return format(parseISO(value.toString()), customFormat, options);
        } catch (error) {
            throw invalidPipeArgumentError(EASDateFormatPipe, error.message);
        }
    }
}

// tslint:disable-next-line:max-classes-per-file
@Pipe({ name: 'easDateFromNow' })
export class EASDateFromNowPipe implements PipeTransform {

    constructor() {}

    transform(value: Date | number): string|null {
        if (value == null || value === undefined) { return null; }

        const formatOptions = { locale: enGB, includeSeconds: true };

        try {
            switch (typeof value) {
                case 'number':
                    const date = subSeconds(new Date(), value);
                    return formatDistanceToNow(date, formatOptions);
                default:
                    return formatDistanceToNow(parseISO(value.toString()), formatOptions);
            }
        } catch (error) {
            throw invalidPipeArgumentError(EASDateFromNowPipe, error.message);
        }
    }
}
