import { Observable } from 'rxjs';
import { FileUpload, FileUploadEvent } from './file-upload';

export interface FileUploadContext {
    oid: string;
}

export abstract class FileUploadControlComponentDelegate {
    abstract setContext(context: FileUploadContext): void;
    abstract fileUploadDidRequestDownloadURLFor(upload: FileUpload): Observable<URL>;
    abstract fileUploadDidRequestUploadFor(uploads: FileUpload[]): Observable<FileUploadEvent>;
}
