import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@fry/lib/auth';

@Component({
  selector: 'eas-auth-login-component',
  templateUrl: 'auth-login-popup.component.html',
})
export class AuthLoginPopupComponent {

  public submitted = false;

  constructor(
    public dialogRef: MatDialogRef<AuthLoginPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
  ) { }

  async login() {
    await this.authService.startLoginPopup();
    this.dialogRef.close();
  }

  logout() {
    this.authService.startLogout();
  }
}
