import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpEventType, HttpResponse, } from '@angular/common/http';
import { from, Observable, Subject } from 'rxjs';
import { concatMap, finalize, map, mergeAll, tap } from 'rxjs/operators';

import { APIService } from '@fry/lib/api';
import { FileUpload, FileUploadEvent } from './file-upload';
import { FileUploadStore } from './file-upload-store';

@Injectable()
export class S3PresignedFileUploadStore extends FileUploadStore {

    private api: APIService;
    private plainHttpClient: HttpClient;

    constructor(api: APIService, httpBackend: HttpBackend) {
        super();
        this.api = api;
        this.plainHttpClient = new HttpClient(httpBackend);
    }

    public downloadURLFor(upload: FileUpload, metadata: any): Observable<URL> {
        const params = {
            'file_info': upload.toJSON(),
            'metadata': metadata
        };
        return this.api.post('documents/get_download_token', params);
    }

    public upload(uploads: FileUpload[]): Observable<FileUploadEvent> {
        this._state = 'pending';

        return from(uploads)
               .pipe(
                   map(u => this.uploadSingle(u)),
                   mergeAll(),
                   tap(() => this._state = 'uploading'),
                   finalize(() => { this._state = 'idle'; })
                );
    }

    private uploadSingle(upload: FileUpload): Observable<FileUploadEvent> {
        const subject = new Subject<FileUploadEvent>();

        this.getSignedURL(upload).pipe(
            concatMap(data => {
                upload.vendor = 's3';
                upload.key = data.key;
                upload.fields = data.fields;
                upload.location = new URL(data.url);
                return this.uploadToPresignedS3(upload, data.url);
            })
        ).subscribe(
            event => {
                if (event.type === HttpEventType.UploadProgress) {
                    upload.setProgress(Math.round(100 * event.loaded / event.total));
                    subject.next({ 'state': upload.state, 'upload': upload });
                } else if (event instanceof HttpResponse) {
                    upload.setComplete(upload.key, upload.location);
                    subject.next({ 'state': upload.state, 'upload': upload });
                }
            },
            error => {
                console.log(error);
                upload.setFailed(error, upload.key, upload.location);
                subject.next({ 'state': upload.state, 'upload': upload });
                subject.error(error);
            },
            () => {
                console.log('Completed');
                subject.next({ 'state': upload.state, 'upload': upload });
                subject.complete();
            }
        );

        return subject;
    }

    public cancel() {}

    //
    // Helpers
    //

    private getSignedURL(upload: FileUpload): Observable<{url: string; key: string; fields: {[key:string]: string}}> {
        const params = {
            'file_extension': upload.filename.split('.').pop(),
            'file_size': upload.size
        };
        return this.api.post('documents/get_upload_token', params);
    }
/*
    private getSignedData(upload: FileUpload): Observable<any> {
        const params = {
            Bucket: S3FileUploadStore.S3_BUCKET_NAME,
            Fields: { key: upload.file.name }
        };
        this.s3.createPresignedPost(params, (err, data) => {
          if (err) {
            console.error('Presigning post data encountered an error', err);
          } else {
            console.log('The post data is', data);
          }
        });
    }
*/
    private uploadToPresignedS3(upload: FileUpload, url: string) {
        const options = {
            reportProgress: true,
            observe: 'events' as 'events',
            // headers: new HttpHeaders({
                // 'Content-Type': 'application/octet-stream',
                // 'x-amz-tagging': 'com.fryit.eb.upload.expires=true'
            // })
        };

        var formData = new FormData();
        for (let [key, value] of Object.entries(upload.fields)) {
            formData.append(key, value);
        }
        formData.append('file', upload.file);
        return this.plainHttpClient.post(url, formData, options);
    }

//    private buildFormData(upload: FileUpload): FormData {
        /*
        // It is important to do this first as order is significant!
        // 'key' field must be first
        Object.keys(signature.fields).forEach(key => {
            formData.append(key, signature.fields[key]);
        });
        */

//        const formData = new FormData();
//        formData.append('file', upload.file);
//        return formData;
//    }
}
