import { Injectable } from '@angular/core';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIService } from '@fry/lib/api';
import { ApiStore, SearchType, SuperType } from '@fry/lib/store';

import { Role } from './role';
import { SYSTEM_ROLES } from './roles.system';


@Injectable({
  providedIn: 'root'
})
export class RolesStore extends ApiStore<Role> {
  AssociatedModel = Role;

  docType = 'role';
  endpoint = 'roles';
  superType = SuperType.Org;
  searchType = SearchType.LOCAL;

  constructor(api: APIService) {
    super(api);
  }

  allWithSystem(opts?: { hidden?: boolean, system?: boolean }) {
    const options = {...(opts || {})};
    return this.all().pipe(
      map(roles => {
        const system = SYSTEM_ROLES.filter(role => {
          if (options.hidden !== undefined && role.doc.hidden !== options.hidden) {
            return false;
          }
          if (options.system !== undefined && role.doc.system !== options.system) {
            return false;
          }
          return true;
        });
        return [...roles, ...(system.map(itm => this.createObject(itm.doc)))];
      })
    );
  }

  public someWithSystem(ids: string[], options?: { hidden?: boolean, system?: boolean }): Observable<Role[]> {
    return this.allWithSystem(options)
      .pipe(
        map(items => items.filter(item => ids.indexOf(item.doc['_id']) !== -1))
      );
  }

  public findAllDependent() {
    return this.all().pipe(
      map(roles => {
        return roles.filter(r => {
          return r.doc.isUserDependent;
        });
      })
    );
  }

  public withPermission(permission: string): Observable<string[]> {
    return this.byPermission().pipe(
      map(data => {
        return data[permission] || [];
      })
    );
  }

  public withPermissions(permissions: string[]): Observable<string[]> {
    return this.byPermission().pipe(
      map(data => {
        const res = permissions.reduce((cum, cur) => {
          cum = cum.concat(data[cur] || []);
          return cum;
        }, []);

        return uniq(res);
      })
    );
  }

  private byPermission() {
    return this.all().pipe(
      map(roles => {
        const byPerm: any = {};
        roles.forEach(role => {
          (role.doc.permissions || []).forEach((perm: string) => {
            if (byPerm[perm] === undefined) {
              byPerm[perm] = [];
            }

            byPerm[perm].push(role.doc._id);
          });
        });
        return byPerm;
      })
    );
  }
}
