import { Injectable } from '@angular/core';

import { ApiStore, DBDoc, SearchType, SuperType } from '@fry/lib/store';
import { APIService } from '@fry/lib/api';
import { ExtendedWorkflow, Workflow } from './workflow';
import { cloneDeep } from 'lodash';
import { guid } from '@fry/lib/utils';


@Injectable({
  providedIn: 'root'
})
export class WorkflowStore extends ApiStore<Workflow> {
  AssociatedModel = Workflow;

  docType = 'workflow';
  endpoint = 'workflow';
  superType = SuperType.Org;
  searchType = SearchType.LOCAL;

  constructor(api: APIService) {
    super(api);
  }

  createObject(doc: DBDoc | ExtendedWorkflow): Workflow {
    if (!doc.usage) {
      return new this.AssociatedModel(doc as DBDoc);
    }

    return new this.AssociatedModel(doc.doc as DBDoc, doc as ExtendedWorkflow);
  }

  duplicate(orig: Workflow): Workflow {
    const doc = cloneDeep(orig.doc);
    doc._id = guid();
    doc.name = `Copy of ${doc.name}`;
    delete doc._rev;
    return this.createObject(doc);
  }
}
