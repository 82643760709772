import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export class DashboardCard {
  static metadata: {[K: string]: InjectionToken<any>} = {
    NAME: new InjectionToken<string>('name'),
    COLS: new InjectionToken<Observable<number>>('cols'),
    ROWS: new InjectionToken<Observable<number>>('rows'),
    OPTIONS: new InjectionToken<any>('options')
  };

  constructor(private _input: {
      name: {
        key: InjectionToken<string>,
        value: string
      },
      cols?: {
        key: InjectionToken<Observable<number>>,
        value: Observable<number>
      },
      rows?: {
        key: InjectionToken<Observable<number>>,
        value: Observable<number>
      },
      options: {
        key: InjectionToken<any>,
        value: any
      }
    }, private _component: any) {
  }

  get input() {
    return this._input;
  }

  get component(): any {
    return this._component;
  }
}
