const actionMapping = {
  add_credential: $localize `credential added`,
  remove_credential: $localize `credential removed`,
  edit_credential_username: $localize `credential updated`,
  edit_credential_password: $localize `password updated`,

  pin_updated: $localize `PIN updated`,
  document_created: $localize `uploaded document`,
  document_delete: $localize `deleted document`,
  event_created: $localize `created event`,
  request_created: $localize `sent invitation`,
  section_assign: $localize `assigned section`,
  section_close: $localize `closed section`,
  section_closegoal: $localize `closed goal set`,
  section_edit: $localize `edited section`,
  section_import: $localize `imported response`,
  section_invite: $localize `sent invitation`,  // This is not used likely as it does request_created
  section_reject: $localize `rejected invitation`,
  section_remind: $localize `sent reminder`,
  section_reopen: $localize `re-opened section`,
  section_retract: $localize `retracted invitation`,
  section_publish: $localize `published section`,
  section_approve: $localize `approved section`,
  response_publish: $localize `submitted response`,
  response_approve: $localize `approved response`,
  pending_section_added: $localize `published section for approval`,
  pending_response_added: $localize `submitted response for approval`,
  goal_reopen: $localize `re-opened goal set`,
  make_private: $localize `set visibility to private`,
  make_public: $localize `set visibility to shared`,
  make_hidden: $localize `hid from navigation`,
  make_shown: $localize `made visible in navigation`,
  user_create: $localize `created user`,
  rcpch_user_create: $localize `created user`,
  user_disable: $localize `disabled user`,
  user_enable: $localize `enabled user`,
  user_edit: $localize `edited profile`,
  extra_commented: $localize `commented`,
  extra_document: $localize `attached a document`,
  extra_documents: $localize `attached documents`,
  extra_tag: $localize `added a tag`,
  extra_tags: $localize `added tags`,
  extra_document_tag: $localize `attached a document and added a tag`,
  extra_documents_tag: $localize `attached documents and added a tag`,
  extra_document_tags: $localize `attached a document and added tags`,
  extra_documents_tags: $localize `attached documents and added tags`,
  celerytask_edit: $localize `modified`,
  goals_add: $localize `added goal`,
  goal_update: $localize `updated goal`,

  // New
  user_approve: $localize `approved user`,
  invited: $localize `invited`,
  reminded: $localize `reminded`,

  created: $localize `created`,  // Anything else event types, roles...
  modified: $localize `modified`, // Anything else
  deleted: $localize `deleted`,
  published: $localize `published`,
  unpublished: $localize `unpublished`,  // Announcements
  archived: $localize `archived`,

  event_edit: $localize `edited`, // I'm not sure what we said about this - but it could be used in theory
  response_edit: $localize `edited`,
  ms_response_delete: $localize `response deleted`,
};

export const ActionMapping = {
  ...actionMapping,
  // Comes from old, here just to make sure we translate them ok
  edited: actionMapping.event_edit,
  commented: actionMapping.extra_commented,
  retracted: actionMapping.section_retract,
  rejected: actionMapping.section_reject,
  imported: actionMapping.section_import,
  'section completed': actionMapping.section_publish,
  'added tags': actionMapping.extra_tags,
  'added a tag': actionMapping.extra_tag,
  'made private': actionMapping.make_private,
  'section closed': actionMapping.section_close,
  'section submitted and waiting for admin approval': actionMapping.pending_section_added,
  'added comment': actionMapping.extra_commented,
  'attached a document': actionMapping.extra_document,
  'attached documents': actionMapping.extra_documents,
  'made public': actionMapping.make_public,
  'section re-opened': actionMapping.section_reopen,
  'reverted section': $localize `reverted section`, // I think this one was manual revert
  'deleted comment': $localize `deleted comment` // Has this been implemented?
};
