//
// Local storage support
//

export type EASBrowserStorageType = 'localStorage' | 'sessionStorage';

export class EASBrowserStorageService {
  public type: EASBrowserStorageType;
  public storage: Storage;

  public constructor(type: EASBrowserStorageType) {
    let instance;
    try {
      this.type = type;
      instance = window[type];
      const x = '__storage_test__';
      instance.setItem(x, x);
      instance.removeItem(x);
      this.storage = instance;
    } catch (error) {
      const supported = error instanceof DOMException && (
                        // everything except Firefox
                        error.code === 22 ||
                        // Firefox
                        error.code === 1014 ||
                        // test name field too, because code might not be present
                        // everything except Firefox
                        error.name === 'QuotaExceededError' ||
                        // Firefox
                        error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                        // acknowledge QuotaExceededError only if there's something already stored
                        (instance && instance.length !== 0);
      if (!supported) {
        throw new Error('Browser storage not supported!');
      }
    }
  }

  public static storageKey(key: string, prefix: string = ''): string {
    return prefix.length > 0 ? `${prefix}_${key}` : key;
  }

  public getItem(key: string): string {
    if (key === undefined || key === null) {
      throw new Error('Key must be specified in order to retreive value!');
    }
    return this.storage.getItem(key);
  }

  public setItem(key: string, value: string) {
    if (key === undefined || key === null) {
      throw new Error('Key must be specified in order to store value!');
    }
    this.storage.setItem(key, value);
  }

  public getArray(key: string): string[] {
    const result = this.getItem(key);
    if (!result) { return undefined; }
    return result.split(',');
  }

  public setArray(key: string, array: string[]) {
    this.setItem(key, array.join(','));
  }
}
