import { Component, Input } from '@angular/core';
import { LoaderState, LoaderStateType } from './loader.directive';

@Component({
  selector: 'eas-loader',
  template: `<ub-box *ngIf="isLoading()" rounded="true">
              <div content i18n>Loading, please wait...</div>
            </ub-box>
            <ub-box *ngIf="isWaiting()" rounded="true">
              <div content i18n>Waiting, please wait...</div>
            </ub-box>
            <ub-box *ngIf="isError()" rounded="true">
              <div content i18n>{{ state.error?.message }} {{ state.error }}</div>
            </ub-box>`
})
export class LoaderComponent {
  @Input() state: LoaderState;

  constructor() { }

  //
  // Template helpers
  //

  public isError(): boolean {
    return this.hasState('error');
  }

  public isLoading(): boolean {
    return this.hasState('loading');
  }

  public isWaiting(): boolean {
    return this.hasState('waiting');
  }

  public isEmpty(): boolean {
    return this.hasState('empty');
  }

  public isFull(): boolean {
    return this.hasState('ready');
  }

  private hasState(state: LoaderStateType): boolean {
    return this.state.state === state;
  }
}
