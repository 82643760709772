import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DashboardCard } from './dashboard-card';
import { BehaviorSubject } from 'rxjs';

export const DASHBOARD_CARDS = new InjectionToken('DASHBOARD_CARDS');

export interface CardData {
  name: string;
  options: any;
  component: string;
}

@Injectable()
export class DashboardCardsService {

  private availableCards = {};

  constructor(
    @Optional() @Inject(DASHBOARD_CARDS) available
  ) {
    if (available) {
      available.forEach(items => {
        items.forEach(item => this.availableCards[item.key] = item);
      });
    }
  }

  private _cards: BehaviorSubject<DashboardCard[]> = new BehaviorSubject<DashboardCard[]>([]);

  buildCard(card: CardData): DashboardCard {
    return new DashboardCard(
      {
        name: {
          key: DashboardCard.metadata.NAME,
          value: card.name
        },
        options: {
          key: DashboardCard.metadata.OPTIONS,
          value: card.options
        }
      }, this.availableCards[card.component]
    );
  }

  addCard(card: CardData) {
    this._addCard(this.buildCard(card));
  }

  addCards(cards: CardData[]) {
    cards.forEach(card => this.addCard(card));
  }

  private _addCard(card: DashboardCard): void {
    this._cards.next(this._cards.getValue().concat(card));
  }

  get cards(): BehaviorSubject<DashboardCard[]> {
    return this._cards;
  }
}
