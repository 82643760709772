import { forkJoin, Observable, of, ReplaySubject, timer } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { AuthService } from '@fry/lib/auth';
import { ComponentMenuService, MenuItem } from './menu.interface';
import { SecurityService } from '@fry/lib/security';


export abstract class BaseMenuService implements ComponentMenuService {
    // Using replay subject so that anyone who subscribes get the latest
    // value but waits for it if not yet available
    //
    // This is unlike the BehaviorSubject which has initial value
    // The point is that the MenuService emits for the first time only
    // after all subscribers have emitted
    private _routes = new ReplaySubject<MenuItem[]>(1);

    constructor(
        protected auth: AuthService,
        protected security: SecurityService
    ) {
        // When to start updating?
        timer(10).subscribe(() => this.startUpdating());
    }

    public startUpdating() {
        this.auth.currentUser().subscribe((data) => {
            if (data.state === 'full') {
                this.updateMenu();
            }
        });
    }

    /**
     * A convenient observable individuals may subscribe to
     *
     * We do not expose the Subject itself so that consumers can
     * only subscribe but not emit anything
     */
    public get routes(): Observable<MenuItem[]> {
        return this._routes.asObservable();
    }

    protected updateMenu() {
        this.cook().subscribe(routes => {
            this.publishMenu(routes);
        });
    }

    protected publishMenu(menuItems: MenuItem[]) {
        this._routes.next(menuItems);
    }

    /**
     * Build the actual menu. This is used when the menu is re-generating
     * for example when the current user changes.
     *
     * This method should construct the whole menu and is emitted
     * as soon is it resolves.
     */
    protected cook(): Observable<MenuItem[]> {
        throw new Error('Override me');
    }

    /**
     * A helper method to apply given permission for given menu items
     *
     * If the user does not have the permission it emits an empty list
     * effectively hiding it from the menu
     *
     * @param menuItems
     * @param permission
     */
    protected menuWithPermission(menuItems: MenuItem[], permission: string): Observable<MenuItem[]> {
        return this.security.withPermission(permission)
            .pipe(
                map(() => {
                    return menuItems;
                }),
                catchError(() => {
                    return of([]);
                })
            );
    }

    /**
     * Merge observable menu items into one long array
     *
     * This is a helper method to keep the individual menus clean
     *
     * @param args Observable MenuItem[]
     */
    protected mergeItems(...args: Observable<MenuItem[]>[]): Observable<MenuItem[]> {
        return forkJoin(args)
            .pipe(
                map((data) => {
                    return [].concat(...data);
                })
            );
    }
}
