import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Venue } from './venue';
import { VenuesStore } from './venues.store';

@Injectable()
export class VenueResolver implements Resolve<Venue> {

    constructor(private store: VenuesStore) { }

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Venue> {
        const id = route.paramMap.get('id');
        return this.store.getForEdit(id);
    }
}
