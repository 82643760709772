import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectableListGroup } from './selectable-list.presentable';
import { SelectableListOptions, SelectableListService, SelectableListStyle } from './selectable-list.service';

/**
 * Selectable list
 *
 * This component implements a selectable list of items as custom Angular
 * FormField.
 *
 * Component provides filtering of the list items as well as support for grouped
 * or plain list of items and custom sorting.
 *
 * Component accepts data via `items` and settings via `options` inputs.
 *
 * @example
 * [
 *   { title: 'Group', items: [
 *     { id: 'a134b', title: 'Add', subtitle: 'Adds stuff'}
 *   ]}
 * ]
 */
@Component({
  selector: 'eas-selectable-list',
  templateUrl: './selectable-list.component.html',
  styleUrls: ['./selectable-list.component.css'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectableListComponent),
      multi: true
    }]
})
export class SelectableListComponent implements OnInit, OnDestroy, ControlValueAccessor {
  // Seems there is no other way to propagate the Enum to template
  SelectableListStyle = SelectableListStyle;

  /**
   * Data to be presented
   *
   * We expect to have at least one Group in the input array or many.
   */
  @Input() items: SelectableListGroup[] = [];

  /**
   * Options
   *
   * Form more on options see `SelectableListOptions`.
   */
  @Input() options!: SelectableListOptions;
  private defaultOptions: SelectableListOptions = {
    allowsFiltering: true,
    allowsMultipleSelection: true,
    allowsItemReordering: false
  };

  public filter?: UntypedFormControl;

  public list!: SelectableListService;

  private destroyed$: Subject<void> = new Subject();

  constructor() { }

  //
  // Lifecycle
  //

  ngOnInit(): void {
    this.options = {...this.defaultOptions, ...this.options};

    this.list = new SelectableListService(this.items, [], this.options);

    if (!!this.options.allowsFiltering) {
      this.filter = new UntypedFormControl('');
      this.filter
          .valueChanges
          .pipe(takeUntil(this.destroyed$))
          .subscribe((query: string) => this.list.applyVisibleItemsFilter(query));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  //
  // ControlValueAccessor interface
  //

  private onChange = (_: any) => {};
  // private onTouch = () => {};

  writeValue(value: string[]) {
    this.list.value = value;
    this.onChange(this.list.value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched() {
    // this.onTouch = fn;
  }

  //
  // Template helpers
  //

  public isReorderingSupported(): boolean {
    return this.list.allowsItemReordering && this.list.isItemReorderingSupported;
  }

  //
  // Form events (material, etc...)
  //

  onSelectionChange(event: MatSelectionListChange) {
    event.options.forEach(
      option => this.list.patchValue(option.value as string)
    );
    this.onChange(this.list.value);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    this.list.moveItem(event.previousIndex, event.currentIndex);
    this.onChange(this.list.value);
  }
}
