import { Injectable } from '@angular/core';

import { ApiStore, SearchType, SuperType } from '@fry/lib/store';

import { APIService } from '@fry/lib/api';
import { Booking } from './booking';
import { AuthService } from '@fry/lib/auth';
import { map, switchMap } from 'rxjs/operators';
import { EasBuilderTransition } from '@fry/components/common/form-builder/builder.interfaces';
import { Observable } from 'rxjs';

export interface BackendActionResultDetail {
  status: string;
  msg: string;
}

export interface BackendActionResultObjectReference {
  rev: string;
  id: string;
}

export interface BackendActionResult {
  status: string;
  message: string;
  result: BackendActionResultObjectReference;
  detail?: BackendActionResultDetail[];
}

export interface UserBookingStoreActionResult {
  booking: Booking;
  result: BackendActionResult;
}

@Injectable({
  providedIn: 'root'
})
export class UserBookingsStore extends ApiStore<Booking> {
  AssociatedModel = Booking;

  docType = 'booking';
  endpoint = 'user-bookings';
  superType = SuperType.User;
  searchType = SearchType.REMOTE;

  constructor(
    api: APIService,
    auth: AuthService,
  ) {
    super(api);
    auth.currentUser().subscribe(current => {
      this.endpoint = `user-bookings/${current.username}`;
    });
  }

  createObject(doc: any): Booking {
    if (doc.bookingOption && doc.bookingOption.id) {
      return new this.AssociatedModel(doc.doc, doc);
    }

    return new this.AssociatedModel(doc);
  }

  update(id: string, rev: string, data: any) {
    const payload = { rev, data };
    return this.api.post(`${this.endpoint}/${id}/update`, payload).pipe(
      switchMap(() => this.get(id)),
    );
  }

  apply(bookingItem: string, bookingOption: string, options) {
    options = options || {};
    const payload = {
      bookingItem,
      bookingOption,
      ignoreEligibility: !!options.ignoreEligibility,
    };
    return this.api.post(`${this.endpoint}/apply`, payload);
  }

  transition(id: string, transition: string, data: any): Observable<UserBookingStoreActionResult> {
    const payload = { transition, data };
    return this.api.post(`${this.endpoint}/${id}/transition`, payload).pipe(
      switchMap(result => {
        return this.get(id).pipe(map(booking => ({booking, result})));
      })
    );
  }

  customTransition(id: string, transition: EasBuilderTransition, data: any) {
    const payload = {
      transition: '__custom__',
      transitionDefinition: transition,
      data,
    };
    return this.api.post(`${this.endpoint}/${id}/transition`, payload).pipe(
      switchMap(() => this.get(id)),
    );
  }
}
