import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormField } from './forms.interface';
import { FormData } from './form-data';
import { pick } from '@fry/lib/utils/common';

export abstract class BaseForm {
    constructor(
        private fb: UntypedFormBuilder
    ) { }

    protected _fields: FormField[] = [];

    public getFields(): Observable<FormField[]> {
        return of(this._fields);
    }

    public buildForm() {
        return this.getFields()
            .pipe(
                map(fields => {
                    const group = {};
                    fields.forEach(item => {
                        group[item.id] = new UntypedFormControl(item.initial, item.validators);
                    });
                    return {
                        fields,
                        form: this.fb.group(group)
                    };
                })
            );
    }

    public getForm(/*options?*/): Observable<FormData> {
        // options = options || {};
        return this.buildForm().pipe(
            map(data => {
                // const toForm = options.toForm ? options.toForm : this.toForm();
                // const toObject = options.toObject ? options.toObject : this.toObject();
                return new FormData(
                    this.fb,
                    data.form,
                    data.fields,
                    {}
                );
            })
        );
    }

    public toForm(): (model, form: FormData) => any {
        return (model, form) => {
            const ids = form.fields.map(fld => fld.id);
            return pick(model, ids);        };
    }

    public toObject(): (value, model, form: FormData) => any {
        return (value, model) => {
            Object.assign(model, value);
        };
    }
}
