import { DBDoc, Model } from '@fry/lib/store/store.interface';


export class BaseModel implements Model {
  public dates: any;
  public id: string;
  public orig: DBDoc;

  protected _doc: DBDoc;
  public get doc(): DBDoc {
    this.patch();
    return this._doc;
  }
  public set doc(value: DBDoc) {
    this._doc = value;
  }

  constructor(doc: DBDoc, _nonDocProperties?: any) {
    this.orig = doc;
    this.id = this.orig._id;
    this.init();
  }

  protected init() {
    this.doc = this.orig;
    this.dates = this.orig.dates;
  }

  /**
   * Patches internal this._doc
   *
   * Entry point for subclasses to patch underlying DBDoc this._doc with values
   * for the properties which are serialised, in case classes implement concrete
   * property types.
   *
   * This method will be called at any type calling BaseModel.doc accessor.
   */
  protected patch() { }

  isNew() {
    return !this.doc._rev;
  }
}
