import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Permission, PermissionGroup } from './permissions.interfaces';
import { intersect, LoggerService } from '@fry/lib/utils';
import { map, reduce, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrganisationService } from '@fry/lib/organisations';

export const PERMISSIONS = new InjectionToken('PERMISSIONS');

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    @Optional() @Inject(PERMISSIONS) private _permissions: PermissionGroup[],
    private organisation: OrganisationService,
    private logger: LoggerService,
   ) {
    if (!this._permissions) {
      this._permissions = [];
    }
    this.logger.info('PermissionsService: Registered permissions for: ', this._permissions.map(item => item.name).join(', '));
  }

  private getAllowedComponentNames(): Observable<string[]> {
    return this.organisation.getAllowedComponents().pipe(
      map(allowed => {
        if (allowed === undefined) {
          return this._permissions.map(({ id }) => id);
        }

        return allowed;
      })
    );
  }

  private getAllowedComponents(): Observable<PermissionGroup[]> {
    return this.getAllowedComponentNames().pipe(
      map(components => {
        return this._permissions
                   .filter(component => components.indexOf(component.id) !== -1);
      })
    );
  }

  get flatPermissions(): Observable<Permission[]> {
    return this.getAllowedComponents().pipe(
      map(components => components.map(component => component.permissions)),
      reduce((acc: Permission[], permissions: Permission[][]) => {
        return acc.concat(...permissions);
      }, [])
    );
  }

  get flatPermissionIDs(): Observable<string[]> {
    return this.flatPermissions.pipe(
      map(permissions => permissions.map(({ id }) => id))
    );
  }

  get availableComponents(): Observable<PermissionGroup[]> {
    return this.getAllowedComponents();
  }

  isAvailable(permission: string | string[]): Observable<boolean> {
    return this.flatPermissionIDs.pipe(
      map(perms => {
        if (Array.isArray(permission)) {
          return intersect(perms, permission).length > 0;
        } else {
          return perms.indexOf(permission) !== -1;
        }
      }),
    );
  }

  withAvailable(permission: string | string[]) {
    return this.isAvailable(permission).pipe(
      tap(valid => {
        if (!valid) {
          throw new Error(`Unknown permission ${permission}`);
        }
      })
    );
  }
}
