import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RelationsStore } from '@fry/lib/relations';

@Component({
  selector: 'eas-relation-title',
  template: '{{ title$ | async }}',
})
export class RelationTitleComponent implements OnInit {
  @Input() id: string;

  title$: Observable<string>;

  constructor(private relations: RelationsStore) {}

  ngOnInit() {
    console.log('Chagnes');
    this.getTitles();
  }

  private getTitles() {
    this.title$ = this.relations.get(this.id).pipe(
      map(itm => itm.doc.name)
    );
  }
}
