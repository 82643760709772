import { Component } from '@angular/core';
import { ListService } from '@fry/lib/list';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-list-paginator',
  templateUrl: './list.paginator.component.html',
  providers: [
  ]
})
export class ListPaginatorComponent {

  pagesCount: Observable<number>;
  currentPage: Observable<number>;

  constructor(
    private list: ListService,
  ) {
    this.pagesCount = this.list.totalCount$.pipe(
      map(total => Math.ceil(total / this.list.pageSize))
    );
    this.currentPage = this.list.currentPage;
  }

  onPageSelected(page: number) {
    return this.list.goToPage(page);
  }
}
