import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { iCaseCompare } from "@fry/lib/utils";
import { OrganisationService } from "@fry/lib/organisations";
import { PaymentsError } from "@fry/payments/lib/errors";

/**
 * CURRENCIES
 *
 * We could possibly to use ts-money for this:
 * https://github.com/macor161/ts-money/tree/master/lib
 */

/**
 * Typed list of supported currency codes
 */
 export const CurrencyCode = {
    GBP : 'GBP',
    HKD : 'HKD',
    CAD : 'CAD',
    EUR : 'EUR',
    USD : 'USD',
    AUD : 'AUD',
    NZD : 'NZD',
} as const;
export type CurrencyCode =
    typeof CurrencyCode[keyof typeof CurrencyCode];

/**
 * Currency structure
 */
export interface Currency {
    code: CurrencyCode;
    name: string;
    symbol: string;
}

/**
 * Service providing access to supported and allowed currencies for
 * the Organisation.
 */
@Injectable({providedIn: 'root'})
export class CurrencyService {

    /**
     * Supported Currencies in the system
     */
    private static SUPPORTED_CURRENCIES: {[key: string]: Currency} = {
        'GBP': { code: CurrencyCode.GBP, symbol: '£', name: $localize `British Pound Sterling` },
        'HKD': { code: CurrencyCode.HKD, symbol: 'HK$', name: $localize `Hong Kong Dollar` },
        'CAD': { code: CurrencyCode.CAD, symbol: 'CA$', name: $localize `Canadian Dollar` },
        'EUR': { code: CurrencyCode.EUR, symbol: '€', name: $localize `Euro` },
        'USD': { code: CurrencyCode.USD, symbol: '$', name: $localize `US Dollar` },
        'AUD': { code: CurrencyCode.AUD, symbol: 'AU$', name: $localize `Australian Dollar` },
        'NZD': { code: CurrencyCode.NZD, symbol: 'NZ$', name: $localize `New Zealand Dollar` }
    };

    /**
     * Helper to get list of supported currencies without needing instance
     * of the service.
     *
     * This list is not sorted!
     */
    public static get supportedCurrencies(): Currency[] {
        return Object.values(CurrencyService.SUPPORTED_CURRENCIES)
                     .sort((a, b) => iCaseCompare(a.name, b.name));
    }

    /**
     * Helper to find the currency by it's code wihtout needing instance of
     * the service.
     */
    public static currencyByCode(id: CurrencyCode): Currency|undefined {
        return CurrencyService.SUPPORTED_CURRENCIES[id];
    }

    public constructor(private organisationService: OrganisationService) {
    }

    public get supportedCurrencies(): Currency[] {
        return CurrencyService.supportedCurrencies;
    }

    public currencyByCode(id: CurrencyCode): Currency|undefined {
        return CurrencyService.currencyByCode(id);
    }

    /**
     * List of allowed currencies for the current organisation
     *
     * Allowed currencies are stored in the Organisation - General settings
     * and can be updated through the UI.
     *
     * In case the allowed currencies are not defined for the organisation
     * or no currency has been set for the organisation we deafult to
     * SUPPORTED_CURRENCIES.
     *
     * @returns Observable returning the sorted list (by Name) of Currencies.
     */
    public allowedCurrencies$(): Observable<Currency[]> {
        return this.organisationService
                   .getGeneralSetup()
                   .pipe(
                      map(settings => {
                        const allowedIDs: string[] = settings.allowedCurrencies ?? [];
                        if (allowedIDs.length === 0) { return this.supportedCurrencies; }

                        const result = allowedIDs.map(code => {
                            const currency = this.currencyByCode(code as CurrencyCode);
                            if (!currency) { throw new PaymentsError($localize `Unrecognised currency code '${code}'!`); }
                            return currency;
                        });

                        return result.sort((a, b) => iCaseCompare(a.name, b.name));
                      })
                   );
    }
}
