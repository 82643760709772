import { BaseModel } from '@fry/lib/store/base-model';
import { DBDoc } from '@fry/lib/store/store.interface';

export class AsyncTask extends BaseModel {

  constructor(doc: DBDoc) {
    super(doc);
  }

}
