import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';

import { environment } from '@fry/environments/environment';
import { of, Observable, map, catchError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Version } from '@fry/environments/version';


interface ServerVersions {
  name: string;
  [key:string]: string
}

@Component({
  selector: 'app-version-information',
  template: `
      <ng-container i18n>Version</ng-container>: {{ serverVersion$ | async }} /
      <span><ng-container i18n>Frontend</ng-container>: {{ frontendVersionDescription(frontendVersion) }}</span>
      <span *ngIf="versionAvailable$ | async"> - <strong i18n>new version available</strong>.
        <ng-container i18n>Please reload the page to activate</ng-container>.
      </span>
  `,
})
export class VersionInformationComponent {

  public frontendVersion: Version = environment.version;
  public serverVersion$: Observable<string> | undefined;
  public versionAvailable$ = of(false);

  constructor(private http: HttpClient, private datePipe: DatePipe) {
    this.serverVersion$ = this.http.get<ServerVersions>('__version__').pipe(
        map(data => data.name),
        catchError(_ => { return of('Information not available') })
      )
  }

  //
  // Template helpers
  //

  public frontendVersionDescription(version: Version): string {
    // If we have some production version we output that one
    const preReleaseVersions = ['master', 'HEAD'];
    if (preReleaseVersions.indexOf(version.branch) !== -1) {
        return version.version;
    } 

    // If we have pre-production we construct detailed information
    const result = [
        `${this.datePipe.transform(version.buildAt as string, 'medium')}`,
        ` - ${version.branch}`,
        ` (${version.hash})`
    ].join('');
    return result;
  }
}
