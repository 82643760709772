import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Model } from '@fry/lib/store';
import { FormWidget, FormField } from '@fry/lib/forms';

export const FilterToken = new InjectionToken('FilterToken');

export interface Filter {
    id: string;
    label: string;
    filter?: (item: Model, value: any) => Observable<boolean>;
    widget: FormWidget;
    options?: any;
    initial?: any;
    featured?: boolean;
}

export interface Sort {
  id: string;
  sort?: (itemA: Model, itemB: Model) => number;
}

export interface SearchModel {
  filter: any;
  sort: { sort_on?: string; sort_order?: string };
}

export interface ListDefaults {
  searchModel?: SearchModel;
  fixedSearchModel?: SearchModel;
  listType?: 'normal' | 'table';
  engine?: 'es' | 'db';
  pageSize?: number;
  exportFormFields?: FormField[];
}

export interface ListSearch {
    filters(): Observable<Filter[]>;
    sorts(): Observable<Sort[]>;
}
