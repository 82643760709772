import { Component, ViewEncapsulation } from '@angular/core';

import { UnboxedBaseFormComponent } from '../base/base';

@Component({
  selector: 'ub-checkbox',
  exportAs: 'unboxedCheckbox',
  styleUrls: ['../vars.css', '../common.css', './checkbox.css'],
  templateUrl: 'checkbox.html',
  encapsulation: ViewEncapsulation.None
})
export class UnboxedCheckbox extends UnboxedBaseFormComponent {}
