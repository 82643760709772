import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SimpleComponent } from '@fry/components/layout/simple/simple.component';
import { MainComponent } from '@fry/components/layout/main/main.component';
import { AuthLoginComponent, AuthLoginOrgComponent } from '@fry/components/common/oidc';
import { PageNotFoundComponent } from '@fry/components/common/page-not-found.component';
import { AuthLoggedoutComponent } from '@fry/components/common/oidc/auth-loggedout.component';

const routes: Routes = [
  {
    path: 'logged_out',
    component: SimpleComponent,
    children: [
      {
        path: '',
        component: AuthLoggedoutComponent
      },
    ]
  },
  {
    path: 'login',
    component: SimpleComponent,
    children: [
      {
        path: '',
        component: AuthLoginComponent
      },
      {
        path: ':org',
        component: AuthLoginOrgComponent,
      }
    ]
  },
  {
    path: 'auth',
    component: SimpleComponent,
    loadChildren: () => import('@fry/components/accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: '',
    component: MainComponent,
    children: [
      // {
      //   path: '',
      //   component: MenuComponent,
      //   outlet: 'header'
      // },
      {
        path: '',
        loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule)
      }
    ]
  },
  {
    path: '**',
    component: SimpleComponent,
    children: [
      {
        path: '',
        component: PageNotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
