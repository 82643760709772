import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormData } from '@fry/lib/forms';
import { Observable } from 'rxjs';
import { LoggerService } from '@fry/lib/utils';

// We have currenly only Submit and Cancel button
export type FormDataDialogButtonType = 'submit'|'cancel';

// Configuration for the dialog
export interface FormDialogData {
  title: string;
  form: FormData;
  onSubmit: (value) => Observable<any>;

  /**
   * Specifies which buttons will be visible, for more see `shouldShowButton()`
   * helper method.
   */
  visibleButtons?: FormDataDialogButtonType[];
}

@Component({
  selector: 'app-form-dialog-component',
  templateUrl: 'form-dialog.component.html',
})
export class FormDialogComponent {

  public submitted = false;

  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData,
    private logger: LoggerService
  ) { }

  //
  // Actions
  //

  onSubmit() {
    if (this.submitted) { return; }

    this.submitted = true;
    const value = this.data.form.getValue();
    this.data.onSubmit(value).subscribe(
      () => {
        this.dialogRef.close(value);
      },
      () => {
        this.logger.warn('Could not submit form');
        this.submitted = false;
      }
    );
  }

  //
  // Template helpers
  //

  /**
   * Helper to determine if button should be visible
   *
   * Button is visible when cofiguration `visibleButtons` is:
   * - NOT provided
   * - IS provided and button is mentioned in the list.
   *
   * @param button Type of button we're testing to be shown
   */
  shouldShowButton(button: FormDataDialogButtonType): boolean {
    if (this.data === undefined) { return true; }
    if (this.data.visibleButtons === undefined) { return true; }
    return this.data.visibleButtons.find(type => type === button) !== undefined;
  }
}
