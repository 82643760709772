import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from './auth.service';
import { AccountService } from './account.service';
import { AuthOidcService } from './oidc/auth.oidc.service';


@NgModule({
  providers: [
    JwtHelperService,
  ]
})

export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error($localize `AuthModule is already loaded. It should only be imported in your application's main module.`);
    }
  }
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AccountService,
        AuthOidcService,
      ]
    };
  }
}
