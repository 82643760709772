import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FBFieldEvaluator } from '@fry/lib/forms';
import { EasElement, EasFieldControl, EasFieldGroup, EasFieldType } from '../easform/easform.interfaces';
import { guid } from '@fry/lib/utils';
import { EasFormGroup, EasFormService } from '../easform';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RolesStore } from '@fry/lib/roles';
import { BuilderEvaluatorService } from './evaluators.service';
import { EasBuilderTransition } from './builder.interfaces';
import { get } from 'lodash';

const ACTION_WIDGETS = {
  'ttl': 'number',
  'subject': 'text',
  'html': 'text',
};

/*
{
  title: "Something",
  type": "adjustment" | "fixed" | "custom"
}
*/

const ACTION_FIELDS: {[key: string]: EasElement} = {
  'refund_options':
    {
      id: 'refund_options',
      type: EasFieldType.Array,
      fields: [
        {
          id: 'title',
          type: EasFieldType.Field,
          title: $localize `Description`,
          widget: {
            type: 'string',
          }
        },
        {
          id: 'type',
          type: EasFieldType.Field,
          title: $localize `Type`,
          widget: {
            type: 'select',
          },
          options: {
            categories: [
              {id: 'adjustment', name: $localize `Percentage`},
              {id: 'fixed', name: $localize `Fixed amount`},
              {id: 'custom', name: $localize `Custom amount`},
            ]
          }
        },
        {
          id: 'adjustment',
          type: EasFieldType.Field,
          title: $localize `Percentage`,
          widget: {
            type: 'number',
          },
          meta: {
            hidden: (_value, parent) => parent.type !== 'adjustment'
          }
        },
        {
          id: 'amount',
          type: EasFieldType.Field,
          title: $localize `Exact amount`,
          widget: {
            type: 'number',
          },
          meta: {
            hidden: (_value, parent) => parent.type !== 'fixed'
          }
        },
        {
          id: 'defaultAmount',
          type: EasFieldType.Field,
          title: $localize `Default amount`,
          widget: {
            type: 'number',
          },
          meta: {
            hidden: (_value, parent) => parent.type !== 'custom'
          }
        },
        {
          id: 'default',
          type: EasFieldType.Field,
          title: $localize `Default option`,
          widget: {
            type: 'boolean',
          }
        },
        {
          id: 'metaHeading',
          type: EasFieldType.Field,
          title: $localize `Metadata`,
          widget: {
            type: 'heading'
          }
        },
        {
          id: 'metadata',
          type: EasFieldType.Array,
          title: $localize `Metadata`,
          fields: [
            {
              id: 'key',
              title: $localize `Name`,
              type: EasFieldType.Field,
              widget: {
                type: 'string',
              }
            },
            {
              id: 'value',
              title: $localize `Value`,
              type: EasFieldType.Field,
              widget: {
                type: 'string',
              }
            }
          ]
        }
      ]
  }
};

const ACTIONS = [
  { id: 'collect_payment', name: $localize `Collect payment`, options: [] },
  { id: 'select_refund_option', name: $localize `Select refund option`, options: ['refund_options']},
  { id: 'refund_payments', name: $localize `Refund payments`, options: ['auto_refund', 'refund_options']},
  { id: 'change_option', name: $localize `Change option`,
    options: ['change_only', 'record_only', 'handle_payments', 'auto_refund', 'ignoreWindow', 'allowAnyOption', 'refund_options'] },
  { id: 'confirm_booking', name: $localize `Confirm booking`, options: [] },
  { id: 'disconfirm_booking', name: $localize `Remove booking confirmation`, options: [] },
  { id: 'eligibility_check', name: $localize `Ensure user is still eligible`, options: [] },
  { id: 'reserve_seat', name: $localize `Reserve seat`, options: ['ttl'] },
  { id: 'confirm_seat', name: $localize `Confirm seat`, options: ['ttl'] },
  { id: 'unset_seat', name: $localize `Remove seat reservation` },
  { id: 'apply_userfields', name: $localize `Apply user fields to profile` },
  { id: 'send_email_to_owner', name: $localize `Send email to owner`, options: ['subject', 'html'] },
];

const GUARDS_WIDGETS = {
  'field': 'string',
  'value': 'string',
  'op': 'string',
};

const GUARDS = [
  { id: 'field_guard', name: $localize `Guard on field value`, options: ['field', 'value', 'op'] },
  { id: 'ensure_payments_paid', name: $localize `Allow only if all payments are complete`, options: [] },
  { id: 'ensure_booking_window_open', name: $localize `Allow only if booking window is open`, options: [] },
  { id: 'ensure_booking_window_closed', name: $localize `Allow only if booking window is closed`, options: [] },
];

/**
 * A service supporting Form builder UI
 */
@Injectable({
  providedIn: 'root'
})
export class BuilderUIFormService {

  private evaluators: {[key: string]: FBFieldEvaluator};

  constructor(
    private evalService: BuilderEvaluatorService,
    private easForm: EasFormService,
    private roles: RolesStore
  ) {
    this.evaluators = this.evalService.evaluators;
  }

  private getOptionsForm(type) {
    const optionGroup: EasFieldGroup = {
      id: 'options',
      type: EasFieldType.Group,
      fields: []
    };

    const evl = this.evaluators[type];
    if (!evl) {
      return of(optionGroup);
    }
    return evl.getForm(type).pipe(
      map((flds: EasElement[]) => {
        optionGroup.fields = flds;
        return optionGroup;
      })
    );
  }

  getFieldForm(data: any): Observable<EasFormGroup> {
    const group: EasFieldGroup = {
      id: 'root',
      type: EasFieldType.Group,
      fields: [
        {
          id: 'type',
          type: EasFieldType.Field,
          title: $localize `Type`,
          options: {
            categories: [
              { id: 'field', name: $localize `Field` },
              { id: 'group', name: $localize `Group` },
              { id: 'array', name: $localize `Array` },
            ],
          },
          widget: {
            type: 'select',
          }
        },
        {
          id: 'title',
          type: EasFieldType.Field,
          title: $localize `Optional title`,
          initial: '',
          widget: {
              type: 'string',
          },
          validators: [],
          meta: {
            hidden: (_value, parent) => {
              return parent.type !== 'group';
            }
          }
        },
        {
          id: 'fieldType',
          type: EasFieldType.Field,
          title: $localize `User field type`,
          initial: '',
          widget: {
              type: 'select',
          },
          validators: [Validators.required],
          options: {
              categories: this.getAvailableFields()
          },
          meta: {
            hidden: (_value, parent) => {
              return parent.type !== 'field';
            }
          }
        },
      ]
    };

    let obser: Observable<EasFieldGroup>;
    if (data.fieldType && this.evaluators[data.fieldType]) {
      obser = this.getOptionsForm(data.fieldType).pipe(
        map((optionGroup) => {
          group.fields.push(optionGroup);
          return group;
        })
      );
    } else {
      obser = of(group);
    }

    return obser.pipe(
      map(_group => this.easForm.createForm(_group, data)),
      tap(formGroup => {
        formGroup.control.get('fieldType').valueChanges.pipe(
          switchMap(val => {
            return this.getOptionsForm(val);
          }),
        ).subscribe(optionGroup => {
          formGroup.removeControl(optionGroup.id);
          formGroup.addControl(optionGroup);
        });
      })
    );
  }

  getStatesForm(group: EasFieldGroup, transitions: EasBuilderTransition[], data: any): Observable<EasFormGroup> {
    return this.roles.allWithSystem().pipe(
      map(roles => roles.map(role => ({id: role.doc._id, name: role.doc.title}))),
      map(roleOptions => {
        const statesGroup: EasFieldGroup = {
          id: 'root',
          type: EasFieldType.Group,
          fields: [
            {
              id: 'name',
              type: EasFieldType.Field,
              title: $localize `Name`,
              initial: '',
              widget: {
                  type: 'string',
              },
            },
            {
              id: 'roles',
              type: EasFieldType.Array,
              fields: [
                {
                  id: 'role',
                  type: EasFieldType.Field,
                  widget: {
                    type: 'select',
                  },
                  title: $localize `Role`,
                  options: {
                    categories: roleOptions,
                  }
                },
                {
                  id: 'group',
                  type: EasFieldType.Group,
                  fields: group.fields.map(fld => ({
                    id: fld.id,
                    type: EasFieldType.Field,
                    initial: 'view',
                    title: fld['title'] || fld['id'],
                    widget: {
                      type: 'select',
                    },
                    options: {
                      categories: [
                        {id: 'view', name: $localize `View`},
                        {id: 'edit', name: $localize `Edit`},
                        {id: 'hidden', name: $localize `Hidden`},
                      ]
                    }
                  })),
                },
                {
                  id: 'transitions',
                  type: EasFieldType.Field,
                  title: $localize `Available transitions`,
                  widget: {
                    type: 'multiSelect',
                  },
                  options: {
                    categories: transitions.map(itm => {
                      return {id: itm.id, name: itm.name};
                    })
                  }
                },
                {
                  id: 'canDelete',
                  type: EasFieldType.Field,
                  title: $localize `Can delete entire booking in this state`,
                  widget: {
                    type: 'boolean'
                  }
                }
              ] as EasFieldControl[]
            }
          ],
        };

        // group.fields.forEach((field, idx) => {
        //   const wfGroup: EasFieldGroup = {
        //     id: field.id,
        //     type: EasFieldType.Group,
        //     fields: [

        //     ]
        //   };
        //   statesGroup.fields.push(wfGroup);
        // });
        return this.easForm.createForm(statesGroup, data);
      })
    );
  }

  getInitialStateForm(data, states) {
    return this.roles.allWithSystem().pipe(
      map(roles => {
        const roleOptions = roles.map(role => ({id: role.doc._id, name: role.doc.title}));
        const stateGroup: EasFieldGroup = {
          id: 'root',
          type: EasFieldType.Group,
          fields: [
            {
              id: 'state',
              title: $localize `Initial state`,
              type: EasFieldType.Field,
              initial: '',
              widget: {
                  type: 'select',
              },
              validators: [Validators.required],
              options: {
                categories: states
              }
            },
            {
              id: 'roles',
              title: $localize `Roles that can initiate request`,
              type: EasFieldType.Field,
              initial: '',
              widget: {
                  type: 'multiSelect',
              },
              validators: [Validators.required],
              options: {
                categories: roleOptions
              }
            },
            {
              id: 'actions',
              title: $localize `Actions`,
              type: EasFieldType.Array,
              fields: [
                {
                  id: 'action',
                  title: $localize `Action`,
                  type: EasFieldType.Field,
                  initial: [],
                  widget: {
                      type: 'select',
                  },
                  validators: [Validators.required],
                  options: {
                    categories: ACTIONS
                  }
                },
                {
                  id: 'data',
                  title: $localize `Data`,
                  initial: {},
                  type: EasFieldType.Group,
                  fields: this.getOptionsForActions()
                }
              ]
            },
          ]
        };
        return this.easForm.createForm(stateGroup, data);
      })
    );

  }
  getOptionsForActions() {
    return this.getOptionsFor(ACTIONS, ACTION_WIDGETS, ACTION_FIELDS);
  }

  getOptionsForGuards() {
    return this.getOptionsFor(GUARDS, GUARDS_WIDGETS);
  }

  getOptionsFor(options: any[], widgets: any, fields?: {[key: string]: EasElement}) {
    fields = fields || {};
    const allOptions = options.reduce((acu, curr) => {
      (curr.options || []).forEach((opt: string) => {
        if (!acu.includes(opt)) {
          acu.push(opt);
        }
      });
      return acu;
    }, []);

    return allOptions.map(opt => {

      const def = {
        meta: {
          hidden: (_value, _parent, doc, path) => {
            // Get real parent
            const splitted = path.split('.');
            const limited = splitted.slice(1, splitted.length - 2).join('.');
            const val = get(doc, limited) || {};
            const part = val.action || val.guard;
            if (!part) {
              return true;
            }
            const action = options.find(itm => itm.id === part);
            if (!action) {
              return true;
            }

            return !(action.options || []).includes(opt);
          }
        }
      };
      let fld: EasElement;
      if (fields[opt] !== undefined) {
        fld = {
          ...fields[opt],
          ...def
        };
      } else {
        fld = {
          id: opt,
          title: opt,
          type: EasFieldType.Field,
          widget: {
            type: widgets[opt] || 'boolean'
          },
          ...def
        };
      }
      return fld;
    });
  }

  getTransitionsForm(data, states) {
    const transGroup: EasFieldGroup = {
      id: 'root',
      type: EasFieldType.Group,
      fields: [
        {
          id: 'id',
          title: $localize `Name`,
          type: EasFieldType.Field,
          initial: '',
          widget: {
              type: 'hidden',
          },
          validators: [Validators.required]
        },
        {
          id: 'name',
          title: $localize `Name`,
          type: EasFieldType.Field,
          initial: '',
          widget: {
              type: 'string',
          },
          validators: [Validators.required]
        },
        {
          id: 'message',
          title: $localize `Success message`,
          type: EasFieldType.Field,
          initial: '',
          widget: {
              type: 'string',
          },
          validators: [Validators.required]
        },
        {
          id: 'destination',
          title: $localize `Destination state`,
          type: EasFieldType.Field,
          initial: '',
          widget: {
              type: 'select',
          },
          validators: [Validators.required],
          options: {
            categories: states
          }
        },
        {
          id: 'sources',
          title: $localize `Source states`,
          type: EasFieldType.Field,
          initial: [],
          widget: {
              type: 'multiSelect',
          },
          validators: [Validators.required],
          options: {
            categories: states
          }
        },
        {
          id: 'autoTransition',
          title: $localize `Auto transition when guards are satisfied`,
          type: EasFieldType.Field,
          initial: false,
          widget: {
              type: 'boolean',
          }
        },
        {
          id: 'allowInvalidForm',
          title: $localize `Allow invalid form`,
          type: EasFieldType.Field,
          initial: false,
          widget: {
              type: 'boolean',
          }
        },
        {
          id: 'actions',
          title: $localize `Actions`,
          type: EasFieldType.Array,
          fields: [
            {
              id: 'action',
              title: $localize `Action`,
              type: EasFieldType.Field,
              initial: [],
              widget: {
                  type: 'select',
              },
              validators: [Validators.required],
              options: {
                categories: ACTIONS
              }
            },
            {
              id: 'data',
              title: $localize `Data`,
              initial: {},
              type: EasFieldType.Group,
              fields: this.getOptionsForActions()
            }
          ]
        },
        {
          id: 'guards',
          title: $localize `Guards`,
          type: EasFieldType.Array,
          fields: [
            {
              id: 'guard',
              title: $localize `Guard`,
              type: EasFieldType.Field,
              initial: [],
              widget: {
                  type: 'select',
              },
              validators: [Validators.required],
              options: {
                categories: GUARDS
              }
            },
            {
              id: 'data',
              title: $localize `Data`,
              initial: {},
              type: EasFieldType.Group,
              fields: this.getOptionsForGuards()
            }
          ]
        },
        {
          id: 'requireConfirmation',
          title: $localize `Require confirmation`,
          type: EasFieldType.Field,
          initial: false,
          widget: {
              type: 'boolean',
          }
        },
        {
          id: 'confirmationTitle',
          title: $localize `Confirmation title`,
          type: EasFieldType.Field,
          initial: '',
          widget: {
              type: 'string',
          },
          meta: {
            hidden: (_value, parent) => {
              return !parent.requireConfirmation;
            }
          }
        },
        {
          id: 'confirmationDescription',
          title: $localize `Confirmation description`,
          type: EasFieldType.Field,
          initial: '',
          widget: {
              type: 'text',
          },
          meta: {
            hidden: (_value, parent) => {
              return !parent.requireConfirmation;
            }
          }
        },
        {
          id: 'cancelButton',
          title: $localize `Cancel button label`,
          type: EasFieldType.Field,
          initial: 'Cancel',
          widget: {
              type: 'string',
          },
          meta: {
            hidden: (_value, parent) => {
              return !parent.requireConfirmation;
            }
          }
        },
        {
          id: 'okButton',
          title: $localize `Continue button label`,
          type: EasFieldType.Field,
          initial: 'Continue',
          widget: {
              type: 'string',
          },
          meta: {
            hidden: (_value, parent) => {
              return !parent.requireConfirmation;
            }
          }
        },
      ]
    };

    return of(this.easForm.createForm(transGroup, data));
  }

  getCustomTransitionsForm(data, states) {
    const transGroup: EasFieldGroup = {
      id: 'root',
      type: EasFieldType.Group,
      fields: [
        {
          id: 'destination',
          title: $localize `Destination state`,
          type: EasFieldType.Field,
          initial: '',
          widget: {
              type: 'select',
          },
          validators: [Validators.required],
          options: {
            categories: states
          }
        },
        {
          id: 'actions',
          title: $localize `Actions`,
          type: EasFieldType.Array,
          fields: [
            {
              id: 'action',
              title: $localize `Action`,
              type: EasFieldType.Field,
              initial: [],
              widget: {
                  type: 'select',
              },
              validators: [Validators.required],
              options: {
                categories: ACTIONS
              }
            },
            {
              id: 'data',
              title: $localize `Data`,
              initial: {},
              type: EasFieldType.Group,
              fields: this.getOptionsForActions()
            }
          ]
        },
      ]
    };

    return of(this.easForm.createForm(transGroup, data));
  }

  initField(value: any) {
    const defaults = { id: guid() };
    let field: EasElement;
    if (value.type === EasFieldType.Group) {
      field = {
        fields: [],
        ...defaults,
        ...value,
      };
    } else if (value.type === EasFieldType.Array) {
      field = {
        fields: [],
        ...defaults,
        ...value,
      };
    } else {
      field = {
        ...defaults,
        ...value,
      };
    }
    if (!field.id) {
      field.id = defaults.id;
    }
    return field;
  }

  getAvailableFields() {
    return Object.keys(this.evaluators).map(itm => ({id: itm, name: itm}));
  }
}
