import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'translations.view', name: $localize `View translations`, description: $localize `Allow user to view translations` }
];

export const translationPermissions: PermissionGroup = {
  id: 'translations',
  name: $localize `Translations`,
  description: '',
  permissions
};
