import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormField } from '@fry/lib/forms';

@Component({
  selector: 'app-form-field',
  styleUrls: ['./form-field.component.scss'],
  templateUrl: './form-field.component.html'
})
export class FormFieldComponent {
  @Input() field: FormField;
  @Input() formGroup: UntypedFormGroup;

  constructor() {}
}
