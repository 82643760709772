import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IndependentTransitionGuard } from '@fry/components/common/form-builder/builder-transition-guard';
import { BookingItemsStore } from '../../booking-items';
import { nowWithinRange } from '@fry/lib/utils';

@Injectable()
export class BookingWindowOpenGuard implements IndependentTransitionGuard {
    public static readonly code: string = 'ensure_booking_window_open';
    public static readonly title?: string = '';
    public get code() { return BookingWindowOpenGuard.code; }
    public get title() { return BookingWindowOpenGuard.title; }

    constructor(private bookingItems: BookingItemsStore) {}

    public evaluate(model: any, _user: any): Observable<boolean> {
      return this.bookingItems.getBookableOption(model.doc.bookingOption, model.doc.bookingItem).pipe(
          map(bookingOption => {
            if (!bookingOption.bookingWindow) {
              return false;
            }

            const start = new Date(bookingOption.bookingWindow.startDate);
            const end = new Date(bookingOption.bookingWindow.endDate);
            return nowWithinRange(start, end);
          })
      );
    }

}
