import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, map, take, tap } from 'rxjs/operators';

import { ConfigService } from '@fry/lib/config';

import { AuthToken } from '@fry/lib/auth/auth.token';
import { AuthOidcService } from './oidc/auth.oidc.service';
import { ClusterService } from '../organisations/cluster.service';

interface AuthResponse {
  token: string;
}

export interface AuthUserProfile {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
}

export interface CurrentUser {
  username: string;
  organisation: string;
  state: 'full' | 'noorg' | 'anonymous';
  original?: AuthUserProfile;
}

const anonymousUser: CurrentUser = {
  username: 'anonymous',
  organisation: 'unknown',
  state: 'anonymous',
};

@Injectable()
export class AuthService {
  // private jwtHelper; // TODO: declared but never read
  private _autoReconnect = false;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private authToken: AuthToken,
    private oidc: AuthOidcService,
    private cluster: ClusterService,
  ) {
    // this.jwtHelper = new JwtHelperService(); // TODO: declared but never read
  }

  private _currentUser = new BehaviorSubject<CurrentUser>(anonymousUser);
  private currentUserDistinct = this._currentUser.asObservable().pipe(
    distinctUntilChanged((x, y) => {
      return x.username === y.username && x.organisation === y.organisation;
    })
  )

  public subscribeUserChange() {
    console.log('AuthService: Subscribing to oidc user change');
    this.currentUserDistinct.subscribe(user => {
      console.log(user);
      if (user.username !== 'anonymous') {
        this.autoReconnect = true;
      }
      this.cluster.setClusterOrganisation(user.organisation);
      this.cluster.setCurrentUser(user.username);
    })
    this.oidc.userChanged.subscribe(_ => this.updateCurrentUser());
  }

  public currentUser() {
    return this.currentUserDistinct;
  }

  public get autoReconnect(): boolean {
    console.log('AuthR: fetching auto reconnect to', this._autoReconnect);
    return this._autoReconnect;
  }

  public set autoReconnect(value) {
    if (this._autoReconnect === value) {
      return;
    }
    console.log('AuthR: setting auto reconnect to', value);
    this._autoReconnect = value;
    if (value) {
      this.oidc.startSilentRenew();
    } else {
      this.oidc.stopSilentRenew();
    }
  }

  public get token() {
    return this.oidc.getToken();
  }

  public set token(token: string) {
    this.authToken.token = token;
    this.updateCurrentUser();
  }

  public get organisation() {
    const data = this.authToken.getOrganisation();
    if (data.loaded) {
      this.updateCurrentUser();
    }
    return data.organisation;
  }

  public set organisation(organisation: string) {
    this.cluster.setClusterOrganisation(organisation);
    this.authToken.organisation = organisation;
    this.oidc.init({ force: true });
    this.updateCurrentUser();
  }

  public async loginAs(username: string) {
    this.autoReconnect = false;
    await this.oidc.loginAs(username);
  }

  public async logoutAs() {
    this.autoReconnect = false;
    await this.oidc.loginAs();
  }

  public startLogin() {
    this.oidc.startAuthentication();
  }

  public startLogout() {
    this.autoReconnect = false;
    this.oidc.startLogout();
  }

  public async handleCallback() {
    await this.oidc.completeAuthentication();
  }

  public async startLoginPopup() {
    await this.oidc.startAuthenticationPopup();
  }

  public async handleCallbackPopup() {
    await this.oidc.completeAuthenticationPopup();
    this.autoReconnect = true;
  }

  public async handleCallbackSilent() {
    this.oidc.completeAuthenticationSilent();
    this.autoReconnect = true;
  }

  /**
   * Handles logging user in. Upon successful login, store the token
   *
   * @return {Observable<boolean>} [description]
   */
  public login() {
    const url = this.config.config.server.urls.auth + '/authenticate';
    return this.http.post<AuthResponse>(url, { organisation: 'org_fry', username: 'fry' })
                    .pipe(
                      tap(data => this.token = data.token),
                      map(() => this.isLoggedIn),
                      catchError((error, caught) => {
                        console.log(error, caught);
                        return new Observable();
                      })
                    );
  }

  public logout() {
    return new Observable((observer) => {
      this.token = null;
      observer.next(this.isLoggedIn);
    });
  }

  get isLoggedIn(): boolean {
    return this.oidc.isLoggedIn();
  }

  get current(): CurrentUser {
    if (!this.isLoggedIn) {
      return anonymousUser;
    }
    const claims = this.oidc.getClaims();
    console.log('Claims:', claims);
    const scope = this.oidc.getScope();

    const laseMatch = scope.match(/lase:([a-zA-Z0-9\-_]+)/);
    const username = laseMatch ? laseMatch[1] : claims.sub;

    let original: AuthUserProfile;
    if (laseMatch) {
      original = {
        username: claims.sub,
        firstName: claims.firstName,
        lastName: claims.lastName,
        email: claims.email,
        fullName: `${claims.firstName} ${claims.lastName}`.trim() || claims.email,
      };
    }

    const state = this.organisation ? 'full' : 'noorg';

    return {
      username,
      organisation: this.organisation,
      state,
      original,
    };
  }

  private updateCurrentUser() {
    const current = this.current;
    console.log(`AuthService.updateCurrentUser: Updating to ${JSON.stringify(current)}`);
    this._currentUser.next(current);
  }

  public isUserCurrent(username: string): Observable<boolean> {
    return this.currentUser().pipe(
      take(1),
      map(currentUser => currentUser.username === username),
    );
  }

  public check() {
    return this.oidc.check();
  }
}
