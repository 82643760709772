import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BookingTypesStore } from '../booking-types';
import { ListSearch } from '@fry/lib/list';


@Injectable()
export class BookingItemsSearch implements ListSearch {

  constructor(
    private bookingTypes: BookingTypesStore,
  ) {}


  private fulltext() {
    return of({
      id: 'text',
      label: '',
      filter: (item, value): Observable<boolean> => {
        return of(item.doc.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      },
      featured: true,
      widget: {
        type: 'string',
        placeholder: $localize `Full text search`,
      }
    });
  }

  private types() {
    return this.bookingTypes.all().pipe(
      map(types => {
        return {
          id: 'bookingType',
          label: $localize `Booking type`,
          widget: {
            type: 'multiSelect'
          },
          options: {
            categories: types.map(btype => {
              return { id: btype.doc._id, name: btype.doc.name };
            })
          }
        };
      })
    );
  }

  private statuses() {
    return this.bookingTypes.all().pipe(
      map(() => {
        return {
          id: 'state',
          label: $localize `State`,
          widget: {
            type: 'multiSelect'
          },
          options: {
            categories: [
              { id: 'published', name: $localize `Published` },
              { id: 'draft', name: $localize `Draft` },
              { id: 'archived', name: $localize `Archived` },
            ]
          },
          filter: (item, value): Observable<boolean> => {
            return of(value.indexOf(item.workflow.state) !== -1);
          }
        };
      })
    );
  }

  private windowState() {
    return of({
      id: 'windowState',
      label: $localize `Booking window`,
      widget: {
        type: 'multiSelect'
      },
      options: {
        categories: [
          { id: 'prewindow', name: $localize `Not yet open` },
          { id: 'window', name: $localize `Open` },
          { id: 'postwindow', name: $localize `Closed` },
          { id: 'nowindow', name: $localize `Always open` },
        ]
      }
    });
  }

  public filters() {
    return forkJoin([
      this.fulltext(),
      this.types(),
      this.statuses(),
      this.windowState(),
    ]);
  }

  public sorts() {
    return of([
      {
        id: 'name',
        name: $localize `Name`,
      },
      {
        id: 'addedDate',
        name: $localize `Created`,
      },
      {
        id: 'modifiedDate',
        name: $localize `Modified`,
      },
      {
        id: 'bookingWindow',
        name: $localize `Booking window open`,
      }
    ]);
  }

}
