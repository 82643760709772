import { DBDoc, PublishableBaseModel } from '@fry/lib/store';


export interface WorkflowUsage {
  id: string;
  name: string;
  type: string;
}


export interface ExtendedWorkflow {
  doc: DBDoc;
  usage: WorkflowUsage[];
  warnings: any[];
}


export interface WorkflowDocSchema extends DBDoc {
  name: string;
  form: WorkflowDocFormSchema;
  type: string;
  organisation: string;
}


export interface WorkflowDocFormSchema {
  id: string;
  name: string;
  states: StateSchema[];
  transitions: TransitionSchema[];
  initial: InitialTransitionSchema;
  group: WorkflowDocFormGroupSchema;
}


export interface WorkflowDocFormGroupSchema {
  fields: WorkflowDocFormFieldSchema[];
  id: string;
  type: string;
}

/**
 * Booking Form field JSON representation
 */
export interface WorkflowDocFormFieldSchema {
  /**
   * Type of the form field
   * 
   * Allowed values are governed by the FormFieldType and FormFieldTypeID.
   */
  fieldType: string;

  /**
   * Unique ID of the form field
   */
  id: string;

  /**
   * Form field options
   */
  options: WorkflowDocFormFieldOptionsSchema;

  /** 
   * Is the filed representing group/array/field within the form?  
   * 
   * Value of this is as string from EasFieldType.
   */
  type: string;

  /**
   * List of the codes/names of asynchronous validators
   */
  asyncValidators?: string[];

  /**
   * List of the codes/names of synchronous validators
   */
  validators?: string[];
}


export interface WorkflowDocFormFieldOptionsSchema {
  /**
   * When Field can only have discrete values they will be listed here.
   * For example Select options.
   */
  categories?: WorkflowDocFormFieldOptionsCategorySchema[];

  /**
   * Some fields have labels some don't. For example paragraph or heading
   * won't have label on the backend.
   */
  label?: string;

  meta?: {[K: string]: any|WorkflowDocFormFieldOptionsVisibilitySchema};

  /**
   * In case the Field has a type UserField, the specific UserField ID is
   * stored in `related`.
   */
  related?: string;

  /**
   * Is field required or not?
   */
  required: boolean;

  /**
   * For Paragraph & Heading this is the content displayed to the user.
   */
  content?: string;
}


export interface WorkflowDocFormFieldOptionsCategorySchema {
  _id: string;
  name: string;
  categories?: WorkflowDocFormFieldOptionsCategorySchema[];
}


export interface WorkflowDocFormFieldOptionsVisibilitySchema {
  /** 
  * Operator of the rule, currenly only 'eq', 'has_any', 'has_one'
  * 
  * TODO: Refactor operators to be defined here and have strict type.
  */
  op: string;

  /**
   * ID of the target field
   */
  value: string;

  /**
   * Value of values to test against the target field.
   * 
   * Simple string in case the `target` is simple field. An array of strings
   * (ids) of values in case `target` is Discrete field type (eg. select).
   */
  cmp: string|string[];
}


export interface TransitionSchema {
  actions?: TransitionActionSchema[];
  allowInvalidForm: boolean;
  autoTransition: boolean;
  cancelButton?: string;
  confirmationDescription?: string;
  confirmationTitle?: string;
  destination: string;
  guards?: TransitionGuardSchema[];
  id: string;
  message: string;
  name: string;
  okButton?: string;
  requireConfirmation: boolean;
  sources: string[];
}


export interface TransitionGuardSchema {
  guard: string;
  data?: object;
}


export interface TransitionActionSchema {
  action: string;
  data?: object;
}


export interface InitialTransitionSchema {
  actions: TransitionActionSchema[];
  state: string;
  roles?: string[];
}


export interface StateSchema {
  id: string;
  name: string;
  roles: StatePermissionSchema[];
}


export interface StatePermissionSchema {
  canDelete: boolean;
  group: any;
  role: string;
  transitions?: string[];
}


export class Workflow extends PublishableBaseModel {
  public usage: WorkflowUsage[] = [];
  public warnings: any[] = [];

  public get doc(): WorkflowDocSchema {
    return super.doc as WorkflowDocSchema;
  }

  public set doc(value: WorkflowDocSchema) {
    super.doc = value;
  }

  constructor(doc: DBDoc, nonDocProperties?: ExtendedWorkflow) {
    super(doc, nonDocProperties);

    if (!!nonDocProperties) {
      this.usage = nonDocProperties.usage ?? [];
      this.warnings = nonDocProperties.warnings ?? [];
    }
  }
}
