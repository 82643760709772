import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'workflows.view', name: $localize `View workflows`, description: $localize `Allow user to view list of workflows` },
  { id: 'workflows.create', name: $localize `Create workflows`, description: $localize `Allow user to create new workflows` },
  { id: 'workflows.edit', name: $localize `Edit workflows`, description: $localize `Allow user to edit workflows`},
];

export const workflowPermissions: PermissionGroup = {
  id: 'workflows',
  name: $localize `Workflows`,
  description: '',
  permissions
};
