import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { UnboxedButtonList } from './list/button-list';
import { UnboxedPagination } from './pagination/pagination';
import { UnboxedCard } from './card/card';
import { UnboxedPageHeader } from './layout/page-header';
import { UnboxedSimpleList } from './list/simple-list';
import { UnboxedSimpleListItem } from './list/simple-list-item';
import { UnboxedFilterList } from './list/filter-list';
import { UnboxedLabel } from './label/label';
import { UnboxedAvatar } from './avatar/avatar';
import { UnboxedHeading } from './typography/heading';
import { UnboxedBox } from './box/box';
import { UnboxedTable } from './table/table';
import { UnboxedAlert } from './alert/alert';
import { UnboxedBaseFormComponent } from './form/base/base';
import { UnboxedFormControl } from './form/form-control/form-control';
import { UnboxedTextInput } from './form/input/text';
import { UnboxedEmailInput } from './form/input/email';
import { UnboxedPasswordInput } from './form/input/password';
import { UnboxedNumberInput } from './form/input/number';
import { UnboxedTextarea } from './form/textarea/textarea';
import { UnboxedCheckbox } from './form/checkbox/checkbox';
import { UnboxedRadio } from './form/radio/radio';
import { UnboxedSelect } from './form/select/select';
import { UnboxedToggle } from './form/toggle/toggle';
import { UnboxedReadable } from './form/readable/readable';
import { UnboxedGrid } from './grid/grid';
import { UnboxedStepper } from './stepper/stepper';
import { UnboxedVisuallyHidden } from './visually-hidden/visually-hidden';

import { UnboxedFormLabelDirective } from './form/label/label';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [
    UnboxedButtonList,
    UnboxedPagination,
    UnboxedCard,
    UnboxedPageHeader,
    UnboxedSimpleList,
    UnboxedSimpleListItem,
    UnboxedFilterList,
    UnboxedLabel,
    UnboxedAvatar,
    UnboxedHeading,
    UnboxedBox,
    UnboxedTable,
    UnboxedAlert,
    UnboxedBaseFormComponent,
    UnboxedFormControl,
    UnboxedTextInput,
    UnboxedEmailInput,
    UnboxedPasswordInput,
    UnboxedNumberInput,
    UnboxedTextarea,
    UnboxedCheckbox,
    UnboxedRadio,
    UnboxedSelect,
    UnboxedToggle,
    UnboxedReadable,
    UnboxedGrid,
    UnboxedStepper,
    UnboxedVisuallyHidden,
    UnboxedFormLabelDirective
  ],
  exports: [
    UnboxedButtonList,
    UnboxedPagination,
    UnboxedCard,
    UnboxedPageHeader,
    UnboxedSimpleList,
    UnboxedSimpleListItem,
    UnboxedFilterList,
    UnboxedLabel,
    UnboxedAvatar,
    UnboxedHeading,
    UnboxedBox,
    UnboxedTable,
    UnboxedAlert,
    UnboxedFormControl,
    UnboxedTextInput,
    UnboxedEmailInput,
    UnboxedPasswordInput,
    UnboxedNumberInput,
    UnboxedTextarea,
    UnboxedCheckbox,
    UnboxedRadio,
    UnboxedSelect,
    UnboxedToggle,
    UnboxedReadable,
    UnboxedGrid,
    UnboxedStepper,
    UnboxedVisuallyHidden,
    UnboxedFormLabelDirective
  ]
})
export class UnboxedModule {}
