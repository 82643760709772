import { Permission, PermissionGroup } from '@fry/lib/permissions';

const permissions: Permission[] = [
  { id: 'users.view', name: $localize `View users`, description: $localize `Allow user to view users list and profile details` },
  { id: 'users.create', name: $localize `Create users`, description: $localize `Allow user to create new users` },
  { id: 'users.edit', name: $localize `Edit users`, description: $localize `Allow user to edit the profile of other users`},
  { id: 'users.review', name: $localize `Review users`, description: $localize `Allow user to disable and enable users`},
  { id: 'users.edit.own', name: $localize `Edit own profile`, description: $localize `Allow user to edit own profile`},
  { id: 'roles.assign', name: $localize `Assign roles`, description: $localize `Allow user to assign roles to other people`},
  { id: 'roles.assign.own', name: $localize `Assign own roles`, description: $localize `Allow user to assign roles to themselves`},
  { id: 'users.edit.locked', name: $localize `Edit locked fields`, description: $localize `Allow user to edit locked user fields` },
  { id: 'credential.manage', name: $localize `Manage credentials`, description: $localize `Allow user to manage login information for other users` },
  { id: 'credential.manage.own', name: $localize `Manage own credentials`, description: $localize `Allow user to manage own login information` },
  { id: 'users.lase', name: $localize `Login as other users`, description: $localize `Allow user to login as other users` },
  { id: 'users.editPreferredLanguage.own', name: $localize `Edit preferred language`, description: $localize `Allow user to edit own preferred language` },
];

export const userPermissions: PermissionGroup = {
  id: 'users',
  name: $localize `Users`,
  description: '',
  permissions
};
