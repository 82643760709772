import { Injectable } from '@angular/core';
import { LoggerService } from '@fry/lib/utils/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

export const NotifyType = {
  Success: { id: 'SUCCESS', name: $localize `Success` },
  Warning: { id: 'WARNING', name: $localize `Warning` },
  Error: { id: 'ERROR', name: $localize `Error` }
}

interface NotifyMessage {
  message: string;
  type: typeof NotifyType[keyof typeof NotifyType];
}

@Injectable({
    providedIn: 'root'
})
export class NotifyService {

  private _messages = new Subject<NotifyMessage>();
  constructor(
    private logger: LoggerService,
    private snackbar: MatSnackBar,
  ) {
    // This actually handles the message displays
    // This will likely be moved out to components
    // as we will want to have a custom component for the
    // snackbar. However the messages is public so that anybody can
    // subscribe and do what ever
    this.messages.subscribe(data => {
      this.logger.info(`Notified ${data.type}: ${data.message}`);
      this.snackbar.open(data.message, data.type.name, {
        duration: 2000
      });
    });
  }

  public get messages() {
    return this._messages.asObservable();
  }

  public show(type: typeof NotifyType[keyof typeof NotifyType], message: string) {
    this._messages.next({ message, type });
  }

  public success(message: string) {
    return this.show(NotifyType.Success, message);
  }

  public warning(message: string) {
    return this.show(NotifyType.Warning, message);
  }

  public error(message: string) {
    return this.show(NotifyType.Error, message);
  }
}
