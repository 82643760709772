import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";

import { UnboxedModule } from '@fry/ui';

import { EASErrorHandler } from './eas-error-handler';
import { EASServerErrorInterceptor } from './eas-server-error.interceptor';
import { ErrorsComponent } from './errors.component';
import { NotificationsService } from './notifications.service';
import { SentryService } from './sentry.service';

@NgModule({
  imports: [
    CommonModule,
    UnboxedModule,
  ],
  declarations: [
    ErrorsComponent,
  ],
  exports: [
    ErrorsComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EASServerErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: EASErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    NotificationsService,
    SentryService,
  ],
})
export class ErrorModule { }
