import { Component, Input } from '@angular/core';
import { FormField } from '@fry/lib/forms';

@Component({
  selector: 'app-form-fields-sub',
  templateUrl: './form-fields-sub.component.html',
})
export class FormFieldsSubComponent {
  @Input() fields: FormField[];
  @Input() model: any;
  @Input() group: any;

  constructor(
  ) { }

}
