import { Injectable } from '@angular/core';
import { ConfigService } from '@fry/lib/config';


const noop = (): any => undefined;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private isDebugMode: boolean;

  constructor(
    private config: ConfigService
  ) {
  }

  init() {
    this.isDebugMode = !this.config.frontend.production;
    this.isDebugMode = true;
    if (this.isDebugMode) {
      console.log('Setting logger in debug mode');
    } else {
      console.log('Setting logger in production mode');
    }
  }

  get debug() {
    if (this.isDebugMode) {
      // tslint:disable-next-line:no-console
      return console.debug.bind(console, (new Date()).toLocaleString() + ':');
    } else {
      return noop;
    }
  }

  get info() {
    if (this.isDebugMode) {
      // tslint:disable-next-line:no-console
      return console.info.bind(console, (new Date()).toLocaleString() + ':');
    } else {
      return noop;
    }
  }

  get warn() {
    return console.warn.bind(console, (new Date()).toLocaleString() + ':');
  }

  get error() {
    return console.error.bind(console, (new Date()).toLocaleString() + ':');
  }

}
