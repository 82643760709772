import { Injectable } from '@angular/core';

import { User } from '@fry/lib/users/user';

@Injectable()
export class UserService {

  public user: User;

  constructor(
  ) {
  }

  setUser(user: User) {
      this.user = user;
  }

  fullname() {
      return `${this.user.doc.firstName} ${this.user.doc.lastName}`;
  }
}
