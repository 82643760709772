import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, filter, map, reduce, shareReplay, switchMap, tap } from 'rxjs/operators';

import { EasTable, Model, SearchStoreService, SearchType } from '@fry/lib/store';
import { Filter, ListDefaults, ListSearch, SearchModel, Sort } from '@fry/lib/list/search.interface';
import { cloneDeep } from 'lodash';
import { FormField } from '../forms';
import { EASPersonalStorageService } from '@fry/lib/users';

export type ListStateType = 'pending' |
                            'empty' |
                            'full' |
                            'loading' |
                            'error';
export interface ListState {
  type: ListStateType;
  error?: Error;
}

export const ListStatePending: ListState = { type: 'pending' };
export const ListStateEmpty: ListState = { type: 'empty' };
export const ListStateFull: ListState = { type: 'full' };
export const ListStateLoading: ListState = { type: 'loading' };

@Injectable()
export class ListService {
  public listId: string;
  private _alreadySetup = false;
  private sourceService: SearchStoreService<Model>;
  public listType: 'normal' | 'table' = 'normal';
  private engine: 'es' | 'db';
  private page = 1;
  private pageItems: Model[];
  private allItems: Model[];
  private filteredItems: Model[];
  private search: ListSearch;
  private order: any;
  private defaults: ListDefaults;
  public fixedSearchModel: SearchModel;
  public defaultSearchModel: SearchModel;
  public searchModel: SearchModel;
  public pageSize = 10;
  public exportFormFields: FormField[] = [];

  private _items: BehaviorSubject<Model[]> = new BehaviorSubject([]);
  public readonly items: Observable<Model[]> = this._items.asObservable();

  private _table = new BehaviorSubject<EasTable>({columns: [], data: []});
  public readonly table = this._table.asObservable();

  private _totalCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly totalCount$ = this._totalCount.asObservable();

  private _currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly currentPage = this._currentPage.asObservable();

  private _filters: Observable<Filter[]>;
  private _sorts: Observable<Sort[]>;

  private _listStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly listReady = this._listStatus.pipe(
    filter(val => val)
  );

  public state = new BehaviorSubject<ListState>(ListStatePending);

  public setPageSize(pageSize: number) {
    if (pageSize < 0 || pageSize > 1000) {
      throw new Error('Page size has to be within 0 and 1000');
    }
    this.pageSize = pageSize;
    this.page = 1;
  }

  public get filters(): Observable<Filter[]> {
    if (!this.search) {
      return of([]);
    }
    if (!this._filters) {
      this._filters = this.search.filters().pipe(
        shareReplay({ refCount: false, bufferSize: 1 })
      );
    }

    return this._filters;
  }

  public get sorts() {
    if (!this.search) {
      return of([]);
    }
    if (!this._sorts) {
      this._sorts = this.search.sorts().pipe(
        shareReplay({ refCount: false, bufferSize: 1 })
      );
    }

    return this._sorts;
  }

  private get searchType() {
    return this.sourceService.searchType;
  }

  constructor(
    private personalStorage: EASPersonalStorageService
  ) {}

  public setupWith(sourceService: SearchStoreService<Model>,
                   search?: ListSearch,
                   order?: any,
                   defaults?: ListDefaults,
                   listId?: string): Observable<any> {
    if (!this._alreadySetup) {
      this.sourceService = sourceService;
      this.search = search;
      this.order = order;
      this.defaults = defaults;
      this.listId = listId || 'general';

      this.fixedSearchModel = cloneDeep(defaults && defaults.fixedSearchModel) || { filter: {}, sort: {}};
      this.defaultSearchModel = cloneDeep(defaults && defaults.searchModel || { filter: {}, sort: {}});
      this.searchModel = this.loadSearchModel() || cloneDeep(this.defaultSearchModel);

      this.listType = defaults && defaults.listType || this.listType;
      this.engine = defaults && defaults.engine || this.engine;
      this.pageSize = defaults && defaults.pageSize || 10;
      this.exportFormFields = defaults && defaults.exportFormFields || [];
      this._alreadySetup = true;
      this._listStatus.next(true);
    }
    this.sourceService.reset();
    this.filteredItems = undefined;
    this.allItems = undefined;
    return this.load();
  }

  private get mergedSearchModel() {
    return this.mergeModels(this.searchModel, this.fixedSearchModel);
  }

  private mergeModels(modelA: SearchModel, modelB: SearchModel) {
    return {
      filter: {...modelA.filter, ...modelB.filter},
      sort: {...modelA.sort, ...modelB.sort}
    };
  }

  public reset() {
    this._alreadySetup = false;
  }

  public resetFilter() {
    this.filteredItems = undefined;
    this.page = 1;
  }

  public clearFilter() {
    this.searchModel.filter = cloneDeep(this.defaultSearchModel.filter);
  }

  private constructFilter() {
    const searchModel = this.mergedSearchModel;
    const flt = {...searchModel.filter,
      start: (this.page - 1) * this.pageSize};
    // TODO - there is no support in backend for sort_order
    if (searchModel.sort.sort_on) {
      flt.sort_on = searchModel.sort.sort_on;
    }
    // flt.includeAggs = true;
    flt.size = this.pageSize;
    return flt;
  }

  public reload() {
    this.state.next(ListStateLoading);
    return this.setupWith(this.sourceService,
                          this.search,
                          this.order,
                          this.defaults);
  }

  private loadSearchModel(): SearchModel | undefined {
    if (this.listId === 'general') {
      return undefined;
    }
    return this.personalStorage.getItem(this.listId);
  }

  private saveSearchModel(): void {
    if (this.listId === 'general') {
      return;
    }
    this.personalStorage.setItem(this.listId, this.searchModel);
  }

  public load(): Observable<EasTable|Model[]> {
    this.saveSearchModel();
    this.state.next(ListStateLoading);
    let result$: Observable<EasTable|Model[]> =
      this.listType === 'normal' ? this.loadPage()
                                 : this.loadTable();

    result$ = result$.pipe(
      tap(() => {
        const total = this.listType === 'normal'
                      ? this.pageItems.length
                      : this._totalCount.getValue();
        this.state.next(total === 0 ? ListStateEmpty : ListStateFull);
      }),
      catchError(error => {
        const err = new Error(error);
        this.state.next({ type: 'error', 'error': err });
        throw err;
      })
    );

    return result$;
  }

  public loadTable(): Observable<EasTable> {
    const filters = this.constructFilter();
    filters.engine = this.engine;
    return this.sourceService.table(filters).pipe(
      tap(res => {
        this._table.next(res);
        this._totalCount.next(res.meta.total);
        this._currentPage.next(this.page);
      })
    );
  }

  public loadPage(): Observable<Model[]> {
    const obs: Observable<Model[]> = (this.searchType === SearchType.REMOTE)
                                     ? this._apiLoadPage()
                                     : this._dbLoadPage();

    return obs.pipe(
      tap((data: Model[]) => {
        this.pageItems = data;
        this._items.next(this.pageItems);
        this._currentPage.next(this.page);
      })
    );
  }

  public export(): Observable<any> {
    const filters = {
      ...this.constructFilter(),
      engine: this.engine,
      start: 0,
      size: 10000,
    };
    return this.sourceService.export(filters);
  }

  public startExport(tableOptions: any): Observable<any> {
    const filters = {
      ...this.constructFilter(),
      engine: this.engine,
      start: 0,
      tableOptions,
    };

    return this.sourceService.startExport(this.listId, filters);
  }

  nextPage() {
    this.page += 1;
    this.load().subscribe();
  }

  prevPage() {
    this.page -= 1;
    this.load().subscribe();
  }

  goToPage(page: number) {
    this.page = page;
    this.load().subscribe();
  }

  //
  // Private
  //

  private _apiLoadPage(): Observable<Model[]> {
    const filters = this.constructFilter();
    return this.sourceService.search(filters).pipe(
      tap(res => this._totalCount.next(res.meta.total)),
      map(res => res.data)
    );
  }

  private _dbLoadPage(): Observable<Model[]> {
    return this._dbFilteredItems().pipe(
      tap(data => this._totalCount.next(data.length)),
      map((data: any[]) => {
        return data.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
      })
    );
  }

  private _dbLoadItems() {
    if (this.allItems !== undefined) {
      return of(this.allItems);
    }

    return this.sourceService.search({}).pipe(
      tap(data => {
        this.allItems = data.data;
      }),
      map(data => data.data)
    );
  }

  private _dbFilteredItems() {
    if (this.filteredItems !== undefined) {
      return of(this.filteredItems);
    }

    // Preprocess filters

    return this._dbLoadItems().pipe(
      switchMap(data => {
        return this._dbFilterItems(data);
      }),
      switchMap(data => {
        return this._dbSortItems(data);
      }),
      tap(data => {
        this.filteredItems = data;
      }),
    );
  }

  /**
   * Update or modify search model if necessary
   *
   * Return a filter function
   */
  private _dbPreProcessFilters() {
    return this.filters.pipe(
      map(filters => {
        const model = this.mergedSearchModel.filter;
        const result = filters.filter(item => {
          return !!model[item.id];
        });
        if (result.length === 0) {
          return null;
        }

        const filterfuncs: ((item) => Observable<boolean>)[] = result.map(item => {
          return (itm: Model) => item.filter(itm, model[item.id]);
        });

        return (item) => {
          const fls = filterfuncs.map(fl => {
            try {
              return fl(item);
            } catch (err) {
              console.log('Constructing filter failed', err);
              return of(false);
            }
          });
          return forkJoin(fls)
            .pipe(
              map((res: boolean[]) => {
                return res.every(itm => itm);
              }),
              map(res => {
                return res ? item : undefined;
              }),
              catchError(() => {
                return of(undefined);
              })
            );
        };
      })
    );
  }

  private _dbFilterItems(data: any[]) {
    return this._dbPreProcessFilters().pipe(
      switchMap(joinFilter => {
        if (joinFilter === null) {
          return of(data);
        }
        return from(data).pipe(
          switchMap(item => {
            return joinFilter(item);
          }),
          catchError((err) => {
            console.log(err);
            return of(undefined);
          }),
          filter(item => {
            return item !== undefined;
          }),
          reduce((acc, val) => acc.concat(val), [])
        );
      })
    );
  }

  private _dbSortItems(data) {
    if (!this.mergedSearchModel.sort) {
      return of(data);
    }

    return this.sorts.pipe(
      map(sorts => {
        const sort = sorts.find((item) => {
          return item.id === this.mergedSearchModel.sort.sort_on;
        });

        if (sort) {
          if (this.mergedSearchModel.sort.sort_order === 'desc') {
            data.sort((a, b) => {
              return -sort.sort(a, b);
            });
          } else {
            data.sort(sort.sort);
          }
        }
        return data;
      })
    );
  }
}
