import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { Integrations } from "@sentry/tracing";
import { EventHint } from '@sentry/types';

import { CurrentUser } from '@fry/lib/auth';

export interface SentryOptions extends Sentry.BrowserOptions {
    dsn: string;
    release: string;
    environment: string;
    tracingOrigins?: string[];
}

export const SENTRY_ORGANISATION_TAG = 'organisation';
export const SENTRY_BOOKING_TAG = 'booking.id';
export const SENTRY_MODULE_TAG = 'module';

@Injectable({
    providedIn: 'root'
})
export class SentryService implements OnDestroy {
    private userSubscription?: Subscription;
    private organisationSubscription?: Subscription;

    private _hub?: Sentry.Hub;
    public get hub(): Sentry.Hub|undefined {
        return this._hub;
    }

    public get isEnabled(): boolean {
        return this.hub?.getClient()?.getOptions().enabled ?? false;
    }

    constructor() {}

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.organisationSubscription) {
            this.organisationSubscription.unsubscribe();
        }
    }

    public init(configuration: SentryOptions): Sentry.Hub {
        if (this.hub) { return this.hub; }

        if (!configuration.dsn) {
            throw new Error('Sentry: Invalid configuration!');
        }

        configuration.tracesSampleRate = configuration.tracesSampleRate ?? 0.0;
        configuration.integrations = [
          new Integrations.BrowserTracing({
            tracingOrigins: configuration.tracingOrigins ?? [],
            routingInstrumentation: Sentry.routingInstrumentation,
            //beforeNavigate: context => {
            //  return {
            //    ...context,
            //    name: location.pathname
            //      .replace(/\/[a-f0-9]{32}/g, "/<hash>")
            //      .replace(/\/\d+/g, "/<digits>"),
            //  };
            //},
          })
        ];

        Sentry.init(configuration);
        this._hub = Sentry.getCurrentHub();
        return this._hub;
    }

    public captureException(exception: any, hint?: EventHint): string|undefined {
        if (!this.hub) { return undefined; }
        return this.hub.captureException(exception, hint);
    }

    public captureMessage(message: string, level?: Sentry.SeverityLevel, hint?: EventHint): string|undefined {
        if (!this.hub) { return undefined; }
        return this.hub.captureMessage(message, level, hint);
    }

    public watchUser(source: Observable<CurrentUser>): void {
        if (!this.hub) { return; }
        if (this.userSubscription) { return; }

        this.userSubscription =
            source.subscribe(user => {
                      if (!this.hub) { return; }
                      const payload: Sentry.User = { username: user.username };
                      payload[SENTRY_ORGANISATION_TAG] = user.organisation;
                      this.hub.setUser(payload);
                      this.setOrganisation(user.organisation);
                  });
    }

    public watchOrganisation(source: Observable<string>): void {
        if (!this.hub) { return; }
        if (this.organisationSubscription) { return; }

        this.organisationSubscription =
            source.subscribe(organisation => this.setOrganisation(organisation));
    }

    private setOrganisation(value: string): void {
        if (!this.hub) { return; }
        this.hub.setTag(SENTRY_ORGANISATION_TAG, value);
    }
}
