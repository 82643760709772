import { GeneralListResolved } from '@fry/components/general/general-list/general.list.interfaces';
import { Injectable } from '@angular/core';
import { TypeInfo, TypeInfoSettings } from '@fry/lib/store';
import { TransactionsStore } from './transactions.store';
import { TransactionSearch } from './transaction.search';

@Injectable({
  providedIn: 'root'
})
export class TransactionInfo implements TypeInfo {

  type = 'transaction';
  title = $localize `Transactions`;
  settings: TypeInfoSettings = {};

  constructor(
    public store: TransactionsStore,
    public search: TransactionSearch
  ) { }

  resolvedList(): GeneralListResolved {
    return {
      store: this.store,
      search: this.search,
      defaults: { listType: 'table', engine: 'es' },
      title: this.title,
      type: this.type,
    };
  }

}
