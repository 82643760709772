
export interface FileUploadEvent {
    state: FileUploadState;
    upload: FileUpload;
}

export interface FileUploadJSON {
    location: string;
    size: number;
    key: string;
    contentType: string;
    filename: string;
    vendor: string;
}

export  type FileUploadState = 'pending' |
                               'in-progress' |
                               'successful' |
                               'failed';

export class FileUpload {

    public location: URL;
    public key: string;
    public fields: {[key:string]: string}
    public progress: number;
    public error: Error;
    public vendor: string;
    public filename: string;
    public size: number;
    public contentType: string;

    public get state(): FileUploadState { return this._state; }
    private _state: FileUploadState;

    private    _file: File;
    public get file(): File { return this._file; }

    /**
     * Creates instance of FileUpload
     *
     * @param data Underlying datatype we wrap in the FileUpload
     */
    constructor(data: File | FileUploadJSON) {
        if (data instanceof File) {
            this._file = data;
            this._state = 'pending';
            this.contentType = data.type;
            this.filename = data.name;
            this.size = data.size;
        } else {
            this._state = 'successful';
            this.contentType = data.contentType;
            this.filename = data.filename;
            this.key = data.key;
            this.size = data.size;
            this.vendor = data.vendor;

            try {
                if (data.location) {
                    this.location = new URL(data.location);
                }
            } catch (error) {
                this._state = 'failed';
                this.location = null;
                this.error = new Error(`Unable to parse FileUploadJSON, invalid Location '${data.location}'`);
            }
        }
    }

    setProgress(progress: number) {
        this.progress = progress;
        this._state = 'in-progress';
    }

    setComplete(key: string, location: URL) {
        this.key = key;
        this.location = location;
        this._state = 'successful';
    }

    setFailed(error: Error, key: string, location: URL) {
        this.key = key;
        this.location = location;
        this.error = error;
        this._state = 'failed';
    }

    public toJSON(): FileUploadJSON {
        return {
            location: this.location ? this.location.href : undefined,
            size: this.size,
            key: this.key,
            contentType: this.contentType,
            filename: this.filename,
            vendor: this.vendor,
        };
    }
}
