import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIService } from '@fry/lib/api';
import { User } from '@fry/lib/users';

/**
 * Both sync and asycn validation will return either `null` or
 * `GMCValidationError` as result of the validation. Error object should be
 * investigated to provide context for error messages.
 */
export interface GMCValidationError {
  value: string;
  firstName?: string;
  lastName?: string;
  res?: any;
}

@Injectable({
  providedIn: 'root'
})
export class GMCValidatorService {

  /**
   * GMC Format validator name
   *
   * *WARNING*
   * THIS STRING IS IN THE DABASE, SO ANY CAHNGE TO THIS MUST BE MIGRATED TO
   * THE FORM FIELD FOR PARTICULAR BOOKING ITEM IN DATABASE!
   */
  public static FormatValidatorName = 'gmcFormatValidator';

  // Key for error of format validator
  public static FormatValidatorErrorKey = 'gmcInvalidFormat';

  /**
   * GMC Format validator name
   *
   * *WARNING*
   * THIS STRING IS IN THE DABASE, SO ANY CAHNGE TO THIS MUST BE MIGRATED TO
   * THE FORM FIELD FOR PARTICULAR BOOKING ITEM IN DATABASE!
   */
  public static CorrectnessValidatorName = 'gmcCorrectnessValidator';

  // Key for error of correctness validator
  public static CorrectnessValidatorErrorKey = 'gmcInvalidNumber';

  // Correct format of GMC number
  private static GMC_FORMAT_REGEXP = new RegExp('^[0-9]{7}$');

  constructor(private api: APIService) { }

  validate(gmcNumber: string, firstName: string, lastName: string) {
    const payload = {
      'gmcNumber': gmcNumber,
      'firstName': firstName,
      'lastName': lastName
    };
    return this.api.post('/gmc/validate', payload);
  }

  formatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error: ValidationErrors = {};
      error[GMCValidatorService.FormatValidatorErrorKey] = {
        value: control.value
      };
      const result = GMCValidatorService.GMC_FORMAT_REGEXP.test(control.value)
                     ? null
                     : error;
      return result;
    };
  }

  /**
   * Verification of the GMC number
   * 
   * This async validator sends the provided GMC nubmer and the details
   * from the provided user to the backend for verification.
   * 
   * @param user User whose details will be used for GMC verification
   */
  correctnessValidator(user: User): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const error: GMCValidationError = {
        value: control.value,
        firstName: user.doc.firstName,
        lastName: user.doc.lastName
      };

      return this.validate(control.value, user.doc.firstName, user.doc.lastName).pipe(
          map(result => {
            if (result.valid) { return null; }
            error.res = result;

            const errors: ValidationErrors = {};
            errors[GMCValidatorService.CorrectnessValidatorErrorKey] = error;
            return errors;
          })
        );
    };
  }
}
