import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { EasElement } from '@fry/components/common/easform/easform.interfaces';

export enum WidgetType {
  string = 'string',
  select = 'select'
}

export enum FieldType {
  FIELD = 'field',
  GROUP = 'group',
  ARRAY = 'array',
}

export interface FormOption {
  id: string;
  name: string;
}

export interface FormWidget {
  type: WidgetType | string;
  autocomplete?: string;
  options?: FormOption[];
}
export interface FormFieldGroup {
  id: string;
  type: 'group' | 'array';
  fields: FormField[];
}

export interface FormField {
  id: string;
  type?: FieldType;
  title?: string;
  initial?: any;
  readOnly?: boolean;
  noFormControl?: boolean;
  validators?: ValidatorFn[];
  asyncValidators?: AsyncValidatorFn[];
  widget?: FormWidget;
  options?: any;
  getTitle?: (value) => string;
  fields?: FormField[];
  fields_from?: 'parent';
  showCondition?: (value: any, parent: any, doc: any) => boolean;
  hidden?: boolean;
  meta?: any;
  templateRefId?: string;
}

export interface FormDataOptions {
  submitTitle?: string;
}

export interface ObservableFBField {
  id: string;
  type: string;
  fieldOptions?: any;
  title?: string;
  fields?: FBFieldList | Observable<FBFieldList>;
  fields_from?: 'parent';
  initial?: any;
  showCondition?: (value: any, parent: any, doc: any) => boolean;
  options?: any;
  templateRefId?: string;
}

export type FBFieldList = (ObservableFBField | Observable<ObservableFBField>)[];

export interface FBField {
  id: string;
  type: string;
  fieldOptions?: any;
  title?: string;
  fields?: FBField[];
  fields_from?: 'parent';
  initial?: any;
  options?: any;
  showCondition?: (value: any, parent: any, doc: any) => boolean;
  templateRefId?: string;
}

export interface FBForm {
  fields: FBFieldList;
}

export interface FBFormOptions {
  readOnly?: boolean;
}

export interface FBFieldEvaluator {
  fieldType: string;
  provides: {id: string; name: string}[];
  getForm(fieldType?: string): Observable<EasElement[]>;
  buildField(field: any, options?: any): Observable<EasElement>;
  evalField(field: ObservableFBField, formOptions: FBFormOptions): Observable<FormField>;
}
