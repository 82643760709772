import { Observable } from 'rxjs';

// export interface

export interface TransitionGuard {
    readonly code: string;
    readonly title?: string;
}

export interface DependentTransitionGuard extends TransitionGuard {
    evaluateData(data: any, user: any, guardData: any): Observable<boolean>;
}

export interface IndependentTransitionGuard extends TransitionGuard {
    evaluate(model: any, user: any, guardData: any): Observable<boolean>;
}

export function isDependentGuard(arg: any): arg is DependentTransitionGuard {
    return arg.evaluateData !== undefined;
}

export function isIndependentGuard(arg: any): arg is IndependentTransitionGuard {
    return arg.evaluate !== undefined;
}
