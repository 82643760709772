import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { LoggerService } from '@fry/lib/utils';
import { ConfigService } from '@fry/lib/config';
import { AuthOidcService } from '@fry/lib/auth/oidc/auth.oidc.service';

export interface ClusterOrganisation {
  id: string;
  name: string;
  urls: {
    api: string;
    lapi: string;
    ldb: string;
  };
  allowedProducts: string[];
  availableLanguages: {
    language_name: string;
    language_code: string;
    locale_name: string;
  }[];
}

export interface ClusterOrganisationResponse {
  orgs: ClusterOrganisation[],
  clusters: {
    id: string;
    status: string;
    reason?: string;
  },
  ok: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ClusterService {

  private _current = new BehaviorSubject<ClusterOrganisation | undefined>(undefined);

  private profileOrganisations: ClusterOrganisation[] = [];
  private currentUsername: string | undefined;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private logger: LoggerService,
    private oidc: AuthOidcService,
  ) {
  }

  private getProfileOrganisations(): Observable<ClusterOrganisationResponse> {
    const url = new URL('my_organisations', this.config.server.urls.org).href;
    const headers : any = {};
    if (this.oidc.isLoggedIn()) {
      headers['Authorization'] = `Bearer ${this.oidc.getToken()}`;
    }
    return this.http.get<ClusterOrganisationResponse>(url, { headers: new HttpHeaders(headers)});
  }

  private loadProfileOrganisations(): Observable<ClusterOrganisation[]> {
    const key = `${this.currentUsername}_profile_organisations`;
    return this.getProfileOrganisations().pipe(
      map(data => {
        localStorage.setItem(key, JSON.stringify(data));
        return data.orgs;
      }),
      catchError((err) => {
        this.logger.warn('Failed fetching organisations, using cache...', err);
        let orgs;
        try {
          const data = localStorage.getItem(key);
          orgs = data !== null ? JSON.parse(data).orgs : [];
        } catch (err) {
          this.logger.warn('Could not parse cached organisations', err);
          orgs = [];
        }
        return of(orgs);
      }),
      tap(orgs => {
        this.profileOrganisations = orgs.filter(org => (org.allowedProducts || []).includes('eas'));
      })
    );
  }

  public getClusterOrganisation(orgId: string) {
    let org: ClusterOrganisation | undefined;
    if (!orgId || orgId === 'unknown') {
      org = undefined;
    } else {
      org = this.profileOrganisations.find(item => item.id === orgId);
      if (!org) {
        throw new Error('This organisation is not loaded');
      }
    }
    return org;
  }

  public setClusterOrganisation(orgId: string) {
    const org = this.getClusterOrganisation(orgId);
    this._current.next(org);
  }

  public setCurrentUser(username: string) {
    this.currentUsername = username;
  }

  getCurrentOrganisation() {
    return this._current.value;
  }

  getCurrentOrganisations() {
    return this.profileOrganisations;
  }

  init() {
    console.log('ClusterService: Initialising cluster - loading organisations');
    return this.loadProfileOrganisations().toPromise();
  }
}
