import { ElementRef } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { APIService } from '@fry/lib/api';
import { ScriptLoaderService } from '@fry/lib/utils';
import { PaymentsErrorIntegrationNotReady, Transaction, TransactionVendorReference } from '@fry/payments/lib';
import { TransactionCardFormComponent } from '@fry/payments/components';

import { IntegrationConfiguration, PaymentGatewayVendor, StripeConnectMode } from './integrations.interfaces';
import { PaymentGatewayIntegration } from './integration';

export interface StripeElementClasses {
  base?: string;
  complete?: string;
  empty?: string;
  focus?: string;
  invalid?: string;
  webkitAutofill?: string;
}

export interface ElementOptions {
  classes?: StripeElementClasses;
}

export class StripeIntegrationService implements PaymentGatewayIntegration {

  public readonly vendor: PaymentGatewayVendor = PaymentGatewayVendor.stripe;

  public get isStripe(): boolean { return true; }
  public get isMoneris(): boolean { return false; }

  public get isInitialized(): boolean {
    return this._configuration !== undefined &&
           this._stripe !== undefined &&
           this._elements !== undefined;
  }

  private _configuration?: IntegrationConfiguration;
  public get configuration(): IntegrationConfiguration|undefined {
    return this._configuration;
  }

  private _element?: stripe.elements.Element;
  private _requestButton?: stripe.elements.Element;
  private _stripe?: stripe.Stripe;
  private _elements?: stripe.elements.Elements;

  constructor(private scriptLoader: ScriptLoaderService, private apiService: APIService) {
  }

  public initialize(): Observable<IntegrationConfiguration> {
    // TODO: This could be implemented as typequard and then it would make this type
    // safe without addeing the |undefined to the type.
    if (this.isInitialized && this.configuration) { return of(this.configuration); }

    // Fetch configuration, use it to load vendor scripts,
    // configure vendor integration.
    return this.fetchVendorConfiguration().pipe(
      tap(configuration => {
        this._configuration = configuration;
      }),
      switchMap(configuration => {
        return this.scriptLoader.load(configuration.script);
      }),
      map(_response => {
        if (!this.configuration) { throw new PaymentsErrorIntegrationNotReady(); }

        const options: stripe.StripeOptions = {};
        // FIXME: This is the only place we use the 'mode' attribute from the backend, this almost seem
        //        we could just remove the 'mode' attribute and add the stripe account to options based on
        //        the actual attribute of the AccountID?
        if (this.configuration.stripeConnectMode === StripeConnectMode.direct) {
          options.stripeAccount = this.configuration.stripeConnectConnectedAccountID;
        }

        this._stripe = Stripe(this.configuration.api_key, options);
        this._elements = this._stripe.elements();

        if (!this.configuration) { throw new Error(); }
        return this.configuration;
      }),
      catchError(error => {
        this._configuration = undefined;
        this._stripe = undefined;
        this._elements = undefined;
        return throwError(error);
      })
    );
  }

  public fetchVendorConfiguration(): Observable<IntegrationConfiguration> {
    if (this.configuration) { return of(this.configuration); }
    return this.apiService.get(`${this.vendor}/`) as Observable<IntegrationConfiguration>;
  }

  // Not used for Stripe
  public prepareVendorInteraction(_transaction: Transaction): Observable<Record<string, any>> {
    return of({})
  }

  public startVendorInteraction(_transaction: Transaction, options: stripe.BillingDetails): Observable<TransactionVendorReference|undefined> {
    if (!this._stripe || !this._element || !this.configuration) {
      throw new PaymentsErrorIntegrationNotReady();
    }

    // TODO: Better conversion to Observable, error handling
    const promise = this._stripe
                        .createPaymentMethod('card', this._element, { billing_details: options })
                        .then((response: stripe.PaymentMethodResponse) => {
                          return new Promise<TransactionVendorReference>((resolve, reject) => {
                            if (!response.paymentMethod) { return reject(response.error); }
                            if (!this.configuration) { throw new PaymentsErrorIntegrationNotReady(); }

                            const reference: TransactionVendorReference = {
                              vendor: this.configuration.vendor,
                              object: response.paymentMethod.object,
                              id: response.paymentMethod.id
                            };
                            resolve(reference);
                          });
                        });
    return from(promise);
  }

  public mountUIElement(elementRef: ElementRef,
                        elementOptions: stripe.elements.ElementsOptions,
                        elementChanges: (event: any) => void): boolean {
    if (!this.isInitialized || !this._elements) { return false; }

    const card = this._elements.create('card', elementOptions);
    card.mount(elementRef.nativeElement);
    card.on('change', elementChanges);
    this._element = card;
    return true;
  }

  public unmountUIElement() {
    if (this._element) {
      this._element.destroy();
    }
    if (this._requestButton) {
      this._requestButton.destroy();
    }
  }

  public handleUIElementChanges(event: any, component: TransactionCardFormComponent) {
    const control = component.form.get('informationCaptured');
    if (!control) { throw new Error(); }

    if (event.error) {
      control.setValue('');
      component.error.emit(new Error(event.error.message));
    } else if (event.complete) {
      control.setValue('1');
      component.error.emit(undefined);
    } else {
      control.setValue('');
    }
  }

  public handleVerificationChallenge(challenge: Record<string, any>): Promise<TransactionVendorReference> {
    if (!this._stripe || !this._element || !this.configuration) {
      throw new PaymentsErrorIntegrationNotReady();
    }

    const secret = challenge['payment_intent_client_secret'];
    return this._stripe.handleCardAction(secret).then((response: stripe.PaymentIntentResponse) => {
      return new Promise<TransactionVendorReference>((resolve, reject) => {
        if (!response.paymentIntent) { return reject(response.error); }
        if (!this.configuration) { throw new PaymentsErrorIntegrationNotReady(); }

        const reference: TransactionVendorReference = {
          vendor: this.configuration.vendor,
          object: response.paymentIntent.object,
          id: response.paymentIntent.id
        };
        resolve(reference);
      });
    });
  }

  /*
  public handlePaymentRequestButton(paymentInfo: any,
                                    elementRef: ElementRef,
                                    elementChanges: (event: stripe.paymentRequest.StripePaymentMethodPaymentResponse) => void) {
    const paymentRequest = this._stripe.paymentRequest({
      country: 'GB',
      currency: paymentInfo.currency,
      total: {
        label: paymentInfo.label,
        amount: paymentInfo.amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    let prButton = this._elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest,
    });
    this._requestButton = prButton;

    // Check the availability of the Payment Request API first.
    return paymentRequest.canMakePayment().then(result => {
      if (result) {
        this._requestButton?.mount(elementRef.nativeElement);
        paymentRequest.on('paymentmethod', elementChanges);
      } else {
        console.log('No support');
      }
    });
  }
  */
}
