import { ITreeOptions } from '@circlon/angular-tree-component';
import * as FryUtilsCommon from '@fry/lib/utils/common';

export interface TreeFormControlNode {
    _id: string;
    name: string;
    categories: TreeFormControlNode[];
}

export interface TreeFormControlOptions extends ITreeOptions {
    allowsHierarchy: boolean;
}

export const TREE_DEFAULT_OPTIONS: ITreeOptions = {
    childrenField: 'categories',
    displayField: 'name',
    idField: '_id'
};

export class TreeFormControl {
    public static emptyNode(): TreeFormControlNode {
        return { _id: FryUtilsCommon.guid(), name: '', categories: [] };
    }
}
